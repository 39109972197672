import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import { ContainerParams } from 'containers'
import { createContainerApiData } from '../../api/data/container'
import containerService from '../../api/services/container'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import { createResponseData } from '../../api/data'
import useNavUtils from '../useNavUtils'
export const useCreateContainer = () => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Container)
    const { navigateContainer } = useNavUtils()

    return useMutation(
        (data: ContainerParams) => containerService.create(createContainerApiData(data)),
        {
            mutationKey: createKey,
            onSuccess: ({ data }) => {
                const { id } = createResponseData(data)
                setSuccessMsg(t('created'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateContainer(id)('GENERAL_INFORMATION')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
