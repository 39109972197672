import { StyledGeneralInfo } from '../../../../../../theme/styles'
import { Box } from '@mui/material'
import DataItem from '../../../../../../components/DataItem'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import Loader from '../../../../../../components/Loader'
import DataGroup from '../../../../../../components/DataGroup'
import { useTranslation } from 'react-i18next'
import { Option } from 'forms'
import { useGetApartment } from '../../../../../../hooks/apartment/useGetApartment'
import useParamManage from '../../../../../../hooks/useParamManage'
import { ROOMS_TAXONOMY } from '../../../../../../constant/taxonomy'

const GeneralInfoApartment = () => {
    const { data: attributes } = useGetAttributes()
    const { apartmentID } = useParamManage()
    const { t } = useTranslation()

    const { data: apartment, isLoading } = useGetApartment(apartmentID, {
        queryKey: [apartmentID],
        enabled: Boolean(apartmentID),
    })

    if (isLoading) return <Loader absoluteCenter />
    return (
        <>
            <StyledGeneralInfo>
                <Box flexBasis={'50%'}>
                    <DataGroup label={t(`${'general_information'}`)}>
                        <DataItem label={t('apartment.title')}>{apartment?.title}</DataItem>
                        <DataItem label={t('apartment.floor')}>{apartment?.floor}</DataItem>
                        <DataItem label={t('apartment.space')}>
                            {apartment?.space} m<sup>2</sup>
                        </DataItem>
                        <DataItem label={t('ID')}>
                            {apartment?.optionalID || apartment?.id}
                        </DataItem>
                        <DataItem label={t('created')}>{apartment?.created}</DataItem>
                    </DataGroup>

                    {!!apartment?.attributes?.length && (
                        <Box mb={5}>
                            <DataGroup label={t(`${'attributes'}`)} collapsable>
                                {apartment?.attributes?.map(({ id, value }: Option) => (
                                    <DataItem
                                        key={id}
                                        label={t(
                                            attributes?.find((attr) => attr.value === id)?.id || ''
                                        )}
                                        variant="data"
                                    >
                                        {value}
                                    </DataItem>
                                ))}
                            </DataGroup>
                        </Box>
                    )}
                    {!!apartment?.rooms?.length && (
                        <Box mb={5}>
                            <DataGroup label={t(`${'rooms.title'}`)} collapsable>
                                {apartment?.rooms?.map(({ id, value }: Option) => (
                                    <DataItem
                                        key={id}
                                        label={t(
                                            ROOMS_TAXONOMY?.find((room) => room.value === id)?.id ||
                                                ''
                                        )}
                                        variant="data"
                                    >
                                        {value} m<sup>2</sup>
                                    </DataItem>
                                ))}
                            </DataGroup>
                        </Box>
                    )}
                </Box>
            </StyledGeneralInfo>
        </>
    )
}

export default GeneralInfoApartment
