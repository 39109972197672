import {
    ContainerList,
    ContainerResponseData,
    ContainerResponseList,
    ContainerParams,
    ContainerData,
    ContainerRequestData,
} from 'containers'
import { MainDataTypes } from '../../types'
import { createListDateFormat } from '.'

export const createContainerApiData = ({
    title,
    zipCode,
    city,
    idOptional,
}: ContainerParams): ContainerRequestData => {
    return {
        title,
        field_account_entity_postalcode: {
            und: [
                {
                    value: zipCode,
                },
            ],
        },
        field_account_entity_city: {
            und: [
                {
                    value: city,
                },
            ],
        },
        field_account_entity_id: {
            und: [
                {
                    value: idOptional,
                },
            ],
        },
    }
}

export const createContainerData = ({
    field_account_entity_city,
    field_account_entity_id,
    field_account_entity_postalcode,
    field_account_entity_geofield,
    nid,
    title,
    created,
    changed,
}: ContainerResponseData): ContainerData => {
    return {
        id: nid,
        nid,
        title,
        city: field_account_entity_city.und?.[0].value,
        zipCode: field_account_entity_postalcode.und?.[0].value,
        idOptional: field_account_entity_id.und?.[0].value,
        lat: Number(field_account_entity_geofield.und?.[0].lat),
        lng: Number(field_account_entity_geofield.und?.[0].lon),
        created: createListDateFormat(Number(created)),
        changed: createListDateFormat(Number(changed)),
        dataType: MainDataTypes.Container,
    }
}

export const createContainerList = (data: ContainerResponseList[]) => {
    return data.map(
        ({
            city,
            zip_code,
            nid,
            id,
            lat,
            lon,
            title,
            count_properties,
            created,
            changed,
        }: ContainerResponseList): ContainerList => {
            return {
                id: nid,
                idOptional: id,
                childrenCount: Number(count_properties),
                nid,
                title,
                city,
                zipCode: zip_code,
                lat: Number(lat),
                lng: Number(lon),
                created: createListDateFormat(Number(created)),
                changed: createListDateFormat(Number(changed)),
                dataType: MainDataTypes.Container,
            }
        }
    )
}
