import { useRef } from 'react'
import { useEffectOnce } from 'usehooks-ts'
import { getLocalStorage } from '../../utils/storage'
import axiosInstance from '../axios'
import { TOKEN_KEY } from '../../constant/const'

const RequestInterceptor = () => {
    const interceptorId = useRef<number | null>(null)

    useEffectOnce(() => {
        axiosInstance.interceptors.request.use(
            async (config: any) => {
                let accessToken = getLocalStorage(TOKEN_KEY)

                if (!accessToken) return config

                config.headers = {
                    ...config.headers,
                    'X-CSRF-Token': `${accessToken}`,
                }

                return config
            },
            (error: any) => {
                Promise.reject(error)
            }
        )

        return () => {
            axiosInstance.interceptors.response.eject(interceptorId.current as number)
        }
    })

    return null
}

export default RequestInterceptor
