import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { useGetObjects } from '../../../../../../hooks/object/useGetObjects'
import theme from '../../../../../../theme'
import OverviewObjectList from '../../../../../../modules/OverviewObjectList'
import LocalNavigation from '../../../../../../components/LocalNavigation'
import useParamManage from '../../../../../../hooks/useParamManage'

const OverviewContainer = () => {
    const { containerID } = useParamManage()

    const { data: objects, isLoading: isLoadingObjects } = useGetObjects(containerID, {
        queryKey: [containerID],
    })

    return (
        <Box>
            <Box
                display={'flex'}
                justifyContent="space-between"
                flexDirection={'row'}
                alignItems="center"
                mb={3}
            >
                <Typography color={theme.palette.secondary.main} fontWeight={600}>
                    {t('object.all_objects')}
                </Typography>
                <LocalNavigation available={['list', 'grid', 'map']} />
            </Box>
            <OverviewObjectList data={objects || []} isLoading={isLoadingObjects} />
        </Box>
    )
}

export default OverviewContainer
