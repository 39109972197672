import { useJsApiLoader } from '@react-google-maps/api'
import React, { PropsWithChildren, useContext } from 'react'

export const useGmap = () => {
    return useContext(GmapContext)
}

export const GmapContext = React.createContext<{
    isGmapLoaded: boolean
}>({
    isGmapLoaded: false,
})

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''
const libs = ['geometry', 'places'] as any[]
export const GmapProvider = ({ children }: PropsWithChildren) => {
    const map = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        language: 'EN',
        libraries: libs,
        version: 'beta',
    })
    console.log('init map')

    return (
        <GmapContext.Provider value={{ isGmapLoaded: map?.isLoaded }}>
            {children}
        </GmapContext.Provider>
    )
}
