import React, { FC, PropsWithChildren } from 'react'
import { StyledHighlighted } from './styles'
import useSearchStore from '../../store/search'

interface Props {
    searchValue?: string
}

const HighlightMatch: FC<PropsWithChildren<Props>> = ({ searchValue, children }) => {
    const { inputValue } = useSearchStore()

    const escapeRegExp = (text: string): string => {
        const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
        const reHasRegExpChar = RegExp(reRegExpChar.source)
        return text && reHasRegExpChar.test(text) ? text.replace(reRegExpChar, '\\$&') : text || ''
    }

    const highlightText = (value: string, searchText: string): string => {
        if (searchText && searchText.length > 1) {
            const searchRegex = new RegExp(`(${escapeRegExp(searchText)})`, 'ig')
            return value?.toString()?.replace(searchRegex, '<span class="match">$1</span>')
        } else {
            return value
        }
    }

    return (
        <StyledHighlighted
            dangerouslySetInnerHTML={{
                __html: highlightText(children as string, searchValue || inputValue),
            }}
        />
    )
}

export default HighlightMatch
