import { AxiosError } from 'axios'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { Navigate, useLocation } from 'react-router'
import authService from '../../api/services/auth'
import { PATHS } from '../../routes/routes'
import { removeLocalStorage } from '../../utils/storage'
import { useNavigate } from 'react-router'
import { stripObj } from '../../utils/helpers'
import { useSnackbar } from '../../hooks/useSnackbar'
import useUserStore from '../../store/user'
import { TOKEN_KEY } from '../../constant/const'

const SignOut = () => {
    let location = useLocation()
    const navigate = useNavigate()
    const { unmountUser } = useUserStore()
    const { setErrorMsg } = useSnackbar()

    const { mutate: signOutMutate } = useMutation(() => authService.signOut(), {
        mutationKey: 'sign-out',
        onSuccess: () => {
            removeLocalStorage(TOKEN_KEY)
            unmountUser()
            navigate(PATHS.SIGNIN)
        },
        onError: (error: AxiosError) => {
            setErrorMsg(stripObj(error?.response?.data))
        },
    })

    useEffect(() => {
        signOutMutate()
    }, [signOutMutate])

    return <Navigate to={PATHS.SIGNIN} state={{ from: location }} replace />
}

export default SignOut
