import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import FormMaintenance from '../../../../../../forms/FormMaintenance'
import { useGetMaintenance } from '../../../../../../hooks/maintenance/useGetMaintenance'
import useParamManage from '../../../../../../hooks/useParamManage'
import useGetMepTypes from '../../../../../../hooks/other/useGetMepTypes'
import { useUpdateMaintenance } from '../../../../../../hooks/maintenance/useUpdateMaintenance'

const UpdateMaintenance = () => {
    const { t } = useTranslation()
    const { data: types } = useGetMepTypes()
    const { navigateMaintenance } = useNavUtils()
    const { maintenanceID } = useParamManage()

    const { data: maintenance, isLoading: isLoadingGet } = useGetMaintenance(maintenanceID, {
        queryKey: [maintenanceID],
    })

    const { mutate: updateMaintenance, isLoading: isLoadingUpdate } =
        useUpdateMaintenance(maintenanceID)

    const handleGoBack = () => {
        if (maintenanceID) return navigateMaintenance(maintenanceID)('LIST')
        return navigateMaintenance('')('LIST')
    }

    return (
        <Modal title={t('maintenance.edit')} onClose={handleGoBack}>
            <LoaderWrapper isLoading={Boolean(isLoadingGet || !maintenance)} center>
                <FormMaintenance
                    types={types}
                    onSubmit={updateMaintenance}
                    maintenance={maintenance}
                    isLoading={isLoadingUpdate}
                    onCancelClick={handleGoBack}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateMaintenance
