import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../../modules/Header'
import MainNavigation from '../../modules/MainNavigation'
import { StyledDashboard, StyledDashboardContainer, StyledDashboardLink } from './styles'
import { useEffectOnce } from 'usehooks-ts'
import { useQueryClient } from 'react-query'
import { useGetUser } from '../../hooks/auth/useGetUser'
import useUserStore from '../../store/user'
import Loader from '../../components/Loader'
import { useLanguage } from '../../hooks/useLanguage'
import { PATHS } from '../../routes/routes'
import HeaderSub from '../../modules/HeaderSub'
import { useGmap } from '../../context/gmap'
import Divider from '../../components/Divider'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

const Dashboard = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { isGmapLoaded } = useGmap()
    const { pathname } = useLocation()
    const { t } = useTranslation()

    const { userID } = useUserStore()
    const { data: user, isLoading, isError } = useGetUser(userID, {})
    const { changeLangLocally } = useLanguage()

    useEffectOnce(() => () => queryClient.clear())
    useEffect(
        () => {
            changeLangLocally(user?.lang)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.lang]
    )

    const isLayoutVisible = !pathname.includes('delete')

    if (isError) navigate(PATHS.SIGNOUT)

    if (isLoading || !isGmapLoaded) return <Loader absoluteCenter />

    const PUBLIC_PAGES = [
        { name: 'privacy_policy', route: PATHS.PRIVACY_POLICY },
        { name: 'terms', route: PATHS.TERMS },
        { name: 'company_details', route: PATHS.COMPANY_DETAILS },
    ] as const

    return (
        <StyledDashboard>
            {isLayoutVisible && (
                <>
                    <Header />
                    <HeaderSub />
                </>
            )}

            <MainNavigation />

            <StyledDashboardContainer fullHeight={!isLayoutVisible}>
                <Outlet />

                {isLayoutVisible && (
                    <Box mt={'auto'}>
                        <Divider />
                        {PUBLIC_PAGES.map(({ name, route }) => (
                            <StyledDashboardLink key={route} to={route}>
                                {t(name)}
                            </StyledDashboardLink>
                        ))}
                    </Box>
                )}
            </StyledDashboardContainer>
        </StyledDashboard>
    )
}

export default Dashboard
