import { QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { NewPasswordParams } from 'auth'
import { AxiosError } from 'axios'
import { useSnackbar } from '../useSnackbar'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../store/user'
import { PATHS } from '../../routes/routes'
import { stripObj } from '../../utils/helpers'
import { t } from 'i18next'
export const useUpdatePassword = (passChangeToken: string) => {
    const navigate = useNavigate()
    const { setNewPasswordKey } = useQueryKeys(QueryDataKey.Auth)
    const { setErrorMsg, setSuccessMsg } = useSnackbar()
    const { userID } = useUserStore()

    return useMutation(
        (params: NewPasswordParams) => {
            return authService.setNewPassword(
                userID,
                {
                    pass: {
                        pass1: params.pass,
                        pass2: params.repass,
                    },
                },
                passChangeToken
            )
        },
        {
            mutationKey: setNewPasswordKey,
            onSuccess: () => {
                setSuccessMsg(t('new_password_page.success_msg'))
                navigate(PATHS.SIGNOUT)
            },
            onError: (error: AxiosError) => {
                setErrorMsg(stripObj(error?.response?.data))
            },
        }
    )
}
