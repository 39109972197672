import axiosInstance from '../axios'

const companySettingsService = {
    get: () => {
        return axiosInstance.get('/cp_settings')
    },
    update: (params: any) => {
        return axiosInstance.post('/cp_settings', params)
    },
}

export default companySettingsService
