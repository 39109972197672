import { Box } from '@mui/system'
import React, { FC } from 'react'
import theme from '../../theme'

interface Props {
    marginSize?: number
    noGutterBottom?: boolean
}

const Divider: FC<Props> = ({ marginSize, noGutterBottom }) => {
    return (
        <Box
            sx={{
                height: '1px',
                borderBottom: `1px solid ${theme.palette.grey['200']}`,
                width: '100%',
                margin: `${theme.spacing(Number(marginSize || 2))} 0`,
                ...(marginSize === 0 && { margin: 0 }),
                ...(noGutterBottom && { marginBottom: 0 }),
            }}
        />
    )
}

export default Divider
