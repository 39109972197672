import { styled, css } from '@mui/system'

export const StyledSubmitButtonsWrapper = styled('div')`
    ${({ theme }) => css`
        border-top: 1px solid ${theme.palette.grey['200']};
        display: flex;
        justify-content: flex-end;
        position: fixed;
        width: calc(100% - 60px);
        bottom: 0;
        left: 0;
        margin: 0 ${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(6)};
        background-color: ${theme.palette.grey['50']};
        z-index: 10;
    `}
`
