import * as yup from 'yup'

const schema = yup
    .object({
        currentPass: yup
            .string()
            .required('form_error.required')
            .min(2, 'form_error.field_too_short')
            .max(48, 'form_error.field_too_long'),
        pass: yup
            .string()
            .required('form_error.required')
            .min(4, 'form_error.field_too_short')
            .max(48, 'form_error.field_too_long')
            .when(['currentPass'], (currentPass, schema) => {
                return schema.notOneOf([currentPass], 'form_error.new_pass')
            }),
        confirmPass: yup
            .string()
            .required('form_error.required')
            .min(4, 'form_error.field_too_short')
            .max(48, 'form_error.field_too_long')
            .oneOf([yup.ref('pass'), null], 'form_error.new_pass_confirm'),
    })
    .required()

export default schema
