import { styled, css } from '@mui/system'

export const StyledThreeDScanItem = styled('div')`
    ${({ theme }) => css`
        height: 500px;
        background-color: ${theme.palette.grey['300']};
        border-radius: ${theme.spacing(1)};
        width: 100%;
        position: relative;
    `}
`
