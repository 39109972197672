import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useUserStore from '../../../../store/user'
import Modal from '../../../../components/Modal'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { useGetUser } from '../../../../hooks/auth/useGetUser'
import { CONTAINER_PATHS, PATHS } from '../../../../routes/routes'
import DataGroup from '../../../../components/DataGroup'
import DataItem from '../../../../components/DataItem'
import ArticleIcon from '@mui/icons-material/Article'
import Tabs from '../../../../components/Tabs'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import { AccountTypes } from '../../../../types/constant'

const UserSettings = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { pathname } = location
    const { userID } = useUserStore()
    const { data: user, isLoading } = useGetUser(userID, {})

    const TABS = [
        {
            id: 'personal_informations',
            path: PATHS.USER_SETTINGS_PERSONAL_INFORMATIONS,
        },
        {
            id: 'change_password',
            path: PATHS.USER_SETTINGS_CHANGE_PW,
        },
    ] as const

    const handleClose = () => navigate(CONTAINER_PATHS.OVERVIEW_ALL)
    const isParentPage = Boolean(matchPath(pathname, PATHS.USER_SETTINGS))

    return (
        <Modal
            title={t('user_information')}
            open={true}
            onClose={handleClose}
            headerElement={
                isParentPage ? (
                    <IconButton
                        color="primary_filled"
                        size="large"
                        onClick={() => {
                            navigate(PATHS.USER_SETTINGS_PERSONAL_INFORMATIONS)
                        }}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                ) : (
                    <IconButton
                        color="primary_filled"
                        size="large"
                        onClick={() => {
                            navigate(PATHS.USER_SETTINGS)
                        }}
                    >
                        <ArticleIcon fontSize="small" />
                    </IconButton>
                )
            }
        >
            <>
                {!isParentPage && (
                    <Tabs value={pathname} sx={{ mb: 5 }}>
                        {({ StyledTab, StyledNavLink }) =>
                            TABS.map(({ path, id }) => (
                                <StyledTab
                                    key={path}
                                    value={path}
                                    label={
                                        <StyledNavLink to={path}>
                                            <Typography>{t(id)}</Typography>
                                        </StyledNavLink>
                                    }
                                    onClick={() => navigate(path)}
                                />
                            ))
                        }
                    </Tabs>
                )}

                {isParentPage && (
                    <LoaderWrapper isLoading={isLoading}>
                        <Box display={'flex'} flexWrap={'wrap'}>
                            <Box flexBasis={'50%'}>
                                <DataGroup label={t(`${'general_information'}`)}>
                                    <DataItem label={t('username')}>{user?.username}</DataItem>
                                    <DataItem label={t('email')}>{user?.email}</DataItem>
                                    <DataItem label={t('account_type')}>{user?.type}</DataItem>
                                    <DataItem label={t('company')}>{user?.company}</DataItem>
                                    <DataItem label={t('company_name')}>
                                        {user?.companyName}
                                    </DataItem>
                                    <DataItem label={t('street')}>{user?.street}</DataItem>
                                    <DataItem label={t('zip_code')}>{user?.zipCode}</DataItem>
                                    <DataItem
                                        label={t('city')}
                                        noBorder={user?.type === AccountTypes.Contractor}
                                    >
                                        {user?.city}
                                    </DataItem>
                                    {user?.type === AccountTypes.Contractor ? (
                                        <>
                                            <DataItem label={t('contractor_sector')} noBorder>
                                                {user?.contractorSector}
                                            </DataItem>
                                            <DataItem label={t('contractor_trade')} noBorder>
                                                {user?.contractorTrade}
                                            </DataItem>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </DataGroup>
                            </Box>
                        </Box>
                    </LoaderWrapper>
                )}
                <Outlet />
            </>
        </Modal>
    )
}

export default UserSettings
