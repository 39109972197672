import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import useParamManage from '../../../../../../hooks/useParamManage'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import { useCreateUnit } from '../../../../../../hooks/unit/useCreateUnit'
import FormUnit from '../../../../../../forms/FormUnit'
import { useGetObject } from '../../../../../../hooks/object/useGetObject'

const CreateUnit = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { objectID } = useParamManage()
    const { data: types = [], isLoading: isLoadingTypes } = useGetTypes()
    const { data: attributes = [], isLoading: isLoadingAttr } = useGetAttributes()
    const { mutate: createUnit, isLoading: isLoadingCreate } = useCreateUnit()
    const { data: object } = useGetObject(objectID, {})

    return (
        <Modal title={t('object.new_object')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoadingTypes || isLoadingAttr}>
                <FormUnit
                    types={types}
                    object={object}
                    onCancelClick={goBack}
                    attributes={attributes}
                    isLoading={isLoadingCreate}
                    onSubmit={createUnit}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default CreateUnit
