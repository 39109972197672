import React, { useRef } from 'react'
import { StyledPlaceResults, StyledResultsHeading } from './styles'
import { t } from 'i18next'
import useSearchStore from '../../../../store/search'
import { useEffectOnce } from 'usehooks-ts'
import { Marker } from '@react-google-maps/api'
import { Box } from '@mui/material'
import { Cluster } from '@react-google-maps/marker-clusterer'

interface Props {
    children: JSX.Element[]
    markers?: Marker[]
    clusters?: Cluster[]

    onItemMouseEnter: (id: string) => void
    onItemMouseLeave: (id: string) => void
    onItemClick: (id: string) => void
}

const MapResultList = ({ children, onItemMouseEnter, onItemMouseLeave, onItemClick }: Props) => {
    const { inputValue } = useSearchStore()
    const listRef = useRef<HTMLDivElement>(null)
    const LIMIT_HEIGHT = 600

    useEffectOnce(() => {
        const setListScroll = (element: HTMLDivElement): void => {
            const height = element.clientHeight
            if (height > LIMIT_HEIGHT) {
                element.style.height = `${LIMIT_HEIGHT}px`
                element.style.overflowY = 'scroll'
            }
        }

        listRef.current && setListScroll(listRef.current)
    })

    const childrenData = children?.filter((c: JSX.Element | boolean) => !!c)

    const renderChilds = () => {
        return React.Children.map(childrenData, (child) => {
            return (
                <Box
                    width={'100%'}
                    display={'flex'}
                    onMouseEnter={() => onItemMouseEnter(child.props.id)}
                    onMouseLeave={() => onItemMouseLeave(child.props.id)}
                >
                    <child.type
                        key={child.key}
                        {...child.props}
                        onClick={() => onItemClick(child.props.id)}
                    />
                </Box>
            )
        })
    }

    return (
        <StyledPlaceResults ref={listRef}>
            <StyledResultsHeading>
                {t('results_for')}: <strong>{inputValue}</strong>
            </StyledResultsHeading>

            {renderChilds()}
        </StyledPlaceResults>
    )
}

export default MapResultList
