import { Box } from '@mui/system'
import { InfoWindow, Marker, MarkerClustererF, useGoogleMap } from '@react-google-maps/api'
import { MapLocation } from '../../../../types'
import React, { useEffect } from 'react'
import useGmapUtils from '../../../../hooks/useGmapUtils'
import { MapTooltipWrapper } from '../MapTooltip'
import { t } from 'i18next'
import MapResultList from '../MapResultList'
import useParamManage from '../../../../hooks/useParamManage'

interface Props {
    data: MapLocation[]
    renderTooltip?: (fieldValues: string[]) => JSX.Element | JSX.Element[]
    renderResults?: (ids: string[]) => JSX.Element[]
}

const MapObject = ({ data, renderTooltip, renderResults }: Props) => {
    const map = useGoogleMap()
    const { unitID } = useParamManage()
    const {
        markerConfig,
        clusterConfig,
        infoWindowConfig,
        visibleTooltips,
        isResultsVisible,
        handleCloseTooltip,
        handleSetView,
        handleResultMouseEnter,
        handleResultMouseLeave,
        handleMarkerClick,
        handleZoomInTooltipClick,
        handleZoomOutTooltipClick,
    } = useGmapUtils(data)

    const handleResultClick = (id: string) => {
        const place = data?.filter(({ id: itemID }) => itemID === id) || []
        handleSetView(place, 18)
    }

    useEffect(() => {
        map && handleSetView(data, 17)
    }, [map, data, handleSetView])

    if (!map) return null

    return (
        <>
            {renderResults && isResultsVisible && data && (
                <MapResultList
                    onItemMouseEnter={handleResultMouseEnter}
                    onItemMouseLeave={handleResultMouseLeave}
                    onItemClick={handleResultClick}
                >
                    {renderResults(data.map(({ id }) => id))}
                </MapResultList>
            )}
            <MarkerClustererF {...clusterConfig}>
                {(clusterer) => (
                    <Box>
                        {data.map(({ id, location }, i: number) => (
                            <Box key={i}>
                                <Marker
                                    {...markerConfig}
                                    key={id}
                                    clusterer={clusterer}
                                    position={location}
                                    label={{
                                        text: id,
                                        className: 'd-none',
                                    }}
                                    onClick={() => {
                                        handleMarkerClick(id, location)
                                    }}
                                />
                                {visibleTooltips[0] === id && (
                                    <InfoWindow
                                        {...infoWindowConfig}
                                        onCloseClick={() => handleCloseTooltip()}
                                        position={location}
                                    >
                                        <MapTooltipWrapper
                                            onCloseClick={() => handleCloseTooltip()}
                                            count={visibleTooltips.length}
                                            subtitleText={
                                                unitID ? t('unit.units') : t('object.objects')
                                            }
                                            onZoomInClick={() => handleZoomInTooltipClick(data)}
                                            onZoomOutClick={() => handleZoomOutTooltipClick(data)}
                                        >
                                            {renderTooltip ? renderTooltip(visibleTooltips) : <></>}
                                        </MapTooltipWrapper>
                                    </InfoWindow>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </MarkerClustererF>
        </>
    )
}

export default MapObject
