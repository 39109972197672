import * as yup from 'yup'

const schema = yup.object({
    primary_bg: yup.string(),
    secondary_bg: yup.string(),
    btn_bg: yup.string(),
    btn_color: yup.string(),
    primary_font_color: yup.string(),
    secondary_font_color: yup.string(),
    letterhead_primary_offset_x: yup.number().typeError('Offset X must be a number'),
    letterhead_primary_offset_y: yup.number().typeError('Offset Y must be a number'),
    letterhead_primary_box_width: yup.number().typeError('Box width must be a number'),
    letterhead_primary_box_height: yup.number().typeError('Box height must be a number'),
    letterhead_secondary_offset_x: yup.number().typeError('Offset X must be a number'),
    letterhead_secondary_offset_y: yup.number().typeError('Offset Y must be a number'),
    letterhead_secondary_box_width: yup.number().typeError('Box width must be a number'),
    letterhead_secondary_box_height: yup.number().typeError('Box height must be a number'),
})

export default schema
