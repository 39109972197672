import React from 'react';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { StyledDnDIcon, StyledContainer } from './styles';

interface Props {
      type: 'image' | 'image_and_pdf';
      onClick?: () => void;
}

const Placeholder: React.FC<Props> = ({ type, onClick }) => {
      const { t } = useTranslation();
      const {
            palette: {
                  neutral: { contrastText },
            },
      } = useTheme();

      const renderIcon = () => {
            switch (type) {
                  case 'image':
                        return <StyledDnDIcon as={AddPhotoAlternateOutlinedIcon} />;
                  case 'image_and_pdf':
                        return <StyledDnDIcon as={CloudUploadOutlinedIcon} />;
                  default:
                        return null;
            }
      };

      const getText = () => {
            switch (type) {
                  case 'image':
                        return t('drag_n_drop_image');
                  case 'image_and_pdf':
                        return t('drag_n_drop_image_and_pdf');
                  default:
                        return '';
            }
      };

      return (
            <ButtonBase
                  sx={{
                        width: '100%',
                        mb: 2,
                        cursor: Boolean(onClick) ? 'pointer' : 'default',
                  }}
                  onClick={onClick}
            >
                  <StyledContainer>
                        <Box>
                              {renderIcon()}
                              <Typography sx={{ color: contrastText }}>{getText()}</Typography>
                        </Box>
                  </StyledContainer>
            </ButtonBase>
      );
};

export default Placeholder;