import { Theme } from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiButton: OverridesStyleRules = {
    defaultProp: {
        size: 'large',
        withArrow: false,
    },
    styleOverrides: {
        root: {},
    },
    variants: [
        {
            props: { size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                fontSize: '.875rem',
                textTransform: 'initial',
                fontWeight: '400',
                boxShadow: 'none',
                padding: `13px ${theme.spacing(4)}`,
                '& .MuiButton-endIcon': {
                    '.MuiSvgIcon-root': {
                        width: '0.8em',
                        height: '0.8em',
                    },
                },
                '& .MuiButton-startIcon': {
                    '.MuiSvgIcon-root': {
                        width: '0.8em',
                        height: '0.8em',
                    },
                },
                '&:hover': {
                    boxShadow: 'none',
                },
            }),
        },
        {
            props: { variant: 'contained' },
            style: {
                justifyContent: 'space-between',
            },
        },
        {
            props: { variant: 'outlined' },
            style: {
                maxHeight: '50px',
            },
        },
        {
            props: { variant: 'text' },
            style: ({ theme }: { theme: Theme }) => ({
                display: 'flex',
                justifyContent: 'start',
                color: theme.palette.secondary.main,
                '&:hover': {
                    background: 'none',
                    textDecoration: 'underline',
                },
            }),
        },
        {
            props: { variant: 'select-button' },
            style: ({ theme }: { theme: Theme }) => ({
                color: theme.palette.common.white,
                textTransform: 'initial',
                fontSize: '14px',
                fontWeight: 400,

                '&:hover': {},
            }),
        },
        {
            props: { variant: 'text-icon' },
            style: ({ theme }: { theme: Theme }) => ({
                display: 'inline-flex',
                padding: '10 13px',
                justifyContent: 'flex-start',
                color: theme.palette.secondary.main,
                '&:hover': {
                    background: 'none',
                    textDecoration: 'underline',
                },
            }),
        },
    ],
}

export default MuiButton
