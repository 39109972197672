import { MenuItem } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledMenuItem = styled(MenuItem)`
    ${({ theme }) => css`
        min-width: 240px;
        cursor: initial;
        background-color: ${theme.palette.grey[100]};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    `}
`
