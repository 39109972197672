import { css, styled } from '@mui/material'

export const StyledNavigationBar = styled('div')`
    ${({ theme }) => css`
        height: 70px;
        padding: ${theme.spacing(2)} ${theme.spacing(1)};
        background: linear-gradient(
            90deg,
            ${theme.palette.darker.main} 0%,
            ${theme.palette.darker.light} 100%
        );
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`
export const StyledRightButtons = styled('div')`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing(2)};
        margin: 0 ${theme.spacing(2)} 0 0;
        height: 48px;
    `}
`
