import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { getLocalStorage, setLocalStorage } from '../../../../utils/storage'
import theme from '../../../../theme'
import { InfoWindow, Marker, MarkerClustererF, useGoogleMap } from '@react-google-maps/api'
import { Box } from '@mui/system'
import useGmapUtils from '../../../../hooks/useGmapUtils'
import Loader from '../../../../components/Loader'
import { MapTooltipWrapper } from '../MapTooltip'
import { debounce } from '@mui/material'
import { t } from 'i18next'
import { ContainerMapLocation, RegionPlacesBackup } from 'map'
import MapResultList from '../MapResultList'

interface Props {
    isMarkerVisible?: boolean
    data: ContainerMapLocation[]
    renderTooltip: (zipCodes: string[]) => JSX.Element[] | JSX.Element
    renderResults?: (containerItems: ContainerMapLocation[]) => JSX.Element[]
}

const LOCAL_STORAGE_ID = 'container_regions_data'

type LookupRegionResponseData = {
    matches: {
        matchedPlaceId: string
    }[]
}

//@ts-ignore
const featureStyleOptions: google.maps.FeatureStyleOptions = {
    strokeColor: theme.palette.primary.main,
    strokeOpacity: 1.0,
    strokeWeight: 3.0,
    fillColor: theme.palette.primary.main,
    fillOpacity: 0.5,
}

interface State {
    places: ContainerMapLocation[]
    cachedPlaces: RegionPlacesBackup[]
    initialLoading: boolean
}

enum ACTIONS {
    SET_PLACES_AND_STORAGE = 'set_places_and_storage',
    SET_PLACES = 'set_places',
    SET_STATUS = 'set_status',
}

type Action =
    | {
          type: ACTIONS.SET_PLACES_AND_STORAGE
          payload: Pick<State, 'cachedPlaces' | 'places' | 'initialLoading'>
      }
    | {
          type: ACTIONS.SET_PLACES
          payload: Pick<State, 'places' | 'initialLoading'>
      }
    | {
          type: ACTIONS.SET_STATUS
          payload: Pick<State, 'initialLoading'>
      }

const reducer = (state: State, { type, payload }: Action) => {
    switch (type) {
        case ACTIONS.SET_PLACES_AND_STORAGE:
            if (!('cachedPlaces' in payload)) return state

            setLocalStorage(LOCAL_STORAGE_ID, payload.cachedPlaces)
            return {
                ...state,
                cachedPlaces: payload.cachedPlaces,
                places: payload.places,
                initialLoading: payload.initialLoading,
            }

        case ACTIONS.SET_PLACES:
            return { ...state, ...payload }

        case ACTIONS.SET_STATUS:
            return { ...state, ...payload }

        default:
            console.error('No action found...')
            return state
    }
}

const MapBoundaries = ({ data, renderTooltip, renderResults }: Props) => {
    const [mapService, dispatch] = useReducer(reducer, {
        places: [],
        cachedPlaces: getLocalStorage(LOCAL_STORAGE_ID) || [],
        initialLoading: false,
    })
    const { places, cachedPlaces, initialLoading } = mapService

    const map = useGoogleMap()
    const {
        visibleTooltips,
        markerConfig,
        markerRefs,
        clusterConfig,
        infoWindowConfig,
        isResultsVisible,
        handleCloseTooltip,
        handleShowTooltip,
        handleSetView,
        handleMarkerClick,
        handleZoomInTooltipClick,
        handleSetLocation,
        handleResultMouseEnter,
        handleResultMouseLeave,
        handleZoomOutTooltipClick,
    } = useGmapUtils(places)

    const placeDetails = useMemo(() => {
        return new google.maps.places.PlacesService(document.createElement('div'))
    }, [])

    const featureLayer = useMemo(() => {
        //@ts-ignore
        return map?.getFeatureLayer('POSTAL_CODE')
    }, [map])

    const isZipCodeArray = (data: ContainerMapLocation[] | string): data is string =>
        typeof data === 'string'

    const getRegionsPlaceIDs = async (zipCodes: string[]): Promise<string[]> => {
        const response = await fetch('https://regionlookup.googleapis.com/v1alpha:lookupRegion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY || '',
            },
            body: JSON.stringify({
                identifiers: zipCodes.map((zipCode) => ({
                    place: zipCode,
                    place_type: 'POSTAL_CODE',
                    region_code: 'DE',
                })),
            }),
        })

        if (response.status !== 200) throw new Error()
        const responseData: LookupRegionResponseData = await response.json()
        return responseData.matches.map(({ matchedPlaceId }) => matchedPlaceId).filter((v) => !!v)
    }

    const getNewOnes = useCallback(
        async (containersMapItems: ContainerMapLocation[]): Promise<ContainerMapLocation[]> => {
            const uniqueContainers = containersMapItems.filter(
                (item, pos, self) => self.indexOf(item) === pos
            )

            try {
                const regionPlacesIDs = await getRegionsPlaceIDs(
                    uniqueContainers.map(({ zipCode }) => zipCode)
                )

                return containersMapItems
                    ?.map(({ zipCode, location, id }, i) => ({
                        id,
                        placeID: regionPlacesIDs[i],
                        zipCode,
                        location,
                    }))
                    .filter(({ placeID }) => !!placeID)
            } finally {
                dispatch({
                    type: ACTIONS.SET_STATUS,
                    payload: { initialLoading: false },
                })
            }
        },
        // eslint-disable-next-line
        [cachedPlaces, placeDetails, data]
    )

    const setFeatureLayer = useCallback(
        (placesID: string[]) => {
            if (!map) return
            //@ts-ignore
            featureLayer.style = (options) => {
                //@ts-ignore
                if (placesID.includes(options.feature.placeId)) {
                    return featureStyleOptions
                }
            }
            return featureLayer
        },
        [map, featureLayer]
    )

    const initialize = useCallback(async () => {
        if (!map) return

        const currentCachedItems = data
            .map((containerMapLocation) => {
                const { placeID = '' } =
                    cachedPlaces?.find(
                        (p: RegionPlacesBackup) => p.id === containerMapLocation.zipCode
                    ) || {}
                if (!placeID) return null
                return { ...containerMapLocation, placeID }
            })
            .filter((c) => !!c) as ContainerMapLocation[]

        const newPlaces = data.filter(
            ({ zipCode }) => !!!cachedPlaces?.find((p: RegionPlacesBackup) => p.id === zipCode)
        )

        let newPlacesBounds: ContainerMapLocation[] | [] = []

        if (newPlaces.length) {
            newPlacesBounds = await getNewOnes(newPlaces)
        }

        return dispatch({
            type: ACTIONS.SET_PLACES_AND_STORAGE,
            payload: {
                cachedPlaces: [
                    ...cachedPlaces,
                    ...newPlacesBounds.map(({ zipCode, placeID }) => ({
                        id: zipCode,
                        placeID,
                    })),
                ],
                places: [...currentCachedItems, ...newPlacesBounds],
                initialLoading: false,
            },
        })
        // eslint-disable-next-line
    }, [map, data])

    const handleAreaClick = async (item: any) => {
        const placeID = await item.features[0].placeId
        const place = places.find((p) => p.placeID === placeID)
        if (!place?.zipCode) return
        handleShowTooltip(place?.zipCode)
        handleSetLocation(place.location)
    }

    const drawAreas = useCallback(() => {
        const placesID = places.map((place) => place?.placeID || '')
        const overlay = setFeatureLayer(placesID)

        //@ts-ignore
        const clickEvent = overlay?.addListener('click', handleAreaClick)
        return () => {
            clickEvent && google.maps.event.removeListener(clickEvent)
        }
        // eslint-disable-next-line
    }, [places, setFeatureLayer])

    const showMarkers = (isVisible: boolean) =>
        markerRefs.current.forEach((marker: Marker) => {
            marker?.marker?.setVisible(isVisible)
        })

    const handleResultClick = (id: string) => {
        const place = places?.filter(({ zipCode }) => zipCode === id) || []
        handleSetView(place)
    }

    useEffect(() => {
        const onMapIdle = () => {
            const mapType = map?.getMapTypeId()
            const zoom = map?.getZoom() || 0

            if (mapType === google.maps.MapTypeId.ROADMAP) {
                return showMarkers(zoom < 10)
            }
            showMarkers(true)
        }
        const addZoomEvent = () => {
            if (!map) return
            return google.maps.event.addListener(
                map as google.maps.Map,
                'zoom_changed',
                debounce(onMapIdle, 500)
            )
        }
        const addMapTypeChangeEvent = () => {
            if (!map) return
            return google.maps.event.addListener(
                map as google.maps.Map,
                'maptypeid_changed',
                onMapIdle
            )
        }

        const mapTypeEvent = addMapTypeChangeEvent()
        const zoomEvent = addZoomEvent()
        return () => {
            zoomEvent?.remove()
            mapTypeEvent?.remove()
        }
        // eslint-disable-next-line
    }, [map])

    useEffect(() => {
        dispatch({
            type: ACTIONS.SET_STATUS,
            payload: { initialLoading: true },
        })
    }, [])

    useEffect(() => {
        initialize()
    }, [map, data, initialize])

    useEffect(() => {
        const clearEventClick = drawAreas()
        handleSetView(places, 13)
        return () => {
            if (places.length) clearEventClick()
        }
    }, [places, drawAreas, handleSetView])

    markerRefs.current = []
    if (initialLoading) return <Loader />

    return (
        <>
            {renderResults && isResultsVisible && !isZipCodeArray(data) && (
                <MapResultList
                    onItemMouseEnter={handleResultMouseEnter}
                    onItemMouseLeave={handleResultMouseLeave}
                    onItemClick={handleResultClick}
                >
                    {renderResults(data)}
                </MapResultList>
            )}
            <MarkerClustererF {...clusterConfig}>
                {(clusterer) => {
                    return (
                        <Box>
                            {places.map(({ location, zipCode }, i) => (
                                <Box key={i} sx={{ position: 'relative' }}>
                                    <Marker
                                        {...markerConfig}
                                        key={zipCode + i}
                                        clusterer={clusterer}
                                        visible={places.length !== 1}
                                        label={{
                                            text: zipCode,
                                            className: 'd-none',
                                        }}
                                        position={location}
                                        onClick={() => handleMarkerClick(zipCode, location)}
                                    />

                                    {visibleTooltips[0] === zipCode && (
                                        <InfoWindow
                                            {...infoWindowConfig}
                                            onCloseClick={() => handleCloseTooltip()}
                                            position={location}
                                        >
                                            <MapTooltipWrapper
                                                onCloseClick={() => handleCloseTooltip()}
                                                onZoomInClick={() =>
                                                    handleZoomInTooltipClick(places)
                                                }
                                                onZoomOutClick={() =>
                                                    handleZoomOutTooltipClick(places)
                                                }
                                                subtitleText={t('container.containers')}
                                                count={visibleTooltips?.length}
                                            >
                                                {renderTooltip(visibleTooltips)}
                                            </MapTooltipWrapper>
                                        </InfoWindow>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )
                }}
            </MarkerClustererF>
        </>
    )
}

export default MapBoundaries
