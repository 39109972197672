import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import { ObjectParams } from 'objects'
import { createObjectApiData } from '../../api/data/object'
import objectService from '../../api/services/object'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import useParamManage from '../useParamManage'
import useNavUtils from '../useNavUtils'
export const useUpdateObject = (objectID: string) => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { updateKey, getQueryAllKey, getQueryOneKey } = useQueryKeys(QueryDataKey.Object)
    const { containerID } = useParamManage()
    const { navigateObject } = useNavUtils()

    return useMutation(
        async (params: ObjectParams) => {
            return objectService.update(objectID, await createObjectApiData(params))
        },
        {
            mutationKey: updateKey,
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: getQueryAllKey([containerID]),
                })

                queryClient.invalidateQueries({ queryKey: getQueryOneKey([objectID]) })

                setSuccessMsg(t('updated'))
                navigateObject(objectID)('GENERAL_INFORMATION')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
