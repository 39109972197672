import { FileData } from 'files'
import { MaintenanceList } from 'maintenance'
import { TaskList } from 'tasks'
import { UnitList } from 'units'
import { TreeItem } from '../components/DataTree'
import { ContainerList } from './containers'
import { ObjectList } from './objects'
import { CreateLocationParams } from '../api/data'
import { ApartmentList } from 'apartment'

export type Coordinates = {
    lat: number
    lng: number
}

export interface ImageData {
    src: string
    filename?: string
}

export interface SelectableFileData {
    src: string
    filename: string
    type?: 'pdf' | 'image'
}

export enum DataTypesAPI {
    Object = 'property',
    Maintenance = 'building_services',
    Unit = 'utilization_unit',
    Container = 'accounting_entity',
    Task = 'todo',
}

export interface ImageResponseData {
    url: string
    filename: string
    full_url: string
}
export interface RoomsFieldResponseData {
    id: string | null
    room: string | null
    value: string | null
}

export enum MainDataTypes {
    Object = 'object',
    Container = 'container',
    Unit = 'unit',
    Task = 'task',
    Maintenance = 'maintenance',
    File = 'file',
    TreeItem = 'tree_item',
}

export type MainData =
    | ObjectList
    | ContainerList
    | UnitList
    | TaskList
    | MaintenanceList
    | FileData
    | TreeItem
    | ApartmentList

export interface ImageDataResponse {
    filename: string
    full_url: string
}

export enum QueryDataKey {
    Apartment = 'apartment',
    Unit = 'unit',
    Task = 'task',
    Maintenance = 'maintenance',
    Object = 'object',
    Container = 'container',
    Type = 'type',
    MepType = 'mep-type',
    Attribute = 'attribute',
    Auth = 'auth',
    ContractorTrade = 'contractor-trade',
    Language = 'language',
    FileGroup = 'filegroup',
    File = 'file',
    CompanySettings = 'company-settings',
}
export type LocationField = CreateLocationParams & { description: string; zipCode: string }
export interface MapLocation {
    id: string
    location: google.maps.LatLng | Coordinates
}

export enum MapSizes {
    SM = 400,
    MD = 555,
}
export interface ModelResponseData {
    url: string
    type: 'indoor' | 'outdoor' | 'technical' | 'outdoorthermo'
    timestamp: string
}
export interface ModelData {
    url: string
    type: string
    timestamp: number
}

export interface BuildAttributeResponseData {
    id: string
    attribute: string
    value: string
}
export enum MainLanguages {
    en = 'english',
    de = 'germany',
}
