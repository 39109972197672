import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { createObjectData } from '../../api/data/object'
import objectService from '../../api/services/object'
import { ObjectData } from 'objects'

export const useGetObject = (
    objectID: string,
    options: UseQueryOptions
): UseQueryResult<ObjectData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Object)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await objectService.getOne(objectID)
            return createObjectData(data)
        },
    }) as UseQueryResult<ObjectData>
}
