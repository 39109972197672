import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { AxiosError } from 'axios'
import { ApartmentList } from 'apartment'
import apartmentService from '../../api/services/apartment'
import { createApartmentList } from '../../api/data/apartment'

export const useGetApartments = (
    unitID: string,
    options: UseQueryOptions<
        Promise<ApartmentList[]>,
        { response: AxiosError<Error> },
        ApartmentList[]
    >
) => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Apartment)
    const { queryKey, ...restOptions } = options

    return useQuery<Promise<ApartmentList[]>, { response: AxiosError<Error> }, ApartmentList[]>({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async (): Promise<ApartmentList[]> => {
            const { data } = await apartmentService.getAll(unitID)
            return createApartmentList(data)
        },
    })
}
