import React, { useRef, useState } from 'react'
import { SwiperProps, SwiperSlide } from 'swiper/react'
import Swiper, { FreeMode, Navigation, Thumbs, Keyboard } from 'swiper'
import {
    StyledGalleryWrapper,
    StyledMainSwiper,
    StyledThumbSwiper,
    StyledTitleArrowsWrapper,
} from './styles'
import { type Swiper as SwiperRef } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { IconButton, Typography } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { ImageData } from '../../../../types'
import { t } from 'i18next'
import { useLightbox } from '../../../../hooks/useLightbox'
import { StyledModalArrows } from '../../../../theme/styles'
import { NavigationOptions } from 'swiper/types'
import Image from '../../../../components/Image'
import Lightbox from '../../../../components/Lightbox'

type Props = {
    images: ImageData[]
}

const ImageGallery = ({ images }: Props) => {
    const indexRef = useRef(null)
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const swiperRef = useRef<Swiper | null>(null)
    const [img, setImg] = useState<string | null>('')

    const swiperConfig: SwiperProps = {
        modules: [FreeMode, Navigation, Thumbs, Keyboard],
        spaceBetween: 0,
        navigation: {
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
        },
        onInit: (swiper: SwiperRef) => {
            swiperRef.current = swiper
        },
        onBeforeInit: (swiper: SwiperRef) => {
            ;(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current
            ;(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current
        },
    }

    const thumbsConfig: SwiperProps = {
        modules: [FreeMode, Navigation, Thumbs],
        spaceBetween: 15,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        onClick: (swiper) => swiperRef.current?.slideTo(swiper.clickedIndex),
    }

    useLightbox({ swiper: swiperRef })

    return (
        <StyledGalleryWrapper>
            {images.length !== 0 && (
                <StyledTitleArrowsWrapper>
                    <Typography>
                        {t('photo')}
                        <span ref={indexRef} />
                    </Typography>

                    <StyledModalArrows>
                        <IconButton size="large" ref={navigationPrevRef} color="neutral">
                            <ArrowLeftIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="large" ref={navigationNextRef} color="neutral">
                            <ArrowRightIcon fontSize="small" />
                        </IconButton>
                    </StyledModalArrows>
                </StyledTitleArrowsWrapper>
            )}
            <StyledMainSwiper {...swiperConfig}>
                {images.map((image, i) => (
                    <SwiperSlide key={i}>
                        <Image src={image.src} onClick={() => setImg(i.toString())} />
                    </SwiperSlide>
                ))}
            </StyledMainSwiper>
            <StyledThumbSwiper {...thumbsConfig}>
                {images.map((image, i) => (
                    <SwiperSlide key={i}>
                        <Image src={image.src} />
                    </SwiperSlide>
                ))}
            </StyledThumbSwiper>
            {img && <Lightbox images={images} open={img} onChange={setImg} />}
        </StyledGalleryWrapper>
    )
}

export default ImageGallery
