import React, { FC } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyledForm, StyledWrapper } from '../styles'
import Input from '../components/Input'
import { Box } from '@mui/system'
import LocationFields, { LocationInputs } from '../components/LocationFields'
import Map from '../../modules/Map'
import Autocomplete from '../components/Autocomplete'
import { UnitParams } from 'units'
import TaxonomyFields from '../components/TaxonomyFields'
import { useEffectOnce } from 'usehooks-ts'
import { Option } from 'forms'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import { MapTooltipItem } from '../../modules/Map/components/MapTooltip'
import MapObject from '../../modules/Map/components/MapObject'
import DataGroup from '../../components/DataGroup'
import { ObjectData } from 'objects'
import useParamManage from '../../hooks/useParamManage'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    unit?: UnitParams
    types: Option[]
    attributes: Option[]
    isLoading: boolean
    object?: ObjectData
    onSubmit: (values: UnitParams) => void
    onCancelClick: () => void
}

const FormUnit: FC<Props> = ({
    unit,
    types = [],
    attributes,
    isLoading,
    object,
    onSubmit,
    onCancelClick,
}) => {
    const { t } = useTranslation()
    const { objectID } = useParamManage()

    const methods = useForm<UnitParams>({
        resolver: yupResolver(schema),
        defaultValues: unit || {
            title: '',
            street: '',
            zipCode: '',
            city: '',
            objectID,
            type: '',
            idOptional: '',
            attributes: [],
        },
    })

    const {
        reset,
        handleSubmit,
        control,
        getValues,
        formState: { isDirty },
    } = methods

    const handleCreateTooltip = () => {
        const { title, zipCode, city, street } = getValues()

        return <MapTooltipItem data={locationField ? { zipCode, city, title, street } : unit} />
    }

    const locationField = useWatch({ control, name: 'location' })
    useEffectOnce(() => () => reset({}, { keepValues: false }))

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledWrapper>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t(`${'general_information'}`)}>
                            <Input name="title" label={t(`${'unit.title'}`)} />
                            <LocationFields
                                label={t('location')}
                                locationOptions={{
                                    input: `${unit?.street} ${unit?.city} ${unit?.zipCode}`,
                                }}
                            >
                                {(noBorder) => (
                                    <>
                                        <Input
                                            name={LocationInputs.Street}
                                            label={t(`${'street'}`)}
                                        />
                                        <Input
                                            name={LocationInputs.ZipCode}
                                            label={t(`${'zip_code'}`)}
                                        />
                                        <Input
                                            name={LocationInputs.City}
                                            label={t(`${'city'}`)}
                                            noBorder={noBorder}
                                        />
                                    </>
                                )}
                            </LocationFields>
                            <Input name="idOptional" label="ID" />
                            <Autocomplete
                                name={`type`}
                                label={t('type')}
                                options={types}
                                noBorder
                            />
                        </DataGroup>
                        <DataGroup label={t('attributes')}>
                            <TaxonomyFields
                                name={'attributes'}
                                btnText={t('add_attribute')}
                                data={attributes}
                            />
                        </DataGroup>
                    </Box>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t('map')}>
                            <Map
                                renderMap={({ createLocation }) => (
                                    <MapObject
                                        data={createLocation(locationField || object)}
                                        renderTooltip={handleCreateTooltip}
                                    />
                                )}
                            />
                        </DataGroup>
                    </Box>
                </StyledWrapper>

                <FormSubmitFooter
                    submitDisabled={!isDirty}
                    onCancelClick={onCancelClick}
                    loading={isLoading}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormUnit
