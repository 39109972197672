import { Button, Box } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledSelectButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        z-index: 1100;
        margin-bottom: ${theme.spacing(2)};
        position: absolute;
        top: -40px; /* Position above the image */
        right: 0;
        &:hover {
            background-color: ${theme.palette.primary.dark};
        }
    `}
`

export const SaveButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        z-index: 9999;
        &:hover {
            background-color: ${theme.palette.success.dark};
        }
    `}
`

export const ExitButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.secondary.contrastText};
        z-index: 9999;
        &:hover {
            background-color: ${theme.palette.secondary.dark};
        }
    `}
`

export const ImageContainer = styled(Box)`
    ${({ theme }) => css`
        position: relative;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(26, 26, 26, 1);
    `}
`

export const InfoBox = styled(Box)`
    ${({ theme }) => css`
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: ${theme.spacing(1)};
        z-index: 1100;
        display: flex;
        gap: ${theme.spacing(2)};
        align-items: center;
        justify-content: space-between;
        text-align: left;
        user-select: none;
        moz-user-select: none;
        webkit-user-select: none;
        ms-user-select: none;
    `}
`

export const SelectionBox = styled(Box)`
    ${({ theme }) => css`
        position: absolute;
        border: 2px solid ${theme.palette.primary.main};
        background-color: rgba(255, 255, 255, 0.2);
        pointer-events: auto;
        cursor: crosshair;
    `}
`

export const ResizeHandle = styled(Box)`
    ${({ theme }) => css`
        width: 20px;
        height: 20px;
        background-color: ${theme.palette.grey['50']};
        border-radius: 50%;
        position: absolute;
        z-index: 9999;
    `}
`
