import { styled, css } from '@mui/system'
import { Swiper } from 'swiper/react'

export const StyledGalleryWrapper = styled('div')`
    ${({ theme }) => css`
        height: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
    `}
`

export const StyledMainSwiper = styled(Swiper)`
    ${({ theme }) => css`
        height: 80%;
        width: 100%;
        margin-bottom: ${theme.spacing(3)};

        .swiper-slide {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ${theme.spacing(1)};
        }
    `}
`

export const StyledThumbSwiper = styled(Swiper)`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        height: 20%;
        .swiper-slide {
            cursor: pointer;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: ${theme.spacing(1)};
            }
        }
    `}
`

export const StyledTitleArrowsWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${theme.spacing(1)};
    `}
`
