import axiosInstance from '../axios'

const fileService = {
    getGroups: (entityID: string) => {
        return axiosInstance.get(`/folder/${entityID}`)
    },
    getFiles: (entityID: string, groupID: string) => {
        return axiosInstance.get(`/files/${entityID}/${groupID}`)
    },
    downloadFiles: (entityID: string, filesIDs: string[]) => {
        return axiosInstance.post(`/batch_dl/${entityID}`, filesIDs)
    },
    transferFiles: (entityID: string, newGroupID: string, fileID: string) => {
        return axiosInstance.put(`/files/${entityID}/${newGroupID}/${fileID}`, {})
    },
    uploadFile: (entityID: string, groupID: string, files: FormData) => {
        return axiosInstance.post(`/files/${entityID}/${groupID}`, files, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    deleteFiles: (entityID: string, groupID: string, files: string) => {
        return axiosInstance.delete(`/files/${entityID}/${groupID}/${files}`)
    },
}
export default fileService
