import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserParams } from 'auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import Select from '../../components/Select'
import { ACCOUNTS_TYPES, CONTRACTOR_SECTORS } from '../../constant/taxonomy'
import LocationFields, { LocationInputs } from '../components/LocationFields'
import schema from './shema'
import { StyledForm, StyledWrapper } from '../styles'
import Input from '../components/Input'
import { UserData } from 'user'
import Autocomplete from '../components/Autocomplete'
import { Option } from 'forms'
import { useGetContractorTrades } from '../../hooks/other/useGetContractorTrades'
import DataGroup from '../../components/DataGroup'
import { CONTAINER_PATHS } from '../../routes/routes'
import { useNavigate } from 'react-router-dom'
import { AccountTypes } from '../../types/constant'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    user: UserData
    onSubmit: (values: UserParams) => void
    isLoading: boolean
}

const FormUser = ({ user, onSubmit, isLoading }: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { data: trades } = useGetContractorTrades({})

    const methods = useForm<UserParams>({
        resolver: yupResolver(schema),
        defaultValues: user,
    })

    const {
        handleSubmit,
        formState: { isDirty },
    } = methods

    const handleCancelClick = () => navigate(CONTAINER_PATHS.OVERVIEW_ALL)

    return (
        <>
            <FormProvider {...methods}>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <StyledWrapper>
                        <Box maxWidth={'50%'} minWidth={'400px'}>
                            <DataGroup label={t(`${'general_information'}`)}>
                                <Input name="username" label={t(`${'username'}`)} disabled />
                                <Input name="email" label={t('email')} disabled />
                                <Select
                                    name="type"
                                    label={t('account_type')}
                                    disabled
                                    fullWidth
                                    options={ACCOUNTS_TYPES}
                                />
                                <Input name="company" label={t(`${'company'}`)} />
                                <Input name="companyName" label={t(`${'company_name'}`)} />
                                <LocationFields
                                    label={t('location')}
                                    locationOptions={{
                                        input: '',
                                    }}
                                >
                                    {() => (
                                        <>
                                            <Input
                                                name={LocationInputs.Street}
                                                label={t('street')}
                                            />
                                            <Input
                                                name={LocationInputs.ZipCode}
                                                label={t('zip_code')}
                                            />
                                            <Input
                                                name={LocationInputs.City}
                                                label={t('city')}
                                                noBorder
                                            />
                                        </>
                                    )}
                                </LocationFields>
                                <>
                                    {user.type === AccountTypes.Contractor && (
                                        <>
                                            <Select
                                                label={t('contractor_sector')}
                                                name="contractorSector"
                                                fullWidth
                                                options={CONTRACTOR_SECTORS}
                                            />
                                            <Autocomplete
                                                placeholderName={t('contractor_trade')}
                                                options={trades as Option[]}
                                                name="contractorTrade"
                                                sx={{ mb: 4 }}
                                            />
                                        </>
                                    )}
                                </>
                            </DataGroup>
                        </Box>
                    </StyledWrapper>
                    <FormSubmitFooter
                        onCancelClick={handleCancelClick}
                        loading={isLoading}
                        submitDisabled={!isDirty}
                    />
                </StyledForm>
            </FormProvider>
        </>
    )
}

export default FormUser
