import React, { FC } from 'react'
import Table from '../../components/Table'
import { Column } from 'react-table'
import { useMemo } from 'react'
import Map from '../Map'
import { MainData } from '../../types/index'
import { ObjectList } from '../../types/objects'
import { StyledGridWrapper } from '../../theme/styles'
import GridItem from '../GridItem'
import { MapTooltipItem } from '../Map/components/MapTooltip'
import MapObject from '../Map/components/MapObject'
import MapResultItem from '../Map/components/MapResultItem'
import { useTranslation } from 'react-i18next'
import useView from '../../store/view'
import useNavUtils from '../../hooks/useNavUtils'
import useParamManage from '../../hooks/useParamManage'

interface Props {
    data: ObjectList[]
    isLoading: boolean
}
type Action = 'update' | 'delete' | 'grid-click' | 'cell-click' | 'tooltip-click'

const OverviewObjectList: FC<Props> = ({ data, isLoading }) => {
    const { t } = useTranslation()
    const { viewType } = useView()
    const { navigateObject } = useNavUtils()
    const { containerID } = useParamManage()

    const columns: Column<ObjectList>[] = useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: ({ idOptional, id }) => idOptional || id,
                size: 1,
                width: 70,
                maxWidth: 70,
            },
            {
                Header: t('title'),
                accessor: ({ childrenCount, title }) => {
                    if (!childrenCount) return title
                    return `${title} (${childrenCount})`
                },
                size: 1,
            },
            {
                Header: t('zip_code'),
                accessor: 'zipCode',
                size: 1,
            },
            {
                Header: t('street'),
                accessor: 'street',
                size: 1,
            },
            {
                Header: t('city'),
                accessor: 'city',
                size: 1,
            },
            {
                Header: t('last_edited'),
                accessor: 'changed',
                size: 1,
            },
            {
                Header: t('type'),
                accessor: 'type',
                size: 1,
            },
        ]
    }, [t])

    const handleNavigate = (action: Action) => (id: string) => {
        const object = containerID || data?.find((d) => d?.nid === id)?.parentID
        const navigate = navigateObject(id, object)

        switch (action) {
            case 'update':
                return navigate('UPDATE')

            case 'delete':
                return navigate('DELETE')

            case 'grid-click':
            case 'cell-click':
            case 'tooltip-click':
                return navigate('GENERAL_INFORMATION')

            default:
                navigate('OVERVIEW')
        }
    }

    const handleCreateTooltip = (fieldValues: string[]) => {
        const objects = data?.filter(({ id }) => fieldValues.includes(id))

        return objects?.map((object, i) => (
            <MapTooltipItem
                key={i}
                data={object}
                index={i}
                length={objects?.length}
                onClick={handleNavigate('tooltip-click')}
            />
        ))
    }

    const handleCreateResults = (ids: string[]) => {
        const objects = data?.filter(({ id }) => ids.includes(id))

        return objects.map((o) => (
            <MapResultItem key={o.id} id={o.id} subtitle={`${o.street} ${o.zipCode}, ${o.city}`}>
                {o.title}
            </MapResultItem>
        ))
    }

    const renderGridItem = (gridItem: MainData) => {
        if (!('parentID' && 'image' in gridItem)) return

        const { id, title, parentID, image } = gridItem
        return (
            <GridItem
                id={id}
                title={title}
                parentID={parentID}
                image={image}
                variant={'basic'}
                onEdit={handleNavigate('update')}
                onDelete={handleNavigate('delete')}
                onClick={handleNavigate('grid-click')}
            />
        )
    }

    return (
        <>
            {viewType !== 'map' && (
                <Table
                    onEdit={handleNavigate('update')}
                    onDelete={handleNavigate('delete')}
                    columns={columns}
                    data={data}
                    onCellClick={handleNavigate('cell-click')}
                    isLoading={isLoading}
                    renderGridItem={renderGridItem}
                    GridWrapperElement={StyledGridWrapper}
                />
            )}

            {viewType === 'map' && (
                <Map
                    renderMap={({ createAndFilterLocations }) => (
                        <MapObject
                            data={createAndFilterLocations(data)}
                            renderTooltip={handleCreateTooltip}
                            renderResults={handleCreateResults}
                        />
                    )}
                    large
                />
            )}
        </>
    )
}

export default OverviewObjectList
