import { CircularProgress, CircularProgressProps } from '@mui/material'
import React from 'react'
interface Props {
    isLoading?: boolean
    children: JSX.Element[] | JSX.Element | boolean
    size?: number
    center?: boolean
    centerY?: boolean
}
const LoaderWrapper = ({
    isLoading,
    children,
    size,
    center = true,
    sx,
    centerY,
    ...props
}: Props & CircularProgressProps) => {
    if (!isLoading) return <>{children}</>

    return (
        <CircularProgress
            {...props}
            sx={{
                ...(centerY && { margin: '0 auto' }),
                ...(center && { margin: 'auto' }),
                ...(sx && sx),
                display: 'flex',
            }}
            size={size}
        />
    )
}

export default LoaderWrapper
