import { Box, BoxProps, CircularProgress } from '@mui/material'
import React from 'react'

interface Props {
    absoluteCenter?: boolean
    size?: number
}

const Loader = ({ size, absoluteCenter, ...restProps }: Props & BoxProps) => {
    return (
        <Box
            display="flex"
            justifyContent={'center'}
            alignItems="center"
            {...restProps}
            sx={
                absoluteCenter
                    ? {
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                      }
                    : {
                          height: '100%',
                          width: '100%',
                      }
            }
        >
            <CircularProgress size={size} sx={{ height: '100%', margin: 'auto' }} />
        </Box>
    )
}

export default Loader
