import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserParams } from '../../types/auth'
import { Option } from '../../types/forms'
import { PATHS } from '../../routes/routes'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import Divider from '@mui/material/Divider'
import { StyledButtonWrapper, StyledLink, StyledLinks } from '../../theme/styles'
import { LoadingButton } from '@mui/lab'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import schema from './shema'
import Dialog from '../../components/Dialog'
import useDialog from '../../hooks/useDialog'
import LocationFields, { LocationInputs } from '../../forms/components/LocationFields'
import theme from '../../theme'
import { useGmap } from '../../context/gmap'
import Input from '../components/Input'
import { StyledForm } from '../../forms/styles'
import { UseMutateFunction } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

type Props = {
    isLoading: boolean
    onSubmit: UseMutateFunction<AxiosResponse, AxiosError, UserParams>
    trades?: Option[]
}

const FormSignUp = ({ isLoading, onSubmit }: Props) => {
    const { t } = useTranslation()
    const { isGmapLoaded } = useGmap()
    const [isOpen, , handleSetDialog, handleToggleDialog] = useDialog()

    const methods = useForm<UserParams>({
        resolver: yupResolver(schema),
        defaultValues: {
            mail: '',
            pass: '',
            company: '',
            companyName: '',
            repass: '',
            street: '',
            zipCode: '',
            city: '',
        },
    })

    const { handleSubmit, getValues } = methods
    const onAcceptDialog = () => onSubmit(getValues())
    const handleAccept = () => handleSetDialog(true)

    return (
        <FormProvider {...methods}>
            <Box marginBottom={6}>
                <Typography variant="h3">{t('register_to_immotech')}</Typography>
            </Box>
            <StyledForm onSubmit={handleSubmit(handleAccept)}>
                <Input labelSmall={t('email')} name="mail" sx={{ mb: 4 }} />
                <Input
                    labelSmall={t('password')}
                    name="pass"
                    sx={{ mb: 4 }}
                    variant={'password'}
                    autoComplete="new-password"
                />
                <Input
                    labelSmall={t('repeat_password')}
                    name="repass"
                    sx={{ mb: 4 }}
                    variant={'password'}
                    autoComplete="repeat-password"
                />

                <Input labelSmall={t('company')} name="company" sx={{ mb: 4 }} />
                <Input labelSmall={t('company_name')} name="companyName" sx={{ mb: 4 }} />

                {isGmapLoaded && (
                    <LocationFields
                        placeholder={t('location')}
                        inputStyles={{
                            margin: `0 0 ${theme.spacing(4)} 0`,
                        }}
                        locationOptions={{
                            input: '',
                        }}
                        noMargin
                        noBorderAutocomplete
                    >
                        {() => (
                            <>
                                <Input
                                    labelSmall={t('street')}
                                    name={LocationInputs.Street}
                                    sx={{ mb: 4 }}
                                    noBorder
                                />
                                <Input
                                    labelSmall={t('zip_code')}
                                    name={LocationInputs.ZipCode}
                                    sx={{ mb: 4 }}
                                    noBorder
                                />
                                <Input
                                    labelSmall={t('city')}
                                    name={LocationInputs.City}
                                    sx={{ mb: 4 }}
                                    noBorder
                                />
                            </>
                        )}
                    </LocationFields>
                )}

                <StyledButtonWrapper>
                    <Button
                        component={Link}
                        to={PATHS.SIGNIN}
                        size="large"
                        variant="text-icon"
                        startIcon={<ArrowLeftIcon />}
                        fullWidth
                    >
                        {t('cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        loading={false}
                        endIcon={<ArrowRightIcon />}
                        fullWidth
                    >
                        {t('register')}
                    </LoadingButton>
                </StyledButtonWrapper>
                <Divider />
                <StyledLinks>
                    <StyledLink to={PATHS.SUPPORT}>{t('contact_support')}</StyledLink>
                </StyledLinks>
            </StyledForm>

            <Dialog
                open={!!isOpen}
                setOpen={handleToggleDialog}
                onAcceptClick={onAcceptDialog}
                title={t('sign_up.dialog_title')}
                isLoading={isLoading}
                onAcceptText={t('confirm')}
            >
                {t('sign_up.dialog_content')}
            </Dialog>
        </FormProvider>
    )
}

export default FormSignUp
