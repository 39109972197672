import { styled, css } from '@mui/system'

export const StyledViewSelection = styled('nav')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

export const StyledIcons = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 10px;
    `}
`
