import React, { forwardRef } from 'react'
import { StyledDragPreview, StyledFileDragPreview } from './styles'
import { Box, Typography } from '@mui/material'
import Icon from '../../../../components/Icon'
import { FileData } from 'files'

interface Props {
    data: FileData
}

const CustomDragPreview = forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const { filename, ext } = data

    return (
        <StyledDragPreview ref={ref}>
            <StyledFileDragPreview>
                <Box flexShrink={0}>
                    <Icon icon={ext} variant="file" height={20} />
                </Box>
                <Typography>{filename}</Typography>
            </StyledFileDragPreview>
        </StyledDragPreview>
    )
})

export default CustomDragPreview
