import React, { FC } from 'react'
import { StyledFormWrapper } from './styles'
import Input from '../components/Input'
import { Box } from '@mui/system'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import LocationFields, { LocationInputs } from '../components/LocationFields'
import Map from '../../modules/Map'
import { ContainerData, ContainerParams } from 'containers'
import { useEffectOnce } from 'usehooks-ts'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import { StyledForm } from '../styles'
import { MapTooltipItem } from '../../modules/Map/components/MapTooltip'
import MapBoundaries from '../../modules/Map/components/MapBoundaries'
import DataGroup from '../../components/DataGroup'
import { useTranslation } from 'react-i18next'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    isLoading: boolean
    onSubmit: (values: ContainerParams) => void
    onCancelClick: () => void
    container?: ContainerData
}

const FormContainer: FC<Props> = ({ container, isLoading, onSubmit, onCancelClick }) => {
    const { t } = useTranslation()
    const methods = useForm<ContainerParams>({
        resolver: yupResolver(schema),
        defaultValues: container || {
            title: '',
            idOptional: '',
            zipCode: '',
            city: '',
        },
    })

    const {
        reset,
        handleSubmit,
        control,
        getValues,
        formState: { isDirty },
    } = methods

    const locationField = useWatch({ control, name: 'location' })
    const zipCodeField = useWatch({ control, name: 'zipCode' })

    const handleCreateTooltip = () => {
        const { title, zipCode, city } = getValues()
        return <MapTooltipItem data={locationField ? { zipCode, city, title } : container} />
    }

    useEffectOnce(() => () => reset({}, { keepValues: false }))

    return (
        <FormProvider {...methods}>
            <StyledFormWrapper>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" gap={10}>
                        <Box flexBasis={'50%'}>
                            <DataGroup label={t(`${'general_information'}`)}>
                                <>
                                    <Input
                                        name="title"
                                        label={t(`${'container.container_name'}`)}
                                    />
                                    <LocationFields
                                        label={t(`${'location'}`)}
                                        locationOptions={{
                                            input: container
                                                ? `${container?.zipCode} ${container?.city}`
                                                : '',
                                        }}
                                        types={['(regions)']}
                                    >
                                        {() => (
                                            <>
                                                <Input
                                                    name={LocationInputs.ZipCode}
                                                    label={t(`${'zip_code'}`)}
                                                />
                                                <Input
                                                    name={LocationInputs.City}
                                                    label={t(`${'city'}`)}
                                                />
                                            </>
                                        )}
                                    </LocationFields>
                                    <Input name="idOptional" label="ID" noBorder />
                                </>
                            </DataGroup>
                        </Box>
                        <Box flexBasis={'50%'}>
                            {zipCodeField && (
                                <DataGroup label={t('map')} innerPadding>
                                    <Map
                                        renderMap={({ createBoundarieLocation }) => (
                                            <MapBoundaries
                                                data={createBoundarieLocation(
                                                    locationField || container
                                                )}
                                                renderTooltip={handleCreateTooltip}
                                            />
                                        )}
                                    />
                                </DataGroup>
                            )}
                        </Box>
                    </Box>
                    <FormSubmitFooter
                        submitDisabled={!isDirty}
                        onCancelClick={onCancelClick}
                        loading={isLoading}
                    />
                </StyledForm>
            </StyledFormWrapper>
        </FormProvider>
    )
}

export default FormContainer
