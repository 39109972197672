import React, { useMemo } from 'react'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { Column, TableInstance } from 'react-table'
import { TreeItem } from '../../../../components/DataTree'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Checkbox } from '@mui/material'
import Table from '../../../../components/Table'

const FileSettings = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useLocation()

    // const {
    //     palette: { grey },
    // } = useTheme();

    const handleClose = () => navigate(state.prevPath)

    const columns: Column<TreeItem>[] = useMemo(() => {
        return [
            {
                id: 'selection',
                accessor: 'id',
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    return (
                        <Checkbox
                            {...getToggleAllRowsSelectedProps()}
                            onClick={getToggleAllRowsSelectedProps().onChange}
                        />
                    )
                },
                Cell: ({ row: { getToggleRowSelectedProps } }: TableInstance<TreeItem>) => {
                    return (
                        <Checkbox
                            {...getToggleRowSelectedProps()}
                            onClick={getToggleRowSelectedProps().onChange}
                        />
                    )
                },
                width: 60,
                maxWidth: 60,
                size: 1,
            },
            {
                Header: t('name'),
                accessor: 'title',
                size: 1,
                Cell: ({ row: { original: file } }: TableInstance<TreeItem>) => {
                    return (
                        <Box display="flex" alignItems={'flex-start'}>
                            {file?.title}
                        </Box>
                    )
                },
            },
        ]
    }, [t])

    return (
        <Modal title={t('user_information')} open={true} onClose={handleClose}>
            <Table
                onEdit={() => {}}
                onDelete={() => {}}
                columns={columns}
                data={[]}
                onCellClick={() => {}}
                isLoading={false}
            />
        </Modal>
    )
}

export default FileSettings
