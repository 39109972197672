import React, { FC } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { default as MaterialDialog } from '@mui/material/Dialog'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'
import theme from '../../theme'
import {
    StyledDialogBody,
    StyledDialogContent,
    StyledDialogContentWrapper,
    StyledDialogFooter,
    StyledDialogHeader,
} from './styles'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '../Divider'
import Icon from '../Icon'

interface Props {
    title: string | React.ReactElement
    onAcceptText?: string
    onAcceptClick?: Function | null
    onTransitionEnd?: any
    onCancelText?: string
    element?: JSX.Element
    children?: JSX.Element | string | boolean | null
    icon?: JSX.Element
    open?: boolean
    renderButtons?: JSX.Element
    isLoading?: boolean
    btnColor?: ButtonProps['color']
    onCancelClick?: () => void
    setOpen?: (state: boolean) => void
}

const Dialog: FC<Props> = ({
    title,
    onAcceptText,
    children,
    onCancelText,
    renderButtons,
    element,
    isLoading,
    open = true,
    btnColor,
    onTransitionEnd,
    onAcceptClick,
    onCancelClick,
    setOpen,
}) => {
    const handleClickOpen = () => {
        setOpen && setOpen(true)
        onAcceptClick && onAcceptClick()
    }

    const handleClose = () => {
        setOpen && setOpen(false)
        onCancelClick && onCancelClick()
    }

    return (
        <>
            {element && <Box onClick={handleClickOpen}>{element}</Box>}
            <MaterialDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                transitionDuration={{
                    enter: 150,
                    exit: 0,
                }}
                PaperProps={{
                    sx: {
                        width: '500px',
                    },
                }}
                onTransitionEnd={onTransitionEnd}
            >
                <StyledDialogHeader>
                    <Typography component={'p'}>{t('confirm_action')}</Typography>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </StyledDialogHeader>

                <StyledDialogBody
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <StyledDialogContentWrapper>
                        <StyledDialogContent>{title}</StyledDialogContent>
                    </StyledDialogContentWrapper>
                    {children && <Box mt={3}>{children}</Box>}

                    <Divider marginSize={4} noGutterBottom />
                </StyledDialogBody>

                <StyledDialogFooter>
                    {renderButtons ? (
                        renderButtons
                    ) : (
                        <>
                            {' '}
                            <Button
                                variant="text-icon"
                                onClick={handleClose}
                                size="large"
                                startIcon={
                                    <Icon
                                        icon={'ChevronIcon'}
                                        height={6}
                                        color={theme.palette.primary.main}
                                        orientation={'left'}
                                    />
                                }
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: theme.spacing(2),
                                }}
                            >
                                {onCancelText || t('cancel')}
                            </Button>
                            <LoadingButton
                                type="submit"
                                size="large"
                                variant="contained"
                                loading={isLoading}
                                onClick={() => onAcceptClick && onAcceptClick()}
                                color={btnColor || 'primary'}
                                endIcon={
                                    <Icon
                                        icon={'ChevronIcon'}
                                        height={6}
                                        color={theme.palette.common.white}
                                        orientation={'right'}
                                    />
                                }
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: theme.spacing(2),
                                }}
                            >
                                {onAcceptText || t('confirm_action_accept')}
                            </LoadingButton>
                        </>
                    )}
                </StyledDialogFooter>
            </MaterialDialog>
        </>
    )
}

export default Dialog
