export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14.424">
        <defs>
            <clipPath id="a">
                <path d="M0 0h12v14.424H0Z" fill="#169de3" />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M1.691 14.424h8.619a1.663 1.663 0 0 0 1.663-1.663v-11.1A1.663 1.663 0 0 0 10.31-.002H1.691A1.663 1.663 0 0 0 .028 1.661v11.1a1.663 1.663 0 0 0 1.663 1.663ZM.929 1.663a.763.763 0 0 1 .762-.762h8.619a.762.762 0 0 1 .762.762v11.1a.762.762 0 0 1-.762.762H1.691a.762.762 0 0 1-.762-.761Z"
                    fill="#169de3"
                />
            </g>
            <text
                transform="translate(6 6.424)"
                fill="#169de3"
                fontSize="4"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="-3.524" y="0">
                    SVG
                </tspan>
            </text>
            <g transform="translate(2 7.424)" fill="#fff" stroke="#00a0e9">
                <circle cx="2" cy="2" r="2" stroke="none" />
                <circle cx="2" cy="2" r="1.5" fill="none" />
            </g>
            <g fill="#00a0e9" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9.191 11.924H6.809L8 9.542l1.191 2.382Z" />
                <path d="m8 10.66-.382.764h.764L8 10.66m0-2.236 2 4H6l2-4Z" />
            </g>
        </g>
    </svg>
)
