import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { IconButton, ListItemIcon, MenuItem, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import DropdownMenu from '../../components/DropdownMenu'
import Logo from '../../components/Logo'
import { LANGUAGES } from '../../constant/const'
import { useGetUser } from '../../hooks/auth/useGetUser'
import { useLanguage } from '../../hooks/useLanguage'
import { CONTAINER_PATHS, PATHS } from '../../routes/routes'
import useUserStore from '../../store/user'
import { getFirstTwoLetters } from '../../utils/helpers'
import { StyledIcons, StyledLogoBar, StyledLogotype, StyledNav } from './styles'
interface DropdownItem {
    name: string
    route: string
}

const Header = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userID } = useUserStore()
    const {
        palette: {
            darker: { main },
        },
    } = useTheme()

    const { data: user } = useGetUser(userID, {
        refetchOnMount: false,
    })

    const { currentLang, changeLang, changeLangLocally } = useLanguage()

    const SETTINGS: DropdownItem[] = [
        { name: 'navigation.company_settings', route: PATHS.COMPANY_SETTINGS },
    ]

    const USER_SETTINGS: DropdownItem[] = [
        { name: 'navigation.user_settings', route: PATHS.USER_SETTINGS },
        { name: 'signout', route: PATHS.SIGNOUT },
    ]

    useEffect(
        () => user?.lang && changeLangLocally(user?.lang),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.lang]
    )

    return (
        <StyledNav>
            <StyledLogoBar>
                <StyledLogotype to={CONTAINER_PATHS.OVERVIEW_ALL}>
                    <Logo />
                </StyledLogotype>
                <StyledIcons>
                    <DropdownMenu
                        title={t('navigation.settings')}
                        element={
                            <IconButton size="large" color="secondary">
                                <SettingsOutlinedIcon fontSize="small" />
                            </IconButton>
                        }
                        gutterTop={1}
                    >
                        {SETTINGS?.map((setting) => (
                            <MenuItem key={setting.name} onClick={() => navigate(setting.route)}>
                                <Typography textAlign="center">
                                    {t(`${setting.name}`)}
                                </Typography>
                            </MenuItem>
                        ))}
                        <DropdownMenu
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            element={
                                <MenuItem>
                                    <ListItemIcon>
                                        <ArrowLeftIcon />
                                    </ListItemIcon>
                                    <Typography textAlign="center">
                                        {t(`navigation.select_language`)}
                                    </Typography>
                                </MenuItem>
                            }
                        >
                            {LANGUAGES.map(({ lang, code }) => (
                                <MenuItem
                                    key={code}
                                    onClick={() => changeLang(code)}
                                    selected={currentLang === code}
                                    sx={{ minWidth: '240px' }}
                                >
                                    <Typography textAlign="center">{t(lang)}</Typography>
                                </MenuItem>
                            ))}
                        </DropdownMenu>
                    </DropdownMenu>
                    <DropdownMenu
                        title={t('navigation.hello', {
                            name: user?.companyName,
                        })}
                        subtitle={user?.company}
                        gutterTop={1}
                        element={
                            <IconButton
                                size="large"
                                color="secondary"
                                sx={{
                                    background: main,
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    fontSize={22}
                                    fontWeight={600}
                                >
                                    {getFirstTwoLetters(user?.companyName || '')}
                                </Box>
                            </IconButton>
                        }
                    >
                        {USER_SETTINGS.map(({ name, route }) => (
                            <MenuItem key={name} onClick={() => navigate(route)}>
                                <Typography textAlign="center">{t(`${name}`)}</Typography>
                            </MenuItem>
                        ))}
                    </DropdownMenu>
                </StyledIcons>
            </StyledLogoBar>
        </StyledNav>
    )
}

export default Header
