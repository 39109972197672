import { useMutation } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import fileService from '../../api/services/file'

export const useBatchDownload = (entityID: string) => {
    const { setErrorMsg } = useSnackbar()

    return useMutation(
        (fileIDs: string[]) => {
            return fileService.downloadFiles(entityID, fileIDs)
        },
        {
            mutationKey: 'download-files',
            onSuccess: async ({ data }) => {
                window.open(data, '_blank')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
