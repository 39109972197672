export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.59 56">
        <defs>
            <clipPath id="a">
                <path
                    d="M179 139.21h46.59v56H179Z"
                    transform="translate(-179 -139.21)"
                    fill="#213f6e"
                />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M6.564 56h33.462a6.457 6.457 0 0 0 6.457-6.457V6.457A6.457 6.457 0 0 0 40.026 0H6.564A6.457 6.457 0 0 0 .107 6.457v43.087a6.457 6.457 0 0 0 6.457 6.457ZM3.607 6.456a2.96 2.96 0 0 1 2.957-2.957h33.462a2.96 2.96 0 0 1 2.957 2.957v43.088a2.96 2.96 0 0 1-2.957 2.957H6.564a2.96 2.96 0 0 1-2.957-2.956Z"
                    fill="#213f6e"
                />
            </g>
            <text
                transform="translate(23.295 24.424)"
                fill="#213f6e"
                fontSize="16"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="-10.688" y="0">
                    IFC
                </tspan>
            </text>
            <g fill="#fff" stroke="#154072" stroke-width="3">
                <path stroke="none" d="m24 28.709 9.9 9.9-9.9 9.899-9.9-9.9z" />
                <path fill="none" d="m24 30.83 7.778 7.778L24 46.387l-7.778-7.779z" />
            </g>
        </g>
    </svg>
)
