import { Checkbox, IconButton } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledItemsWrapper = styled('section')`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: ${theme.spacing(4)};
    `}
`

export const StyledGridItem = styled('div')<{ pointer?: boolean }>`
    ${({ theme, pointer }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: ${theme.spacing(4)};
        height: 100%;

        ${pointer &&
        css`
            cursor: pointer;
            user-select: none;
        `}
    `}
`

export const StyledGridBtnWrapper = styled('div')`
    ${({ theme }) => css`
        transition: 500ms cubic-bezier(0.19, 1, 0.22, 1);
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        padding: ${theme.spacing(1)};
        width: calc(100% - '2px');
        display: flex;
        justify-content: center;
        background: white;
        gap: ${theme.spacing(1)};
        z-index: 9;
    `}
`

export const StyledGridBtn = styled(IconButton)`
    ${({ theme }) => css`
        align-self: baseline;
        border: 1px solid ${theme.palette.grey[200]};
        background: ${theme.palette.common.white};
        color: ${theme.palette.primary.main};
    `}
`

export const StyledGridItemWrapper = styled('div')`
    ${({ theme }) => css`
        position: relative;
        transition: 500ms cubic-bezier(0.19, 1, 0.22, 1);
        border: 1px solid ${theme.palette.grey[200]};
        border-radius: ${theme.spacing(2)};
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 100%;
        transition: all 0.2s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.025);
            .checkbox-wrapper {
                opacity: 1;
                visibility: visible;
            }

            .grid-btn-wrapper {
                bottom: 0;
            }
        }
    `}
`
export const StyledItem = styled('div')`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: ${theme.palette.neutral.contrastText};
        border-radius: ${theme.spacing(2)};
        transition: all 0.2s;
        width: 100%;
        height: 100%;
    `}
`

export const StyledNameOptionWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${theme.spacing(2)};
    `}
`

export const StyledImage = styled('img')`
    ${({ theme }) => css`
        height: 180px;
        width: 100%;
        border-radius: ${theme.spacing(2)};
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    `}
`

export const StyledImagePlaceholder = styled('div')`
    ${({ theme }) => css`
        height: 180px;
        width: 100%;
        background-color: ${theme.palette.grey[100]};
        border-radius: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(3)};
        background-size: cover;
        background-position: center;
        cursor: pointer;
    `}
`

export const StyledGridTitle = styled('div')`
    ${({ theme }) => css`
        cursor: pointer;
    `}
`
export const StyledCheckboxWrapper = styled('div')<{ checked?: boolean }>`
    ${({ theme, checked }) => css`
        transition: 300ms cubic-bezier(0.19, 1, 0.22, 1);
        position: absolute;
        top: ${theme.spacing(1)};
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 30px;

        ${checked &&
        css`
            opacity: 1;
            visibility: visible;
        `};
    `}
`
export const StyledCheckbox = styled(Checkbox)`
    ${({ theme }) => css``}
`
