export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="46.59" height="56">
        <defs>
            <clipPath id="a">
                <path
                    d="M179 139.21h46.59v56H179Z"
                    transform="translate(-179 -139.21)"
                    fill="#90bdc5"
                />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M6.564 56h33.462a6.457 6.457 0 0 0 6.457-6.457V6.457A6.457 6.457 0 0 0 40.026 0H6.564A6.457 6.457 0 0 0 .107 6.457v43.087a6.457 6.457 0 0 0 6.457 6.457ZM3.607 6.456a2.96 2.96 0 0 1 2.957-2.957h33.462a2.96 2.96 0 0 1 2.957 2.957v43.088a2.96 2.96 0 0 1-2.957 2.957H6.564a2.96 2.96 0 0 1-2.957-2.956Z"
                    fill="#90bdc5"
                />
            </g>
            <text
                transform="translate(23.295 24.424)"
                fill="#90bdc5"
                fontSize="16"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="-15.272" y="0">
                    MP4
                </tspan>
            </text>
            <g fill="#fff">
                <path d="M19.295 42.816V32.184l8.638 5.316-8.638 5.316Z" />
                <path
                    d="m25.07 37.5-4.275-2.631v5.262l4.276-2.631m5.724 0-13 8v-16l13 8Z"
                    fill="#90bdc5"
                />
            </g>
        </g>
    </svg>
)
