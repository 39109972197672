import { QueryDataKey } from '../types'
import { MutationKey, QueryKey } from 'react-query'

export interface QueryAuthKeys {
    getQueryUserKey: (keys?: QueryKey) => QueryKey
    signInKey: MutationKey
    signUpKey: MutationKey
    setNewPasswordKey: MutationKey
    resetPasswordKey: MutationKey
    resetPasswordSignInKey: MutationKey
    updateKey: MutationKey
}

export interface QueryKeys {
    getQueryAllKey: (keys?: QueryKey) => QueryKey
    getQueryOneKey: (keys?: QueryKey) => QueryKey
    updateKey: MutationKey
    deleteKey: MutationKey
    createKey: MutationKey
    transferKey: MutationKey
}

function useQueryKeys(key: QueryDataKey.Auth): QueryAuthKeys
function useQueryKeys(key: QueryDataKey): QueryKeys
function useQueryKeys(key: QueryDataKey): QueryAuthKeys | QueryKeys {
    switch (key) {
        case QueryDataKey.Auth:
            return {
                getQueryUserKey: (keys?: QueryKey): QueryKey =>
                    [`get-${key}`, ...((keys || []) as string[])].filter((item) => Boolean(item)),
                signInKey: [`${key}-sign-in`],
                signUpKey: [`${key}-sign-up`],
                setNewPasswordKey: [`${key}-new-password`],
                resetPasswordKey: [`${key}-reset-password`],
                resetPasswordSignInKey: [`${key}-reset-password-sign-in`],
                updateKey: [`${key}-user-update`],
            }

        default:
            return {
                getQueryAllKey: (keys?: QueryKey): QueryKey =>
                    [`get-${key}s`, ...((keys || []) as string[])].filter((item) => Boolean(item)),
                getQueryOneKey: (keys?: QueryKey): QueryKey =>
                    [`get-${key}`, ...((keys || []) as string[])].filter((item) => Boolean(item)),
                updateKey: [`update-${key}`] as MutationKey,
                deleteKey: [`delete-${key}`] as MutationKey,
                transferKey: [`transfer-${key}`] as MutationKey,
                createKey: [`create-${key}`] as MutationKey,
            }
    }
}
export default useQueryKeys
