import { styled, css } from '@mui/system'

export const StyledModalWrapper = styled('section')`
    ${({ theme }) => css`
        border-radius: 10px;
        height: calc(100vh - 20px);
        margin: 10px;
        overflow: auto;
        background-color: ${theme.palette.grey['50']};
    `}
`
export const StyledWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        min-height: calc(100% - 70px);
        padding: ${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(25)} ${theme.spacing(4)};
    `}
`
