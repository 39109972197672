import { styled, css } from '@mui/system'

export const StyledDragPreview = styled('div')`
    ${({ theme }) => css`
        max-width: 300px;
        position: fixed;
        left: -100%;
    `}
`
export const StyledFileDragPreview = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(2)};
        background: ${theme.palette.common.white};
        box-shadow: 0px 2px 12px 0px rgba(117, 117, 117, 0.12);
        padding: ${theme.spacing(2)};
        border-radius: ${theme.shape.borderRadius}px;
    `}
`
