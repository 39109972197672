import { Box, Collapse, IconButton } from '@mui/material'
import React, { FC, PropsWithChildren, useState } from 'react'
import { StyledFieldGroup, StyledTitle } from './styles'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

interface Props {
    label?: string
    noBg?: boolean
    hide?: boolean
    innerPadding?: boolean
    collapsable?: boolean
}

const DataGroup: FC<PropsWithChildren<Props>> = ({
    label,
    children,
    noBg = false,
    collapsable,
    hide,
    innerPadding,
}) => {
    const [isCollapse, setCollapse] = useState<boolean>(true)
    const handleToggle = () => setCollapse(!isCollapse)

    if (hide) return null
    if (!children) return null

    return (
        <Box mb={3}>
            {label && (
                <Box mb={3} display={'flex'} width={'100%'} justifyContent={'space-between'}>
                    <StyledTitle>{label}</StyledTitle>

                    {collapsable && (
                        <IconButton
                            size="large"
                            onClick={handleToggle}
                            sx={{
                                alignSelf: 'center',
                                transform: `rotate(${isCollapse ? '0' : '180deg'})`,
                                '&:hover:not(.active)': {
                                    background: 'transparent',
                                },
                            }}
                        >
                            <ArrowDropUpIcon />
                        </IconButton>
                    )}
                </Box>
            )}

            {collapsable && (
                <Collapse in={isCollapse} timeout="auto">
                    <StyledFieldGroup noBg={noBg}>
                        <Box p={innerPadding ? 2 : 0}>{children}</Box>
                    </StyledFieldGroup>
                </Collapse>
            )}

            {!collapsable && (
                <StyledFieldGroup noBg={noBg}>
                    <Box p={innerPadding ? 2 : 0}>{children}</Box>
                </StyledFieldGroup>
            )}
        </Box>
    )
}

export default DataGroup
