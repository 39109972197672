import { Button } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import React, { FC } from 'react'
import { StyledButtonWrapper } from '../../theme/styles'
import { StyledSubmitButtonsWrapper } from './styles'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

interface Props {
    onCancelClick?: () => void
    loading: boolean
    submitDisabled?: boolean
    hide?: boolean
    submitButtonText?: string
}

const FormSubmitFooter: FC<Props> = ({
    loading,
    submitButtonText,
    submitDisabled,
    hide,
    onCancelClick,
}) => {
    const { t } = useTranslation()

    if (hide) return null
    return (
        <StyledSubmitButtonsWrapper>
            <StyledButtonWrapper justifyContent="end">
                <Button
                    onClick={onCancelClick}
                    size="large"
                    variant="text-icon"
                    startIcon={<ArrowLeftIcon />}
                >
                    {t('cancel')}
                </Button>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    disabled={Boolean(submitDisabled)}
                    endIcon={<ArrowRightIcon />}
                >
                    {submitButtonText || t('save_changes')}
                </LoadingButton>
            </StyledButtonWrapper>
        </StyledSubmitButtonsWrapper>
    )
}

export default FormSubmitFooter
