import { Box, Typography, useTheme } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import LocalNavigation from '../../../../../../components/LocalNavigation'
import { useGetApartments } from '../../../../../../hooks/apartment/useGetApartments'
import OverviewApartmentList from '../../../../../../modules/OverviewApartmentList'
import useParamManage from '../../../../../../hooks/useParamManage'

const OverviewUnit = () => {
    const { unitID } = useParamManage()
    const {
        palette: {
            secondary: { main },
        },
    } = useTheme()

    const { data: apartments, isLoading } = useGetApartments(unitID, {
        queryKey: [unitID],
        enabled: Boolean(unitID),
        refetchOnMount: false,
    })

    return (
        <Box>
            <Box
                display={'flex'}
                justifyContent="space-between"
                flexDirection={'row'}
                alignItems="center"
                mb={3}
            >
                <Typography color={main} fontWeight={600}>
                    {t('apartment.all_apartments')}
                </Typography>
                <LocalNavigation available={['list', 'grid']} />
            </Box>

            <OverviewApartmentList data={apartments || []} isLoading={isLoading} />
        </Box>
    )
}

export default OverviewUnit
