import * as yup from 'yup'

const note = {
    name: yup.string().required('form_error.required'),
    value: yup.string().required('form_error.required'),
}

const schema = yup.object({
    name: yup.string().required('form_error.required'),
    manufacture: yup.string().required('form_error.required'),
    location: yup.string().required('form_error.required'),
    type: yup.string().required('form_error.required'),
    date: yup.date(),
    isMulti: yup.boolean(),
    base: yup.string().required('form_error.required'),
    subType: yup.string(),
    number: yup.string(),
    images: yup.array(),
    notes: yup.array().of(yup.object().shape(note)),
})

export default schema
