import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { UserData } from 'user'
import { createUserData } from '../../api/data/user'
import authService from '../../api/services/auth'

export const useGetUser = (userID: string, options: UseQueryOptions): UseQueryResult<UserData> => {
    const { getQueryUserKey } = useQueryKeys(QueryDataKey.Auth)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryUserKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await authService.getUser(userID)
            return createUserData(data)
        },
    }) as UseQueryResult<UserData>
}
