import { ButtonBase } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledWrapper = styled('div')<{ large: boolean; height?: number }>`
    ${({ large, height }) => css`
        position: relative;
        height: ${large ? 'calc(100vh - 340px)' : '500px'};
        transition: 300ms;
        transition-delay: 800ms;

        ${height &&
        css`
            height: ${height}px;
        `}
        .d-none {
            display: none;
        }
        .gm-style-iw {
            padding: 0;
            box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.001);
        }
        .gm-style-iw-d {
            overflow: auto !important;
        }
    `}
`

export const StyledMapTypeButtonsWrapper = styled('div')`
    ${({ theme }) => css`
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: ${theme.spacing(2)};
        right: ${theme.spacing(2)};
        border-radius: ${theme.spacing(1)};
        overflow: hidden;
    `}
`

export const StyledMapTypeButton = styled(ButtonBase, {
    shouldForwardProp: (props) => props !== 'isActive',
})<{
    isActive: boolean
}>`
    ${({ theme, isActive }) => css`
        color: ${theme.palette.neutral.main};
        font-size: 14px;
        cursor: pointer;
        border: none;
        padding: ${theme.spacing(2)} ${theme.spacing(4)};
        position: relative;
        z-index: 4;
        background: transparent;

        &:before {
            opacity: 0.8;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: -1;
            height: 100%;
            background-color: ${theme.palette.neutral.contrastText};
        }

        ${isActive &&
        css`
            color: ${theme.palette.common.white};
            &:before {
                background-color: ${theme.palette.primary.main};
            }
        `}

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    `}
`

export const StyledZoomButtonsWrapper = styled('div')`
    ${({ theme }) => css`
        position: absolute;
        width: 40px;
        height: 120px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        bottom: ${theme.spacing(4)};
        right: ${theme.spacing(2)};
        border-radius: ${theme.spacing(1)};
        overflow: hidden;
    `}
`

export const StyledZoomButton = styled(ButtonBase)`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: ${theme.palette.neutral.contrastText};
        border: none;
        color: ${theme.palette.grey['800']};
        cursor: pointer;
        border-bottom: 1px solid ${theme.palette.grey['200']};

        &:hover {
            svg {
                fill: ${theme.palette.secondary.main};
            }
        }
    `}
`
