import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledForm, StyledWrapper } from '../styles'
import Input from '../components/Input'
import { Box } from '@mui/system'
import DataItem from '../../components/DataItem'
import { useEffectOnce } from 'usehooks-ts'
import { MaintenanceParams } from 'maintenance'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import Autocomplete from '../components/Autocomplete'
import { Option } from 'forms'
import FormData from '../components/FormData'
import LoaderWrapper from '../../components/LoaderWrapper'
import TaskListData from '../../modules/TaskListData'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Divider from '../../components/Divider'
import { TaskList } from 'tasks'
import DateInput from '../components/DateInput'
import { MAINTENANCE_BASE } from '../../constant/taxonomy'
import Select from '../../components/Select'
import NoteFields from '../components/NoteFields'
import DataGroup from '../../components/DataGroup'
import { MONTH_DATE_FORMAT } from '../../constant/const'
import { MaintenanceBase } from '../../types/constant'
import useNavUtils from '../../hooks/useNavUtils'
import useParamManage from '../../hooks/useParamManage'
import ImageUpload from '../../modules/ImageUpload'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    maintenance?: MaintenanceParams
    onSubmit: (values: MaintenanceParams) => void
    isLoading: boolean
    types?: Option[]
    isLoadingTask?: boolean
    tasks?: TaskList[]
    onCreateTask?: () => void
    onCancelClick: () => void
}

const FormMaintenance: FC<Props> = ({
    maintenance,
    types,
    isLoading,
    isLoadingTask,
    tasks,
    onCreateTask,
    onCancelClick,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const { lastParamID } = useParamManage()
    const { navigateMaintenance } = useNavUtils()
    const isReadAction = Boolean(tasks)

    const methods = useForm<MaintenanceParams>({
        resolver: yupResolver(schema),
        defaultValues: maintenance || {
            parentID: lastParamID,
            date: new Date(),
            name: '',
            manufacture: '',
            location: '',
            images: [],
            notes: [],
            title: '',
            responsible: '',
            type: '',
            subType: '',
            base: '',
            number: 0,
            isMulti: false,
        },
    })

    const {
        reset,
        control,
        handleSubmit,
        formState: { isDirty },
    } = methods

    const handleUploadImages = () => navigateMaintenance(maintenance?.id)('UPDATE')

    useEffectOnce(() => () => reset({}, { keepValues: false }))

    const images = useWatch({
        control,
        name: 'images',
    })

    const base = useWatch({
        control,
        name: 'base',
    })

    const isMulti = useWatch({
        control,
        name: 'isMulti',
    })

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledWrapper>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t(`${'general_information'}`)}>
                            <>
                                {maintenance?.title && (
                                    <DataItem label={t('title')}>{maintenance?.title}</DataItem>
                                )}
                                <FormData renderAsData={isReadAction}>
                                    <Input name="name" label={t('name')} />
                                    <Input
                                        name="manufacture"
                                        label={t('maintenance.manufacture')}
                                    />
                                    <Input name="location" label={t('location')} />
                                    <DateInput
                                        name="date"
                                        label={t('date')}
                                        format={MONTH_DATE_FORMAT}
                                    />
                                    <Autocomplete name={`type`} label={t('type')} options={types} />
                                    <Input name="subType" label={t('subtype')} />
                                    <Input name="responsible" label={t('responsible')} />
                                    <Select
                                        name="base"
                                        label={t('base')}
                                        fullWidth
                                        options={MAINTENANCE_BASE}
                                    />
                                    {base === MaintenanceBase.Mep && (
                                        <Input
                                            variant={'checkbox'}
                                            name="isMulti"
                                            label={t('multi')}
                                        />
                                    )}

                                    {base === MaintenanceBase.Mep && isMulti && (
                                        <Input type="number" name="number" label={t('number')} />
                                    )}
                                </FormData>
                            </>
                        </DataGroup>

                        <DataGroup label={t('notes')}>
                            <NoteFields renderAsData={isReadAction} />
                        </DataGroup>
                    </Box>
                    <Box flexBasis={'50%'} maxWidth={'50%'}>
                        <DataGroup label={t('photo_gallery')} innerPadding>
                            <ImageUpload
                                images={images}
                                name="images"
                                onUploadEnd={isReadAction ? handleUploadImages : undefined}
                                variant={isReadAction ? 'gallery' : 'list'}
                            />
                        </DataGroup>
                    </Box>
                </StyledWrapper>

                {isReadAction && (
                    <>
                        <Divider marginSize={10} />
                        <DataGroup label={t('maintenance.todos')}>
                            <LoaderWrapper isLoading={Boolean(isLoadingTask)}>
                                <TaskListData
                                    tasks={tasks || []}
                                    renderOnBottom={(renderPagination) => {
                                        return (
                                            <Box
                                                display="flex"
                                                justifyContent={'space-between'}
                                                sx={{ width: '100%' }}
                                                gap={5}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    onClick={onCreateTask}
                                                    sx={{ m: 2 }}
                                                    startIcon={<AddIcon />}
                                                >
                                                    {t('task.add_task')}
                                                </Button>
                                                <Box>{renderPagination && renderPagination()}</Box>
                                            </Box>
                                        )
                                    }}
                                />
                            </LoaderWrapper>
                        </DataGroup>
                    </>
                )}

                <FormSubmitFooter
                    submitDisabled={!isDirty}
                    onCancelClick={onCancelClick}
                    loading={isLoading}
                    submitButtonText={t('save_changes')}
                    hide={isReadAction}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormMaintenance
