export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14.424">
        <defs>
            <clipPath id="a">
                <path d="M0 0h12v14.424H0Z" fill="#15b71a" />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M1.691 14.424h8.619a1.663 1.663 0 0 0 1.663-1.663v-11.1A1.663 1.663 0 0 0 10.31-.002H1.691A1.663 1.663 0 0 0 .028 1.661v11.1a1.663 1.663 0 0 0 1.663 1.663ZM.929 1.663a.763.763 0 0 1 .762-.762h8.619a.762.762 0 0 1 .762.762v11.1a.762.762 0 0 1-.762.762H1.691a.762.762 0 0 1-.762-.761Z"
                    fill="#15b71a"
                />
            </g>
            <path d="m.06 11.421 3.53-3.528.635.636-3.528 3.529Z" fill="#15b71a" />
            <path d="m3.587 7.875 4.082 4.081-.637.637L2.951 8.51Z" fill="#15b71a" />
            <text
                transform="translate(6 6.424)"
                fill="#15b71a"
                fontSize="4"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="-3.598" y="0">
                    JPG{' '}
                </tspan>
            </text>
            <path d="m5.06 10.421 3.53-3.528.635.636-3.528 3.529Z" fill="#15b71a" />
            <path d="m8.587 6.875 3.35 3.35-.636.637-3.35-3.35Z" fill="#15b71a" />
        </g>
    </svg>
)
