import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import maintenanceService from '../../api/services/maintenance'
import { MaintenanceParams } from 'maintenance'
import {
    createMaintenanceApiData,
    createMaintenanceRemoveImagesData,
} from '../../api/data/maintenance'
import useQueryKeys from '../useQueryKeys'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import useNavUtils from '../useNavUtils'
export const useUpdateMaintenance = (maintenanceID: string) => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { updateKey, getQueryAllKey, getQueryOneKey } = useQueryKeys(QueryDataKey.Maintenance)
    const { navigateMaintenance } = useNavUtils()

    return useMutation(
        async (params: MaintenanceParams) => {
            const isImageDeleteRequest = params?.images?.filter((img) => Boolean(!img))?.length

            if (isImageDeleteRequest)
                await maintenanceService.update(
                    maintenanceID,
                    createMaintenanceRemoveImagesData(params)
                )

            return maintenanceService.update(maintenanceID, await createMaintenanceApiData(params))
        },
        {
            mutationKey: updateKey,
            onSuccess: async () => {
                setSuccessMsg(t('updated'))
                await queryClient.invalidateQueries({ queryKey: getQueryOneKey([maintenanceID]) })
                await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateMaintenance()('LIST')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
