import { Tab, Tabs, css, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const StyledNavLink = styled(NavLink)`
    ${({ theme }) => css`
        height: 100%;
        flex: 1;
        width: 100%;
        padding: ${theme.spacing(4)};
        display: flex;
        align-items: center;
        color: ${theme.palette.neutral.main};
        text-decoration: none;
        gap: ${theme.spacing(1)};
    `}
`

export const StyledTabs = styled(Tabs)`
    display: flex;
    list-style: none;
    align-items: center;
    height: 100%;
    .MuiTabs-scroller {
        height: 100%;
        .MuiTabs-flexContainer {
            height: 100%;
            button {
                height: 100%;
            }
        }
    }
`
export const StyledTab = styled(Tab)`
    ${({ theme }) => css`
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        text-transform: initial;
        transition: 150ms;
        padding: 0;

        &.Mui-selected p {
            color: ${theme.palette.secondary.main};
        }
        &:hover {
            p {
                color: ${theme.palette.secondary.main};
            }
            background-color: ${theme.palette.grey[100]};
        }
    `}
`
