import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import objectService from '../../api/services/object'
import useNavUtils from '../useNavUtils'
export const useDeleteObject = (objectID: string) => {
    const { setSuccessMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { deleteKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Object)
    const { navigateContainer } = useNavUtils()

    return useMutation(
        async () => {
            return objectService.delete(objectID)
        },
        {
            mutationKey: deleteKey,
            onSuccess: () => {
                setSuccessMsg(t('deleted'))
                navigateContainer()('OVERVIEW')
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
            },
        }
    )
}
