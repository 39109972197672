import { Button, MenuItem } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledSelectButton = styled(Button)`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        padding: ${theme.spacing(2)} ${theme.spacing(3)};
    `}
`
export const StyledMenuItem = styled(MenuItem)<{
    active?: boolean
}>`
    ${({ theme, active }) => css`
        padding: ${theme.spacing(3)} ${theme.spacing(4)};

        &:not(:last-child) {
            border-bottom: 1px solid ${theme.palette.grey[200]};
        }

        ${active &&
        css`
            color: ${theme.palette.secondary.main};
            background: ${theme.palette.primary.light};
        `}

        &:hover {
            color: ${theme.palette.primary.main};
        }
    `}
`
