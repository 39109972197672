import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { getLocalStorage } from '../utils/storage'
import { PATHS } from './routes'
import { TOKEN_KEY } from '../constant/const'

type Props = {
    children: JSX.Element
}

const RequireAuth = ({ children }: Props) => {
    const token = getLocalStorage(TOKEN_KEY)
    let location = useLocation()

    if (!token) {
        return <Navigate to={PATHS.SIGNIN} state={{ from: location }} replace />
    }

    return children
}

export default RequireAuth
