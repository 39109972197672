import { Collapse } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledTreeItem = styled('div')`
    ${({ theme }) => css`
        user-select: none;
        flex: 1;
        position: relative;
    `}
`

export const StyledTreeItemLabel = styled('div')<{
    isActive?: boolean
}>`
    ${({ theme, isActive }) => css`
        font-size: 13px;
        position: relative;
        z-index: 2;
        padding: ${theme.spacing(4)} ${theme.spacing(3)};
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${theme.palette.grey['200']};

        ${isActive &&
        css`
            font-weight: 600;
            color: ${theme.palette.secondary.main};
        `}
    `}
`

export const StyledCollapse = styled(Collapse)`
    ${({ theme }) => css`
        margin-left: ${theme.spacing(3)};
    `}
`
