import styled from './styled'
import { Box, css } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledButtonWrapper = styled(Box)`
    display: flex;
    margin: ${({ theme }) => `${theme.spacing(4)}`} 0;
    gap: ${({ theme }) => `${theme.spacing(2)}`};
`

export const StyledLinks = styled(Box)`
    margin: ${({ theme }) => `${theme.spacing(5)}`} 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.425rem;

    &:last-child {
        margin-bottom: 0;
    }
`

export const StyledLink = styled(Link)`
    display: inline-flex;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.main};

    &:hover {
        text-decoration: underline;
    }
`

export const StyledFormItem = styled('div')<{
    noBorder?: boolean
}>`
    ${({ theme, noBorder }) => css`
        display: flex;
        align-items: stretch;
        flex-basis: 100%;
        ${noBorder !== true &&
        css`
            border-bottom: 1px solid ${theme.palette.grey['200']};
        `}
    `}//
`
export const StyledGeneralInfo = styled('div')`
    display: flex;

    ${({ theme }) => css`
        gap: ${theme.spacing(5)};
    `}
`

export const StyledCloseModalBar = styled('div')`
    ${({ theme }) => css`
        box-shadow: ${theme.shadows[1]};
        display: flex;
        background-color: ${theme.palette.common.white};
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing(2)} ${theme.spacing(4)};
        position: relative;
        z-index: 9;
        bottom: 1px;
        height: 70px;
    `}
`

export const StyledModalArrows = styled('div')`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        width: 110px;
        margin-left: auto;
    `}
`

export const StyledGridWrapper = styled('div')`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: ${theme.spacing(4)};
    `}
`
