import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { PATHS } from '../../../routes/routes'
import { StyledLink, StyledLinks } from '../../../theme/styles'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

type Props = {}

const ResetPasswordEmailSent = (props: Props) => {
    const { t } = useTranslation()
    const { state } = useLocation()

    const email = state?.email

    if (!email) return null
    return (
        <Box>
            <Box mb={6}>
                <Typography variant="h3">{t('reset_password_email_sent.title')}</Typography>
            </Box>
            <Box mb={2}>
                <Typography
                    variant="body1"
                    mb={4}
                    dangerouslySetInnerHTML={{
                        __html: t('reset_password_email_sent.email_sent', {
                            email: email,
                        }),
                    }}
                />
                <Typography variant="body1">
                    {t('reset_password_email_sent.email_failure')}
                </Typography>
            </Box>
            <Box mb={2}>
                <Button
                    component={Link}
                    to={PATHS.SIGNIN}
                    size="large"
                    variant="text"
                    startIcon={<ArrowLeftIcon />}
                    fullWidth
                    sx={{ paddingLeft: 0 }}
                >
                    {t('back_to_login')}
                </Button>
            </Box>
            <Divider />
            <StyledLinks>
                <StyledLink to={PATHS.SUPPORT}>{t('contact_support')}</StyledLink>
            </StyledLinks>
        </Box>
    )
}

export default ResetPasswordEmailSent
