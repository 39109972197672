import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledPageTitle, StyledPublicPage } from '../styles'
import { Typography } from '@mui/material'
import { StyledLink } from '../../../theme/styles'
import { useLanguage } from '../../../hooks/useLanguage'

const CompanyDetails = () => {
    const { t } = useTranslation(['company-details'])
    const { t: t2 } = useTranslation(['common'])
    const { currentLang } = useLanguage()
    return (
        <StyledPublicPage>
            <StyledPageTitle>{t('title')}</StyledPageTitle>
            <Typography fontWeight={600}>{t('subtitle')}</Typography>
            <Typography>{t('person')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('address_subtitle')}</Typography>
            <Typography maxWidth={'180px'}>{t2('support.address')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle2')}</Typography>
            <Typography>{t('person')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle3')}</Typography>
            <Typography>
                Web:{' '}
                <StyledLink as="a" href={t2('omnystate_link')} target="_blank">
                    {t2('omnystate_link')}
                </StyledLink>
            </Typography>
            Email:{' '}
            <StyledLink as="a" href={`mailto:${t('email')}`} target="_blank">
                {t('email')}
            </StyledLink>
            <br />
            <br />
            <Typography fontWeight={600}>{t('subtitle4')}</Typography>
            <Typography>{t('value4')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle5')}</Typography>
            <Typography>{t('value5')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle6')}</Typography>
            <Typography>{t('value6')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle7')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle8')}</Typography>
            <Typography>{t('p1')}</Typography>
            <br />
            <Typography fontWeight={600}>{t('subtitle9')}</Typography>
            <Typography>{t('p2')}</Typography>
            <br />
            {currentLang === 'de' && (
                <>
                    <Typography fontWeight={600}>{t('subtitle10')}</Typography>
                    <Typography>{t('p3')}</Typography>
                </>
            )}
        </StyledPublicPage>
    )
}

export default CompanyDetails
