import React from 'react'
import FormUser from '../../../../../../forms/FormUser'
import { useUpdateUser } from '../../../../../../hooks/auth/useUpdateUser'
import useUserStore from '../../../../../../store/user'
import { useGetUser } from '../../../../../../hooks/auth/useGetUser'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'

const UserSettingsPersonal = () => {
    const { userID } = useUserStore()
    const { mutate: updateUserMutate, isLoading } = useUpdateUser(userID)
    const { data: user, isLoading: isLoadingGetUSer } = useGetUser(userID, {})

    return (
        <LoaderWrapper isLoading={isLoadingGetUSer}>
            <>
                {user && <FormUser onSubmit={updateUserMutate} user={user} isLoading={isLoading} />}
            </>
        </LoaderWrapper>
    )
}

export default UserSettingsPersonal
