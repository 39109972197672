import { Box } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledPlaceResults = styled(Box)`
    ${({ theme }) => css`
        background: ${theme.palette.common.white};
        border-radius: 5px;
        position: absolute;
        left: 20px;
        top: 20px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-width: 300px;
        width: 100%;
    `}
`
export const StyledResultsHeading = styled(Box)`
    ${({ theme }) => css`
        background: ${theme.palette.common.white};
        position: sticky;
        left: 0;
        top: 0;
        z-index: 9;
        width: 100%;
        box-shadow: 0px 2px 12px 0px rgba(117, 117, 117, 0.06);
        padding: ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)};
    `}
`
