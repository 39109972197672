import { useRef } from 'react'
import axiosInstance, { getAccessToken } from '../axios'
import { useEffectOnce } from 'usehooks-ts'
import { setLocalStorage } from '../../utils/storage'
import axios from 'axios'
import { AUTH_ERRORS, TOKEN_KEY } from '../../constant/const'

export const ResponseInterceptor = () => {
    // const navigate = useNavigate();

    const interceptorId = useRef<number | null>(null)

    useEffectOnce(() => {
        interceptorId.current = axiosInstance.interceptors.response.use(
            undefined,
            async (error: { config: any; response: any }) => {
                const originalRequest = error?.config
                const response = error?.response?.data
                const isTokenValidationError = AUTH_ERRORS.INVALID_TOKEN === response?.[0]

                if (
                    error?.response?.status === 401 &&
                    !originalRequest._retry &&
                    isTokenValidationError
                ) {
                    originalRequest._retry = true
                    const accessToken = await getAccessToken()
                    if (!accessToken) return Promise.reject(error)
                    setLocalStorage(TOKEN_KEY, accessToken)

                    axios.defaults.headers.common['X-CSRF-Token'] = `${accessToken}`
                    return axiosInstance(originalRequest)
                }

                // if (error?.response?.status === 403) return navigate(PATHS.SIGNOUT);
                // if (error?.response?.status === 406) return navigate(PATHS.DASHBOARD);
                // if (error?.response?.status === 500)
                //     error.response.data = error.response.statusText;

                return Promise.reject(error)
            }
        )

        return () => {
            axiosInstance.interceptors.response.eject(interceptorId.current as number)
        }
    })

    return null
}
