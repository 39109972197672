import {
    NewPasswordApiParams,
    ResetPasswordApiParams,
    ResetPasswordSignInParams,
    SignInParams,
    UserParams,
} from '../../types/auth'
import axiosInstance from '../axios'
import { createUserApiData } from '../data/user'

const authService = {
    getToken: () => {
        return axiosInstance.post('/user/token', {})
    },
    signIn: (params: SignInParams) => {
        return axiosInstance.post('/user/login', params)
    },
    signUp: (params: UserParams) => {
        return axiosInstance.post('/user', createUserApiData(params))
    },
    signOut: () => {
        return axiosInstance.post('/user/logout', {})
    },
    resetPasswordSendMail: (params: ResetPasswordApiParams) => {
        return axiosInstance.post('/user/request_new_password', params)
    },
    resetPasswordSignIn: (params: ResetPasswordSignInParams) => {
        return axiosInstance.post('/user/user_pass_reset', params)
    },
    setNewPassword: (uid: string, params: NewPasswordApiParams, token: string = '') => {
        return axiosInstance.put(`/user/${uid}?pass-reset-token=${token}`, params)
    },
    updateUser: (
        uid: string,
        params: UserParams | NewPasswordApiParams,
        queryParams: string = ''
    ) => {
        return axiosInstance.put(`/user/${uid}?${queryParams}`, params)
    },
    getUser: (userID: string) => {
        return axiosInstance.get(`/user/${userID}`, {})
    },
}

export default authService
