import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import containerService from '../../api/services/container'
export const useDeleteContainer = (containerID: string): any => {
    const { setSuccessMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { deleteKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Container)

    return useMutation(
        async () => {
            return containerService.delete(containerID)
        },
        {
            mutationKey: deleteKey,
            onSuccess: async () => {
                await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                setSuccessMsg(t('deleted'))
            },
        }
    )
}
