import create, { StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'

interface UserState {
    userID: string
    unmountUser: () => void
    setUser: (userID: string) => void
}

type Persistance = (
    config: StateCreator<UserState>,
    options: PersistOptions<UserState>
) => StateCreator<UserState>

const useUserStore = create<UserState>(
    (persist as unknown as Persistance)(
        (set) => ({
            userID: '',
            setUser: (userID?: string) =>
                set((state) => ({
                    ...state,
                    userID,
                })),
            unmountUser: () =>
                set((state) => ({
                    ...state,
                    userID: '',
                })),
        }),
        {
            name: 'user',
        }
    )
)

export default useUserStore
