import React from 'react'
import Dialog from '../../../../../../components/Dialog'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import { useDeleteTask } from '../../../../../../hooks/task/useDeleteTask'
import { useGetTasks } from '../../../../../../hooks/task/useGetTasks'

const DeleteTask = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { taskID, lastParamID } = useParamManage()
    const { mutate: deleteMaintenance, isLoading: isLoadingDelete } = useDeleteTask()

    const { data: tasks } = useGetTasks(lastParamID, {
        queryKey: [lastParamID],
        refetchOnMount: false,
    })

    const task = tasks?.find((m) => m?.nid === taskID)

    return (
        <Dialog
            setOpen={goBack}
            onAcceptClick={() => deleteMaintenance(taskID)}
            onAcceptText={t('delete')}
            title={t('delete_item.title', { name: task?.title })}
            isLoading={isLoadingDelete}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteTask
