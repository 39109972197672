import { AxiosError } from 'axios'
import { ImageData } from '../types/index'
import { FileExtList } from '../components/Icon'
import { SEARCH_INPUT_MIN } from '../constant/const'

export const hideEmail = (email: string): string => {
    let hiddenEmail = ''
    for (let i = 0; i < email.length; i++) {
        if (
            (i > 0 && i < email.indexOf('@') - 1) ||
            (i < email.lastIndexOf('.') - 1 && i > email.indexOf('@') + 1)
        ) {
            hiddenEmail += '*'
        } else {
            hiddenEmail += email[i]
        }
    }
    return hiddenEmail
}

export const getFirstTwoLetters = (text: string) => text?.slice(0, 2)?.toUpperCase()

// @ts-ignore
export const stripObj = (obj) => {
    if (typeof obj === 'string') return obj
    // @ts-ignore
    return Object.values(obj)
        .reduce(
            (prev: any, curr: any) =>
                typeof curr === 'object' ? [...prev, stripObj(curr)] : [...prev, curr],
            []
        )
        .join(' ')
}

export const removeHtml = (msg: string) => msg.replace(/<[^>]*>?/gm, '')

export const getTextError = (err: AxiosError): string => {
    return (err?.response?.statusText || '').split(' : ')?.reverse()?.[0]
}

export const getBase64 = (url: string) => {
    if (!url) return ''
    return new Promise(async (resolve, _) => {
        const response = await fetch(url)
        const blob = await response.blob()
        const fileReader = new FileReader()
        fileReader.readAsDataURL(blob)
        fileReader.onloadend = function () {
            resolve((fileReader.result as string).split(',')[1])
        }
    })
}

export const getBase64Items = (images: ImageData[]) => {
    if (!images) return []

    const promises = images.map((image) => getBase64(image.src))
    return Promise.all(promises)
}

export const getImagesToApiUpload = (images?: ImageData[]) =>
    images?.filter((img) => !!img.src?.includes('blob')) || []

export const getRemovedImagesData = (images?: ImageData[]) => {
    let imageReq = {}

    images?.forEach((img, i) => {
        if (img) return
        imageReq = {
            ...imageReq,
            [i]: 'delete',
        }
    })

    return imageReq
}

export const getFilterData = <T extends {}>(data: T[], searchValue: string) => {
    if (searchValue.length <= SEARCH_INPUT_MIN) return data

    return data?.filter((item) => {
        return !!Object.values(item).filter((val: unknown) =>
            val?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
        ).length
    }) as T[]
}

export const getFileName = (path?: string) => path?.split('/')?.reverse()?.[0]

export const throttle = (func: Function, interval: number) => {
    let shouldFire = true
    return function () {
        if (shouldFire) {
            func()
            shouldFire = false
            setTimeout(() => {
                shouldFire = true
            }, interval)
        }
    }
}

export const getUrlExt = (text: string): FileExtList => {
    return (text.match(/[^/.]+(?=\.*$)/i)?.[0] || 'abc') as FileExtList
}

export const getPercentages = (values: number[], total: number | undefined): string[] => {
    return values.map((v) => {
        const value = (v / (total || 0)) * 100
        return `${Math.floor(value * 100) / 100}%`
    })
}
export const createFormData = (data: any[]) => {
    let formData = new FormData()
    for (let i = 0; i < data.length; i++) {
        formData.append('files[]', data[i])
    }

    return formData
}

export const getCurrencyFormat = (number: number | string): string =>
    `${number}€`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const doSort = (obj: { [key: string]: number }) => {
    return Object.fromEntries(Object.entries(obj).sort(([, a], [, b]) => b - a))
}
