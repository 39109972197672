import { useState, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { getLocalStorage } from '../utils/storage'

const usePersistState = (storageKey: string, initialState: any) => {
    const [state, setInternalState] = useState(initialState)
    const [, setStorage] = useLocalStorage(storageKey, initialState)

    useEffect(() => {
        const storageInBrowser = getLocalStorage(storageKey)
        if (storageInBrowser) {
            setInternalState(storageInBrowser)
        }
    }, [storageKey])

    const setState = useMemo(
        () => (newState: any) => {
            setStorage(newState)
            setInternalState(newState)
        },
        [setInternalState, setStorage]
    )

    return [state, setState]
}

export default usePersistState
