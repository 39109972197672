import React from 'react'
import { useUpdateApartment } from '../../../../../../hooks/apartment/useUpdateApartment'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import FormApartment from '../../../../../../forms/FormApartment'
import { useGetApartment } from '../../../../../../hooks/apartment/useGetApartment'
import useParamManage from '../../../../../../hooks/useParamManage'
import useNavUtils from '../../../../../../hooks/useNavUtils'

const UpdateApartment = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { apartmentID } = useParamManage()
    const { mutate: updateApartment, isLoading } = useUpdateApartment()
    const { data: attributes } = useGetAttributes()

    const { data: apartment } = useGetApartment(apartmentID, {
        queryKey: [apartmentID],
    })

    return (
        <Modal title={t('apartment.edit_apartment')} onClose={goBack}>
            <LoaderWrapper isLoading={!attributes || !Boolean(apartment)}>
                <FormApartment
                    onCancelClick={goBack}
                    apartment={apartment}
                    isLoading={isLoading}
                    onSubmit={updateApartment}
                    attributes={attributes}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateApartment
