import { styled, css } from '@mui/system'

export const StyledImageItem = styled('div')`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.spacing(4)};
        padding: ${theme.spacing(3)};
        &:not(:last-child) {
            border-bottom: 1px solid ${theme.palette.grey['200']};
        }
    `}
`
