import { MainLanguages, QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import otherService from '../../api/services/other'

export const useUpdateLang = () => {
    const { updateKey } = useQueryKeys(QueryDataKey.Language)

    return useMutation((lang: keyof typeof MainLanguages) => otherService.updateLanguage(lang), {
        mutationKey: updateKey,
    })
}
