import React from 'react'
import Dialog from '../../../../../../components/Dialog'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useDeleteUnit } from '../../../../../../hooks/unit/useDeleteUnit'
import { useGetUnits } from '../../../../../../hooks/unit/useGetUnits'

const DeleteUnit = () => {
    const { t } = useTranslation()
    const { unitID, objectID } = useParamManage()
    const { goBack } = useNavUtils()
    const { mutate: deleteUnit, isLoading } = useDeleteUnit()

    const { data: units } = useGetUnits(objectID, {
        queryKey: [objectID],
    })
    const unit = units?.find((c) => c?.nid === unitID)

    return (
        <Dialog
            onAcceptClick={deleteUnit}
            onAcceptText={t('delete')}
            onCancelClick={goBack}
            title={t('delete_item.title', { name: unit?.title })}
            isLoading={isLoading}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteUnit
