import { MaintenanceList } from 'maintenance'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { createMaintenanceList } from '../../api/data/maintenance'
import maintenanceService from '../../api/services/maintenance'
import { QueryDataKey } from '../../types'
import useQueryKeys from '../useQueryKeys'

export const useGetMaintenances = (
    parentID?: string,
    options?: UseQueryOptions
): UseQueryResult<MaintenanceList[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Maintenance)
    const { queryKey, ...restOptions } = options || {}

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await maintenanceService.getAll(parentID)
            return createMaintenanceList(data)
        },
    }) as UseQueryResult<MaintenanceList[]>
}
