import { styled, css } from '@mui/system'

export const StyledFormWrapper = styled('div')`
    ${({ theme }) => css`
        max-width: 100vw;
    `}
`

export const StyledForm = styled('form')`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `}
`

export const StyledWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        column-gap: ${theme.spacing(6)};
    `}
`
export const StyledNestedItems = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${theme.palette.grey['200']};

        &:nth-last-of-type(1) {
            border: 0;
        }
        .input-wrapper {
            padding-right: 0;
        }
    `}
`
export const StyledFieldGroup = styled('div')`
    ${({ theme }) => css`
        flex-basis: 50%;
        background-color: ${theme.palette.neutral.contrastText};
        border-radius: ${theme.spacing(2)};
    `}
`
