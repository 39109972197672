import { Box, Checkbox, FormControl, SxProps, TextField } from '@mui/material'
import { AnimationEvent, FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DataItem from '../../../components/DataItem'
import { StyledFormItem } from '../../../theme/styles'
import InputPassword from '../InputPassword'
import { StyledColorTextField, StyledTextarea } from './styles'

interface Props {
    label?: string
    labelSmall?: string
    name: string
    disabled?: boolean
    noBorder?: boolean
    type?: string
    showLengthUnit?: boolean
    sx?: SxProps
    variant?: 'default' | 'password' | 'textarea' | 'checkbox' | 'color'
    width?: string
    autoComplete?: string
    endAdornment?: JSX.Element
}

const Input: FC<Props> = ({
    label = '',
    labelSmall,
    name,
    disabled = false,
    showLengthUnit,
    noBorder,
    autoComplete,
    endAdornment,
    type,
    sx,
    variant = 'default',
}) => {
    const { t } = useTranslation()
    const { control } = useFormContext()
    const [isAutofill, setAutofill] = useState(false)

    const autofillProps = {
        onAnimationStart: ({ animationName }: AnimationEvent<HTMLDivElement>) => {
            animationName === 'mui-auto-fill' && setAutofill(true)
        },
        ...(isAutofill && {
            InputLabelProps: { shrink: isAutofill },
        }),
    }

    const renderInput = ({ field, fieldState: { error } }: any) => {
        switch (variant) {
            case 'textarea':
                return (
                    <StyledTextarea
                        multiline
                        minRows={2}
                        helperText={t(error?.message)}
                        error={Boolean(error)}
                        {...field}
                    />
                )

            case 'password':
                return (
                    <InputPassword
                        error={!!error}
                        label={labelSmall}
                        helperText={t(error?.message)}
                        fullWidth
                        disabled={disabled}
                        autoComplete={autoComplete}
                        {...field}
                        {...autofillProps}
                    />
                )

            case 'checkbox':
                const { value, ...restField } = field
                return <Checkbox {...restField} checked={value} />
            case 'color':
                return <>
                    <StyledColorTextField
                        {...field}
                        type={'color'}
                        label={labelSmall}
                        error={!!error}
                        helperText={t(error?.message)}
                        fullWidth
                        style={{ backgroundColor: field.value ?? '#FFF' }}
                        disabled={disabled}
                        InputProps={{
                            endAdornment,
                        }}
                        {...autofillProps} />
                </>
            case 'default':
            default:
                return (
                    <>
                        <TextField
                            {...field}
                            type={type}
                            label={labelSmall}
                            error={!!error}
                            helperText={t(error?.message)}
                            fullWidth
                            autoComplete={autoComplete}
                            disabled={disabled}
                            // InputProps={{
                            //     endAdornment,
                            // }}
                            {...autofillProps}
                        />
                        {showLengthUnit && (
                            <Box ml={2}>
                                m<sup>2</sup>
                            </Box>
                        )}
                    </>
                )
        }
    }

    return (
        <FormControl fullWidth>
            <StyledFormItem noBorder={noBorder || !label} sx={sx}>
                <DataItem label={label} variant="input">
                    <Controller name={name} control={control} render={renderInput} />
                </DataItem>
            </StyledFormItem>
        </FormControl>
    )
}

export default Input
