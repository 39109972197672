import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import fileService from '../../api/services/file'
import { FileData } from 'files'
import { createFileList } from '../../api/data/files'
import queryClient from '../../utils/queryClient'
import { AxiosError } from 'axios'

export const useGetFiles = (
    entityID: string,
    groupID: string,
    options: UseQueryOptions<Promise<FileData[]>, { response: AxiosError<Error> }, FileData[]>
) => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.File)
    const { queryKey, ...restOptions } = options

    return useQuery<Promise<FileData[]>, { response: AxiosError<Error> }, FileData[]>({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async (): Promise<FileData[]> => {
            const { data } = await fileService.getFiles(entityID, groupID)
            return createFileList(data)
        },
        onError: ({ response }: { response: AxiosError<Error> }) => {
            response.status === 406 && queryClient.setQueriesData(getQueryAllKey(queryKey), [])
        },
    })
}
