import { styled, css } from '@mui/system'
import { TreeView } from '@mui/lab'
import { Box } from '@mui/material'

export const StyledFileTableWrapper = styled('div')`
    ${({ theme }) => css`
        flex-grow: 2;
        padding-bottom: ${theme.spacing(10)};
    `}
`

export const StyledTreeWrapper = styled(Box)`
    ${({ theme }) => css`
        overflow: hidden;
        width: 100%;
        border-radius: ${theme.spacing(2)};
        background-color: ${theme.palette.common.white};
        display: flex;
        min-width: 350px;
    `}
`

export const StyledTreeView = styled(TreeView)`
    ${({ theme }) => css`
        max-width: 460px;
        min-width: 300px;
        overflow-y: auto;
        position: relative;
        flex-grow: 1;
    `}
`

export const StyledFilesFooter = styled('div')<{
    hide?: boolean
}>`
    ${({ theme, hide }) => css`
        border-radius: 0 0 ${theme.spacing(2)} ${theme.spacing(2)};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: ${theme.spacing(2)};
        min-height: 73px;

        ${!hide &&
        css`
            background: ${theme.palette.common.white};
        `}
    `}
`

export const StyledFilesGrid = styled('div')`
    ${({ theme }) => css`
        background: ${theme.palette.common.white};
        border-radius: ${theme.spacing(2)} ${theme.spacing(2)} 0 0;
        padding: ${theme.spacing(2)};
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: ${theme.spacing(3)};
    `}
`

export const StyledBreadcrumbs = styled('div')`
    ${({ theme }) => css`
        display: flex;
        cursor: pointer;
        flex-wrap: wrap;
        color: ${theme.palette.secondary.main};
        font-weight: 600;
        padding-right: ${theme.spacing(1)};
    `}
`
