import { useSnackbar as useSnackbarNotistack } from 'notistack'
import { removeHtml } from '../utils/helpers'

export const useSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbarNotistack()

    return {
        setErrorMsg: (message: string) => {
            const bar = enqueueSnackbar(removeHtml(message || 'Error'), {
                variant: 'error',
                onClick: () => closeSnackbar(bar),
                autoHideDuration: 3000,
            })
        },
        setSuccessMsg: (message: string) => {
            const bar = enqueueSnackbar(removeHtml(message || 'Error'), {
                variant: 'success',
                onClick: () => closeSnackbar(bar),
                autoHideDuration: 1500,
            })
        },
        setInfoMsg: (message: string) => {
            enqueueSnackbar(removeHtml(message || 'Error'), {
                variant: 'info',
            })
        },
        close: () => {
            closeSnackbar()
        },
    }
}
