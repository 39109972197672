import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    StyledPageItem,
    StyledPageList,
    StyledPageListItem,
    StyledPageSubtitle,
    StyledPageTitle,
    StyledPageWrapper,
    StyledPublicPage,
} from '../styles'
import { StyledLink } from '../../../theme/styles'

const Terms = () => {
    const { t } = useTranslation(['terms'])
    const { t: t2 } = useTranslation(['common'])

    return (
        <StyledPublicPage>
            <StyledPageTitle>{t('title')}</StyledPageTitle>

            <StyledPageWrapper listStyleType="terms">
                <StyledPageItem>
                    <StyledPageSubtitle>{t('scope.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="scope.p1"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t2('omnystate_link')}>
                                            {t2('omnystate_link')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t2('omnystate_link'),
                                }}
                            />
                        </StyledPageListItem>
                        <StyledPageListItem>{t('scope.p2')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('description.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="description.p1"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t2('omnystate_link')}>
                                            {t2('omnystate_link')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t2('omnystate_link'),
                                }}
                            />
                        </StyledPageListItem>
                        <StyledPageListItem>{t('description.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('description.p3')}</StyledPageListItem>
                        <StyledPageListItem>{t('description.p4')}</StyledPageListItem>
                        <StyledPageListItem>{t('description.p5')}</StyledPageListItem>
                        <StyledPageListItem>{t('description.p6')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('digital.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('digital.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('digital.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('digital.p3')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('prices.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="prices.p1"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t2('omnystate_link')}>
                                            {t2('omnystate_link')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t2('omnystate_link'),
                                }}
                            />
                        </StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('subscription.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('subscription.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('subscription.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('subscription.p3')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('conclusion.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="conclusion.p1"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t2('omnystate_link')}>
                                            {t2('omnystate_link')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t2('omnystate_link'),
                                }}
                            />
                        </StyledPageListItem>
                        <StyledPageListItem>{t('conclusion.p2')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('duties.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('duties.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('duties.p2')}</StyledPageListItem>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="duties.p3"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t('url')}>
                                            {t('url')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t('url'),
                                }}
                            />
                        </StyledPageListItem>
                        <StyledPageListItem>{t('duties.p4')}</StyledPageListItem>
                        <StyledPageListItem>{t('duties.p5')}</StyledPageListItem>
                        <StyledPageList listStyleType="abc" gutters>
                            <StyledPageListItem>{t('duties.points.a')}</StyledPageListItem>
                            <StyledPageListItem>{t('duties.points.b')}</StyledPageListItem>
                            <StyledPageListItem>{t('duties.points.c')}</StyledPageListItem>
                            <StyledPageListItem>{t('duties.points.d')}</StyledPageListItem>
                            <StyledPageListItem>{t('duties.points.e')}</StyledPageListItem>
                        </StyledPageList>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('rights.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('rights.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('rights.p2')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('billing.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('billing.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('billing.p2')}</StyledPageListItem>
                        <StyledPageListItem>
                            <Trans
                                t={t}
                                i18nKey="billing.p3"
                                components={{
                                    1: (
                                        <StyledLink as="a" href={t('url')}>
                                            {t('url')}
                                        </StyledLink>
                                    ),
                                }}
                                values={{
                                    link: t('url'),
                                }}
                            />
                        </StyledPageListItem>
                        <StyledPageListItem>{t('billing.p4')}</StyledPageListItem>
                        <StyledPageListItem>{t('billing.p5')}</StyledPageListItem>
                        <StyledPageListItem>{t('billing.p6')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('offsetting.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('offsetting.p1')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('termination.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('termination.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('termination.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('termination.p3')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('liability.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('liability.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('liability.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('liability.p3')}</StyledPageListItem>
                        <StyledPageListItem>{t('liability.p4')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('legal.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('legal.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('legal.p2')}</StyledPageListItem>
                        <StyledPageListItem>{t('legal.p3')}</StyledPageListItem>
                        <StyledPageListItem>{t('legal.p4')}</StyledPageListItem>
                        <StyledPageListItem>{t('legal.p5')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('final.title')}</StyledPageSubtitle>
                    <StyledPageList listStyleType="terms-sublist" gutters>
                        <StyledPageListItem>{t('final.p1')}</StyledPageListItem>
                        <StyledPageListItem>{t('final.p2')}</StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>
            </StyledPageWrapper>
        </StyledPublicPage>
    )
}

export default Terms
