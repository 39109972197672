import { TextField } from '@mui/material'
import { css, styled } from '@mui/system'

export const StyledTextarea = styled(TextField)`
    ${({ theme }) => css`
        width: 100%;

        && {
            height: auto;
        }
    `}
`

export const StyledColorTextField = styled(TextField)(({ theme }) => ({
    '& input[type="color"]': {
        border: 'none',
        cursor: 'pointer',
        width: '100%',
        height: '40px', // Adjust this as needed
        appearance: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        outline: 'none',
    },
    '& input[type="color"]::-webkit-color-swatch-wrapper': {
        padding: 0,
    },
    '& input[type="color"]::-webkit-color-swatch': {
        border: 'none',
    },
    '& input[type="color"]::-moz-color-swatch': {
        border: 'none',
    },
    '@-moz-document url-prefix()': {
        '& input[type="color"]': {
            border: 'none',
            width: '100%',
        },
    },
}))
