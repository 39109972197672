import { QueryDataKey } from '../../types'
import { useMutation, useQueryClient } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import fileService from '../../api/services/file'
import { useSnackbar } from '../useSnackbar'
import { AxiosError } from 'axios'

export const useTransferFiles = (entityID: string) => {
    const { transferKey, getQueryAllKey } = useQueryKeys(QueryDataKey.File)
    const { setErrorMsg, setSuccessMsg } = useSnackbar()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ newGroupID, fileID }: { newGroupID: string; fileID: string }) => {
            return fileService.transferFiles(entityID, newGroupID, fileID)
        },
        onSuccess: async (res, variables, context) => {
            await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
            setSuccessMsg(res.statusText)
        },
        mutationKey: transferKey,
        onError: (err: AxiosError) => {
            setErrorMsg((err.response?.data as unknown as string[])?.[0])
        },
    })
}
