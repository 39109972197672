import { Theme } from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiTypography: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            span: {
                color: theme.palette.secondary.main,
            },
            a: {
                color: theme.palette.secondary.main,
                textDecoration: 'none',
            },
        }),
    },
    variants: [
        {
            props: { variant: 'caption' },
            style: {
                fontSize: '11px',
            },
        },
    ],
}

export default MuiTypography
