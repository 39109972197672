import { styled, css } from '@mui/system'
import { alpha } from '@mui/material'

export const StyledDnD = styled('div')<{
    isDragOver: boolean
    overContainer?: boolean
}>`
    ${({ overContainer, isDragOver, theme }) => css`
        position: relative;
        ${isDragOver &&
        css`
            &:before {
                content: '';
                position: absolute;
                top: -10px;
                left: -10px;
                border: 3px dashed ${alpha(theme.palette.info.main, 0.3)};
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                z-index: 9;
                background: ${alpha(theme.palette.info.main, 0.08)};

                ${overContainer &&
                css`
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                `}
            }
        `}
    `}
`

export const StyledHiddenInput = styled('input')`
    display: none;
`
