import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { TaskParams } from 'tasks'
import { createTaskApiData, createTaskRemoveImagesData } from '../../api/data/task'
import taskService from '../../api/services/task'
import { useSnackbar } from '../useSnackbar'
import useQueryKeys from '../useQueryKeys'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import useParamManage from '../useParamManage'
import useNavUtils from '../useNavUtils'

export const useUpdateTask = (taskID?: string) => {
    const { setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { updateKey, getQueryAllKey, getQueryOneKey } = useQueryKeys(QueryDataKey.Task)
    const { maintenanceID } = useParamManage()
    const { navigateTask, navigateMaintenance } = useNavUtils()

    return useMutation(
        async (params: Partial<TaskParams>) => {
            const isImageDeleteRequest = params?.images?.filter((img) => Boolean(!img))?.length

            if (isImageDeleteRequest)
                await taskService.update(taskID, createTaskRemoveImagesData(params))
            return taskService.update(taskID || params?.id, await createTaskApiData(params))
        },
        {
            mutationKey: updateKey,
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: getQueryAllKey([maintenanceID]),
                })

                await queryClient.invalidateQueries({ queryKey: getQueryOneKey([taskID]) })

                if (maintenanceID) return navigateMaintenance(maintenanceID)('READ')
                navigateTask('')('LIST')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
