import { useRef } from 'react'

const useFileUpload = () => {
    const uploadInputRef = useRef<HTMLInputElement>(null)

    const handleUploadBtnClick = (): void => {
        uploadInputRef.current?.click()
    }

    const resetInput = () => {
        if (!uploadInputRef.current) return
        uploadInputRef.current.value = ''
    }

    return {
        uploadInputRef,
        handleUploadBtnClick,
        resetInput,
    }
}
export default useFileUpload
