import { MutableRefObject, useEffect } from 'react'
import Swiper from 'swiper'

export type ActiveImageIndex = number | null

interface Props {
    swiper: MutableRefObject<Swiper | null>
}
export const useLightbox = ({ swiper }: Props) => {
    useEffect(() => {
        function onKeyPress(event: any) {
            switch (event.key) {
                case 'ArrowLeft':
                    swiper?.current?.slidePrev()
                    break
                case 'ArrowRight':
                    swiper?.current?.slideNext()
                    break
            }
        }

        document.addEventListener('keydown', onKeyPress)

        return () => document.removeEventListener('keydown', onKeyPress)
        // eslint-disable-next-line
    }, [])

    return {}
}
