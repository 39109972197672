import { Box, Typography } from '@mui/material';
import { RenderPageProps } from '@react-pdf-viewer/core';
import React, { FC, useEffect, useState } from 'react';
import { useSelection } from '../../../../hooks/useSelection';
import { ExitButton, InfoBox, ResizeHandle, SaveButton, SelectionBox } from '../../../ImageViewerWithSelection/styles';
import { t } from "i18next"

interface PdfPageWithSelectionProps extends RenderPageProps {
      onSave: (selectionData: { x: number; y: number; width: number; height: number }) => void;
      onExit: () => void;
      initialSelection?: { x: number; y: number; width: number; height: number };
}

const PdfPageWithSelection: FC<PdfPageWithSelectionProps> = ({
      pageIndex,
      rotation,
      scale,
      doc,
      height,
      width,
      onSave,
      onExit,
      initialSelection,
      markRendered,
      canvasLayer,
      svgLayer,
      textLayer,
      textLayerRendered,
      annotationLayer,
}) => {
      const [originalSize, setOriginalSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

      const {
            ref: pageRef,
            isSelectionEnabled,
            selection,
            handleMouseDown,
            handleMouseMove,
            handleMouseUp,
      } = useSelection<HTMLDivElement>(originalSize, initialSelection);

      useEffect(() => {
            if (doc) {
                  doc.getPage(pageIndex + 1).then((page) => {
                        const { width, height } = page.getViewport({ scale: 1 });
                        setOriginalSize({ width, height });
                  });
            }
      }, [doc, pageIndex]);

      useEffect(() => {
            if (markRendered) {
                  markRendered(pageIndex);
            }
      }, [markRendered, pageIndex]);


      const handleSave = () => {
            if (selection) {
                  onSave({ ...selection });
            }
      };

      const preventDefault = (event: React.MouseEvent | React.TouchEvent) => {
            event.preventDefault();
      };

      return (
            <Box position="relative" width={width} height={height} marginTop={10}>
                  <div
                        ref={pageRef}
                        style={{ position: 'relative', height, width, userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' }}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onTouchStart={preventDefault}
                        onTouchMove={preventDefault}
                        onTouchEnd={preventDefault}
                  >
                        {canvasLayer.children}
                        {svgLayer.children}
                        {textLayer.children}
                        {annotationLayer.children}
                        {isSelectionEnabled && (
                              <div
                                    style={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                          pointerEvents: 'none',
                                    }}
                              />
                        )}

                        {selection && pageRef.current && (
                              <SelectionBox
                                    style={{
                                          top: selection.y * (height / originalSize.height),
                                          left: selection.x * (width / originalSize.width),
                                          width: selection.width * (width / originalSize.width),
                                          height: selection.height * (height / originalSize.height),
                                    }}
                              >
                                    <ResizeHandle data-handle="top-left" style={{ top: -10, left: -10, cursor: 'nwse-resize' }} />
                                    <ResizeHandle data-handle="top-right" style={{ top: -10, right: -10, cursor: 'nesw-resize' }} />
                                    <ResizeHandle data-handle="bottom-left" style={{ bottom: -10, left: -10, cursor: 'nesw-resize' }} />
                                    <ResizeHandle data-handle="bottom-right" style={{ bottom: -10, right: -10, cursor: 'nwse-resize' }} />
                              </SelectionBox>
                        )}
                  </div>
                  <Box display="flex" flexDirection="row" alignItems="center" gap={2} mt={2} position="absolute" bottom={-40} right={0}>
                        <SaveButton variant="contained" onClick={handleSave}>
                              {t('save')}
                        </SaveButton>
                        <ExitButton variant="contained" onClick={onExit}>
                              {t('exit')}
                        </ExitButton>
                  </Box>
                  {selection && (
                        <InfoBox style={{ position: 'absolute', bottom: -40, left: 0 }}>
                              <Typography variant="body2">X: {selection.x}</Typography>
                              <Typography variant="body2">Y: {selection.y}</Typography>
                              <Typography variant="body2">W: {selection.width}</Typography>
                              <Typography variant="body2">H: {selection.height}</Typography>
                        </InfoBox>
                  )}
            </Box>
      );
};

export default PdfPageWithSelection;
