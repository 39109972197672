import { QueryDataKey } from '../../types'
import { useMutation, useQueryClient } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { useSnackbar } from '../useSnackbar'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import apartmentService from '../../api/services/apartment'
import { ApartmentParams } from 'apartment'
import { createApartmentApiData } from '../../api/data/apartment'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../useNavUtils'

export const useUpdateApartment = () => {
    const { t } = useTranslation()
    const { updateKey } = useQueryKeys(QueryDataKey.Apartment)
    const queryClient = useQueryClient()
    const { setErrorMsg, setSuccessMsg } = useSnackbar()
    const { navigateApartment } = useNavUtils()

    return useMutation({
        mutationFn: (params: ApartmentParams) => {
            return apartmentService.create(createApartmentApiData(params))
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: updateKey })
            setSuccessMsg(t('updated'))
            navigateApartment(res?.data?.nid)('GENERAL_INFORMATION')
        },
        onError: (err: AxiosError) => {
            setErrorMsg(getTextError(err))
        },
    })
}
