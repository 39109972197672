import { IconButton, Typography } from '@mui/material'
import React, { useRef } from 'react'
import {
    StyledLightboxPagination,
    StyledMainSwiper,
    StyledModal,
    StyledThumbSwiper,
} from './styles'
import { StyledCloseModalBar } from '../../theme/styles'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CloseIcon from '@mui/icons-material/Close'
import { SwiperProps, SwiperSlide } from 'swiper/react'
import Image from '../Image'
import { FreeMode, Navigation, Thumbs, Keyboard, Lazy, Zoom } from 'swiper'
import { type Swiper as SwiperRef } from 'swiper'
import { ImageData } from '../../types/index'
import { Box } from '@mui/system'
import theme from '../../theme'
import { useLightbox } from '../../hooks/useLightbox'
import { NavigationOptions, Swiper } from 'swiper/types'
import GetAppIcon from '@mui/icons-material/GetApp'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

interface Props {
    images: ImageData[]
    thumbs?: ImageData[]
    open: null | string
    pageIndex?: number
    nextPage?: () => void
    prevPage?: () => void
    onChange: (id: string | null) => void
}

const Lightbox = ({ thumbs, images, open, pageIndex = 0, nextPage, prevPage, onChange }: Props) => {
    const PRELOAD_IMAGES_COUNT = 4
    const navigationPrevRef = useRef<HTMLButtonElement>(null)
    const navigationNextRef = useRef<HTMLButtonElement>(null)
    const imageTitleRef = useRef<HTMLDivElement>(null)
    const counterRef = useRef<HTMLDivElement>(null)
    const downloadRef = useRef<HTMLAnchorElement>(null)
    const swiperRef = useRef<Swiper | null>(null)
    const zoomRef = useRef<any>(null)
    const initialSlide = images.findIndex((el) => el.src === open)
    useLightbox({ swiper: swiperRef })

    const handleClose = () => onChange(null)

    const handleZoom = () => {
        zoomRef.current.style.cursor = swiperRef.current!.zoom.scale === 3 ? 'zoom-in' : 'zoom-out'
    }

    const handleOnSlideChange = (swiper: Swiper) => {
        setPreloadImages(swiper)

        const img = images[swiper.activeIndex]
        const max = String(images.length).padStart(2, '0')
        const current = String(swiper.activeIndex + 1).padStart(2, '0')
        if (imageTitleRef.current) imageTitleRef.current.innerText = img?.filename ?? ''

        if (counterRef.current) counterRef.current.innerText = `${current}/${max}`
        if (downloadRef.current) downloadRef.current.href = `data,${img.src}`
        if (downloadRef.current) downloadRef.current.download = img?.filename ?? ''
    }

    const setPreloadImages = (swiper: Swiper) => {
        const curr = swiper.realIndex + 1
        swiper.slides.forEach((slide, i) => {
            const slideImg = slide.querySelector('img')
            if (!slideImg) return
            if (i > curr && i >= curr + PRELOAD_IMAGES_COUNT) {
                slideImg.setAttribute('loading', 'lazy')
            } else {
                setTimeout(() => {
                    slideImg.removeAttribute('loading')
                }, 1500)
            }
        })
    }

    const mainSwiperConfig: SwiperProps = {
        modules: [FreeMode, Navigation, Thumbs, Keyboard, Lazy, Zoom],
        spaceBetween: 10,
        freeMode: false,
        initialSlide,
        preloadImages: false,
        lazy: true,
        zoom: {
            maxRatio: 3,
            minRatio: 1,
            containerClass: 'swiper-zoom-container',
        },
        onZoomChange: () => handleZoom(),
        onClick: (swiper) => swiper.zoom.toggle(),
        onInit: (swiper) => {
            swiperRef.current = swiper
            handleOnSlideChange(swiper)
        },
        onSlideChange: handleOnSlideChange,
        navigation: {
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
        },
        onBeforeInit: (swiper: SwiperRef) => {
            ; (swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current
                ; (swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current
        },
    }

    const thumbsSwiperConfig: SwiperProps = {
        modules: [FreeMode, Navigation, Thumbs],
        spaceBetween: 10,
        slidesPerView: 6,
        initialSlide,
        freeMode: false,
        watchSlidesProgress: true,
        onInit: (swiper) => ((swiperRef.current as Swiper).thumbs.swiper = swiper),
        onClick: (swiper) => swiperRef.current?.slideTo(swiper.clickedIndex),
    }

    return (
        <StyledModal key={pageIndex} open={true} onClose={handleClose}>
            <Box sx={{ background: theme.palette.grey[100], maxHeight: '100%', height: '100%' }}>
                <StyledCloseModalBar sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
                    <Box display="flex">
                        <Typography mr={3} fontWeight={600} ref={counterRef} />
                        <Typography ref={imageTitleRef} />
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} gap={5}>
                        <StyledLightboxPagination>
                            <IconButton onClick={prevPage} size="large" color="neutral">
                                <DoubleArrowIcon
                                    fontSize="small"
                                    sx={{ transform: 'rotate(180deg)' }}
                                />
                            </IconButton>
                            {pageIndex + 1}
                            <IconButton size="large" onClick={nextPage} color="neutral">
                                <DoubleArrowIcon fontSize="small" />
                            </IconButton>
                        </StyledLightboxPagination>
                        <Box>
                            <a href="/" ref={downloadRef} rel="noreferrer" download>
                                <IconButton size="large" color="neutral">
                                    <GetAppIcon fontSize="small" />
                                </IconButton>
                            </a>
                            <IconButton size="large" ref={navigationPrevRef} color="neutral">
                                <ArrowLeftIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="large" ref={navigationNextRef} color="neutral">
                                <ArrowRightIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={handleClose} size="large">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                </StyledCloseModalBar>
                <StyledMainSwiper {...mainSwiperConfig} ref={zoomRef}>
                    {images.map((image, i) => (
                        <SwiperSlide key={i}>
                            <Image src={image.src} loading="lazy" />
                        </SwiperSlide>
                    ))}
                </StyledMainSwiper>
                <StyledThumbSwiper {...thumbsSwiperConfig}>
                    {(thumbs || images).map((image, i) => (
                        <SwiperSlide key={i}>
                            <Image src={image.src} objectFit="contain" />
                            <IconButton
                                disableTouchRipple
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    left: '0',
                                    top: '0',
                                    borderRadius: 0,
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </StyledThumbSwiper>
            </Box>
        </StyledModal>
    )
}

export default Lightbox
