export const setLocalStorage = (key: string, value: string | object | null | undefined) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = (key: string) => {
    const stored = localStorage.getItem(key)
    try {
        return stored && JSON.parse(stored)
    } catch (err) {
        return false
    }
}

export const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key)
}
