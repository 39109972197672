import { QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { AxiosError } from 'axios'
import { useSnackbar } from '../useSnackbar'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/routes'
import { stripObj } from '../../utils/helpers'
import { ResetPasswordSignInParams } from 'auth'

export const useSignInResetPassword = () => {
    const { resetPasswordSignInKey } = useQueryKeys(QueryDataKey.Auth)
    const { setErrorMsg, setInfoMsg } = useSnackbar()
    const navigate = useNavigate()

    return useMutation(
        (params: ResetPasswordSignInParams) => {
            return authService.resetPasswordSignIn(params)
        },
        {
            mutationKey: resetPasswordSignInKey,
            onSuccess: ({ data: { message, pass_reset_token } }) => {
                setInfoMsg(message)
                navigate(PATHS.NEW_PASSWORD, {
                    state: {
                        passChangeToken: pass_reset_token,
                    },
                })
            },
            onError: (error: AxiosError) => {
                setErrorMsg(stripObj(error?.response?.data))
                navigate(PATHS.SIGNIN)
            },
        }
    )
}
