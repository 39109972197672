import React, { useEffect } from 'react'
import DynamicBackground from '../../components/DynamicBackground'
import { PATHS, PUBLIC_PAGE_ROUTES } from '../../routes/routes'
import Logo from '../../components/Logo'
import {
    StyledLogotype,
    StyledPublicWrapperContainer,
    StyledPublicWrapper,
    StyledPublicWrapperPaper,
    StyledPublicContainer,
    StyledLogoSettingsWrapper,
    StyledPublicFooter,
} from './styles'
import { Location, Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { getLocalStorage } from '../../utils/storage'
import DropdownMenu from '../../components/DropdownMenu'
import { IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../hooks/useLanguage'
import { StyledLink } from '../../theme/styles'
import { LANGUAGES, TOKEN_KEY } from '../../constant/const'

const Public = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { pathname, state }: { pathname: string; state: { from: Location } } = useLocation()
    const token = getLocalStorage(TOKEN_KEY)
    const { changeLangLocally, currentLang } = useLanguage()

    const isAlignCenter = pathname.includes(PATHS.ACTIVATION_LINK)

    const isPublicPage = Boolean(
        PUBLIC_PAGE_ROUTES.filter(({ path }) => path && matchPath(pathname, path))?.length
    )

    useEffect(() => {
        const redirectIfSignedIn = () => {
            if (!token) return
            if (isPublicPage) return
            const prevPath = state?.from?.pathname
            if (prevPath !== PATHS.SIGNOUT) return navigate(PATHS.DASHBOARD)
        }

        redirectIfSignedIn()
    }, [token, navigate, state, isPublicPage])

    return (
        <StyledPublicWrapper isAlignCenter={isAlignCenter}>
            <DynamicBackground />
            <StyledPublicContainer>
                <StyledLogoSettingsWrapper>
                    <StyledLogotype to={PATHS.DASHBOARD} isAlignCenter={isAlignCenter}>
                        <Logo />
                    </StyledLogotype>
                    <DropdownMenu
                        title={t('navigation.settings')}
                        element={
                            <IconButton size="large" color="secondary">
                                <SettingsOutlinedIcon fontSize="small" />
                            </IconButton>
                        }
                        gutterTop={2}
                    >
                        {/* this div helps with closing menus at once */}
                        <div></div>
                        <DropdownMenu
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            element={
                                <MenuItem>
                                    <ListItemIcon>
                                        <ArrowLeftIcon />
                                    </ListItemIcon>
                                    <Typography textAlign="center">
                                        {t(`navigation.select_language`)}
                                    </Typography>
                                </MenuItem>
                            }
                        >
                            {LANGUAGES.map(({ lang, code }) => (
                                <MenuItem
                                    key={code}
                                    sx={{ width: '200px' }}
                                    onClick={() => changeLangLocally(code)}
                                    selected={currentLang === code}
                                >
                                    <Typography textAlign="center">{t(lang)}</Typography>
                                </MenuItem>
                            ))}
                        </DropdownMenu>
                    </DropdownMenu>
                </StyledLogoSettingsWrapper>
                <StyledPublicWrapperContainer marginTop={'0'}>
                    <StyledPublicWrapperPaper fullWidth={isPublicPage}>
                        <Outlet />
                    </StyledPublicWrapperPaper>
                </StyledPublicWrapperContainer>
                <StyledPublicFooter>
                    <StyledLink to={PATHS.PRIVACY_POLICY}>{t('privacy_policy')}</StyledLink>
                    <StyledLink to={PATHS.TERMS}>{t('terms')}</StyledLink>
                    <StyledLink to={PATHS.COMPANY_DETAILS}>{t('company_details')}</StyledLink>
                </StyledPublicFooter>
            </StyledPublicContainer>
        </StyledPublicWrapper>
    )
}

export default Public
