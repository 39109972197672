import { styled, css } from '@mui/system'
import { Box, Typography } from '@mui/material'
export const StyledTooltipContent = styled('div')`
    ${({ theme }) => css`
        font-weight: 400;
        opacity: 0.6;
        font-size: 13px;
    `}
`

export const StyledTooltipTitle = styled(Typography)`
    ${({ theme }) => css`
        font-weight: 600;
        font-size: 10px;
        opacity: 0.6;
    `}
`
export const StyledTooltipHeader = styled('div')`
    ${({ theme }) => css`
        background-color: ${theme.palette.grey[100]};
        padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(4)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.02);
    `}
`

export const StyledTooltip = styled('div')`
    ${({ theme }) => css`
        padding: ${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(0)};
        margin: 0 0 ${theme.spacing(2)} 0;
        font-family: 'Fira Sans';
        min-width: 120px;

        &:not(:nth-last-of-type(2)) {
            border-bottom: 1px dashed ${theme.palette.grey[200]};
        }
    `}
`
export const StyledTooltipHeading = styled('div')`
    ${({ theme }) => css`
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 5px 0;
    `}
`

export const StyledTooltipControls = styled('div')`
    ${({ theme }) => css`
        margin: 2px 0 0 -11px;
        display: flex;
    `}
`

export const StyledButtonWrapper = styled(Box)`
    display: flex;
    margin: ${({ theme }) => `${theme.spacing(4)}`} 0;
    gap: ${({ theme }) => `${theme.spacing(2)}`};
`
