import React from 'react'
import { Button, IconButton } from '@mui/material'
import { t } from 'i18next'
import {
    StyledTooltip,
    StyledTooltipContent,
    StyledTooltipHeader,
    StyledTooltipHeading,
    StyledTooltipTitle,
} from './styles'
import { ContainerList } from 'containers'
import { ObjectData, ObjectList } from 'objects'
import theme from '../../../../theme'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { UnitData } from 'units'
// import AddIcon from '@mui/icons-material/Add';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import VisibilityIcon from '@mui/icons-material/Visibility';
interface TooltipItemProps {
    data?: Partial<ContainerList | ObjectList | UnitData | ObjectData>
    index?: number
    length?: number
    onClick?: (id: string) => void
}
interface TooltipWrapperProps {
    children: JSX.Element[] | JSX.Element
    count?: number
    subtitleText: string
    onZoomInClick: () => void
    onZoomOutClick: () => void
    onCloseClick: () => void
}

const MapTooltipWrapper = ({
    children,
    subtitleText,
    count,
    onZoomInClick,
    onZoomOutClick,
    onCloseClick,
}: TooltipWrapperProps) => {
    return (
        <Box pt={11}>
            {children}
            <StyledTooltipHeader>
                {count && count > 1 && (
                    <StyledTooltipTitle>
                        {count} {subtitleText}
                    </StyledTooltipTitle>
                )}
                {/* <Box>
                    <IconButton onClick={onZoomInClick} title={t('zoom_in')}>
                        <ZoomInIcon fontSize={'small'} />
                    </IconButton>
                    <IconButton onClick={onZoomOutClick} title={t('zoom_out')}>
                        <VisibilityIcon fontSize={'small'} />
                    </IconButton>
                </Box> */}
                <Box alignSelf={'flex-end'} ml={'auto'}>
                    <IconButton title={t('close_tooltip')} onClick={onCloseClick}>
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                </Box>
            </StyledTooltipHeader>
        </Box>
    )
}

const MapTooltipItem = ({ data, index = 0, length = 1, onClick }: TooltipItemProps) => {
    const showOrderNumber = length > 1
    const showSelectButton = !!onClick

    if (!data) return null
    return (
        <StyledTooltip
            sx={showOrderNumber ? { pl: 0, pr: 1 } : showSelectButton ? { pl: 3 } : { px: 3 }}
        >
            <Box
                display="flex"
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={3}
                pr={1}
            >
                <Box display={'flex'} alignItems="center">
                    {showOrderNumber && (
                        <Box sx={{ color: theme.palette.grey[500], fontWeight: 600 }} px={3}>
                            {index + 1}
                        </Box>
                    )}
                    <Box>
                        <StyledTooltipHeading>{data?.title}</StyledTooltipHeading>
                        <StyledTooltipContent>
                            {'street' in data && data?.street} {data?.city}, {data?.zipCode}
                        </StyledTooltipContent>
                    </Box>
                </Box>

                {showSelectButton && (
                    <Button
                        title={t('select_item')}
                        onClick={() => onClick(data.id || '')}
                        centerRipple
                        size="medium"
                        variant="text"
                        sx={{ minWidth: 'auto' }}
                    >
                        {t('go')}
                    </Button>
                )}
            </Box>
        </StyledTooltip>
    )
}

export { MapTooltipWrapper, MapTooltipItem }
