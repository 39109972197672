import { MaintenanceRequestData } from 'maintenance'
import axiosInstance from '../axios'

const maintenanceService = {
    getAll: (parentID?: string) => {
        return axiosInstance.get(`/maintenance${parentID ? `?parent_id=${parentID}` : ''}`)
    },
    getOne: (id?: string) => {
        return axiosInstance.get(`/maintenance/${id}`)
    },
    delete: (id?: string) => {
        return axiosInstance.delete(`/maintenance/${id}`)
    },
    create: (params: Partial<MaintenanceRequestData>) => {
        return axiosInstance.post(`/maintenance`, params)
    },
    update: (id?: string, params?: Partial<MaintenanceRequestData>) => {
        return axiosInstance.put(`/maintenance/${id}`, params)
    },
}
export default maintenanceService
