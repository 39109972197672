import React, { FC, useEffect, useRef } from 'react'
import { StyledWrapper } from './styles'
import { MainDataTypes } from 'index'
import DataTreeItem from './components/DataTreeItem'

export type OnDropFunction = () => void

export interface TreeItem {
    id: string
    title: string
    parents: string[]
    icon?: string
    children?: TreeItem[]
    dataType: MainDataTypes.TreeItem
}

interface Props {
    data: TreeItem[]
    active: string[]
    expanded: string[]
    uploadPath?: string
    breadcrumbs: TreeItem[]
    isUploadFile: boolean
    checkDropAllow: (id: string, hasChildren: boolean) => boolean
    onUpload?: (files: File[], onSuccess?: () => void) => void
    onDrop?: OnDropFunction
    onChangeBreadcrumbs: (breadcrumbs: TreeItem[]) => void
    onChange: (group: TreeItem, isDragging: boolean) => void
}

const DataTree: FC<Props> = ({
    data,
    active,
    expanded,
    isUploadFile,
    uploadPath,
    checkDropAllow,
    onDrop,
    onUpload,
    onChangeBreadcrumbs,
    onChange,
}) => {
    const breadcrumbsRef = useRef<Map<string, TreeItem>>(new Map())
    const breadcrumbs = breadcrumbsRef.current

    const renderGroup = (groups: TreeItem[]) => {
        return groups?.map((group: TreeItem) => {
            const handleChange = (group: TreeItem) => onChange(group, false)
            const handleDragEnter = (group: TreeItem) => group?.id !== 'v0' && onChange(group, true)

            const { id, title, children } = group
            const isExpand = expanded.includes(id)
            const isActive = active.includes(id)

            const handleDataTreeRef = (el: HTMLDivElement) => {
                if (el && isActive) {
                    breadcrumbs.set(id, group)
                }
                if (!el) breadcrumbs.delete(id)
            }

            return (
                <DataTreeItem
                    key={id}
                    label={title}
                    isActive={isActive}
                    isDropAllow={checkDropAllow(id, !!children)}
                    isExpanded={isExpand}
                    onClick={() => handleChange(group)}
                    onDragEnter={() => handleDragEnter(group)}
                    isUploadFile={isUploadFile}
                    onUpload={onUpload}
                    onDrop={onDrop}
                    uploadPath={uploadPath}
                    ref={handleDataTreeRef}
                >
                    {children && renderGroup(children)}
                </DataTreeItem>
            )
        })
    }

    useEffect(
        () => {
            onChangeBreadcrumbs(Array.from(breadcrumbs, ([, value]) => value))
        },
        // eslint-disable-next-line
        [active, breadcrumbs]
    )

    return (
        <StyledWrapper display={'flex'} flexDirection="column" flex="1">
            {renderGroup(data)}
        </StyledWrapper>
    )
}

export default DataTree
