import Menu from '@mui/material/Menu'
import { PopoverOrigin, SxProps, Typography } from '@mui/material'
import theme from '../../theme/index'
import { StyledMenuItem } from './styles'
import React, { FC, PropsWithChildren, ReactElement } from 'react'

interface Props {
    element: JSX.Element
    title?: string
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    gutterTop?: number
    subtitle?: string
    sx?: SxProps
}

const DropdownMenu: FC<PropsWithChildren<Props>> = ({
    element,
    children,
    title,
    subtitle,
    gutterTop,
    sx,
    anchorOrigin,
    transformOrigin,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    const renderChild = (children: ReactElement) => {
        if (React.isValidElement(children)) return children
        return React.Children.map(children, (child: ReactElement) => {
            if (!child) return null
            const handleChildClick = () => {
                handleClose()
                child?.props?.onClick && child?.props?.onClick()
            }

            return (
                <child.type
                    {...child.props}
                    onClick={handleChildClick}
                    children={renderChild(child.props.children)}
                />
            )
        })
    }

    return (
        <>
            <element.type
                {...element.props}
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />
            <Menu
                aria-labelledby="menu-button"
                anchorEl={anchorEl}
                open={open}
                keepMounted
                onClose={handleClose}
                anchorOrigin={
                    anchorOrigin || {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
                transformOrigin={
                    transformOrigin || {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                }
                sx={{
                    ...sx,
                    ...(gutterTop && { top: theme.spacing(gutterTop) }),
                }}
            >
                {title && (
                    <StyledMenuItem>
                        <Typography
                            sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: '600',
                            }}
                            textAlign="center"
                        >
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography
                                sx={{
                                    color: theme.palette.grey['800'],
                                    fontSize: '10px',
                                    fontWeight: '600',
                                }}
                            >
                                {subtitle}
                            </Typography>
                        )}
                    </StyledMenuItem>
                )}
                {renderChild(children as ReactElement)}
            </Menu>
        </>
    )
}

export default DropdownMenu
