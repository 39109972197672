import { Box, Checkbox, capitalize } from '@mui/material'
import { format } from 'date-fns'
import { Option } from 'forms'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import DataItem from '../../../components/DataItem'
import { getCurrencyFormat } from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { MONTH_DATE_FORMAT } from '../../../constant/const'
interface Props {
    children: any
    renderAsData: boolean
}
const FormData = ({ children, renderAsData }: Props) => {
    const { t } = useTranslation()
    const {
        formState: { defaultValues },
    } = useFormContext()

    const getOptionValue = (props: any) => {
        const inputValue = defaultValues?.[props.name]

        const selected = props?.options.find(({ value }: Option) => value === inputValue)
        return (
            <Box display={'flex'} gap={2}>
                {selected?.Icon} {capitalize(t(selected?.id))}
            </Box>
        )
    }

    const renderValue = (props: any, i: number) => {
        const inputValue = defaultValues?.[props.name]

        if (props?.options) return getOptionValue(props)

        if (inputValue instanceof Date) return format(inputValue, MONTH_DATE_FORMAT)

        if (props.name === 'cost') return getCurrencyFormat(inputValue)

        if (props?.variant === 'checkbox')
            return <Checkbox checked={inputValue} disabled sx={{ ml: -3 }} />

        return inputValue
    }

    const childrenData = children.filter((c: JSX.Element | boolean) => !!c)

    return (
        <>
            {React.Children.map(childrenData, (child, i) => {
                if (!renderAsData)
                    return <child.type {...child.props} noBorder={childrenData.length - 1 === i} />

                return <DataItem label={child.props.label}>{renderValue(child.props, i)}</DataItem>
            })}
        </>
    )
}

export default FormData
