import React, { useEffect, useMemo, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import theme from '../../theme'
import { StyledThreeDScanItem } from './styles'
import fromUnixTime from 'date-fns/fromUnixTime'
import { format } from 'date-fns'
import { ModelData } from 'index'
import { useTranslation } from 'react-i18next'

type Props = {
    data: ModelData[]
}

interface DataWithYear {
    url: string
    type: string
    year: string
}

const ThreeDScanItem = ({ data }: Props) => {
    const [year, setYear] = useState('')
    const [type, setType] = useState('')
    const { t } = useTranslation()

    const dataWithYear = useMemo(() => {
        return data?.map((item: ModelData) => {
            const result = fromUnixTime(item.timestamp)

            return {
                ...item,
                year: format(new Date(result), 'yyyy'),
            }
        })
    }, [data])

    const scanTypes = dataWithYear?.map(({ type }: DataWithYear) => type)
    const scanType = scanTypes.filter((el, i) => scanTypes.indexOf(el) === i)

    const yearOption = useMemo(() => {
        const options = dataWithYear
            ?.filter((item: DataWithYear) => item?.type === type)
            .map(({ year }) => year)
        return options.filter((el, i) => options.indexOf(el) === i)
    }, [type, dataWithYear])

    const getDefaultType = (arr: DataWithYear[]) => {
        if (type) return
        const defaultType = arr[0]?.type
        setType(defaultType)
    }

    const getDefaultYear = (arr: DataWithYear[]) => {
        if (year) return

        if (type) {
            const yearsArr = arr
                ?.filter((el: DataWithYear) => el.type === type)
                .map(({ year }) => year)
            const arrOfNum = yearsArr?.map((str) => Number(str))
            const max = Math.max(...arrOfNum).toString()
            setYear(max)
        }
    }

    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value as string)
    }

    const handleTypeChange = (event: SelectChangeEvent) => {
        const years = dataWithYear
            ?.filter(({ type }: DataWithYear) => type === event.target.value)
            .map(({ year }) => year)

        const availableYear = dataWithYear?.filter(
            ({ type }: DataWithYear) => type === event.target.value
        )[0].year

        setType(event.target.value as string)

        if (years.includes(year)) {
            setYear(year)
        } else setYear(availableYear)
    }

    useEffect(() => {
        getDefaultType(dataWithYear)
        getDefaultYear(dataWithYear)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, type])

    const modelUrl = useMemo(
        () =>
            dataWithYear?.find((item: DataWithYear) => item.year === year && item.type === type)
                ?.url,
        [year, type, dataWithYear]
    )

    return (
        <StyledThreeDScanItem>
            <FormControl sx={{ width: 120, position: 'absolute', top: 10, left: 10 }}>
                <Select
                    id="build-year-select"
                    displayEmpty
                    value={year}
                    onChange={handleYearChange}
                    MenuProps={{
                        style: {
                            maxHeight: 400,
                        },
                    }}
                    sx={{
                        background: theme.palette.neutral.contrastText,
                    }}
                >
                    {yearOption?.map((item: string, i: number) => (
                        <MenuItem key={i} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150, position: 'absolute', top: 10, left: 140 }}>
                <Select
                    id="scan-type-select"
                    displayEmpty
                    value={type}
                    onChange={handleTypeChange}
                    MenuProps={{
                        style: {
                            maxHeight: 400,
                        },
                    }}
                    sx={{
                        background: theme.palette.neutral.contrastText,
                    }}
                >
                    {scanType.map((item: string, i: number) => (
                        <MenuItem key={i} value={item}>
                            {t(`3D_scan.${item}`)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {modelUrl && (
                <iframe
                    title="Glasgow 130.fbx"
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height="500"
                    src={modelUrl}
                ></iframe>
            )}
        </StyledThreeDScanItem>
    )
}

export default ThreeDScanItem
