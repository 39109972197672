import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import {
    APARTMENT_PATHS,
    CONTAINER_PATHS,
    MAINTENANCE_PATHS,
    OBJECT_PATHS,
    TASK_IN_MAINTENANCES_PATHS,
    TASK_PATHS,
    UNIT_PATHS,
} from '../routes/routes'
import { RouterState } from 'router'
import useParamManage from './useParamManage'
import useLocationState from './useLocationState'

type MaintenanceAndTaskVariant = 'CREATE' | 'UPDATE' | 'READ' | 'DELETE' | 'LIST'

const useNavUtils = () => {
    const params = useParamManage()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { prevPath } = useLocationState()

    const state = {
        prevPath: pathname,
    } as RouterState

    return {
        navigateApartment: (id?: string | number) => (key: keyof typeof APARTMENT_PATHS) =>
            navigate(
                generatePath(APARTMENT_PATHS[key], {
                    ...params,
                    apartmentID: id || '',
                }),
                {
                    state,
                }
            ),
        navigateUnit: (id?: string | number) => (key: keyof typeof UNIT_PATHS) =>
            navigate(
                generatePath(UNIT_PATHS[key], {
                    ...params,
                    unitID: id || '',
                }),
                {
                    state,
                }
            ),
        navigateContainer: (id?: string | number) => (key: keyof typeof CONTAINER_PATHS) =>
            navigate(
                generatePath(CONTAINER_PATHS[key], {
                    ...params,
                    containerID: (id || '').toString(),
                }),
                {
                    state,
                }
            ),
        navigateObject:
            (id?: string | number, parentID?: string) => (key: keyof typeof OBJECT_PATHS) =>
                navigate(
                    generatePath(OBJECT_PATHS[key], {
                        ...params,
                        ...(parentID ? { containerID: parentID } : {}),
                        objectID: (id || '').toString(),
                    }),
                    {
                        state,
                    }
                ),
        navigateMaintenance: (id?: string) => (variant: MaintenanceAndTaskVariant) => {
            const { apartmentID, objectID, unitID } = params

            const navMaintenance = (path: keyof typeof MAINTENANCE_PATHS) =>
                navigate(
                    generatePath(MAINTENANCE_PATHS[path], {
                        ...params,
                        maintenanceID: id || '',
                    }),
                    {
                        state,
                    }
                )

            const navToByType = (prefix: MaintenanceAndTaskVariant) => {
                if (apartmentID) return navMaintenance(`${prefix}_APARTMENT`)
                if (unitID) return navMaintenance(`${prefix}_UNIT`)
                if (objectID) return navMaintenance(`${prefix}_OBJECT`)

                return navMaintenance(prefix as 'UPDATE' | 'DELETE')
            }

            switch (variant) {
                case 'CREATE':
                    return navToByType('CREATE')
                case 'UPDATE':
                    return navToByType('UPDATE')
                case 'LIST':
                    return navToByType('LIST')
                case 'DELETE':
                    return navToByType('DELETE')
                case 'READ':
                default:
                    return navToByType('READ')
            }
        },
        navigateTask: (id?: string) => (variant: MaintenanceAndTaskVariant) => {
            const { apartmentID, objectID, unitID, maintenanceID } = params

            const navTaskInMaintenance = (key: 'CREATE' | 'UPDATE' | 'READ' | 'DELETE') => {
                const { apartmentID, objectID, unitID } = params

                const getKey = (): '_APARTMENT' | '_UNIT' | '_OBJECT' | '' => {
                    if (apartmentID) return '_APARTMENT'
                    if (unitID) return '_UNIT'
                    if (objectID) return '_OBJECT'
                    return ''
                }

                navigate(
                    generatePath(TASK_IN_MAINTENANCES_PATHS[`${key}${getKey()}`], {
                        ...params,
                        taskID: id || '',
                    }),
                    {
                        state,
                    }
                )
            }

            const navTask = (key: keyof typeof TASK_PATHS) =>
                navigate(
                    generatePath(TASK_PATHS[key], {
                        ...params,
                        taskID: id || '',
                    }),
                    {
                        state,
                    }
                )

            const navToByType = (prefix: MaintenanceAndTaskVariant) => {
                if (maintenanceID && prefix !== 'LIST') return navTaskInMaintenance(prefix)
                if (apartmentID) return navTask(`${prefix}_APARTMENT`)
                if (unitID) return navTask(`${prefix}_UNIT`)
                if (objectID) return navTask(`${prefix}_OBJECT`)

                return navTask(prefix as 'READ' | 'UPDATE' | 'DELETE')
            }

            switch (variant) {
                case 'CREATE':
                    return navToByType('CREATE')
                case 'UPDATE':
                    return navToByType('UPDATE')
                case 'LIST':
                    return navToByType('LIST')
                case 'DELETE':
                    return navToByType('DELETE')
                case 'READ':
                default:
                    return navToByType('READ')
            }
        },
        goBack: () => {
            return navigate(prevPath)
        },
    }
}

export default useNavUtils
