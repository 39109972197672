import React from 'react'
import OverviewObjectList from '../../../../../../modules/OverviewObjectList'
import { useGetObjects } from '../../../../../../hooks/object/useGetObjects'

const OverviewObjects = () => {
    const { data: objects = [], isLoading: isLoadingObjects } = useGetObjects()

    return <OverviewObjectList data={objects} isLoading={isLoadingObjects} />
}

export default OverviewObjects
