import { Box, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useSelection } from '../../hooks/useSelection';
import { ExitButton, ImageContainer, InfoBox, ResizeHandle, SaveButton, SelectionBox, StyledSelectButton } from './styles';
import { t } from "i18next"

interface ImageViewerWithSelectionProps {
      imageUrl: string;
      initialSelection?: { x: number; y: number; width: number; height: number };
      onSave: (selectionData: { x: number; y: number; width: number; height: number }) => void;
      onExit: () => void;
}

const ImageViewerWithSelection: FC<ImageViewerWithSelectionProps> = ({ imageUrl, initialSelection, onSave, onExit }) => {
      const [originalSize, setOriginalSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

      const {
            isSelectionEnabled,
            isSelecting,
            selection,
            ref: imgRef,
            handleMouseDown,
            handleMouseMove,
            handleMouseUp,
            toggleSelection,
      } = useSelection<HTMLImageElement>(originalSize, initialSelection);

      const handleImageLoad = () => {
            if (imgRef.current) {
                  const { naturalWidth, naturalHeight } = imgRef.current;
                  setOriginalSize({ width: naturalWidth, height: naturalHeight });
            }
      };

      const handleSave = () => {
            if (selection) {
                  onSave({ ...selection });
            }
      };

      const preventDefault = (event: React.MouseEvent | React.TouchEvent) => {
            event.preventDefault();
      };


      return (
            <Box position="fixed" top={0} left={0} right={0} bottom={0} bgcolor="rgba(26, 26, 26, 1)" zIndex={1000} display="flex" alignItems="center" justifyContent="center">
                  <ImageContainer style={{
                        cursor: isSelectionEnabled ? 'crosshair' : 'default',
                  }}>
                        <StyledSelectButton variant="contained" onClick={toggleSelection}>
                              {isSelectionEnabled ? 'Disable Selection' : 'Enable Selection'}
                        </StyledSelectButton>
                        <div
                              style={{ position: 'relative', overflow: 'hidden' }}
                              onMouseDown={handleMouseDown}
                              onMouseMove={handleMouseMove}
                              onMouseUp={handleMouseUp}
                              onTouchStart={preventDefault}
                              onTouchMove={preventDefault}
                              onTouchEnd={preventDefault}
                        >
                              <img
                                    ref={imgRef}
                                    src={imageUrl}
                                    alt="Selected"
                                    onLoad={handleImageLoad}
                                    draggable="false"
                                    style={{
                                          maxWidth: '100%',
                                          maxHeight: 'calc(100vh - 128px)',
                                          pointerEvents: 'none',
                                          userSelect: 'none',
                                          MozUserSelect: 'none',
                                          WebkitUserSelect: 'none',
                                          msUserSelect: 'none',
                                    }}
                              />
                              {isSelectionEnabled && !isSelecting && (
                                    <div
                                          style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                pointerEvents: 'none',
                                          }}
                                    />
                              )}
                              {selection && imgRef.current && (
                                    <SelectionBox
                                          style={{
                                                top: selection.y * (imgRef.current.clientHeight / originalSize.height),
                                                left: selection.x * (imgRef.current.clientWidth / originalSize.width),
                                                width: selection.width * (imgRef.current.clientWidth / originalSize.width),
                                                height: selection.height * (imgRef.current.clientHeight / originalSize.height),
                                          }}
                                    >
                                          <ResizeHandle data-handle="top-left" style={{ top: -10, left: -10, cursor: 'nwse-resize' }} />
                                          <ResizeHandle data-handle="top-right" style={{ top: -10, right: -10, cursor: 'nesw-resize' }} />
                                          <ResizeHandle data-handle="bottom-left" style={{ bottom: -10, left: -10, cursor: 'nesw-resize' }} />
                                          <ResizeHandle data-handle="bottom-right" style={{ bottom: -10, right: -10, cursor: 'nwse-resize' }} />
                                    </SelectionBox>
                              )}
                        </div>
                        <Box display="flex" flexDirection="row" alignItems="center" gap={2} mt={2} position="absolute" bottom={-40} right={0}>
                              <SaveButton variant="contained" onClick={handleSave}>
                                    {t('save')}
                              </SaveButton>
                              <ExitButton variant="contained" onClick={onExit}>
                                    {t('exit')}
                              </ExitButton>
                        </Box>
                        {selection && (
                              <InfoBox style={{ position: 'absolute', bottom: -40, left: 0 }}>
                                    <Typography variant="body2">X: {selection.x}</Typography>
                                    <Typography variant="body2">Y: {selection.y}</Typography>
                                    <Typography variant="body2">W: {selection.width}</Typography>
                                    <Typography variant="body2">H: {selection.height}</Typography>
                              </InfoBox>
                        )}
                  </ImageContainer>
            </Box>
      );
};

export default ImageViewerWithSelection;
