import React from 'react'
import { StyledNavigationBar, StyledRightButtons } from './styles'
import { useTranslation } from 'react-i18next'
import SelectHover from '../../components/SelectHover'
import AddIcon from '@mui/icons-material/Add'
import { Box, MenuItem, Typography, ListItemIcon, IconButton } from '@mui/material'
import useParamManage from '../../hooks/useParamManage'
import useNavUtils from '../../hooks/useNavUtils'
import { useGetContainers } from '../../hooks/container/useGetContainers'
import theme from '../../theme'
import { useGetObjects } from '../../hooks/object/useGetObjects'
import DropdownMenu from '../../components/DropdownMenu'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import { useGetUnits } from '../../hooks/unit/useGetUnits'
import { useGetApartments } from '../../hooks/apartment/useGetApartments'

type Action = 'create' | 'menu-item' | 'label' | 'show-all' | 'update'
type Variant = 'container' | 'object' | 'unit' | 'apartment' | 'maintenance' | 'task'

const HeaderSub = () => {
    const { t } = useTranslation()
    const { apartmentID, unitID, containerID, objectID } = useParamManage()
    const {
        navigateApartment,
        navigateUnit,
        navigateContainer,
        navigateObject,
        navigateMaintenance,
        navigateTask,
    } = useNavUtils()

    const { data: containers = [] } = useGetContainers()
    const { data: objects } = useGetObjects(containerID, {
        queryKey: [containerID],
    })

    const { data: units } = useGetUnits(objectID, {
        queryKey: [objectID],
        enabled: Boolean(objectID),
    })

    const { data: apartments } = useGetApartments(unitID, {
        queryKey: [unitID],
        enabled: Boolean(unitID),
    })

    const handleEditItem = () => {
        if (apartmentID) return navTo('apartment')('update')(undefined)
        if (unitID) return navTo('unit')('update')(undefined)
        if (objectID) return navTo('object')('update')(undefined)
        if (containerID) return navTo('container')('update')(undefined)
    }

    const navToContainer = (action: Action) => (id?: string | number) => {
        const navigate = navigateContainer(id || containerID)

        switch (action) {
            case 'create':
                return navigateContainer()('CREATE')

            case 'menu-item':
                return navigate('GENERAL_INFORMATION')

            case 'show-all':
                return navigateContainer()('OVERVIEW_ALL')

            case 'update':
                return navigate('UPDATE')

            case 'label':
            default:
                return containerID ? navigate('GENERAL_INFORMATION') : navigate('OVERVIEW_ALL')
        }
    }

    const navToObject = (action: Action) => (id?: string | number) => {
        const navigate = navigateObject(id || objectID)

        switch (action) {
            case 'create':
                return navigateObject()('CREATE')

            case 'menu-item':
                return navigate('GENERAL_INFORMATION')

            case 'update':
                return navigate('UPDATE')

            case 'show-all':
                return navigateContainer(containerID)('OVERVIEW')

            case 'label':
            default:
                return objectID
                    ? navigate('GENERAL_INFORMATION')
                    : navigateContainer(containerID)('GENERAL_INFORMATION')
        }
    }

    const navToUnit = (action: Action) => (id?: string | number) => {
        const navigate = navigateUnit(id || unitID)

        switch (action) {
            case 'create':
                return navigateUnit()('CREATE')

            case 'menu-item':
                return navigate('GENERAL_INFORMATION')

            case 'update':
                return navigate('UPDATE')

            case 'show-all':
                return navigateObject(objectID)('OVERVIEW')

            case 'label':
            default:
                return unitID
                    ? navigate('GENERAL_INFORMATION')
                    : navigateObject(objectID)('GENERAL_INFORMATION')
        }
    }

    const navToApartment = (action: Action) => (id?: string | number) => {
        const navigate = navigateApartment(id || apartmentID)

        switch (action) {
            case 'create':
                return navigateApartment()('CREATE')

            case 'menu-item':
                return navigate('GENERAL_INFORMATION')

            case 'update':
                return navigate('UPDATE')

            case 'show-all':
                return navigateUnit(unitID)('OVERVIEW')

            case 'label':
            default:
                return apartmentID
                    ? navigate('GENERAL_INFORMATION')
                    : navigateUnit(unitID)('GENERAL_INFORMATION')
        }
    }

    const navTo =
        (variant: Variant) =>
        (action: Action) =>
        (e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            const { value: id } = (e?.target as HTMLLIElement) || {}

            switch (variant) {
                case 'container':
                    return navToContainer(action)(id)

                case 'object':
                    return navToObject(action)(id)

                case 'unit':
                    return navToUnit(action)(id)

                case 'apartment':
                    return navToApartment(action)(id)

                case 'task':
                    return navigateTask()('CREATE')

                case 'maintenance':
                    return navigateMaintenance()('CREATE')

                default:
                    return navigateContainer()('OVERVIEW_ALL')
            }
        }

    const SELECT_COMPONENTS = [
        {
            navigate: navTo('container'),
            noValueTitle: t(`container.all_containers`),
            addNewText: t(`container.add_container`),
            showAllText: t(`container.show_all_containers`),
            data: containers,
            selected: containers?.find((c) => c.id === containerID),
            show: true,
        },
        {
            navigate: navTo('object'),
            noValueTitle: t(`object.all_objects`),
            addNewText: t(`object.add_object`),
            showAllText: t(`object.show_all_objects`),
            data: objects,
            selected: objects?.find((o) => o.id === objectID),
            show: containerID,
        },
        {
            navigate: navTo('unit'),
            noValueTitle: t(`unit.all_units`),
            addNewText: t(`unit.add_unit`),
            showAllText: t(`unit.show_all_units`),
            data: units,
            selected: units?.find((u) => u.id === unitID),
            show: objectID,
        },
        {
            navigate: navTo('apartment'),
            noValueTitle: t(`apartment.all_apartments`),
            addNewText: t(`apartment.add_apartment`),
            showAllText: t(`apartment.show_all_apartments`),
            data: apartments,
            selected: apartments?.find((u) => u.id === apartmentID),
            show: unitID,
        },
    ] as const

    return (
        <StyledNavigationBar>
            <Box display={'flex'}>
                {SELECT_COMPONENTS.map(
                    (
                        { selected, navigate, noValueTitle, data, addNewText, show, showAllText },
                        i
                    ) => {
                        if (!show) return null
                        return (
                            <SelectHover
                                key={i}
                                currentValue={selected?.id}
                                currentTitle={selected?.title}
                                onLabelClick={navigate('label')}
                                noValueTitle={noValueTitle}
                            >
                                <MenuItem
                                    onClick={navigate('create')}
                                    sx={{ color: theme.palette.secondary.main }}
                                >
                                    <ListItemIcon sx={{ color: theme.palette.secondary.main }}>
                                        <AddIcon />
                                        <Typography textAlign="center">{addNewText}</Typography>
                                    </ListItemIcon>
                                </MenuItem>

                                <MenuItem onClick={navigate('show-all')}>{showAllText}</MenuItem>

                                {data?.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        onClick={navigate('menu-item')}
                                    >
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </SelectHover>
                        )
                    }
                )}
            </Box>
            <StyledRightButtons>
                <DropdownMenu
                    title={t('navigation.add_new')}
                    gutterTop={1}
                    element={
                        <IconButton size="large" color="primary">
                            <AddIcon />
                        </IconButton>
                    }
                >
                    {!containerID && (
                        <MenuItem onClick={navTo('container')('create')}>
                            <Typography textAlign="center">{t(`container.container`)}</Typography>
                        </MenuItem>
                    )}

                    {containerID && !objectID && (
                        <MenuItem onClick={navTo('object')('create')}>
                            <Typography textAlign="center">{t(`object.object`)}</Typography>
                        </MenuItem>
                    )}

                    {objectID && !unitID && (
                        <MenuItem onClick={navTo('unit')('create')}>
                            <Typography textAlign="center">{t(`unit.unit`)}</Typography>
                        </MenuItem>
                    )}

                    {objectID && unitID && !apartmentID && (
                        <MenuItem onClick={navTo('apartment')('create')}>
                            <Typography textAlign="center">{t(`apartment.apartment`)}</Typography>
                        </MenuItem>
                    )}

                    {(objectID || unitID) && (
                        <MenuItem onClick={navTo('maintenance')('create')}>
                            <Typography textAlign="center">{t(`maintenance.title`)}</Typography>
                        </MenuItem>
                    )}

                    {(objectID || unitID) && (
                        <MenuItem onClick={navTo('task')('create')}>
                            <Typography textAlign="center">{t(`task.title`)}</Typography>
                        </MenuItem>
                    )}
                </DropdownMenu>

                <IconButton
                    size="large"
                    color="secondary_filled"
                    disabled={!containerID && !objectID}
                    onClick={handleEditItem}
                >
                    <ModeEditOutlinedIcon fontSize="small" />
                </IconButton>
            </StyledRightButtons>
        </StyledNavigationBar>
    )
}

export default HeaderSub
