import { Box, styled, keyframes } from '@mui/system'

export const StyledBackgroundImageWrapper = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    z-index: -10;
`

const animate = keyframes`
    0% { opacity: 0}
    10% { opacity: 1}
    30% { opacity: 1 }
    50% { opacity: 0 }
    100% { opacity: 0 }
`

export const StyledFirstImage = styled('div')`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center bottom;
`

export const StyledBackgroundImage = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center bottom;
    opacity: 0;
    will-change: opacity;
    transition: 0s;
    animation-name: ${animate};
    animation-duration: 30s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    &:nth-of-type(2) {
        animation-delay: 10s;
    }
    &:nth-of-type(3) {
        animation-delay: 20s;
    }
`

export const StyledTextIconWrapper = styled('div')`
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: ${({ theme }) => theme.palette.common.white};
    display: flex;
    align-items: center;
    gap: 15px;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        bottom: 40px;
        right: 40px;
    }
`
