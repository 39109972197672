import { useState, useRef, useEffect, MutableRefObject, useCallback } from 'react'

interface Selection {
    x: number
    y: number
    width: number
    height: number
}

export const useSelection = <T extends HTMLElement>(
    originalSize: { width: number; height: number },
    initialSelection?: Selection
) => {
    const [isSelectionEnabled, setIsSelectionEnabled] = useState(true)
    const [isSelecting, setIsSelecting] = useState(false)
    const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null)
    const [selection, setSelection] = useState<Selection | undefined>(initialSelection)
    const [resizing, setResizing] = useState<string | null>(null)
    const ref = useRef<T | null>(null) as MutableRefObject<T | null>

    const clamp = (value: number, min: number, max: number) => {
        return Math.max(min, Math.min(max, value))
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isSelectionEnabled || !ref.current) return
        const rect = ref.current.getBoundingClientRect()
        const startX = (event.clientX - rect.left) * (originalSize.width / rect.width)
        const startY = (event.clientY - rect.top) * (originalSize.height / rect.height)

        if (event.target instanceof HTMLElement && event.target.dataset.handle) {
            setResizing(event.target.dataset.handle)
        } else {
            setStartPoint({ x: startX, y: startY })
            setSelection({ x: startX, y: startY, width: 0, height: 0 })
            setIsSelecting(true)
        }
    }

    const handleMouseMove = useCallback(
        (event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
            if (!ref.current) return
            const rect = ref.current.getBoundingClientRect()
            const currentX = (event.clientX - rect.left) * (originalSize.width / rect.width)
            const currentY = (event.clientY - rect.top) * (originalSize.height / rect.height)

            if (resizing && selection) {
                const { x, y, width, height } = selection
                const minWidth = 10
                const minHeight = 10

                switch (resizing) {
                    case 'top-left':
                        setSelection({
                            x: clamp(
                                Math.min(x + width - minWidth, currentX),
                                0,
                                originalSize.width
                            ),
                            y: clamp(
                                Math.min(y + height - minHeight, currentY),
                                0,
                                originalSize.height
                            ),
                            width: Math.max(
                                minWidth,
                                clamp(x + width - currentX, 0, originalSize.width - x)
                            ),
                            height: Math.max(
                                minHeight,
                                clamp(y + height - currentY, 0, originalSize.height - y)
                            ),
                        })
                        break
                    case 'top-right':
                        setSelection({
                            x,
                            y: clamp(
                                Math.min(y + height - minHeight, currentY),
                                0,
                                originalSize.height
                            ),
                            width: Math.max(
                                minWidth,
                                clamp(currentX - x, 0, originalSize.width - x)
                            ),
                            height: Math.max(
                                minHeight,
                                clamp(y + height - currentY, 0, originalSize.height - y)
                            ),
                        })
                        break
                    case 'bottom-left':
                        setSelection({
                            x: clamp(
                                Math.min(x + width - minWidth, currentX),
                                0,
                                originalSize.width
                            ),
                            y,
                            width: Math.max(
                                minWidth,
                                clamp(x + width - currentX, 0, originalSize.width - x)
                            ),
                            height: Math.max(
                                minHeight,
                                clamp(currentY - y, 0, originalSize.height - y)
                            ),
                        })
                        break
                    case 'bottom-right':
                        setSelection({
                            x,
                            y,
                            width: Math.max(
                                minWidth,
                                clamp(currentX - x, 0, originalSize.width - x)
                            ),
                            height: Math.max(
                                minHeight,
                                clamp(currentY - y, 0, originalSize.height - y)
                            ),
                        })
                        break
                    default:
                        break
                }
            } else if (isSelecting && startPoint) {
                const x = clamp(
                    Math.max(0, Math.min(startPoint.x, currentX)),
                    0,
                    originalSize.width
                )
                const y = clamp(
                    Math.max(0, Math.min(startPoint.y, currentY)),
                    0,
                    originalSize.height
                )
                const width = Math.min(originalSize.width - x, Math.abs(currentX - startPoint.x))
                const height = Math.min(originalSize.height - y, Math.abs(currentY - startPoint.y))
                setSelection({ x, y, width, height })
            }
        },
        [isSelecting, startPoint, resizing, selection, originalSize]
    )

    const handleMouseUp = useCallback((event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        setIsSelecting(false)
        setResizing(null)
    }, [])

    useEffect(() => {
        const handleMouseMoveGlobal = (event: MouseEvent) => handleMouseMove(event)
        const handleMouseUpGlobal = (event: MouseEvent) => handleMouseUp(event)

        document.addEventListener('mousemove', handleMouseMoveGlobal)
        document.addEventListener('mouseup', handleMouseUpGlobal)

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveGlobal)
            document.removeEventListener('mouseup', handleMouseUpGlobal)
        }
    }, [handleMouseMove, handleMouseUp])

    const toggleSelection = () => {
        setIsSelectionEnabled(!isSelectionEnabled)
        if (isSelectionEnabled) {
            setSelection(undefined)
        }
    }

    return {
        ref,
        isSelectionEnabled,
        isSelecting,
        selection,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        toggleSelection,
    }
}
