import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import maintenanceService from '../../api/services/maintenance'
import { createMaintenanceApiData } from '../../api/data/maintenance'
import { MaintenanceParams } from 'maintenance'
import useQueryKeys from '../useQueryKeys'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import useNavUtils from '../useNavUtils'

export const useCreateMaintenance = () => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const { navigateMaintenance } = useNavUtils()
    const queryClient = useQueryClient()
    const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Maintenance)

    return useMutation(
        async (data: MaintenanceParams) =>
            maintenanceService.create(await createMaintenanceApiData(data)),
        {
            mutationKey: createKey,
            onSuccess: () => {
                setSuccessMsg(t('created'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateMaintenance()('LIST')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
