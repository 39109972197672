import { Theme } from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiPopover: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({}),
    },
}

export default MuiPopover
