export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.59 56">
        <defs>
            <clipPath id="a">
                <path
                    d="M179 139.21h46.59v56H179Z"
                    transform="translate(-179 -139.21)"
                    fill="#157220"
                />
            </clipPath>
        </defs>
        <g>
            <g>
                <g clipPath="url(#a)">
                    <path
                        d="M6.564 56h33.462a6.457 6.457 0 0 0 6.457-6.457V6.457A6.457 6.457 0 0 0 40.026 0H6.564A6.457 6.457 0 0 0 .107 6.457v43.087a6.457 6.457 0 0 0 6.457 6.457ZM3.607 6.456a2.96 2.96 0 0 1 2.957-2.957h33.462a2.96 2.96 0 0 1 2.957 2.957v43.088a2.96 2.96 0 0 1-2.957 2.957H6.564a2.96 2.96 0 0 1-2.957-2.956Z"
                        fill="#157220"
                    />
                </g>
                <text
                    transform="translate(23.295 24.424)"
                    fill="#157220"
                    fontSize="16"
                    fontFamily="FiraSans-Bold, Fira Sans"
                    fontWeight="700"
                >
                    <tspan x="-12.824" y="0">
                        OBJ
                    </tspan>
                </text>
                <g fill="#fff">
                    <path d="M33.5 40.402 22.621 29.523H33.5v10.879Z" />
                    <path
                        d="M26.243 31.023 32 36.78v-5.757h-5.757m-7.243-3h16v16l-16-16Z"
                        fill="#157220"
                    />
                </g>
                <path fill="#fff" d="M16 30.424h16v11H16z" />
                <g fill="#fff" stroke="#157220" stroke-width="3">
                    <path stroke="none" d="M13 33.023h16v16H13z" />
                    <path fill="none" d="M14.5 34.523h13v13h-13z" />
                </g>
            </g>
        </g>
    </svg>
)
