import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyledForm, StyledWrapper } from '../styles'
import Input from '../components/Input'
import { Box } from '@mui/system'
import TaxonomyFields from '../components/TaxonomyFields'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import { useEffectOnce } from 'usehooks-ts'
import { Option } from 'forms'
import DataGroup from '../../components/DataGroup'
import { ApartmentData, ApartmentParams } from 'apartment'
import { useParams } from 'react-router-dom'
import { RouterParams } from 'router'
import { ROOMS_TAXONOMY } from '../../constant/taxonomy'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    isLoading: boolean
    apartment?: ApartmentData
    attributes?: Option[]
    onSubmit: (values: ApartmentParams) => void
    onCancelClick: () => void
}

const FormApartment: FC<Props> = ({
    apartment,
    isLoading,
    attributes,
    onSubmit,
    onCancelClick,
}) => {
    const { t } = useTranslation()
    const { unitID } = useParams<RouterParams>()

    const methods = useForm<ApartmentParams>({
        resolver: yupResolver(schema),
        defaultValues: apartment || {
            id: '',
            title: '',
            attributes: [],
            space: '',
            optionalID: '',
            floor: '',
            rooms: [],
            unitID,
        },
    })

    const {
        reset,
        handleSubmit,
        formState: { isDirty },
    } = methods

    useEffectOnce(() => {
        return () => reset({}, { keepValues: false })
    })

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledWrapper>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t(`${'general_information'}`)}>
                            <Input name="title" label={t(`${'apartment.title'}`)} />
                            <Input
                                name="space"
                                type="number"
                                label={t(`${'apartment.space'}`)}
                                showLengthUnit
                            />
                            <Input name="floor" label={t(`${'apartment.floor'}`)} />
                            <Input name="optionalID" label="ID" noBorder />
                        </DataGroup>
                        {attributes && (
                            <DataGroup label={t('attributes')}>
                                <TaxonomyFields
                                    name={'attributes'}
                                    btnText={t('add_attribute')}
                                    data={attributes}
                                />
                            </DataGroup>
                        )}
                        <DataGroup label={t('rooms.title')}>
                            <TaxonomyFields
                                name={'rooms'}
                                type="number"
                                btnText={t('rooms.add')}
                                showLengthUnit
                                data={ROOMS_TAXONOMY}
                            />
                        </DataGroup>
                    </Box>
                </StyledWrapper>

                <FormSubmitFooter
                    submitDisabled={!isDirty}
                    onCancelClick={onCancelClick}
                    loading={isLoading}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormApartment
