import { ContainerList } from 'containers'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { createContainerList } from '../../api/data/container'
import containerService from '../../api/services/container'
import { QueryDataKey } from '../../types'
import useQueryKeys from '../useQueryKeys'
export const useGetContainers = (options?: UseQueryOptions): UseQueryResult<ContainerList[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Container)
    const { queryKey, ...restOptions } = options || {}

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await containerService.getAll()
            return createContainerList(data)
        },
        initialData: [],
        placeholderData: [],
    }) as UseQueryResult<ContainerList[]>
}
