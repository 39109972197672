import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/system'
import { ContainerData } from 'containers'
import { Option } from 'forms'
import { ObjectData, ObjectParams } from 'objects'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'usehooks-ts'
import DataGroup from '../../components/DataGroup'
import { useGmap } from '../../context/gmap'
import useParamManage from '../../hooks/useParamManage'
import FormSubmitFooter from '../../modules/FormSubmitFooter'
import ImageUpload from '../../modules/ImageUpload'
import Map, { MapDataFunctions } from '../../modules/Map'
import MapBoundaries from '../../modules/Map/components/MapBoundaries'
import MapObject from '../../modules/Map/components/MapObject'
import { MapTooltipItem } from '../../modules/Map/components/MapTooltip'
import { MapSizes } from '../../types'
import Autocomplete from '../components/Autocomplete'
import Input from '../components/Input'
import LocationFields from '../components/LocationFields'
import TaxonomyFields from '../components/TaxonomyFields'
import { StyledForm, StyledWrapper } from '../styles'
import schema from './shema'

interface Props {
    isLoading: boolean
    container?: ContainerData
    onSubmit: (values: ObjectParams) => void
    onCancelClick: () => void
    object?: ObjectData
    types: Option[]
    attributes: Option[]
}

const FormObject = ({
    object,
    isLoading,
    onSubmit,
    types,
    attributes,
    container,
    onCancelClick,
}: Props) => {
    const { t } = useTranslation()
    const { containerID } = useParamManage()
    const { isGmapLoaded } = useGmap()

    const methods = useForm<ObjectParams>({
        resolver: yupResolver(schema),
        defaultValues: object || {
            entityID: containerID,
            street: '',
            title: '',
            zipCode: '',
            attributes: [],
            type: '',
            city: '',
            idOptional: '',
        },
    })

    const {
        reset,
        handleSubmit,
        control,
        getValues,
        formState: { isDirty },
    } = methods

    const locationField = useWatch({ control, name: 'location' })
    const imageField = useWatch({ control, name: 'image' })

    const handleCreateTooltip = () => {
        const { title, zipCode, city, street } = getValues()

        return (
            <MapTooltipItem data={locationField ? { zipCode, city, title, street } : container} />
        )
    }

    const renderMap = ({ createLocation, createBoundarieLocation }: MapDataFunctions) => {
        if (!locationField && object) {
            return (
                <MapObject
                    data={createLocation({ id: object.id, lat: object?.lat, lng: object?.lng })}
                    renderTooltip={handleCreateTooltip}
                />
            )
        }
        if (locationField)
            return (
                <MapObject
                    data={createLocation(locationField)}
                    renderTooltip={handleCreateTooltip}
                />
            )

        if (container)
            return (
                <MapBoundaries
                    data={createBoundarieLocation(container)}
                    renderTooltip={handleCreateTooltip}
                />
            )

        return <MapObject data={[]} />
    }

    useEffectOnce(() => {
        return () => reset({}, { keepValues: false })
    })

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <StyledWrapper>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t(`${'general_information'}`)}>
                            <Input name="title" label={t(`${'object.object_title'}`)} />
                            <LocationFields
                                label={t('location')}
                                locationOptions={{
                                    input: `${object?.street} ${object?.city} ${object?.zipCode}`,
                                }}
                            >
                                {() => (
                                    <>
                                        <Input name="street" label={t(`${'street'}`)} />
                                        <Input name="zipCode" label={t(`${'zip_code'}`)} />
                                        <Input name="city" label={t(`${'city'}`)} />
                                    </>
                                )}
                            </LocationFields>
                            <Input name="idOptional" label="ID" />
                            <Autocomplete
                                name={`type`}
                                label={t('type')}
                                options={types}
                                noBorder
                            />
                        </DataGroup>
                        <DataGroup label={t('attributes')}>
                            <TaxonomyFields
                                name={'attributes'}
                                btnText={t('add_attribute')}
                                data={attributes}
                            />
                        </DataGroup>
                    </Box>
                    <Box flexBasis={'50%'}>
                        {isGmapLoaded && (
                            <DataGroup label={t('map')} collapsable innerPadding>
                                <Map height={MapSizes.SM} renderMap={renderMap} />
                            </DataGroup>
                        )}

                        <DataGroup label={t('photo_image')} collapsable innerPadding>
                            <ImageUpload name="image" image={imageField} variant="single" />
                        </DataGroup>
                    </Box>
                </StyledWrapper>

                <FormSubmitFooter
                    submitDisabled={!isDirty}
                    onCancelClick={onCancelClick}
                    loading={isLoading}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormObject
