import React, { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, FormControl, Box, IconButton, debounce } from '@mui/material'
import { StyledOutlinedInput } from './styles'

interface Props {
    withSelect?: boolean
    placeholder: string
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
    value: string
    showInput?: boolean
    onSearchToggle?: Dispatch<SetStateAction<boolean>>
    iconButtonSx?: object
    outlinedInputSx?: object
}

const Search = ({
    placeholder,
    value,
    showInput,
    outlinedInputSx,
    iconButtonSx,
    onInputChange,
    onSearchToggle,
}: Props) => {
    const [isOpen, setToggle] = useState<boolean>(Boolean(value))
    const openState = showInput || isOpen

    const handleSearchToggle = () => {
        if (onSearchToggle) return onSearchToggle(!showInput)
        setToggle(!isOpen)
    }

    const handleInputChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        onInputChange(e)
    }, 500)

    const inputRef = useRef<HTMLInputElement>()

    return (
        <>
            {openState ? (
                <Box>
                    <FormControl
                        sx={{
                            flexDirection: 'row',
                            height: '48px',
                            '&:hover': {
                                border: 'none',
                            },
                        }}
                        variant="outlined"
                    >
                        <StyledOutlinedInput
                            onChange={handleInputChange}
                            placeholder={placeholder}
                            inputRef={inputRef}
                            sx={outlinedInputSx}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchToggle} edge="end">
                                        <SearchIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            ) : (
                <IconButton size="large" sx={iconButtonSx} onClick={handleSearchToggle}>
                    <SearchIcon fontSize="small" />
                </IconButton>
            )}
        </>
    )
}

export default Search
