import { Button } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledModelWrapper = styled('div')`
    ${({ theme }) => css``}
`

export const StyledButton = styled(Button)`
    ${({ theme }) => css`
        position: absolute;
        top: ${theme.spacing(6)};
        right: ${theme.spacing(6)};
        z-index: 10;
    `}
`

export const StyledFooter = styled('div')`
    ${({ theme }) => css`
        margin-top: ${theme.spacing(10)};
        border-top: 1px solid ${theme.palette.grey['200']};
        display: flex;
        justify-content: end;
    `}
`
