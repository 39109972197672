import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import objectService from '../../api/services/object'
import { createObjectApiData } from '../../api/data/object'
import { ObjectParams } from 'objects'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import { createResponseData } from '../../api/data'
import useNavUtils from '../useNavUtils'
export const useCreateObject = () => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Object)
    const { navigateObject } = useNavUtils()

    return useMutation(
        async (data: ObjectParams) => await objectService.create(await createObjectApiData(data)),
        {
            mutationKey: createKey,
            onSuccess: ({ data }) => {
                const { id } = createResponseData(data)

                setSuccessMsg(t('created'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateObject(id)('GENERAL_INFORMATION')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
