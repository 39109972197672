import { AutocompleteRenderInputParams, Box, SxProps, TextField, capitalize } from '@mui/material'
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import { Option } from 'forms'
import React, { FC, SyntheticEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { StyledFormItem } from '../../../theme/styles'
import Loader from '../../../components/Loader'
import DataItem from '../../../components/DataItem'
import { useTranslation } from 'react-i18next'

interface Props {
    name: string
    options?: Option[]
    label?: string
    noBorder?: boolean
    noMargin?: boolean
    autocompleteProps?: any
    placeholderName?: string
    sx?: SxProps
}

const Autocomplete: FC<Props> = ({
    name,
    options,
    label,
    autocompleteProps = {},
    noBorder,
    placeholderName,
    sx,
}) => {
    const { t } = useTranslation()
    const { control } = useFormContext()

    const getAdornment = (id?: string) => options?.find(({ value }) => value === id)?.Icon || null

    return (
        <StyledFormItem noBorder={!!noBorder} sx={sx}>
            <DataItem label={label} variant="input">
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        if (!options) return <Loader />
                        return (
                            <MuiAutocomplete
                                options={['', ...options]}
                                getOptionLabel={(option: Option) => capitalize(t(option?.id || ''))}
                                value={options?.find((o) => o.value === value) || ''}
                                autoComplete
                                renderOption={(props, option: Option) => (
                                    <Box
                                        display="flex"
                                        component="li"
                                        p={1}
                                        alignItems={'center'}
                                        gap={2}
                                        {...props}
                                    >
                                        {option?.Icon && option?.Icon} {capitalize(t(option?.id))}
                                    </Box>
                                )}
                                includeInputInList
                                filterSelectedOptions
                                isOptionEqualToValue={(option: Option, value: Option) => {
                                    if (!option) return true

                                    return option.id === value.id
                                }}
                                onChange={(e: SyntheticEvent, option: Option) =>
                                    onChange(option?.value)
                                }
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        helperText={error ? t(`${error.message}`) : null}
                                        error={!!error}
                                        label={placeholderName}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: getAdornment(value),
                                        }}
                                    />
                                )}
                                {...autocompleteProps}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        )
                    }}
                />
            </DataItem>
        </StyledFormItem>
    )
}
export default Autocomplete
