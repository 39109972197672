import { Button, Box } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledSelectButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        z-index: 1100;
        margin-bottom: ${theme.spacing(2)};
        position: absolute;
        top: 0;
        &:hover {
            background-color: ${theme.palette.primary.dark};
        }
    `}
`

export const SaveButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        z-index: 1200;
        &:hover {
            background-color: ${theme.palette.success.dark};
        }
    `}
`

export const ExitButton = styled(Button)`
    ${({ theme }) => css`
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.secondary.contrastText};
        z-index: 1200;
        &:hover {
            background-color: ${theme.palette.secondary.dark};
        }
    `}
`

export const PdfContainer = styled(Box)(
    ({ theme }) => css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1050;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(26, 26, 26, 1);
        overflow: hidden;

        .rpv-core__viewer--dark {
            overflow: hidden;
            height: 100vh;
        }

        .rpv-core__inner-page-container {
            &:nth-of-type(n + 2) {
                overflow: hidden;

                display: none;
            }
        }
    `
)

export const InfoBox = styled(Box)`
    ${({ theme }) => css`
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: ${theme.spacing(1)};
        z-index: 1100;
        display: flex;
        gap: ${theme.spacing(2)};
        align-items: center;
        justify-content: space-between;
        text-align: left;
        user-select: none;
        moz-user-select: none;
        webkit-user-select: none;
        ms-user-select: none;
    `}
`

export const SelectionBox = styled(Box)`
    ${({ theme }) => css`
        position: absolute;
        border: 2px solid ${theme.palette.primary.main};
        background-color: rgba(255, 255, 255, 0.2);
        pointer-events: auto;
        cursor: crosshair;
    `}
`
