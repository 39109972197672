import { Box, Typography } from '@mui/material'
import React from 'react'
import { StyledButtonWrapper } from '../../../theme/styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import { PATHS } from '../../../routes/routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'usehooks-ts'
import { NewPasswordParams } from '../../../types/auth'
import { useUpdatePassword } from '../../../hooks/auth/useUpdatePassword'
import Input from '../../../forms/components/Input'

type Props = {}

const NewPassword = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useLocation()
    const passChangeToken = state?.passChangeToken
    const { mutate: setNewPassword, isLoading } = useUpdatePassword(passChangeToken)

    const methods = useForm<NewPasswordParams>({
        resolver: yupResolver(schema),
    })
    const { handleSubmit } = methods
    const onSubmit = (values: NewPasswordParams) => setNewPassword(values)

    useEffectOnce(() => {
        if (!passChangeToken) navigate(PATHS.SIGNIN)
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
                <Box mb={6}>
                    <Typography variant="h3">{t('new_password_page.title')}</Typography>
                </Box>
                <Box mb={7}>
                    <Typography variant="body1">{t('new_password_page.content')}</Typography>
                </Box>
                <Input name="pass" labelSmall={t('user_settings.new_password')} sx={{ mb: 4 }} />
                <Input name="repass" labelSmall={t('repeat_password')} sx={{ mb: 4 }} />

                <StyledButtonWrapper>
                    <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        loading={isLoading}
                        endIcon={<ArrowRightIcon />}
                    >
                        {t('submit')}
                    </LoadingButton>
                </StyledButtonWrapper>
            </FormProvider>
        </form>
    )
}

export default NewPassword
