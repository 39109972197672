import { Box } from '@mui/system'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Autocomplete from '../Autocomplete'
import Input from '../Input'
import { StyledNestedItems } from '../../styles'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../../theme'
import { Option } from 'forms'
import AddIcon from '@mui/icons-material/Add'
import { StyledLabel } from '../../../components/DataItem/styles'

interface Props {
    name: string
    type?: string
    btnText: string
    showLengthUnit?: boolean
    data: Option[]
}

const defaultAttr = {
    id: '',
    value: '',
} as Option

const TaxonomyFields = ({ data, name, btnText, type, showLengthUnit }: Props) => {
    const { control } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    return (
        <Box>
            {fields.map((field: any, i: number) => (
                <StyledNestedItems key={field.id}>
                    <Box display="flex" flexBasis={'100%'} alignItems={'center'}>
                        <StyledLabel padding={`0 ${theme.spacing(2)}`} width="50%">
                            <Autocomplete
                                name={`${name}.${i}.id`}
                                noBorder
                                options={data}
                                sx={{
                                    m: 2,
                                }}
                            />
                        </StyledLabel>
                        <Input
                            type={type || 'text'}
                            name={`${name}.${i}.value`}
                            noBorder={i === fields.length - 1}
                            sx={{ ml: '8px', py: '8px' }}
                        />
                        {showLengthUnit && (
                            <Box ml={2}>
                                m<sup>2</sup>
                            </Box>
                        )}
                    </Box>
                    <IconButton onClick={() => remove(i)} sx={{ mx: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </StyledNestedItems>
            ))}
            <Button
                variant="contained"
                size="large"
                onClick={() => append(defaultAttr)}
                sx={{ margin: theme.spacing(2) }}
                startIcon={<AddIcon />}
            >
                {btnText}
            </Button>
        </Box>
    )
}

export default TaxonomyFields
