import { StyledGeneralInfo } from '../../../../../../theme/styles'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import DataItem from '../../../../../../components/DataItem'
import Map from '../../../../../../modules/Map'
import { useGetObject } from '../../../../../../hooks/object/useGetObject'
import ThreeDScan from '../../../../../../modules/ThreeDScan'
import { Box, Button } from '@mui/material'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import Loader from '../../../../../../components/Loader'
import Image from '../../../../../../components/Image'
import { MapTooltipItem } from '../../../../../../modules/Map/components/MapTooltip'
import MapObject from '../../../../../../modules/Map/components/MapObject'
import ImageIcon from '@mui/icons-material/Image'
import DataGroup from '../../../../../../components/DataGroup'
import { useTranslation } from 'react-i18next'
import { Option } from 'forms'
import useParamManage from '../../../../../../hooks/useParamManage'
import useNavUtils from '../../../../../../hooks/useNavUtils'

const GeneralInfoObject = () => {
    const { data: attributes } = useGetAttributes()
    const { t } = useTranslation()
    const { objectID } = useParamManage()
    const { navigateObject } = useNavUtils()

    const { data: object, isLoading } = useGetObject(objectID, {
        queryKey: [objectID],
    })
    const { data: types } = useGetTypes()

    const objecType = types?.find((type) => type.value === object?.type)

    const handleCreateTooltip = () => <MapTooltipItem data={object} />
    const handleUpdateImageClick = () => navigateObject(objectID)('UPDATE')

    if (!objectID || isLoading) return <Loader absoluteCenter />

    return (
        <>
            <StyledGeneralInfo>
                <Box flexBasis={'50%'}>
                    <DataGroup label={t(`${'general_information'}`)}>
                        <DataItem label={t('object.object_title')}>{object?.title}</DataItem>
                        <DataItem label={t('street')}>{object?.street}</DataItem>
                        <DataItem label={t('zip_code')}>{object?.zipCode}</DataItem>
                        <DataItem label={t('city')}>{object?.city}</DataItem>
                        <DataItem label={'ID'}>{object?.idOptional || object?.id}</DataItem>
                        <DataItem label={t('type')} noBorder>
                            {objecType?.id}
                        </DataItem>
                    </DataGroup>
                    {!!object?.attributes?.length && (
                        <Box mb={5}>
                            <DataGroup label={t(`${'attributes'}`)} collapsable>
                                {object?.attributes?.map(({ id, value }: Option) => (
                                    <DataItem
                                        key={id}
                                        label={
                                            attributes?.find((objAttr) => objAttr.value === id)
                                                ?.id || ''
                                        }
                                    >
                                        {value}
                                    </DataItem>
                                ))}
                            </DataGroup>
                        </Box>
                    )}
                </Box>
                <Box flexBasis={'50%'}>
                    <DataGroup label={t('map')} innerPadding collapsable>
                        <Map
                            renderMap={({ createLocation }) => (
                                <MapObject
                                    data={createLocation(object)}
                                    renderTooltip={handleCreateTooltip}
                                />
                            )}
                        />
                    </DataGroup>
                    <DataGroup label={t('overview_image')} innerPadding collapsable>
                        <Box
                            display="flex"
                            flexDirection={'column'}
                            gap={2}
                            justifyContent={'center'}
                        >
                            {object?.image?.src ? (
                                <Image src={object?.image?.src} maxHeight="600" />
                            ) : (
                                <Button
                                    variant="contained"
                                    size="large"
                                    startIcon={<ImageIcon />}
                                    onClick={handleUpdateImageClick}
                                    sx={{ mr: 'auto' }}
                                >
                                    {t('update_image')}
                                </Button>
                            )}
                        </Box>
                    </DataGroup>
                </Box>
            </StyledGeneralInfo>

            {Boolean(object?.models?.length) && (
                <DataGroup label={t('3D_scan.3D_scan')} collapsable innerPadding>
                    <ThreeDScan data={object?.models} />
                </DataGroup>
            )}
        </>
    )
}

export default GeneralInfoObject
