import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useUpdateContainer } from '../../../../../../hooks/container/useUpdateContainer'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import { useGetContainer } from '../../../../../../hooks/container/useGetContainer'
import useParamManage from '../../../../../../hooks/useParamManage'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import FormContainer from '../../../../../../forms/FormContainer'

const UpdateContainer = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { containerID } = useParamManage()

    const { data: container, isLoading } = useGetContainer(containerID, {
        queryKey: [containerID],
    })

    const { mutate: updateContainer, isLoading: isLoadingUpdate } = useUpdateContainer(containerID)

    return (
        <Modal title={t('container.edit_container')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoading}>
                <FormContainer
                    container={container}
                    isLoading={isLoadingUpdate}
                    onCancelClick={goBack}
                    onSubmit={updateContainer}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateContainer
