import axios from 'axios'
import axiosInstance from '../axios'

const otherService = {
    searchRegion: (params: any) => {
        return axios({
            headers: {
                'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY || '',
                'Access-Control-Allow-Origin': '*',
            },
            method: 'POST',
            url: 'https://regionlookup.googleapis.com/v1alpha:lookupRegion',
            data: params,
        })
    },
    getAttributes: () => {
        return axiosInstance.get(`/attribute_types`)
    },
    getTypes: () => {
        return axiosInstance.get(`/object_types`)
    },
    getStatus: () => {
        return axiosInstance.get(`/tp_status`)
    },
    getMepTypes: () => {
        return axiosInstance.get(`/mep_types`)
    },
    contractorTrades: () => {
        return axiosInstance.get('/trades')
    },
    updateLanguage: (lang: string) => {
        return axiosInstance.put(`/language/${lang}`, {})
    },
}
export default otherService
