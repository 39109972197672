import { styled as emotionStyled } from '@mui/material'
import isPropValid from '@emotion/is-prop-valid'
import { StyledOptions } from '@emotion/styled'
import { MuiStyledOptions } from '@mui/system'

const whiteList = ['placement']

const styled = (component: any, options?: (StyledOptions<any> & MuiStyledOptions) | undefined) =>
    emotionStyled(component, {
        shouldForwardProp: (prop) => isPropValid(String(prop)) || whiteList.includes(String(prop)),
        ...options,
    })

export default styled
