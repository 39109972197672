import React, { memo } from 'react'
import { StyledChartWrapper, StyledStatBody, StyledStatCard, StyledStatHeading } from '../../styles'
import { t } from 'i18next'
import {
    Chart as ChartJS,
    Tooltip,
    ChartOptions,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { TASK_PRIORITY } from '../../../../constant/taxonomy'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import { TaskCostStatsProps } from '../..'
import { useChartData } from '../../../../context/stats'
import { getCurrencyFormat } from '../../../../utils/helpers'
import NoData from '../../../../components/NoData'
import { capitalize } from '@mui/material'

const TaskCostStats = ({ title, data }: TaskCostStatsProps) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)
    const { colors, createTooltip } = useChartData()

    const initialChartValues = TASK_PRIORITY.reduce<{ [key: string]: number }>(
        (acc, { value }) => ({ ...acc, [value]: 0 }),
        {}
    )

    const chartValues =
        data?.reduce<{ [key: string]: number }>((acc, { cost, priority }) => {
            acc[priority] ? (acc[priority] += cost) : (acc[priority] = cost)
            return acc
        }, initialChartValues) || []

    const handleTooltipValue = (value: string) => getCurrencyFormat(value)

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    callback: (value) => getCurrencyFormat(value),
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
                external: (chart) => createTooltip(chart, handleTooltipValue),
            },
            legend: {
                display: false,
            },
        },
    }

    const chartData = {
        labels: TASK_PRIORITY.filter(({ value }) => value !== 'none').map(({ id }) =>
            capitalize(t(id))
        ),
        datasets: [
            {
                data: Object.values(chartValues),
                backgroundColor: colors,
            },
        ],
    }

    return (
        <StyledStatCard>
            <StyledStatHeading>{title}</StyledStatHeading>
            <StyledStatBody>
                <LoaderWrapper center isLoading={data === undefined}>
                    {data?.length !== 0 ? (
                        <StyledChartWrapper width={'100%'}>
                            <Bar width={60} height={20} data={chartData} options={options} />
                        </StyledChartWrapper>
                    ) : (
                        <NoData />
                    )}
                </LoaderWrapper>
            </StyledStatBody>
        </StyledStatCard>
    )
}

export default memo(TaskCostStats)
