import React, { memo } from 'react'
import {
    StyledChartWrapper,
    StyledLegend,
    StyledLegendItem,
    StyledStatBody,
    StyledStatCard,
    StyledStatHeading,
} from '../../styles'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import { TaskStatusStatsProps } from '../..'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Chart, ChartOptions } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import theme from '../../../../theme'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import { useChartData } from '../../../../context/stats'
import { doSort, getPercentages } from '../../../../utils/helpers'
import NoData from '../../../../components/NoData'

const TaskStatusStats = ({ title, data }: TaskStatusStatsProps) => {
    ChartJS.register(ArcElement, Tooltip, Legend)
    const { colors, createTooltip, drawInsideDoughnut } = useChartData()

    const chartValues = data
        ?.map(({ status }) => status)
        .reduce<{ [key: string]: number }>((acc, curr) => {
            acc[curr] ? (acc[curr] += 1) : (acc[curr] = 1)
            return acc
        }, {})

    const chartValuesSorted = (chartValues && doSort(chartValues)) || []

    const options: ChartOptions<'doughnut'> = {
        cutout: 34,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                external: createTooltip,
                enabled: false,
            },
            legend: {
                display: false,
            },
        },
    }

    const plugins = [
        {
            id: 'beforeDraw',
            beforeDraw: (chart: Chart) =>
                drawInsideDoughnut(chart, `${data?.length}`, t('task.all_tasks')),
        },
    ]

    const chartData = {
        labels: Object.keys(chartValuesSorted).map((v) => t(v)),
        datasets: [
            {
                data: Object.values(chartValuesSorted),
                backgroundColor: colors,
                borderColor: [theme.palette.common.white],
                borderWidth: 1,
            },
        ],
    }
    const percentageData = getPercentages(Object.values(chartData.datasets[0].data), data?.length)

    return (
        <StyledStatCard>
            <StyledStatHeading>{title}</StyledStatHeading>
            <StyledStatBody>
                <LoaderWrapper center isLoading={data === undefined}>
                    {data?.length !== 0 ? (
                        <>
                            <StyledChartWrapper>
                                <Doughnut
                                    width={20}
                                    height={20}
                                    data={chartData}
                                    options={options}
                                    plugins={plugins}
                                    redraw
                                />
                            </StyledChartWrapper>
                            <StyledLegend>
                                {chartData.labels.map((label, i) => (
                                    <StyledLegendItem
                                        key={label}
                                        color={chartData.datasets[0].backgroundColor[i]}
                                    >
                                        <Typography>{label}</Typography>
                                        <Typography>{percentageData[i]}</Typography>
                                    </StyledLegendItem>
                                ))}
                            </StyledLegend>
                        </>
                    ) : (
                        <NoData />
                    )}
                </LoaderWrapper>
            </StyledStatBody>
        </StyledStatCard>
    )
}

export default memo(TaskStatusStats)
