import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { IconButton } from '@mui/material'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import useParamManage from '../../../../../../hooks/useParamManage'
import EditIcon from '@mui/icons-material/Edit'
import { useGetTask } from '../../../../../../hooks/task/useGetTask'
import FormTask from '../../../../../../forms/FormTask'
import useLocationState from '../../../../../../hooks/useLocationState'

const ReadTask = () => {
    const { t } = useTranslation()
    const { goBack, navigateTask, navigateMaintenance } = useNavUtils()
    const { taskID, maintenanceID } = useParamManage()
    const { prevPath } = useLocationState()

    const { data: task, isLoading: isLoadingGet } = useGetTask(taskID, {
        queryKey: [taskID],
    })

    const handleEditTask = () => navigateTask(taskID)('UPDATE')

    const handleGoBack = () => {
        if (prevPath) return goBack()
        if (maintenanceID) return navigateMaintenance(maintenanceID)('READ')
        return navigateTask('')('LIST')
    }

    return (
        <Modal
            title={t('task.title')}
            onClose={handleGoBack}
            headerElement={
                <IconButton color="primary_filled" size="large" onClick={handleEditTask}>
                    <EditIcon fontSize="small" />
                </IconButton>
            }
        >
            <LoaderWrapper isLoading={Boolean(isLoadingGet)}>
                <FormTask task={task} isLoading={false} />
            </LoaderWrapper>
        </Modal>
    )
}

export default ReadTask
