import React from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { StyledWrapper } from './styles'
import MapWrapper from './components/MapWrapper'
import { getFilterData } from '../../utils/helpers'
import useSearchStore from '../../store/search'
import { ObjectData, ObjectList } from 'objects'
import { UnitList } from 'units'
import { CreateLocationParams, createLocationData } from '../../api/data'
import { LocationField, MapLocation } from '../../types'
import { ContainerData, ContainerList } from 'containers'
import { ContainerMapLocation } from 'map'

export type MapProps =
    | ContainerList
    | ObjectList
    | UnitList
    | ObjectData
    | ContainerData
    | LocationField
    | CreateLocationParams

interface Props {
    height?: number
    large?: boolean
    renderMap: (functions: MapDataFunctions) => JSX.Element
}

export interface MapDataFunctions {
    createAndFilterLocations: (data: MapProps[]) => MapLocation[]
    createAndFilterBoundarieLocations: (data: ContainerData[]) => ContainerMapLocation[]
    createLocation: (data: MapProps | undefined) => MapLocation[]
    createBoundarieLocation: (data?: ContainerData | LocationField) => ContainerMapLocation[]
}
const MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID || ''

const Map = ({ large, height, renderMap }: Props) => {
    const { inputValue: searchValue } = useSearchStore()

    const createAndFilterLocations = (data: MapProps[]): MapLocation[] => {
        const result = getFilterData<MapProps>(data, searchValue)
        return result.map(({ id, lat, lng }) => createLocationData({ id, lat, lng }))
    }

    const createLocation = (data: MapProps | undefined): MapLocation[] => {
        if (!data) return []
        return [data].map(({ id, lat, lng }) => createLocationData({ id, lat, lng }))
    }

    const createAndFilterBoundarieLocations = (data: ContainerList[]): ContainerMapLocation[] => {
        const result = getFilterData<ContainerList>(data, searchValue)
        return result.map(({ id, zipCode, lat, lng }) => ({
            id,
            zipCode,
            location: { lat, lng },
            placeID: '',
        }))
    }

    const createBoundarieLocation = (
        data?: ContainerData | LocationField
    ): ContainerMapLocation[] => {
        if (!data) return []
        return [data].map(({ id, zipCode, lat, lng }) => ({
            id,
            zipCode,
            location: { lat, lng },
            placeID: '',
        }))
    }

    const mapFunctions: MapDataFunctions = {
        createLocation,
        createBoundarieLocation,
        createAndFilterLocations,
        createAndFilterBoundarieLocations,
    }

    return (
        <StyledWrapper large={Boolean(large)} height={height}>
            <GoogleMap
                options={{ mapId: MAP_ID, disableDefaultUI: true }}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                zoom={10}
            >
                <MapWrapper>{renderMap(mapFunctions)}</MapWrapper>
            </GoogleMap>
        </StyledWrapper>
    )
}

export default Map
