import React, { FC, useState } from 'react'
import { StyledModelWrapper, StyledButton, StyledFooter } from './styles'
import { Box, Button } from '@mui/material'
import theme from '../../theme'
import ThreeDScanItem from '../ThreeDScanItem'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { ModelData } from '../../types'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../hooks/useParamManage'
import useNavUtils from '../../hooks/useNavUtils'

interface Props {
    data?: ModelData[]
}

const ThreeDScan: FC<Props> = ({ data }) => {
    const [compareYears, setCompareYears] = useState(false)
    const { t } = useTranslation()
    const { objectID, unitID } = useParamManage()
    const { navigateObject, navigateUnit } = useNavUtils()

    const handleCompareYears = () => {
        setCompareYears(!compareYears)
    }

    const handleEditItem = () => {
        if (unitID) return navigateUnit(unitID)
        if (objectID) return navigateObject(objectID)
    }

    if (!data?.length) return null

    return (
        <Box position={'relative'}>
            <StyledModelWrapper>
                {data.length > 1 && (
                    <StyledButton variant="contained" size="large" onClick={handleCompareYears}>
                        {!compareYears ? t('3D_scan.compare_scans') : t('3D_scan.close_comparison')}
                    </StyledButton>
                )}

                <Box display="flex" gap="2px">
                    <>
                        <ThreeDScanItem data={data!} />
                        {compareYears && <ThreeDScanItem data={data!} />}
                    </>
                </Box>
            </StyledModelWrapper>
            <StyledFooter>
                <Button
                    size="large"
                    variant="contained"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    sx={{ mt: theme.spacing(2), marginLeft: 'auto' }}
                    onClick={handleEditItem}
                >
                    {t('3D_scan.edit_item')}
                </Button>
            </StyledFooter>
        </Box>
    )
}

export default ThreeDScan
