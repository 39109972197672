import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiCssBaseline: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: `
      @font-face {
        font-family: "Fira Sans";
        src: uronts/FiraSans-Regular.eot) format('eot'),
        url(/fonts/FiraSans-Regular.ttf) format('ttf'),
        url(/fonts/FiraSans-Regular.svg) format('svg'),
        url(/fonts/FiraSans-Regular.woff) format('woff'),
        url(/fonts/FiraSans-Regular.woff2}) format('woff2');
        font-style: normal;
        font-weight: 400;
      }
      @font-face {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        src: url(/fonts/FiraSans-Medium.eot) format('eot'),
        url(/fonts/FiraSans-Medium.ttf) format('ttf'),
        url(/fonts/FiraSans-Medium.svg') format('svg'),
        url(/fonts/FiraSans-Medium.woff) format('woff'),
        url(/fonts/FiraSans-Medium.woff2) format('woff2');
      };
      @font-face {
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 600;
        src: url(/fonts/FiraSans-SemiBold.eot}) format('eot'),
        url(/fonts/FiraSans-SemiBold.ttf) format('ttf'),
        url(/fonts/FiraSans-SemiBold.svg) format('svg'),
        url(/fonts/FiraSans-SemiBold.woff) format('woff'),
        url(/fonts/FiraSans-SemiBold.woff2) format('woff2');
      };   
    `,
    variants: [],
}

export default MuiCssBaseline
