import { Typography, SxProps } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, useState } from 'react'
import theme from '../../theme'
import { StyledImage, StyledSkeleton } from './styles'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import { t } from 'i18next'
interface Props {
    src: string
    objectFit?: string
    sxWrapper?: SxProps
    maxHeight?: string
    loading?: 'lazy' | 'eager'
    onClick?: (e: any) => void
}
const Image: FC<Props> = ({ sxWrapper, src, onClick, objectFit, loading, maxHeight }) => {
    const [loaded, setLoaded] = useState<boolean | 'error'>(false)

    if (loaded === 'error')
        return (
            <Box
                display="flex"
                alignItems={'center'}
                justifyContent="center"
                flexDirection="column"
            >
                <BrokenImageIcon
                    htmlColor={theme.palette.common.black}
                    fontSize={'large'}
                    sx={{ mb: 3 }}
                />
                <Typography color={theme.palette.common.black}>{t('image_broken')}</Typography>
            </Box>
        )

    return (
        <Box
            sx={sxWrapper || { width: '100%', height: '100%', display: 'flex' }}
            className="swiper-zoom-container"
        >
            {!loaded && <StyledSkeleton />}
            <StyledImage
                src={src}
                draggable="false"
                maxHeight={maxHeight}
                loading={loading}
                onClick={onClick}
                objectfit={objectFit || 'cover'}
                visible={Boolean(loaded)}
                onError={() => setLoaded('error')}
                onLoad={() => setLoaded(true)}
            />
        </Box>
    )
}

export default Image
