import { OutlinedInput } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledOutlinedInput = styled(OutlinedInput)`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.contrastText};
        padding-right: ${theme.spacing(4)};
        legend {
            display: none;
        }
        fieldset {
            top: 0;
        }
    `}
`
