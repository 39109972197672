import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TASK_STATUS } from '../../constant/taxonomy'
import { TaskParams } from 'tasks'
import Select from '../../components/Select'
import LoaderWrapper from '../../components/LoaderWrapper'

interface Props {
    onChange: (params: Partial<TaskParams>) => void
    isLoading?: boolean
    id: string
    defaultValue?: string
}

const FormStatus = ({ isLoading, onChange, id, defaultValue }: Props) => {
    const methods = useForm<TaskParams>({
        mode: 'onSubmit',
        defaultValues: { status: defaultValue || '', id: '' },
    })

    const handleChange = (value: string) => {
        onChange({ id, status: value })
    }

    return (
        <FormProvider {...methods}>
            <LoaderWrapper size={20} isLoading={isLoading}>
                <Select
                    name={`status`}
                    variant="standard"
                    options={TASK_STATUS}
                    onChange={handleChange}
                    selectSx={{ pt: 1, pb: 1 }}
                    noBorder
                />
            </LoaderWrapper>
        </FormProvider>
    )
}

export default FormStatus
