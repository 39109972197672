import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataGroup from "../../components/DataGroup";
import FormSubmitFooter from "../../modules/FormSubmitFooter";
import ImageUpload from "../../modules/ImageUpload";
import { CONTAINER_PATHS } from "../../routes/routes";
import { CompanySettingsParams } from "../../types/company";
import Input from "../components/Input";
import { StyledForm, StyledWrapper } from "../styles";
import schema from "./shema";
import SelectableFileUpload from "../../modules/SelectableFileUpload";
import { SelectableFileData } from "../../types";

interface Props {
      isLoading: boolean;
      onSubmit: (values: CompanySettingsParams) => void;
      onCancelClick: () => void;
      companySettings?: CompanySettingsParams;
}

const FormCompany = ({
      isLoading,
      onSubmit,
      onCancelClick,
      companySettings
}: Props) => {
      console.log("🚀 ~ companySettings:", companySettings);
      const { t } = useTranslation();
      const navigate = useNavigate();

      const methods = useForm<CompanySettingsParams>({
            resolver: yupResolver(schema),
            defaultValues: companySettings || {
                  primary_bg: '',
                  secondary_bg: '',
                  btn_bg: '',
                  btn_color: '',
                  primary_font_color: '',
                  secondary_font_color: '',
                  letterhead_primary_offset_x: 0,
                  letterhead_primary_offset_y: 0,
                  letterhead_primary_box_width: 0,
                  letterhead_primary_box_height: 0,
                  letterhead_secondary_offset_x: 0,
                  letterhead_secondary_offset_y: 0,
                  letterhead_secondary_box_width: 0,
                  letterhead_secondary_box_height: 0,
            },
      });

      const {
            handleSubmit,
            formState: { isDirty },
            control,
      } = methods;

      const logoField = useWatch({ control, name: 'logo' });
      const letterheadPrimaryField = useWatch({ control, name: 'letterhead_primary' });
      const letterheadSecondaryField = useWatch({ control, name: 'letterhead_secondary' });

      const handleCancelClick = () => navigate(CONTAINER_PATHS.OVERVIEW_ALL);

      return (
            <FormProvider {...methods}>
                  <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <StyledWrapper>
                              <Box flexBasis={'50%'}>
                                    <DataGroup label={t('general_information')}>
                                          <Input name="primary_bg" label={t(`${'primaryBackgroundColor'}`)} variant="color" />
                                          <Input name="secondary_bg" label={t('secondaryBackgroundColor')} variant="color" />
                                          <Input name="btn_bg" label={t('buttonBackgroundColor')} variant="color" />
                                          <Input name="btn_color" label={t('buttonFontColor')} variant="color" />
                                          <Input name="primary_font_color" label={t('primaryFontColor')} variant="color" />
                                          <Input name="secondary_font_color" label={t('secondaryFontColor')} variant="color" />
                                    </DataGroup>
                              </Box>
                              <Box flexBasis={'50%'}>
                                    <DataGroup label={t('logo')} collapsable innerPadding>
                                          <ImageUpload name="logo" image={logoField} variant="single" />
                                    </DataGroup>
                              </Box>
                        </StyledWrapper>
                        <StyledWrapper>

                              <Box flexBasis={'50%'}>
                                    <DataGroup label={t('letterheadPrimary')} collapsable innerPadding>
                                          <SelectableFileUpload name="letterhead_primary" file={letterheadPrimaryField as SelectableFileData} />
                                    </DataGroup>
                                    <Input disabled name="letterhead_primary_offset_x" label={t('letterheadPrimaryArea.x')} />
                                    <Input disabled name="letterhead_primary_offset_y" label={t('letterheadPrimaryArea.y')} />
                                    <Input disabled name="letterhead_primary_box_width" label={t('letterheadPrimaryArea.width')} />
                                    <Input disabled name="letterhead_primary_box_height" label={t('letterheadPrimaryArea.height')} />
                              </Box>

                              <Box flexBasis={'50%'}>
                                    <DataGroup label={t('letterheadSecondary')} collapsable innerPadding>
                                          <SelectableFileUpload name="letterhead_secondary" file={letterheadSecondaryField as SelectableFileData} />
                                    </DataGroup>
                                    <Input disabled name="letterhead_secondary_offset_x" label={t('letterheadSecondaryArea.x')} />
                                    <Input disabled name="letterhead_secondary_offset_y" label={t('letterheadSecondaryArea.y')} />
                                    <Input disabled name="letterhead_secondary_box_width" label={t('letterheadSecondaryArea.width')} />
                                    <Input disabled name="letterhead_secondary_box_height" label={t('letterheadSecondaryArea.height')} />
                              </Box>
                        </StyledWrapper>

                        <FormSubmitFooter submitDisabled={!isDirty} onCancelClick={handleCancelClick} loading={isLoading} />
                  </StyledForm>
            </FormProvider>
      );
};

export default FormCompany;
