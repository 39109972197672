import { Option } from 'forms'
import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { createOptionsFromResponse } from '../../api/data'
import otherService from '../../api/services/other'
import useQueryKeys from '../useQueryKeys'

const useGetAttributes = (options?: UseQueryOptions) => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Attribute)
    const { queryKey, ...restOptions } = options || {}

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        keepPreviousData: true,
        refetchOnMount: false,
        queryFn: async () => {
            const { data } = await otherService.getAttributes()
            return createOptionsFromResponse(data)
        },
    }) as UseQueryResult<Option[]>
}

export default useGetAttributes
