import { Select } from '@mui/material'
import { styled, css } from '@mui/system'
import { Link } from 'react-router-dom'

export const StyledNav = styled('nav')`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
    `}
`

export const StyledLogoBar = styled('div')`
    ${({ theme }) => css`
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing(2)} ${theme.spacing(3)};

        background: linear-gradient(
            90deg,
            ${theme.palette.darker.main} 0%,
            ${theme.palette.darker.light} 100%
        );
        border-bottom: 1px solid ${theme.palette.grey['700']};
    `}
`

export const StyledLogotype = styled(Link)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const StyledIcons = styled('div')`
    display: flex;
    gap: 10px;
    height: 48px;
`

export const StyledBadge = styled('div')<{ notification?: number }>`
    ${({ theme, notification }) => css`
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${notification ? theme.palette.primary.main : 'transparent'};
        border: ${notification ? `1px solid ${theme.palette.neutral.contrastText}` : 'none'};
        position: absolute;
        top: 10%;
        right: 10%;
    `}
`

export const StyledHeaderSelect = styled(Select)`
    cursor: pointer;
`
