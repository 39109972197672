import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "../useSnackbar"
import { t } from "i18next"
import useQueryKeys from "../useQueryKeys"
import { QueryDataKey } from "../../types"
import { CompanySettingsParams } from "../../types/company"
import companySettingsService from "../../api/services/company-settings"
import { AxiosError } from "axios"
import { getTextError } from "../../utils/helpers"
import { createCompanySettingsApiData } from "../../api/data/company"

export const useCreateCompanySettings = () => {
      const { setSuccessMsg, setErrorMsg } = useSnackbar()
      const queryClient = useQueryClient()
      const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.CompanySettings)

      return useMutation(async (data: CompanySettingsParams) => await companySettingsService.update(await createCompanySettingsApiData(data)), {
            mutationKey: createKey,
            onSuccess: () => {
                  setSuccessMsg(t('created'))
                  queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
            },
            onError: (err: AxiosError) => {
                  setErrorMsg(getTextError(err))
            }
      })
}