import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { UnitData } from 'units'
import { createUnitData } from '../../api/data/unit'
import unitService from '../../api/services/unit'
import useQueryKeys from '../useQueryKeys'

export const useGetUnit = (unitID: string, options: UseQueryOptions): UseQueryResult<UnitData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Unit)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await unitService.getOne(unitID)
            return createUnitData(data)
        },
    }) as UseQueryResult<UnitData>
}
