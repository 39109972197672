import { TabsProps, useTheme } from '@mui/material'
import React from 'react'
import { StyledTabs, StyledTab, StyledNavLink } from './styles'

const components = {
    StyledTab,
    StyledNavLink,
} as const

type Props<T> = Omit<T, 'children'> & {
    children: (items: typeof components) => JSX.Element | JSX.Element[]
}

const Tabs = ({ children, ...tabsProps }: Props<TabsProps>) => {
    const {
        palette: {
            secondary: { main: secondaryMain },
        },
    } = useTheme()

    return (
        <StyledTabs
            {...tabsProps}
            TabIndicatorProps={{
                sx: { height: 3, background: secondaryMain },
            }}
        >
            {children(components)}
        </StyledTabs>
    )
}

export default Tabs
