import { Box, Paper, css, styled } from '@mui/material'
import { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

export type StyledAlignProps = {
    isAlignCenter: boolean
}

export const StyledPublicWrapper = styled('main', {
    shouldForwardProp: (props) => props !== 'isAlignCenter',
})<StyledAlignProps>`
    position: relative;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    ${({ isAlignCenter }) =>
        isAlignCenter &&
        `
            display: flex;
            justify-content: center;
        `};
`

export const StyledPublicContainer = styled(Box)`
    ${({ theme }) => css`
        gap: ${theme.spacing(10)};
        position: relative;
        z-index: 90;
        display: flex;
        flex-direction: column;
        gap: 5;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        padding: ${theme.spacing(4)} ${theme.spacing(2)};
    `}

    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding: ${({ theme }) =>
            `${theme.spacing(7)} ${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(8)}`};
    }
`

export const StyledLogoSettingsWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    height: 50px;
`

export const StyledLogotype = styled(Link, {
    shouldForwardProp: (props) => props !== 'isAlignCenter',
})<StyledAlignProps>`
    margin-left: ${({ theme }) => `${theme.spacing(4)}`};
    align-self: end;
`

export const StyledPublicWrapperContainer = styled(Box)``
export const StyledPublicFooter = styled(Box)`
    ${({ theme }) => css`
        overflow: hidden;
        margin-top: auto;
        display: flex;

        a {
            color: ${theme.palette.common.white};
        }

        a:not(:last-child) {
            &:after {
                content: ' | ';
                margin: 0 ${theme.spacing(3)};
            }
        }
    `}
`

export const StyledPublicWrapperPaper = styled(Paper, {
    shouldForwardProp: (props) => props !== 'fullWidth',
})<{ fullWidth?: boolean }>`
    ${({ fullWidth, theme }) => css`
        width: 100%;
        border-radius: ${theme.shape.boxBorderRadius};
        box-shadow: ${theme.shadows[1]};
        padding: ${theme.spacing(6)} ${theme.spacing(8)};

        ${!fullWidth &&
        css`
            max-width: 400px;
        `}
    `}
`

export const StyledPageTitle = styled('h1')`
    margin-bottom: 2rem;
`

export const StyledPageSubtitle = styled('h3')`
    font-size: 1.3rem;
    margin: 0 0 1.5rem 0;
`

export const StyledPageWrapper = styled('ul')<Pick<CSSProperties, 'listStyleType'>>`
    ${({ listStyleType }) => css`
        list-style-type: ${listStyleType || 'decimal'};

        @counter-style terms {
            system: numeric;
            symbols: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
            prefix: '§ ';
            suffix: ' ';
        }

        @counter-style terms-sublist {
            system: numeric;
            symbols: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
            prefix: '(';
            suffix: ') ';
        }
        @counter-style abc {
            system: alphabetic;
            symbols: a b c;
            suffix: ') ';
        }
    `}
`

export const StyledPageItem = styled('li')`
    margin-bottom: 2rem;

    ::marker {
        font-weight: 600;
        font-size: 1.3rem;
    }

    p {
        margin: 0 0 1rem 0;
    }
`

export const StyledPageList = styled('ul')<
    Pick<CSSProperties, 'listStyleType'> & { gutters?: boolean }
>`
    ${({ listStyleType, gutters }) => css`
        list-style-type: ${listStyleType || 'decimal'};
        margin-bottom: 1rem;

        li {
            margin-bottom: ${gutters ? '1rem' : '0'};
        }

        @counter-style abc {
            system: alphabetic;
            symbols: a b c d e f g;
            suffix: ') ';
        }
    `}
`

export const StyledPageListItem = styled('li')<Pick<CSSProperties, 'fontWeight'>>`
    ${({ fontWeight }) => css`
        ::marker {
            font-weight: ${fontWeight || 400};
        }
    `}
`
export const StyledPublicPage = styled('main')``
