import React, { FC } from 'react'
import Table from '../../components/Table'
import { Column } from 'react-table'
import { useMemo } from 'react'
import { MainData } from '../../types/index'
import { UnitList } from 'units'
import { StyledGridWrapper } from '../../theme/styles'
import GridItem from '../GridItem'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../../hooks/useNavUtils'

interface Props {
    data: UnitList[]
    isLoading: boolean
}

type Action = 'update' | 'delete' | 'grid-click' | 'cell-click' | 'tooltip-click'

const OverviewUnitList: FC<Props> = ({ data, isLoading }) => {
    const { t } = useTranslation()
    const { navigateUnit } = useNavUtils()

    const columns: Column<UnitList>[] = useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: ({ idOptional, id }) => idOptional || id,
                size: 1,
                width: 70,
                maxWidth: 70,
            },
            {
                accessor: 'id',
                size: 0,
            },
            {
                Header: t('title'),
                accessor: 'title',
                size: 1,
            },
            {
                Header: t('zip_code'),
                accessor: 'zipCode',
                size: 1,
            },
            {
                Header: t('street'),
                accessor: 'street',
                size: 1,
            },
            {
                Header: t('city'),
                accessor: 'city',
                size: 1,
            },
            {
                Header: t('last_edited'),
                accessor: 'changed',
                size: 1,
            },
            {
                Header: t('type'),
                accessor: 'type',
                size: 1,
            },
        ]
    }, [t])

    const handleNavigate = (action: Action) => (id: string) => {
        const navigate = navigateUnit(id)

        switch (action) {
            case 'update':
                return navigate('UPDATE')

            case 'delete':
                return navigate('DELETE')

            case 'grid-click':
            case 'cell-click':
            case 'tooltip-click':
                return navigate('GENERAL_INFORMATION')

            default:
                navigate('OVERVIEW')
        }
    }

    const renderGridItem = ({ id, title }: MainData) => {
        return (
            <GridItem
                id={id}
                title={title}
                image={''}
                variant={'basic'}
                onEdit={handleNavigate('update')}
                onDelete={handleNavigate('delete')}
                onClick={handleNavigate('grid-click')}
            />
        )
    }

    return (
        <Table
            onEdit={handleNavigate('update')}
            onDelete={handleNavigate('delete')}
            columns={columns}
            data={data}
            onCellClick={handleNavigate('cell-click')}
            isLoading={isLoading}
            GridWrapperElement={StyledGridWrapper}
            renderGridItem={renderGridItem}
        />
    )
}

export default OverviewUnitList
