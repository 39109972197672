import { css } from '@mui/system'
import styled from '../../theme/styled'

export const StyledCookieBanner = styled('div')<{
    show: boolean
}>`
    ${({ theme, show }) => css`
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: ${theme.palette.grey[900]};
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        z-index: 99;
        color: ${theme.palette.common.white};
        transition: 600ms cubic-bezier(0.075, 0.82, 0.165, 1);

        ${show
            ? css`
                  transform: translateY(0);
              `
            : css`
                  transform: translateY(100%);
              `}
    `}
`
