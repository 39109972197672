import { format, parseISO } from 'date-fns'
import { ImageDataResponse, MainDataTypes } from '../../types'
import {
    MaintenanceList,
    MaintenanceData,
    MaintenanceParams,
    MaintenanceRequestData,
    MaintenanceResponseData,
    MaintenanceResponseList,
} from 'maintenance'
import { createImageData, createListDateFormat, createNoteApiData, createNoteData } from '.'
import { ENTITY_TYPES } from '../../constant/taxonomy'
import { getBase64Items, getImagesToApiUpload, getRemovedImagesData } from '../../utils/helpers'
import { MONTH_API_DATE_FORMAT } from '../../constant/const'

export const createMaintenanceRemoveImagesData = ({ images }: Partial<MaintenanceParams>) => {
    const imageRemoveData = getRemovedImagesData(images)

    return {
        ...(images && { field_buildingservice_pictures: imageRemoveData }),
    }
}

export const createMaintenanceApiData = async ({
    parentID,
    type,
    name,
    manufacture,
    location,
    date,
    images,
    subType,
    base,
    number,
    isMulti,
    responsible,
    notes,
}: Partial<MaintenanceParams>): Promise<Partial<MaintenanceRequestData>> => {
    const imagesToSend = getImagesToApiUpload(images)
    return {
        ...(parentID && { field_buildingservice_assignment: parentID }),
        ...(type && {
            field_buildingservice_type: {
                und: [type],
            },
        }),
        ...(name && {
            field_buildingservice_name: {
                und: [
                    {
                        value: name,
                    },
                ],
            },
        }),
        ...(subType && {
            field_buildingservice_subtype: {
                und: [
                    {
                        value: subType,
                    },
                ],
            },
        }),
        ...(base && {
            field_buildingservice_base: {
                und: base,
            },
        }),
        ...(number && {
            field_buildingservice_number: {
                und: [
                    {
                        value: number,
                    },
                ],
            },
        }),

        field_buildingservice_multi: {
            und: isMulti
                ? [
                      {
                          value: '1',
                      },
                  ]
                : null,
        },
        ...(manufacture && {
            field_buildingservice_manufactur: {
                und: [
                    {
                        value: manufacture,
                    },
                ],
            },
        }),
        ...(location && {
            field_buildingservice_location: {
                und: [
                    {
                        value: location,
                    },
                ],
            },
        }),
        ...(responsible && {
            field_buildingservice_responsibl: {
                und: [
                    {
                        value: responsible,
                    },
                ],
            },
        }),
        ...(notes && {
            field_buildingservice_notes: {
                und: createNoteApiData(notes),
            },
        }),
        ...(date && {
            field_buildingservice_build_date: {
                und: [
                    {
                        value: {
                            date: format(date, MONTH_API_DATE_FORMAT),
                        },
                    },
                ],
            },
        }),
        ...(imagesToSend?.length && {
            field_buildingservice_pictures: await getBase64Items(imagesToSend),
        }),
    }
}

export const createMaintenanceList = (data?: MaintenanceResponseList[]): MaintenanceList[] => {
    if (!data) return []

    return data.map(
        ({
            nid,
            title,
            name,
            created,
            changed,
            assigned_entity_type,
            assigned_entity_title,
            type,
            responsible,
            location,
            images,
        }) => {
            return {
                id: nid,
                nid,
                title,
                name,
                created: Number(created),
                changed: Number(changed),
                entityType: ENTITY_TYPES.find(({ value }) => value === assigned_entity_type),
                entityTitle: assigned_entity_title,
                responsible,
                type,
                location,
                images,
            }
        }
    )
}

export const createMaintenanceData = ({
    nid,
    title,
    created,
    field_buildingservice_assignment,
    field_buildingservice_name,
    field_buildingservice_location,
    field_buildingservice_pictures,
    field_buildingservice_manufactur,
    field_buildingservice_build_date,
    field_buildingservice_type,
    field_buildingservice_subtype,
    field_buildingservice_base,
    field_buildingservice_responsibl,
    field_buildingservice_multi,
    field_buildingservice_number,
    field_buildingservice_notes,
    changed,
}: MaintenanceResponseData): MaintenanceData => {
    const date = field_buildingservice_build_date?.und?.[0]?.value

    return {
        id: nid,
        nid,
        title,
        name: field_buildingservice_name?.und?.[0]?.value,
        base: field_buildingservice_base?.und?.[0]?.value,
        number: Number(field_buildingservice_number?.und?.[0]?.value ?? 0),
        isMulti: field_buildingservice_multi?.und?.[0]?.value === '1',
        changed: createListDateFormat(Number(changed)) || '',
        created: createListDateFormat(Number(created)) || '',
        type: field_buildingservice_type?.und?.[0]?.tid || '',
        subType: field_buildingservice_subtype?.und?.[0]?.value || '' || '',
        location: field_buildingservice_location?.und?.[0]?.value || '',
        responsible: field_buildingservice_responsibl?.und?.[0]?.value,
        parentID: field_buildingservice_assignment?.und?.[0]?.target_id || '',
        manufacture: field_buildingservice_manufactur?.und?.[0]?.value || '',
        date: parseISO(date) || '',
        images: field_buildingservice_pictures?.und?.map((file: ImageDataResponse) =>
            createImageData({ url: file?.full_url })
        ),
        notes: createNoteData(field_buildingservice_notes?.und),
        dataType: MainDataTypes.Maintenance,
    }
}

export const createMaintenanceParams = ({
    id,
    title,
    parentID,
    type,
    name,
    manufacture,
    location,
    date,
    images,
    subType,
    responsible,
    base,
    number,
    isMulti,
    notes,
}: MaintenanceData): MaintenanceParams => {
    return {
        id,
        title,
        parentID,
        type,
        name,
        manufacture,
        location,
        responsible,
        date,
        images,
        subType,
        base,
        number,
        isMulti,
        notes,
    }
}
