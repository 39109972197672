export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14.424">
        <defs>
            <clipPath id="a">
                <path d="M0 0h12v14.424H0Z" fill="#a80000" />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M1.691 14.424h8.619a1.663 1.663 0 0 0 1.663-1.663v-11.1A1.663 1.663 0 0 0 10.31-.002H1.691A1.663 1.663 0 0 0 .028 1.661v11.1a1.663 1.663 0 0 0 1.663 1.663ZM.929 1.663a.763.763 0 0 1 .762-.762h8.619a.762.762 0 0 1 .762.762v11.1a.762.762 0 0 1-.762.762H1.691a.762.762 0 0 1-.762-.761Z"
                    fill="#a80000"
                />
            </g>
            <path d="M2.169 8.722h7.663v.9H2.169Z" fill="#a80000" />
            <path d="M2.169 10.052h7.663v.9H2.169Z" fill="#a80000" />
            <path d="M2.169 11.382h7.663v.9H2.169Z" fill="#a80000" />
            <text
                transform="translate(2 6.424)"
                fill="#b80000"
                fontSize="4"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="0" y="0">
                    PDF
                </tspan>
            </text>
        </g>
    </svg>
)
