import { SelectChangeEvent, Popper, Grow, Paper } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { Box } from '@mui/system'
import { StyledMenuItem, StyledSelectButton } from './styles'
import theme from '../../theme'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { IconsOrientation } from '../Icon'

interface Props {
    noValueTitle: string
    children: ReactNode[]
    currentTitle?: string
    currentValue?: string
    onLabelClick?: () => void
}

const SelectHover = ({
    currentTitle,
    currentValue,
    children,
    noValueTitle,
    onLabelClick,
}: Props) => {
    const anchorRef = React.useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState<boolean>(false)

    const handleSelectClose = () => setOpen(false)
    const handleSelectOpen = () => setOpen(true)

    const handleButtonClick = () => {
        onLabelClick && onLabelClick()
        handleSelectClose()
    }

    const RenderChild = (children: JSX.Element) => {
        if (React.isValidElement(children)) return children

        return React.Children.map(children, (child: React.ReactElement) => {
            if (!child) return null
            const { value, onClick } = child.props

            const handleClick = (e: SelectChangeEvent<string>) => {
                handleSelectClose()
                onClick(e)
            }

            return (
                <StyledMenuItem
                    {...child.props}
                    active={value && currentValue === value ? 1 : 0}
                    onClick={handleClick}
                />
            )
        })
    }

    return (
        <Box
            onMouseEnter={handleSelectOpen}
            onMouseLeave={handleSelectClose}
            display="flex"
            sx={{ height: '100%' }}
        >
            <StyledSelectButton
                onClick={handleButtonClick}
                ref={anchorRef}
                variant={'select-button'}
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
            >
                {currentTitle || noValueTitle}
                <ArrowDropDownIcon
                    rotate={open ? IconsOrientation.up : IconsOrientation.down}
                    sx={{
                        fill: theme.palette.common.white,
                        width: 20,
                        height: 20,
                        ml: 1,
                    }}
                />
            </StyledSelectButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                    zIndex: 9,
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            zIndex: 9,
                            maxHeight: '624px',
                            overflowY: 'auto',
                        }}
                    >
                        <Paper sx={{ zIndex: 9 }}>
                            {RenderChild(children as unknown as JSX.Element)}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

export default SelectHover
