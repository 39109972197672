import { useQuery, UseQueryResult } from 'react-query'
import { TaskList } from 'tasks'
import { createTaskList } from '../../api/data/task'
import taskService from '../../api/services/task'

import { QueryDataKey } from '../../types'
import { UseQueryOptions } from 'react-query'
import useQueryKeys from '../useQueryKeys'
export const useGetTasks = (
    taskID?: string,
    options?: UseQueryOptions
): UseQueryResult<TaskList[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Task)
    const { queryKey, ...restOptions } = options || {}

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await taskService.getAll(taskID)
            return createTaskList(data)
        },
    }) as UseQueryResult<TaskList[]>
}
