import React from 'react'
import {
    StyledMapMarker,
    StyledPlaceName,
    StyledPlaceResultItem,
    StyledPlaceSubtitle,
} from './styles'
import marker from '../../../../assets/images/marker3.svg'
import { Box } from '@mui/material'
import { MapPlaceResultClick } from 'map'
import HighlightMatch from '../../../../components/HighlightMatch'

interface Props {
    children: string
    subtitle: string
    id: string
    onClick?: MapPlaceResultClick
}

const MapResultItem = ({ id, children, subtitle, onClick }: Props) => {
    return (
        <StyledPlaceResultItem onClick={() => onClick && onClick(id)}>
            <StyledMapMarker src={marker} />
            <Box>
                <StyledPlaceName>
                    <HighlightMatch>{children}</HighlightMatch>
                </StyledPlaceName>
                <StyledPlaceSubtitle>
                    <HighlightMatch>{subtitle}</HighlightMatch>
                </StyledPlaceSubtitle>
            </Box>
        </StyledPlaceResultItem>
    )
}

export default MapResultItem
