import { ImageData } from '../../../../types'
import React from 'react'
import { StyledImageItem } from './styles'
import { Box } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Image from '../../../../components/Image'

interface Props {
    key: number
    image: ImageData
    index: number
    onDeleteClick?: (src: string) => void
}
const ImageListItem = ({ image: { src, filename }, index, onDeleteClick }: Props) => {
    return (
        <StyledImageItem key={index}>
            <Box sx={{ width: '70px', height: '50px', flexShrink: 0 }}>
                <Image src={src} />
            </Box>
            <Box display={'flex'} alignItems="center" justifyContent={'space-between'} width="100%">
                <Typography>
                    {index}. {filename}
                </Typography>

                {onDeleteClick && (
                    <IconButton onClick={() => onDeleteClick(src)} size="large">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </StyledImageItem>
    )
}

export default ImageListItem
