import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import unitService from '../../api/services/unit'
import { createUnitApiData } from '../../api/data/unit'
import { UnitParams } from 'units'
import useQueryKeys from '../useQueryKeys'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import useNavUtils from '../useNavUtils'
import useParamManage from '../useParamManage'

export const useUpdateUnit = (unitID: string) => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const { updateKey, getQueryAllKey, getQueryOneKey } = useQueryKeys(QueryDataKey.Unit)
    const { navigateObject } = useNavUtils()
    const { objectID } = useParamManage()
    const queryClient = useQueryClient()

    return useMutation(
        (params: UnitParams) => {
            return unitService.update(unitID, createUnitApiData(params))
        },
        {
            mutationKey: updateKey,
            onSuccess: async () => {
                await queryClient.invalidateQueries({ queryKey: getQueryAllKey([objectID]) })
                await queryClient.invalidateQueries({ queryKey: getQueryOneKey([unitID]) })
                setSuccessMsg(t('updated'))

                navigateObject()('OVERVIEW')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
