import React, { FC } from 'react'
import Table from '../../components/Table'
import { Column } from 'react-table'
import { useMemo } from 'react'
import { MainData } from '../../types/index'
import { StyledGridWrapper } from '../../theme/styles'
import GridItem from '../GridItem'
import { useTranslation } from 'react-i18next'
import { ApartmentList } from 'apartment'
import useNavUtils from '../../hooks/useNavUtils'

interface Props {
    data: ApartmentList[]
    isLoading: boolean
}

const OverviewApartmentList: FC<Props> = ({ data, isLoading }) => {
    const { t } = useTranslation()
    const { navigateApartment } = useNavUtils()

    const columns: Column<ApartmentList>[] = useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: ({ id, optionalID }) => optionalID || id,
                size: 1,
                width: 70,
                maxWidth: 70,
            },
            {
                accessor: 'id',
                size: 0,
            },
            {
                Header: t('title'),
                accessor: 'title',
                size: 1,
            },
            {
                Header: t('apartment.floor'),
                accessor: 'floor',
                size: 1,
            },
            {
                Header: t('apartment.space'),
                accessor: 'space',
                size: 1,
            },
        ]
    }, [t])

    const handleAction =
        (action: 'update' | 'delete' | 'grid-click' | 'cell-click') => (id: string) => {
            const navigate = navigateApartment(id)

            switch (action) {
                case 'update':
                    return navigate('UPDATE')

                case 'delete':
                    return navigate('DELETE')

                case 'grid-click':
                case 'cell-click':
                    return navigate('GENERAL_INFORMATION')

                default:
                    navigate('OVERVIEW')
            }
        }

    const renderGridItem = ({ id, title }: MainData) => {
        return (
            <GridItem
                id={id}
                title={title}
                image={''}
                variant={'basic'}
                onEdit={handleAction('update')}
                onDelete={handleAction('delete')}
                onClick={handleAction('grid-click')}
            />
        )
    }

    return (
        <Table
            onEdit={handleAction('update')}
            onDelete={handleAction('delete')}
            columns={columns}
            data={data}
            onCellClick={handleAction('cell-click')}
            isLoading={isLoading}
            GridWrapperElement={StyledGridWrapper}
            renderGridItem={renderGridItem}
        />
    )
}

export default OverviewApartmentList
