import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import schema from './shema'
import useUserStore from '../../store/user'
import { UserParams } from '../../types/auth'
import Input from '../components/Input'
import { useUpdateUser } from '../../hooks/auth/useUpdateUser'
import DataGroup from '../../components/DataGroup'
import { StyledForm } from '../styles'
import { useNavigate } from 'react-router-dom'
import { CONTAINER_PATHS } from '../../routes/routes'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

const FormPassword = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userID } = useUserStore()
    const { mutate: updateUserMutate, isLoading } = useUpdateUser(userID)

    const methods = useForm<UserParams>({
        resolver: yupResolver(schema),
        defaultValues: {
            currentPass: '',
            pass: '',
            confirmPass: '',
        },
    })
    const {
        handleSubmit,
        formState: { isDirty },
    } = methods

    const onSubmit = (values: UserParams) => updateUserMutate(values)

    const handleCancelClick = () => navigate(CONTAINER_PATHS.OVERVIEW_ALL)

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Box maxWidth={'50%'} minWidth={'400px'}>
                    <DataGroup label={t('reset_password')}>
                        <Input
                            name="currentPass"
                            label={t('user_settings.current_password')}
                            variant={'password'}
                            width={'190px'}
                        />
                        <Input
                            name="pass"
                            label={t('user_settings.new_password')}
                            variant={'password'}
                            width={'190px'}
                        />
                        <Input
                            name="confirmPass"
                            label={t('repeat_new_password')}
                            variant={'password'}
                            width={'190px'}
                            noBorder
                        />
                    </DataGroup>
                </Box>
                <FormSubmitFooter
                    onCancelClick={handleCancelClick}
                    loading={isLoading}
                    submitDisabled={!isDirty}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormPassword
