import { UserParams } from 'auth'
import { UserResponse, UserData } from 'user'

export const createUserApiData = ({
    mail,
    pass,
    type,
    currentPass,
    contractorSector,
    contractorTrade,
    company,
    companyName,
    street,
    zipCode,
    city,
    confirmPass,
}: UserParams) => {
    return {
        ...(mail && { ...{ username: mail } }),
        ...(currentPass && { ...{ current_pass: currentPass } }),
        ...(mail && { ...{ mail } }),
        ...(pass && { ...{ pass } }),
        ...(confirmPass && { ...{ confirmPass } }),
        field_user_account_type: {
            und: type || 'company',
        },
        ...((street || zipCode || city) && {
            ...{
                field_user_company_address: {
                    und: [
                        {
                            ...(street && {
                                ...{ thoroughfare: street },
                            }),
                            ...(zipCode && { ...{ postal_code: zipCode } }),
                            ...(city && { ...{ locality: city } }),
                        },
                    ],
                },
            },
        }),
        ...(company && {
            ...{
                field_user_company: {
                    und: [
                        {
                            value: company,
                        },
                    ],
                },
            },
        }),
        ...(companyName && {
            ...{
                field_user_company_name: {
                    und: [
                        {
                            value: companyName,
                        },
                    ],
                },
            },
        }),
        // ...(type === 'contractor' && {
        //     ...{
        //         field_user_contractor_sector: {
        //             und: contractorSector,
        //         },
        //         ...(contractorTrade && {
        //             field_user_contractor_trade: {
        //                 und: contractorTrade,
        //             },
        //         }),
        //     },
        // }),
    }
}

export const createUserData = ({
    name,
    mail,
    field_user_company_name,
    field_user_company,
    field_user_company_address,
    field_user_account_type,
    field_user_contractor_sector,
    field_user_contractor_trade,
    uid,
    language,
}: UserResponse): UserData => {
    return {
        username: name,
        email: mail,
        uid,
        lang: language,
        companyName: field_user_company_name?.und?.[0]?.value,
        company: field_user_company?.und?.[0]?.value,
        street: field_user_company_address?.und?.[0]?.thoroughfare,
        city: field_user_company_address?.und?.[0]?.locality,
        zipCode: field_user_company_address?.und?.[0]?.postal_code,
        type: field_user_account_type?.und?.[0].value,
        contractorSector: field_user_contractor_sector?.und?.[0].value,
        contractorTrade: field_user_contractor_trade?.und?.[0],
    }
}
