import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import useParamManage from '../../../../../../hooks/useParamManage'
import { useUpdateTask } from '../../../../../../hooks/task/useUpdateTask'
import { useGetTask } from '../../../../../../hooks/task/useGetTask'
import FormTask from '../../../../../../forms/FormTask'

const UpdateTask = () => {
    const { t } = useTranslation()
    const { navigateTask, goBack } = useNavUtils()
    const { taskID, maintenanceID } = useParamManage()

    const { data: task, isLoading: isLoadingGet } = useGetTask(taskID, {
        queryKey: [taskID],
    })

    const { mutate: updateMaintenance, isLoading: isLoadingUpdate } = useUpdateTask(taskID)

    const handleGoBack = () => {
        if (maintenanceID) return goBack()
        if (taskID) return navigateTask(taskID)('LIST')
        return navigateTask('')('LIST')
    }

    return (
        <Modal title={t('task.edit_task')} onClose={handleGoBack}>
            <LoaderWrapper isLoading={Boolean(isLoadingGet || !task)} center>
                <FormTask
                    onSubmit={updateMaintenance}
                    task={task}
                    isLoading={isLoadingUpdate}
                    onCancelClick={handleGoBack}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateTask
