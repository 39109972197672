import { QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { AxiosError } from 'axios'
import { useSnackbar } from '../useSnackbar'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/routes'
import { stripObj } from '../../utils/helpers'
import { ResetPasswordApiParams } from 'auth'

export const useResetPassword = (email: string) => {
    const { resetPasswordKey } = useQueryKeys(QueryDataKey.Auth)
    const { setErrorMsg } = useSnackbar()
    const navigate = useNavigate()

    return useMutation(
        (params: ResetPasswordApiParams) => {
            return authService.resetPasswordSendMail(params)
        },
        {
            mutationKey: resetPasswordKey,
            onSuccess: (res) => {
                navigate(PATHS.RESET_PASSWORD_EMAIL_SENT, {
                    state: {
                        email: email,
                    },
                })
            },
            onError: (error: AxiosError) => {
                setErrorMsg(stripObj(error?.response?.data))
            },
        }
    )
}
