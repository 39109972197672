import { StyledGeneralInfo } from '../../../../../../theme/styles'
import { Box } from '@mui/material'
import DataItem from '../../../../../../components/DataItem'
import Map from '../../../../../../modules/Map'
import { useGetUnit } from '../../../../../../hooks/unit/useGetUnit'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import Loader from '../../../../../../components/Loader'
import ThreeDScan from '../../../../../../modules/ThreeDScan'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import { MapTooltipItem } from '../../../../../../modules/Map/components/MapTooltip'
import MapObject from '../../../../../../modules/Map/components/MapObject'
import { MapSizes } from '../../../../../../types'
import DataGroup from '../../../../../../components/DataGroup'
import { useTranslation } from 'react-i18next'
import { Option } from 'forms'
import useParamManage from '../../../../../../hooks/useParamManage'

const GeneralInfoUnit = () => {
    const { t } = useTranslation()
    const { data: attributes } = useGetAttributes()
    const { unitID } = useParamManage()

    const { data: unit, isLoading } = useGetUnit(unitID, {
        queryKey: [unitID],
        enabled: Boolean(unitID),
    })

    const { data: types } = useGetTypes()
    const unitType = types?.find((type) => type.value === unit?.type)
    const handleCreateTooltip = () => <MapTooltipItem data={unit} />

    if (!unitID || isLoading) return <Loader absoluteCenter />

    return (
        <>
            <StyledGeneralInfo>
                <Box flexBasis={'50%'}>
                    <DataGroup label={t(`${'general_information'}`)}>
                        <DataItem label={t('unit.title')}>{unit?.title}</DataItem>
                        <DataItem label={t('city')}>{unit?.city}</DataItem>
                        <DataItem label={t('zip_code')}>{unit?.zipCode}</DataItem>
                        <DataItem label={t('street')}>{unit?.street}</DataItem>
                        <DataItem label={'ID'}>{unit?.idOptional || unit?.id}</DataItem>
                        <DataItem label={t('type')} noBorder>
                            {t(unitType?.id || '')}
                        </DataItem>
                    </DataGroup>

                    {!!unit?.attributes?.length && (
                        <Box mb={5}>
                            <DataGroup label={t(`${'attributes'}`)} collapsable>
                                {unit?.attributes?.map(({ id, value }: Option) => (
                                    <DataItem
                                        key={id}
                                        label={t(
                                            attributes?.find((attr) => attr.value === id)?.id || ''
                                        )}
                                    >
                                        {value}
                                    </DataItem>
                                ))}
                            </DataGroup>
                        </Box>
                    )}
                </Box>
                <Box flexBasis={'50%'}>
                    <DataGroup label={t('map')} collapsable innerPadding>
                        <Map
                            height={MapSizes.MD}
                            renderMap={({ createLocation }) => (
                                <MapObject
                                    data={createLocation(unit)}
                                    renderTooltip={handleCreateTooltip}
                                />
                            )}
                        />
                    </DataGroup>
                </Box>
            </StyledGeneralInfo>
            {Boolean(unit?.models?.length) && (
                <DataGroup label={t('3D_scan.3D_scan')} collapsable innerPadding>
                    <ThreeDScan data={unit?.models} />
                </DataGroup>
            )}
        </>
    )
}

export default GeneralInfoUnit
