import React from 'react'
import ArrowDropdownIcon from './components/ArrowDropdown'
import FileGroupIcon from './components/FileGroup'
import DoubleChevronIcon from './components/DoubleChevron'
import DwgIcon from './components/DWG'
import PdfIcon from './components/PDF'
import JpgIcon from './components/JPG'
import PngIcon from './components/PNG'
import SvgIcon from './components/SVG'
import SquareIcon from './components/Square'
import ChevronIcon from './components/Chevron'
import AbcIcon from './components/ABC'
import CircleIcon from './components/Circle'
import IfcIcon from './components/IFC'
import LasIcon from './components/LAS'
import MinusIcon from './components/Minus'
import Mp4Icon from './components/MP4'
import MtlIcon from './components/MTL'
import ObjIcon from './components/OBJ'
import RvtIcon from './components/RVT'
import XyzIcon from './components/XYZ'
import Urgency from './components/Urgency'
import { StyledIcon } from './styles'

const GeneralIconsComponents = {
    ArrowDropdownIcon,
    DoubleChevronIcon,
    FileGroupIcon,
    CircleIcon,
    MinusIcon,
    SquareIcon,
    ChevronIcon,
    Urgency,
} as const

const FileIcons = {
    JPG: JpgIcon,
    jpg: JpgIcon,
    jpeg: JpgIcon,
    dwg: DwgIcon,
    pdf: PdfIcon,
    svg: SvgIcon,
    png: PngIcon,
    abc: AbcIcon,
    ifc: IfcIcon,
    las: LasIcon,
    mp4: Mp4Icon,
    mtl: MtlIcon,
    obj: ObjIcon,
    rvt: RvtIcon,
    xyz: XyzIcon,
} as const

export type FileExtList = keyof typeof FileIcons
export type IconList = keyof typeof GeneralIconsComponents

export const IconsOrientation = {
    up: '0',
    down: '180deg',
    left: '-90deg',
    right: '90deg',
} as const
export interface IconComponentsProps {
    color?: string
    height?: number
    width?: number
}

interface GeneralIconProps {
    icon: keyof typeof GeneralIconsComponents
    orientation?: keyof typeof IconsOrientation

    variant?: 'general'
}
interface FileIconProps {
    icon: keyof typeof FileIcons
    orientation?: keyof typeof IconsOrientation
    variant: 'file'
}
function Icon({ orientation, ...props }: IconComponentsProps & GeneralIconProps): JSX.Element
function Icon({ ...props }: IconComponentsProps & FileIconProps): JSX.Element
function Icon({
    icon,
    variant,
    orientation,
    ...props
}: (IconComponentsProps & GeneralIconProps) | FileIconProps) {
    const getIconsSource = () => {
        switch (variant) {
            case 'file':
                return FileIcons?.[icon] || FileIcons.xyz

            default:
                return GeneralIconsComponents?.[icon]
        }
    }

    return (
        <StyledIcon {...props} rotate={orientation ? IconsOrientation[orientation] : undefined}>
            {getIconsSource()}
        </StyledIcon>
    )
}
export default Icon
