import { Box } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledWrapper = styled(Box)`
    ${({ theme }) => css`
    position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom:0;
            height: 1px;
            width: 100%;
            z-index: 3;
            background: ${theme.palette.common.white};
        `}
    }
`
