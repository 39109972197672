import { useGoogleMap } from '@react-google-maps/api'
import React, { useState } from 'react'
import {
    StyledMapTypeButton,
    StyledMapTypeButtonsWrapper,
    StyledZoomButtonsWrapper,
    StyledZoomButton,
} from '../../styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined'
import { t } from 'i18next'

type Props = {
    children?: JSX.Element
}

const MapWrapper = ({ children }: Props) => {
    const map = useGoogleMap()
    const [mapType, setMapType] = useState<google.maps.MapTypeId>(google.maps.MapTypeId.ROADMAP)

    const handleChangeMapType = (view: google.maps.MapTypeId) => {
        setMapType(view)
        map?.setMapTypeId(view)
    }

    const handleZoomIn = () => map?.setZoom((map?.getZoom() || 0) + 1)
    const handleZoomOut = () => map?.setZoom((map?.getZoom() || 0) - 1)

    return (
        <>
            {children}

            <StyledZoomButtonsWrapper>
                <StyledZoomButton onClick={handleZoomIn} aria-label="zoomin">
                    <AddIcon />
                </StyledZoomButton>
                <StyledZoomButton onClick={handleZoomOut} aria-label="zoomout">
                    <RemoveIcon />
                </StyledZoomButton>
                <StyledZoomButton aria-label="compas">
                    <ExploreOutlinedIcon />
                </StyledZoomButton>
            </StyledZoomButtonsWrapper>

            <StyledMapTypeButtonsWrapper>
                <StyledMapTypeButton
                    isActive={mapType === google.maps.MapTypeId.ROADMAP}
                    onClick={() => handleChangeMapType(google.maps.MapTypeId.ROADMAP)}
                >
                    {t(`map_view`)}
                </StyledMapTypeButton>
                <StyledMapTypeButton
                    isActive={mapType === google.maps.MapTypeId.SATELLITE}
                    onClick={() => handleChangeMapType(google.maps.MapTypeId.SATELLITE)}
                >
                    {t(`satellite_view`)}
                </StyledMapTypeButton>
            </StyledMapTypeButtonsWrapper>
        </>
    )
}

export default MapWrapper
