import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import { useGetContainer } from '../../../../../../hooks/container/useGetContainer'
import useParamManage from '../../../../../../hooks/useParamManage'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import FormObject from '../../../../../../forms/FormObject'
import { useGetObject } from '../../../../../../hooks/object/useGetObject'
import { useUpdateObject } from '../../../../../../hooks/object/useUpdateObject'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'

const UpdateObject = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { objectID, containerID } = useParamManage()
    const { data: object, isLoading } = useGetObject(objectID, {
        queryKey: [objectID],
    })
    const { data: container } = useGetContainer(containerID, {
        queryKey: [containerID],
    })
    const { mutate: updateObject, isLoading: isLoadingUpdate } = useUpdateObject(objectID)
    const { data: types = [] } = useGetTypes()
    const { data: attributes = [] } = useGetAttributes()

    return (
        <Modal title={t('object.edit_object')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoading}>
                <FormObject
                    attributes={attributes}
                    types={types}
                    container={container}
                    object={object}
                    isLoading={isLoadingUpdate}
                    onCancelClick={goBack}
                    onSubmit={updateObject}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateObject
