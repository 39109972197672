import React from 'react'
import Modal from '../../../../../../components/Modal'
import FormContainer from '../../../../../../forms/FormContainer'
import { useCreateContainer } from '../../../../../../hooks/container/useCreateContainer'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'

const CreateContainer = () => {
    const { t } = useTranslation()
    const { mutate: createContainer, isLoading: isLoadingCreate } = useCreateContainer()
    const { goBack } = useNavUtils()

    return (
        <Modal title={t('container.new_container')} onClose={goBack}>
            <FormContainer
                isLoading={isLoadingCreate}
                onSubmit={createContainer}
                onCancelClick={goBack}
            />
        </Modal>
    )
}

export default CreateContainer
