import { useQuery, UseQueryResult } from 'react-query'
import { TaskData } from 'tasks'
import { createTaskData, createTaskParams } from '../../api/data/task'
import taskService from '../../api/services/task'

import { QueryDataKey } from '../../types'
import { UseQueryOptions } from 'react-query'
import useQueryKeys from '../useQueryKeys'
export const useGetTask = (taskID: string, options: UseQueryOptions): UseQueryResult<TaskData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Task)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await taskService.getOne(taskID)
            return createTaskParams(createTaskData(data))
        },
    }) as UseQueryResult<TaskData>
}
