import { UnitList } from 'units'
import { createUnitsList } from '../../api/data/unit'
import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import unitService from '../../api/services/unit'
import useQueryKeys from '../useQueryKeys'

export const useGetUnits = (
    parentID: string,
    options: UseQueryOptions
): UseQueryResult<UnitList[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Unit)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await unitService.getAll(parentID)
            return createUnitsList(data)
        },
    }) as UseQueryResult<UnitList[]>
}
