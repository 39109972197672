import { css } from '@emotion/react'
import styled from '../../theme/styled'
import { Link } from 'react-router-dom'

export const StyledDashboard = styled('main')`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

export const StyledDashboardContainer = styled('section')<{ fullHeight: boolean }>`
    ${({ theme, fullHeight }) => css`
        padding: ${theme.spacing(3)} ${theme.spacing(6)};
        background-color: ${theme.palette.grey['50']};
        min-height: calc(100vh - 210px);
        display: flex;
        flex-direction: column;

        ${fullHeight &&
        css`
            min-height: 100vh;
        `}
    `}
`
export const StyledDashboardLink = styled(Link)`
    ${({ theme }) => css`
        display: inline-flex;
        color: ${theme.palette.grey[700]};
        cursor: pointer;
        padding: ${theme.spacing(2)} 0;
        text-decoration: none;

        &:hover {
            color: ${theme.palette.primary.main};
        }

        &:hover:after {
            color: ${theme.palette.grey[700]};
        }

        &:not(:last-child):after {
            content: ' | ';
            margin: 0 ${theme.spacing(3)};
        }
    `}
`
