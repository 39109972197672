import { QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { SignInParams } from 'auth'
import { AxiosError } from 'axios'
import { useLocalStorage } from 'usehooks-ts'
import { useSnackbar } from '../useSnackbar'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../store/user'
import { CONTAINER_PATHS, PATHS } from '../../routes/routes'
import { stripObj } from '../../utils/helpers'
import { TOKEN_KEY } from '../../constant/const'

export const useSignIn = () => {
    const navigate = useNavigate()
    const { setUser } = useUserStore()
    const { signInKey } = useQueryKeys(QueryDataKey.Auth)
    const { setErrorMsg } = useSnackbar()
    const [, setStorageToken] = useLocalStorage(TOKEN_KEY, '')

    return useMutation(
        (params: SignInParams) => {
            return authService.signIn(params)
        },
        {
            mutationKey: signInKey,
            onSuccess: (res) => {
                const { user, token } = res?.data || {}
                if (token) setStorageToken(token)
                if (user) setUser(user?.uid)

                navigate(PATHS.DASHBOARD)
            },
            onError: (error: AxiosError) => {
                if (error?.response?.status === 406) navigate(CONTAINER_PATHS.OVERVIEW_ALL)
                setErrorMsg(stripObj(error?.response?.data))
            },
        }
    )
}
