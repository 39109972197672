import { ObjectRequestData } from 'objects'
import axiosInstance from '../axios'
import { ApartmentCreateRequest, ApartmentResponseData, ApartmentResponseList } from 'apartment'

interface ResponseData {
    nid: string
}

const apartmentService = {
    getAll: (id?: string) => {
        return axiosInstance.get<ApartmentResponseList[]>(`/housing_unit?utilization_unit_id=${id}`)
    },
    getOne: (id?: string) => {
        return axiosInstance.get<ApartmentResponseData>(`/housing_unit/${id}`)
    },
    delete: (id?: string) => {
        return axiosInstance.delete(`/housing_unit/${id}`)
    },
    create: (params: ApartmentCreateRequest) => {
        return axiosInstance.post<ResponseData>(`/housing_unit`, params)
    },
    update: (id?: string, params?: ObjectRequestData) => {
        return axiosInstance.put(`/housing_unit/${id}`, params)
    },
}
export default apartmentService
