import { Box, BoxProps, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    variant?: 'default'
}

const NoData = ({ variant, ...restProps }: Props & BoxProps) => {
    const { t } = useTranslation()

    switch (variant) {
        default:
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={'100%'}
                    {...restProps}
                >
                    <Typography>{t('no_data')}</Typography>
                </Box>
            )
    }
}

export default NoData
