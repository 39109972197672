import { useParams } from 'react-router-dom'
import { RouterParams } from 'router'

type AdditionFields = { lastParamID: string }

const useParamManage = () => {
    const params = useParams<RouterParams>()
    const { objectID, unitID, apartmentID, maintenanceID } = params

    const routerParams = Object.entries(params).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value || '',
        }),
        {}
    ) as Required<RouterParams>

    const additionFields: AdditionFields = {
        lastParamID: maintenanceID || apartmentID || unitID || objectID || '',
    }

    return { ...routerParams, ...additionFields } as Required<RouterParams & AdditionFields>
}

export default useParamManage
