import { QueryDataKey } from '../../types/index'
import { useMutation } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { UserParams } from 'auth'
import { AxiosError } from 'axios'
import { useSnackbar } from '../useSnackbar'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../store/user'
import { PATHS } from '../../routes/routes'
import { stripObj } from '../../utils/helpers'
export const useSignUp = () => {
    const navigate = useNavigate()
    const { setUser } = useUserStore()
    const { signUpKey } = useQueryKeys(QueryDataKey.Auth)
    const { setErrorMsg } = useSnackbar()

    return useMutation(
        (params: UserParams) => {
            return authService.signUp(params)
        },
        {
            mutationKey: signUpKey,
            onSuccess: (res) => {
                const data = res?.config?.data
                const user = JSON.parse(data)
                navigate(PATHS.EMAIL_SENT, {
                    state: {
                        email: user?.email,
                    },
                })
                setUser(user?.uid)
            },
            onError: (error: AxiosError) => {
                setErrorMsg(stripObj(error?.response?.data))
            },
        }
    )
}
