import { MainData } from '../types'
import { useReducer } from 'react'
import { TreeItem } from '../components/DataTree'

type State = {
    currentGroup: Partial<TreeItem> | null
    activeGroups: string[]
    breadcrumbs: TreeItem[]
    expanded: string[]
}

enum ACTIONS {
    SET_CURRENT_GROUP = 'set_current_group',
    SET_BREADCRUMBS = 'set_breadcrumbs',
    SET_ACTIVE_GROUPS = 'set_active_groups',
    SET_EXPANDED = 'set_expanded',
    SET_GROUP_LIST = 'set_group_list',
}

type Action =
    | {
          type: ACTIONS.SET_CURRENT_GROUP
          payload: TreeItem
      }
    | {
          type: ACTIONS.SET_BREADCRUMBS
          payload: Pick<State, 'breadcrumbs' | 'currentGroup'>
      }
    | {
          type: ACTIONS.SET_ACTIVE_GROUPS
          payload: string[]
      }
    | {
          type: ACTIONS.SET_EXPANDED
          payload: string[]
      }
    | {
          type: ACTIONS.SET_GROUP_LIST
          payload: Partial<State>
      }

const reducer = (state: State, { type, payload }: Action) => {
    switch (type) {
        case ACTIONS.SET_CURRENT_GROUP:
            return { ...state, currentGroup: payload }

        case ACTIONS.SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: payload.breadcrumbs,
                currentGroup: payload.currentGroup,
            }

        case ACTIONS.SET_ACTIVE_GROUPS:
            return { ...state, activeGroups: payload }

        case ACTIONS.SET_EXPANDED:
            return { ...state, expanded: payload }

        case ACTIONS.SET_GROUP_LIST:
            return {
                ...state,
                ...payload,
            }

        default:
            console.error('No action found...')
            return state
    }
}

const useDataTree = () => {
    const [{ activeGroups, currentGroup, expanded, breadcrumbs }, dispatch] = useReducer(reducer, {
        currentGroup: null,
        breadcrumbs: [],
        activeGroups: [],
        expanded: [],
    })

    const handleChangeBreadcrumbs = (breadcrumbs: TreeItem[]) => {
        dispatch({
            type: ACTIONS.SET_BREADCRUMBS,
            payload: {
                breadcrumbs,
                currentGroup: breadcrumbs[breadcrumbs.length - 1] || null,
            },
        })
    }

    const handleChange = (data: TreeItem, isDragging?: boolean) => {
        const { id, parents, children } = data
        if (activeGroups.includes(id)) {
            children &&
                !isDragging &&
                dispatch({
                    type: ACTIONS.SET_ACTIVE_GROUPS,
                    payload: isDragging ? [id] : parents?.filter((p) => p !== id),
                })
        } else {
            ;(!expanded.includes(id) || !children) &&
                dispatch({
                    type: ACTIONS.SET_ACTIVE_GROUPS,
                    payload: parents?.length ? parents : [id],
                })
        }

        handleSetExpanded(data, isDragging)
    }

    const handleSetExpanded = ({ id, children, parents }: TreeItem, isDragging?: boolean) => {
        if (!children) return
        if (expanded.includes(id))
            return (
                !isDragging &&
                dispatch({
                    type: ACTIONS.SET_GROUP_LIST,
                    payload: {
                        expanded: parents.filter((p) => p !== id),
                        activeGroups: parents.filter((p) => p !== id),
                    },
                })
            )

        dispatch({
            type: ACTIONS.SET_EXPANDED,
            payload: [...expanded, id],
        })
    }

    const handleCloseGroup = (id: string) => {
        dispatch({
            type: ACTIONS.SET_GROUP_LIST,
            payload: {
                activeGroups: activeGroups.filter((groupID: string) => groupID !== id),
                expanded: expanded.filter((groupID: string) => groupID !== id),
            },
        })
    }

    const handleBreadcrumbClick = ({ parents }: TreeItem) => {
        dispatch({
            type: ACTIONS.SET_GROUP_LIST,
            payload: {
                activeGroups: activeGroups.filter((p: string) => parents.includes(p)),
                expanded: parents,
                currentGroup: breadcrumbs?.[breadcrumbs.length - 2] || null,
                breadcrumbs,
            },
        })
    }

    const handleChangeFromTable = (data: MainData) => {
        if (!('parents' in data)) return

        dispatch({
            type: ACTIONS.SET_GROUP_LIST,
            payload: {
                currentGroup: data,
                expanded: [...expanded, data.id],
                activeGroups: data.parents,
                breadcrumbs,
            },
        })
    }

    return {
        currentGroup,
        activeGroups,
        expanded,
        breadcrumbs,
        handleChangeBreadcrumbs,
        handleChangeFromTable,
        handleChange,
        handleCloseGroup,
        handleBreadcrumbClick,
    }
}

export default useDataTree
