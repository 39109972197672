import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import FormTask from '../../../../../../forms/FormTask'
import { useCreateTask } from '../../../../../../hooks/task/useCreateTask'

const CreateTask = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { mutate: createMaintenance, isLoading: isLoadingCreate } = useCreateTask()

    return (
        <Modal title={t('task.new_task')} onClose={goBack}>
            <FormTask onSubmit={createMaintenance} isLoading={isLoadingCreate} />
        </Modal>
    )
}

export default CreateTask
