import { Box, Paper, Typography } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledStatCard = styled(Paper)`
    ${({ theme }) => css`
        box-shadow: none;
    `}
`
export const StyledStatHeading = styled(Typography)`
    ${({ theme }) => css`
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${theme.palette.grey[200]};
        padding: 0 ${theme.spacing(4)};
    `}
`
export const StyledStatListHeading = styled('h5')`
    ${({ theme }) => css`
        color: ${theme.palette.grey[400]};
        text-transform: uppercase;
        margin: 0 0 ${theme.spacing(3)} 0;
    `}
`
export const StyledStatWrapper = styled(Box)`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, calc(25% - 10px)));
        gap: ${theme.spacing(2)};
        margin: 0 0 ${theme.spacing(4)} 0;
    `}
`
export const StyledStatBody = styled(Box)`
    ${({ theme }) => css`
        padding: ${theme.spacing(4)};
        display: flex;
        gap: ${theme.spacing(4)};
        height: 130px;
    `}
`
export const StyledStatItem = styled(Box, {
    shouldForwardProp: (props) => props !== 'color',
})<{
    color?: string
}>`
    ${({ theme, color }) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: ${theme.palette.grey[800]};

        & > *:nth-last-of-type(1) {
            color: ${color || theme.palette.primary.main};
            font-weight: 600;
        }

        ${color &&
        css`
            &:before {
                content: '';
                display: block;
                flex: 1 0 0;
                width: 20px;
                height: 20px;
                background: ${color};
            }
        `}
    `}
`
export const StyledCircleNumber = styled(Box)`
    ${({ theme }) => css`
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        flex-shrink: 0;
        font-size: 2.5rem;
        color: ${theme.palette.common.white};
        background: linear-gradient(
            to right,
            ${theme.palette.primary.main},
            ${theme.palette.secondary.main},
            ${theme.palette.secondary.light}
        );
    `}
`

export const StyledChartWrapper = styled(Box)`
    ${({ theme }) => css`
        display: flex;
        position: relative;
    `}
`

export const StyledLegend = styled('ul')`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        justify-content: center;
        width: 100%;
    `}
`
export const StyledLegendItem = styled('li')<{
    color: string
}>`
    ${({ theme, color }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        p {
            color: ${theme.palette.grey[600]};
        }

        & > *:nth-last-of-type(1) {
            color: ${color};
            font-weight: 900;
            margin: 0 0 0 auto;
        }

        &:before {
            display: inline-flex;
            content: '';
            background: ${color};
            width: 10px;
            height: 10px;
            border-radius: 2px;
            margin: 0 ${theme.spacing(2)} 0 0;
        }
    `}
`
