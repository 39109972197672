import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import authService from '../../api/services/auth'
import { createUserApiData } from '../../api/data/user'
import { UserParams } from 'auth'

export const useUpdateUser = (userID: string) => {
    const { updateKey, getQueryUserKey } = useQueryKeys(QueryDataKey.Auth)
    const queryClient = useQueryClient()
    const { setSuccessMsg } = useSnackbar()

    return useMutation(
        (params: UserParams) => authService.updateUser(userID, createUserApiData(params)),
        {
            mutationKey: updateKey,
            onSuccess: () => {
                setSuccessMsg(t('updated'))
                queryClient.invalidateQueries({ queryKey: getQueryUserKey() })
            },
        }
    )
}
