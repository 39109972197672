import { Box, IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import DropdownMenu from '../../components/DropdownMenu'
import {
    StyledCheckbox,
    StyledCheckboxWrapper,
    StyledGridBtn,
    StyledGridBtnWrapper,
    StyledGridItem,
    StyledGridTitle,
    StyledGridItemWrapper,
    StyledItem,
    StyledNameOptionWrapper,
} from './styles'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { t } from 'i18next'
import buildingPhoto from '../../assets/images/default-building.jpg'
import Icon from '../../components/Icon'
import GetAppIcon from '@mui/icons-material/GetApp'
import { TableToggleRowsSelectedProps } from 'react-table'
import Image from '../../components/Image'
import { FileData } from '../../types/files'
import { TreeItem } from '../../components/DataTree'
import HighlightMatch from '../../components/HighlightMatch'
import DragItem from '../DragItem'
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'
import { MainData } from 'index'

export interface GroupGridItem {
    variant: 'group'
    data: TreeItem
    onClick?: (data: TreeItem) => void
}
export interface FileGridItemProps {
    variant: 'file'
    data: FileData
    image: string
    allowDrag?: boolean
    isClickable?: (data: FileData) => boolean
    onDragStart?: (item: MainData) => void
    onClick?: () => void
    onEdit?: (id: string) => void
    onDelete?: (id: string) => void
}

export type FileGridItem = FileGridItemProps & TableToggleRowsSelectedProps

interface BasicGridItem {
    id: string
    title: string
    parentID?: string
    variant: 'basic'
    image?: string
    onEdit: (id: string) => void
    onDelete: (id: string) => void
    onClick: (id: string, parentID?: string) => void
}

function GridItem(props: FileGridItem): JSX.Element
function GridItem(props: GroupGridItem): JSX.Element
function GridItem(props: BasicGridItem): JSX.Element

function GridItem(props: BasicGridItem | FileGridItem | GroupGridItem): JSX.Element | void {
    const { variant, onClick } = props
    const {
        palette: {
            primary: { main },
            grey,
        },
    } = useTheme()

    switch (variant) {
        case 'basic':
            const { id, title, parentID, image, onEdit, onDelete } = props

            const handleEdit = (e: SyntheticEvent) => onEdit(id)
            const handleDelete = (e: SyntheticEvent) => onDelete(id)
            const handleClick = () => onClick(id, parentID)

            return (
                <StyledItem sx={{ p: 3 }}>
                    <StyledNameOptionWrapper>
                        <StyledGridTitle onClick={handleClick}>
                            <HighlightMatch>{title}</HighlightMatch>
                        </StyledGridTitle>

                        <DropdownMenu
                            element={
                                <IconButton>
                                    <MoreHorizIcon />
                                </IconButton>
                            }
                        >
                            <MenuItem onClick={handleEdit}>
                                <Typography>{t(`edit`)}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Typography>{t(`delete`)}</Typography>
                            </MenuItem>
                        </DropdownMenu>
                    </StyledNameOptionWrapper>

                    <Image
                        src={image || buildingPhoto}
                        sxWrapper={{ height: '120px' }}
                        onClick={handleClick}
                    />
                </StyledItem>
            )

        case 'file':
            const {
                data,
                checked,
                image: fileImage,
                onChange,
                isClickable,
                allowDrag,
                onDragStart,
            } = props
            const { id: fileID, name, date, ext, thumbnail, fullUrl } = data

            const handleFileGridClick = () => {
                if (!onClick) return
                if (isClickable === undefined) return onClick()
                return isClickable(data) ? onClick() : undefined
            }

            return (
                <DragItem data={data} allowDrag={allowDrag} onDragStart={onDragStart}>
                    <StyledGridItemWrapper>
                        <StyledGridItem
                            key={fileID}
                            pointer={(() => {
                                if (isClickable === undefined) return Boolean(onClick)
                                return isClickable(data)
                            })()}
                            onClick={handleFileGridClick}
                        >
                            <Box mb={2}>
                                {fileImage ? (
                                    <Box height={100} width={100}>
                                        <Image src={thumbnail} />
                                    </Box>
                                ) : (
                                    <Icon variant="file" icon={ext} height={50} width={50} />
                                )}
                            </Box>

                            <Typography fontSize={11}>{name}</Typography>
                            <Typography color={grey[400]} fontSize={10}>
                                {date}
                            </Typography>
                        </StyledGridItem>

                        <StyledCheckboxWrapper checked={checked} className="checkbox-wrapper">
                            <StyledCheckbox onChange={onChange} checked={checked} />
                        </StyledCheckboxWrapper>

                        <StyledGridBtnWrapper className="grid-btn-wrapper">
                            {fullUrl ? (
                                <a href={fullUrl} download={name} target="_blank" rel="noreferrer">
                                    <StyledGridBtn color="secondary_light" size="large">
                                        <GetAppIcon fontSize="small" sx={{ color: main }} />
                                    </StyledGridBtn>
                                </a>
                            ) : (
                                <GetAppIcon fontSize="small" />
                            )}
                        </StyledGridBtnWrapper>
                    </StyledGridItemWrapper>
                </DragItem>
            )

        case 'group':
            const { data: groupData } = props
            const handleGroupGridClick = () => (onClick ? onClick(groupData) : undefined)

            return (
                <StyledGridItemWrapper key={groupData.id} onClick={handleGroupGridClick}>
                    <StyledGridItem pointer={true}>
                        <Box mb={2}>
                            <FolderCopyOutlinedIcon
                                sx={{ width: '50px', height: '50px', fill: grey[400] }}
                            />
                        </Box>
                        <Typography fontSize={11}>{groupData.title}</Typography>
                    </StyledGridItem>
                </StyledGridItemWrapper>
            )

        default:
            return console.error('GridItem - variant missing')
    }
}

export default GridItem
