import { Skeleton } from '@mui/material'
import { css } from '@mui/system'
import styled from '../../theme/styled'

interface ImageProps {
    visible: boolean
    objectfit: string
    maxHeight?: string
    cursor: 'pointer' | 'initial'
}
export const StyledSkeleton = styled(Skeleton)`
    width: 100%;
    height: 100%;
    transform: initial;
    flex-shrink: 0;
    background-color: rgba(211, 211, 211, 1);
`
export const StyledImage = styled('img')<ImageProps>`
    ${({ theme, visible, objectfit, cursor, maxHeight }) => css`
        width: 100%;
        height: 100%;
        opacity: ${visible ? 1 : 0};
        transition: 300ms;
        pointer-events: 'none',
        user-select: 'none',
        -moz-user-select: 'none',
        -webkit-user-select: 'none',
        -ms-user-select: 'none',
        border-radius: ${theme.spacing(1)};
        flex-shrink: 0;

        ${
            objectfit &&
            css`
                object-fit: ${objectfit};
            `
        }

        ${
            maxHeight &&
            css`
                max-height: ${maxHeight}px;
            `
        }
    `}
`
