import { Box, Typography } from '@mui/material'
import React from 'react'
import { PATHS } from '../../../routes/routes'
import { StyledButtonWrapper, StyledLink } from '../../../theme/styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Link } from 'react-router-dom'

type Props = {}

//TODO: unsed page
const ActivationLink = (props: Props) => {
    const { t } = useTranslation()

    return (
        <Box>
            <Box mb={6}>
                <Typography variant="h3">
                    {t('activation_link.title', { name: 'Marcin' })}
                </Typography>
            </Box>
            <Box mb={7}>
                <Typography variant="body1" mb={4}>
                    {t('activation_link.thanks')}
                    <StyledLink to={t('immotech_cloud_link')}>{t('immotech_cloud')}</StyledLink>
                </Typography>
                <Typography variant="body1">{t('activation_link.get_started')}</Typography>
            </Box>
            <StyledButtonWrapper>
                <LoadingButton
                    size="large"
                    component={Link}
                    to={PATHS.DASHBOARD}
                    variant="contained"
                    loading={false}
                    endIcon={<ArrowRightIcon />}
                    sx={{
                        width: '70%',
                    }}
                >
                    {t('activation_link.confirm_registration')}
                </LoadingButton>
            </StyledButtonWrapper>
            <Box mb={5} mt={7}>
                <Typography variant="body1">{t('activation_link.if_you_didnt_submit')}</Typography>
            </Box>
            <Box>
                <Typography variant="body1">{t('regards')},</Typography>
                <Typography variant="body1">{t('the_immotech_team')},</Typography>
            </Box>
        </Box>
    )
}

export default ActivationLink
