import React from 'react'
import {
    InputLabel,
    FormControl,
    FormHelperText,
    SxProps,
    Select as MSelect,
    MenuItem,
    useTheme,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@mui/system'
import { StyledFormItem } from '../../theme/styles'
import { Option } from 'forms'
import HighlightMatch from '../HighlightMatch'
import DataItem from '../DataItem'
import { useTranslation } from 'react-i18next'
interface Props {
    name: string
    label?: string
    sx?: SxProps
    required?: boolean
    disabled?: boolean
    placeholder?: string

    noBorder?: boolean
    noMargin?: boolean
    fullWidth?: boolean
    labelSmall?: string
    selectSx?: any
    onChange?: (value: string) => void
    variant?: 'standard' | 'outlined' | 'filled'

    options: Option[]
}

const Select = ({
    name,
    label,
    sx,
    noBorder,
    noMargin,
    labelSmall,
    required,
    disabled,
    fullWidth,
    placeholder,
    options,
    variant,
    selectSx,
    onChange,
}: Props) => {
    const { control } = useFormContext()
    const { t } = useTranslation()
    const {
        palette: {
            error: { main: errorColor },
        },
    } = useTheme()

    return (
        <FormControl fullWidth sx={sx}>
            <StyledFormItem noBorder={!!noBorder}>
                <DataItem variant="input" label={label}>
                    <Controller
                        name={name || ''}
                        control={control}
                        render={({
                            field: { onChange: onChangeController, value },
                            fieldState: { error },
                        }) => (
                            <Box sx={{ width: '100%' }}>
                                {labelSmall && (
                                    <InputLabel htmlFor={`select-${label}}`} error={Boolean(error)}>
                                        {labelSmall}
                                    </InputLabel>
                                )}
                                <MSelect
                                    label={labelSmall}
                                    value={value || ''}
                                    onChange={(params) => {
                                        onChangeController(params)
                                        onChange && onChange(params?.target?.value)
                                    }}
                                    renderValue={(selectValue: string) => {
                                        const { id, Icon } =
                                            // @ts-ignore
                                            // https://github.com/microsoft/TypeScript/issues/44373/
                                            options?.find(({ value }) => value === selectValue) ||
                                            {}
                                        return (
                                            <Box display={'flex'} gap={1}>
                                                {Icon && Icon}
                                                {id && <HighlightMatch>{t(id)}</HighlightMatch>}
                                            </Box>
                                        )
                                    }}
                                    error={Boolean(error)}
                                    displayEmpty={true}
                                    placeholder={placeholder}
                                    required={required}
                                    variant={variant}
                                    fullWidth={fullWidth}
                                    disabled={disabled}
                                    sx={{
                                        width: '100%',
                                        '&&&& .MuiSelect-select': { pt: 3, pb: 3, ...selectSx },
                                        //remove underline
                                        '&:before': { display: 'none' },
                                        '&:after': { display: 'none' },
                                    }}
                                >
                                    {options?.map(({ value, ...option }, i) => (
                                        <MenuItem
                                            key={i}
                                            value={value}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 2,
                                            }}
                                        >
                                            {'Icon' in option && option?.Icon}
                                            {'id' in option && t(option.id)}
                                        </MenuItem>
                                    ))}
                                </MSelect>
                                {error && (
                                    <FormHelperText sx={{ color: errorColor }}>
                                        {t(`${error?.message}`)}
                                    </FormHelperText>
                                )}
                            </Box>
                        )}
                    />
                </DataItem>
            </StyledFormItem>
        </FormControl>
    )
}

export default React.memo(Select)
