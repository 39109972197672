import React from 'react'
import { DataTypesAPI } from '../types/index'
import Icon from '../components/Icon'
import theme from '../theme'
import { Option, OptionWithIcon } from 'forms'
import { AccountTypeProps } from 'auth'
import { AccountTypes, MaintenanceBase, RoomsTaxonomy } from '../types/constant'

export const ACCOUNTS_TYPES: (Option<AccountTypes> & AccountTypeProps)[] = [
    {
        id: 'Contractor',
        value: AccountTypes.Contractor,
        registerAllow: true,
    },
    {
        id: 'Company',
        value: AccountTypes.Company,
        registerAllow: true,
    },
]

export const CONTRACTOR_SECTORS: Option[] = [
    {
        id: 'Appraiser',
        value: 'appraiser',
    },
    {
        id: 'Drone',
        value: 'drone',
    },
    {
        id: 'Drafter',
        value: 'drafter',
    },
    {
        id: 'Craftsman',
        value: 'craftsman',
    },
]

export const TASK_PRIORITY: OptionWithIcon[] = [
    {
        id: 'short_term',
        value: 'short',
        Icon: (
            <Icon
                icon={'ChevronIcon'}
                height={12}
                color={theme.palette.error.dark}
                orientation={'up'}
            />
        ),
    },
    {
        id: 'middle_term',
        value: 'middle',
        Icon: <Icon icon={'CircleIcon'} height={8} width={8} color={theme.palette.warning.light} />,
    },
    {
        id: 'long_term',
        value: 'long',
        Icon: (
            <Icon
                icon={'ChevronIcon'}
                height={12}
                color={theme.palette.info.main}
                orientation={'down'}
            />
        ),
    },
    {
        id: 'critical_term',
        value: 'critical',
        Icon: <Icon icon={'Urgency'} height={16} width={16} />,
    },
    {
        id: 'no_urgency_term',
        value: 'none',
        Icon: <Icon icon={'MinusIcon'} height={3} color={theme.palette.grey[500]} />,
    },
]
export const TASK_STATUS: OptionWithIcon[] = [
    {
        id: 'open',
        value: 'open',
        Icon: <Icon icon={'SquareIcon'} height={8} width={8} color={theme.palette.error.dark} />,
    },
    {
        id: 'in_progress',
        value: 'in_progress',
        Icon: <Icon icon={'SquareIcon'} height={8} width={8} color={theme.palette.warning.light} />,
    },
    {
        id: 'done',
        value: 'done',
        Icon: <Icon icon={'SquareIcon'} height={8} width={8} color={theme.palette.success.light} />,
    },
]

export const ENTITY_TYPES: Option<DataTypesAPI>[] = [
    {
        id: 'container.container',
        value: DataTypesAPI.Container,
    },
    {
        id: 'object.property',
        value: DataTypesAPI.Object,
    },
    {
        id: 'unit.unit',
        value: DataTypesAPI.Unit,
    },
    {
        id: 'maintenance.title',
        value: DataTypesAPI.Maintenance,
    },
]

export const MAINTENANCE_BASE: Option[] = [
    {
        id: 'MEP',
        value: MaintenanceBase.Mep,
    },
    {
        id: 'maintenance.title',
        value: MaintenanceBase.Maintenance,
    },
]

export const ROOMS_TAXONOMY: Option[] = [
    {
        id: 'rooms.livingroom',
        value: RoomsTaxonomy.Livingroom,
    },
    {
        id: 'rooms.bedroom',
        value: RoomsTaxonomy.Bedroom,
    },
    {
        id: 'rooms.bathroom',
        value: RoomsTaxonomy.Bathroom,
    },
    {
        id: 'rooms.corridor',
        value: RoomsTaxonomy.Corridor,
    },
    {
        id: 'rooms.kidsroom',
        value: RoomsTaxonomy.Kidsroom,
    },
    {
        id: 'rooms.office',
        value: RoomsTaxonomy.Office,
    },
]
