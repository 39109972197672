import { QueryDataKey } from '../../types/index'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import maintenanceService from '../../api/services/maintenance'
import useQueryKeys from '../useQueryKeys'
import useNavUtils from '../useNavUtils'
export const useDeleteMaintenance = (): UseMutationResult<any> => {
    const { setSuccessMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { deleteKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Maintenance)
    const { navigateMaintenance } = useNavUtils()

    return useMutation(
        async (maintenanceID: string) => {
            return maintenanceService.delete(maintenanceID)
        },
        {
            mutationKey: deleteKey,
            onSuccess: () => {
                setSuccessMsg(t('deleted'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateMaintenance()('LIST')
            },
        }
    ) as UseMutationResult<any>
}
