import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {
    StyledCol,
    StyledHeadRow,
    StyledRow,
    StyledRowIcon,
    StyledRowIconWrapper,
    StyledTableBottom,
    StyledWrapper,
} from './styles'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
    useTable,
    useSortBy,
    usePagination,
    Column,
    useFilters,
    useGlobalFilter,
    useFlexLayout,
    useRowSelect,
    TableInstance,
    Row,
    UseTableRowProps,
} from 'react-table'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import useSearchStore from '../../store/search'
import { ImageData, MainData } from 'index'
import Loader from '../Loader'
import GetAppIcon from '@mui/icons-material/GetApp'
import { FileData } from 'files'
import usePersistState from '../../hooks/usePersistState'
import { StyledComponent } from '@emotion/styled'
import { MUIStyledCommonProps } from '@mui/system'
import Lightbox from '../Lightbox'
import useFileUtils from '../../hooks/useFileUtils'
import { createImageData } from '../../api/data'
import { ContainerList } from 'containers'
import { ObjectList } from 'objects'
import { TreeItem } from '../DataTree'
import { MaintenanceList } from 'maintenance'
import { TaskList } from 'tasks'
import { UnitList } from 'units'
import HighlightMatch from '../HighlightMatch'
import DragItem from '../../modules/DragItem'
import NoData from '../NoData'
import { useEffectOnce } from 'usehooks-ts'
import { PAGE_SIZES } from '../../constant/const'
import { ApartmentList } from 'apartment'
import useView from '../../store/view'
import Pagination from '../../modules/Pagination'

interface TableProps {
    isLoading?: boolean
    isGridView?: boolean
    allowDrag?: boolean
    isFullWidthPaginationWrapper?: boolean
    onDragStart?: (item: MainData) => void
    onDragEnd?: (e: MainData) => void
    renderOnBottom?:
        | ((renderPagination: () => JSX.Element | null, chosenCount?: number) => JSX.Element)
        | null
    onEdit?: (item: string) => void
    onDelete?: (item: string) => void
    isColClickable?: (item: MainData, columnID?: string) => boolean
    onRowClick?: (data: MainData) => void
    isRowClickable?: (data: MainData) => boolean
    onCellClick?: (id: string) => void
    getChosenRows?: (ids: string[]) => void
    renderGridItem?: (item: MainData, props?: any) => JSX.Element | undefined
    GridWrapperElement?: typeof React.Component | StyledComponent<MUIStyledCommonProps>
}

type TableData<T extends {}> = {
    data: T[]
    columns: Column<T>[]
}

type TablePropsData =
    | TableData<ContainerList>
    | TableData<ObjectList>
    | TableData<MaintenanceList>
    | TableData<TaskList>
    | TableData<FileData>
    | TableData<TreeItem>
    | TableData<UnitList>
    | TableData<ApartmentList>

const Table = ({
    isLoading,
    GridWrapperElement,
    columns,
    data,
    allowDrag,
    isFullWidthPaginationWrapper,
    isRowClickable,
    onDragStart,
    isColClickable,
    renderOnBottom,
    onEdit,
    onDelete,
    onRowClick,
    onCellClick,
    getChosenRows,
    renderGridItem,
}: TablePropsData & TableProps) => {
    let containerRef = useRef<HTMLDivElement>(null)
    let bottomWrapperRef = useRef<HTMLDivElement>(null)
    const wrapper = useRef<HTMLElement>(null)
    const { viewType } = useView()

    const { inputValue: searchValue } = useSearchStore()
    const { isImage, isFile, isPDF } = useFileUtils()

    const [perPage, setPerPage] = usePersistState('page_size_new', PAGE_SIZES[0]?.value)
    const [chosen, setChosen] = useState<string | null>(null)
    const [isFixed, setFixed] = useState<boolean>(false)

    const handleNextCol = () => {
        let container = containerRef.current
        container?.scroll({
            left: container.scrollLeft + 250,
            top: 0,
            behavior: 'smooth',
        })
    }

    const handlePrevCol = () => {
        let container = containerRef.current
        container?.scroll({
            left: container.scrollLeft - 250,
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleDelete = useCallback(
        (data: MainData) => {
            if (!onDelete) return
            onDelete(data.id)
        },
        [onDelete]
    )

    const handleEdit = useCallback(
        (data: MainData) => {
            if (!onEdit) return
            onEdit(data.id)
        },
        [onEdit]
    )

    const handleItemClick = (data: MainData) => {
        if (onCellClick) return onCellClick(data?.id)
        if (!isFile(data)) return
        if (isPDF(data.ext)) return window.open(data.fullUrl)
        setChosen(data.fullUrl)
    }

    const columnsData = useMemo(
        () => [
            {
                Header: '#',
                id: 'row',
                maxWidth: 50,
                size: 1,
                order: 1,
                accessor: (_: MainData, i: number) => i + 1,
            },
            ...columns,
            {
                id: 'nav',
                Header: () => (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                        width={'100%'}
                    >
                        <IconButton size="large" onClick={handlePrevCol}>
                            <ArrowLeftIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="large" onClick={handleNextCol}>
                            <ArrowRightIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ),
                Cell: ({
                    cell: {
                        row: { original },
                    },
                }: TableInstance<MainData>) => (
                    <StyledRowIconWrapper className="icon-wrapper">
                        {onEdit && (
                            <StyledRowIcon
                                color="primary_outlined"
                                size="large"
                                onClick={() => handleEdit(original)}
                            >
                                <ModeEditOutlinedIcon fontSize="small" />
                            </StyledRowIcon>
                        )}
                        {isFile(original) && (
                            <a
                                href={original?.fullUrl}
                                target="_blank"
                                rel="noreferrer"
                                download={original?.name}
                            >
                                <StyledRowIcon color="primary_outlined" size="large">
                                    <GetAppIcon fontSize="small" />
                                </StyledRowIcon>
                            </a>
                        )}
                        {onDelete && (
                            <StyledRowIcon
                                color="primary_outlined"
                                size="large"
                                onClick={() => handleDelete(original)}
                            >
                                <DeleteForeverIcon fontSize="small" />
                            </StyledRowIcon>
                        )}
                    </StyledRowIconWrapper>
                ),
                width: 115,
                maxWidth: 115,
                size: 1,
            },
        ],
        // eslint-disable-next-line
        [columns, onEdit, onDelete, handleDelete, handleEdit]
    )

    const defaultColumn: Object = useMemo(
        () => ({
            Cell: ({
                cell: { value },
                state: { globalFilter },
                column: { disableGlobalFilter },
            }: TableInstance) => {
                if (disableGlobalFilter) return value
                return <HighlightMatch searchValue={globalFilter}>{value}</HighlightMatch>
            },
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        nextPage,
        previousPage,
        selectedFlatRows,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { pageIndex },
    } = useTable<MainData>(
        {
            columns: columnsData as Column<MainData>[],
            data,
            defaultColumn,
            autoResetExpanded: false,
            autoResetSortBy: false,
            autoResetPage: false,
            initialState: {
                pageSize: perPage,
                hiddenColumns: ['nid', 'id'],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useFlexLayout,
        useRowSelect
    )

    const images: ImageData[] = useMemo(
        () =>
            page
                .filter((p: UseTableRowProps<MainData>) => isImage(p.original))
                .map((f: UseTableRowProps<MainData>) => f.original as FileData)
                .map((f: FileData) => createImageData(f)),
        [page, isImage]
    )

    const thumbs: ImageData[] = useMemo(
        () =>
            page
                .filter((p: UseTableRowProps<MainData>) => isImage(p.original))
                .map((f: UseTableRowProps<MainData>) => f.original as FileData)
                .map((f: FileData) => createImageData({ ...f, url: f.thumbnail })),
        [page, isImage]
    )

    useEffect(() => {
        setGlobalFilter(searchValue.length > 1 ? searchValue : undefined)
    }, [searchValue, setGlobalFilter])

    useEffect(() => {
        gotoPage(0)
    }, [gotoPage])

    useEffect(() => {
        setPageSize(perPage)
        // eslint-disable-next-line
    }, [perPage])

    useEffect(() => {
        const handleExportSelectedRows = () => {
            if (!getChosenRows) return
            const ids = selectedFlatRows.map(({ original: { id } }: Row<MainData>) => id)
            getChosenRows(ids)
        }

        handleExportSelectedRows()
    }, [selectedFlatRows, getChosenRows])

    useEffectOnce(() => () => setGlobalFilter(undefined))

    const renderPagination: () => JSX.Element | null = () => {
        if (!page.length) return null
        return (
            <Pagination
                setPerPage={setPerPage}
                perPage={perPage}
                pageIndex={pageIndex + 1}
                pageLength={pageOptions.length === 0 ? 1 : pageOptions.length}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                gotoPage={gotoPage}
            />
        )
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries, observer) => {
            const main = entries[0].target
            if (!wrapper.current) return
            setFixed(main.clientHeight > 1200)
            if (!bottomWrapperRef.current) return
            bottomWrapperRef.current.style.maxWidth = `${main.clientWidth}px`
        })

        const initResizeObserver = () => {
            if (!wrapper.current) return
            resizeObserver.observe(wrapper.current)
        }

        initResizeObserver()

        return () => resizeObserver.disconnect()
    }, [])

    useEffect(() => {
        !page?.length && setFixed(false)
    }, [page])

    const renderTableBottom = () => {
        if (renderOnBottom) return renderOnBottom(renderPagination, selectedFlatRows?.length)
        if (renderOnBottom === null) return renderOnBottom
        return renderPagination()
    }

    if (isLoading) return <Loader />

    const GridWrapper = (GridWrapperElement || Box) as typeof React.Component

    return (
        <>
            {!page.length ? (
                <Box sx={{ py: 8 }}>
                    <NoData />
                </Box>
            ) : viewType === 'grid' ? (
                <Box ref={wrapper}>
                    <GridWrapper>
                        {page.map((row: any, i) => {
                            prepareRow(row)
                            return (
                                <Box key={i}>
                                    {renderGridItem &&
                                        renderGridItem(row.original, {
                                            onDragStart: () =>
                                                onDragStart && onDragStart(row.original),
                                            onClick: () => handleItemClick(row.original),
                                            ...row.getToggleRowSelectedProps(),
                                        })}
                                </Box>
                            )
                        })}
                    </GridWrapper>
                </Box>
            ) : (
                <Box ref={wrapper}>
                    <StyledWrapper {...getTableProps()} className="table" ref={containerRef}>
                        <div>
                            {headerGroups.map(({ headers, getHeaderGroupProps }) => (
                                <StyledHeadRow {...getHeaderGroupProps()} className="tr">
                                    {headers.map(
                                        ({
                                            id,
                                            getHeaderProps,
                                            render,
                                            getSortByToggleProps,
                                            isSorted,
                                            isSortedDesc,
                                        }) => (
                                            <StyledCol
                                                className="td"
                                                {...getHeaderProps([getSortByToggleProps()])}
                                                //disable when checkbox selection field
                                                {...(id === 'selection'
                                                    ? { onClick: undefined }
                                                    : {})}
                                            >
                                                {render('Header')}
                                                {isSorted &&
                                                    (isSortedDesc ? (
                                                        <KeyboardArrowDownIcon />
                                                    ) : (
                                                        <KeyboardArrowUpIcon />
                                                    ))}
                                            </StyledCol>
                                        )
                                    )}
                                </StyledHeadRow>
                            ))}
                        </div>
                        <div {...getTableBodyProps()} className="tbody">
                            {page.map((row, i) => {
                                prepareRow(row)
                                const { getRowProps, cells, original } = row

                                const allowRowClick =
                                    isRowClickable === undefined || isRowClickable(original)

                                return (
                                    <DragItem
                                        key={row.original.id}
                                        onDragStart={() => onDragStart && onDragStart(original)}
                                        data={original}
                                        allowDrag={allowDrag}
                                    >
                                        <StyledRow
                                            className="tr"
                                            {...(getRowProps && getRowProps())}
                                            pointer={Boolean(allowRowClick)}
                                            onClick={() =>
                                                allowRowClick && onRowClick && onRowClick(original)
                                            }
                                        >
                                            {cells.map(
                                                ({
                                                    row: { original },
                                                    column: { id },
                                                    getCellProps,
                                                    render,
                                                }) => {
                                                    const allowClick = Boolean(
                                                        isColClickable === undefined ||
                                                            (isColClickable &&
                                                                isColClickable(original, id))
                                                    )

                                                    const allowCellClick =
                                                        allowClick &&
                                                        id !== 'selection' &&
                                                        id !== 'nav'

                                                    const handleClick = allowCellClick
                                                        ? () => handleItemClick(original)
                                                        : undefined

                                                    return (
                                                        <StyledCol
                                                            {...getCellProps()}
                                                            pointer={Boolean(allowCellClick)}
                                                            onClick={handleClick}
                                                            className="td"
                                                        >
                                                            {render('Cell')}
                                                        </StyledCol>
                                                    )
                                                }
                                            )}
                                        </StyledRow>
                                    </DragItem>
                                )
                            })}
                        </div>
                    </StyledWrapper>
                </Box>
            )}

            <StyledTableBottom
                ref={bottomWrapperRef}
                isFixed={isFixed}
                fullWidth={Boolean(selectedFlatRows?.length || isFullWidthPaginationWrapper)}
            >
                {renderTableBottom()}
            </StyledTableBottom>

            {chosen && images?.length && (
                <Lightbox
                    images={images}
                    pageIndex={pageIndex}
                    nextPage={nextPage}
                    prevPage={previousPage}
                    thumbs={thumbs}
                    open={chosen}
                    onChange={setChosen}
                />
            )}
        </>
    )
}
export default React.memo(Table)
