import { ImageDataResponse, MainDataTypes } from '../../types'
import {
    TaskListResponse,
    TaskList,
    TaskData,
    TaskParams,
    TaskDataResponse,
    TaskRequestData,
} from 'tasks'
import { createImageData, createListDateFormat } from '.'
import {
    getBase64Items,
    getCurrencyFormat,
    getImagesToApiUpload,
    getRemovedImagesData,
} from '../../utils/helpers'
import { ENTITY_TYPES } from '../../constant/taxonomy'

export const createTaskRemoveImagesData = ({ images }: Partial<TaskParams>) => {
    const imageRemoveData = getRemovedImagesData(images)

    return {
        ...(images && { field_todo_pictures: imageRemoveData }),
    }
}

export const createTaskApiData = async ({
    description,
    shortDescription,
    note,
    cost,
    priority,
    responsible,
    todoAssignment,
    status,
    images,
}: Partial<TaskParams>): Promise<Partial<TaskRequestData>> => {
    const imagesToSend = getImagesToApiUpload(images)

    return {
        ...(todoAssignment && { field_todo_assignment: todoAssignment }),
        ...(description && {
            field_todo_description: {
                und: [
                    {
                        value: description || '',
                    },
                ],
            },
        }),
        ...(shortDescription && {
            field_todo_short_desc: {
                und: [
                    {
                        value: shortDescription,
                    },
                ],
            },
        }),
        ...(note && {
            field_todo_note: {
                und: [
                    {
                        value: note,
                    },
                ],
            },
        }),
        ...(cost && {
            field_todo_value: {
                und: [
                    {
                        value: cost,
                    },
                ],
            },
        }),
        ...(priority && {
            field_todo_priority: {
                und: priority,
            },
        }),
        ...(status && {
            field_todo_status: {
                und: status,
            },
        }),
        ...(responsible && {
            field_todo_responsible: {
                und: [
                    {
                        value: responsible,
                    },
                ],
            },
        }),
        ...(imagesToSend?.length && { field_todo_pictures: await getBase64Items(imagesToSend) }),
    }
}

export const createTaskList = (data?: TaskListResponse[]): TaskList[] => {
    if (!data) return []

    return data.map(
        ({
            nid,
            title,
            created,
            changed,
            assigned_entity_type,
            assigned_entity_title,
            assigned_entity_id,
            short_description,
            cost,
            priority,
            status,
            responsible,
        }) => {
            return {
                id: nid,
                nid,
                title,
                responsible,
                changed: createListDateFormat(Number(changed)),
                created: createListDateFormat(Number(created)),
                entityType: ENTITY_TYPES.find(({ value }) => value === assigned_entity_type),
                entityTitle: assigned_entity_title,
                entityID: assigned_entity_id,
                shortDescription: short_description,
                cost: Number(cost),
                formattedCost: getCurrencyFormat(cost),
                priority,
                status,
                dataType: MainDataTypes.Task,
            }
        }
    )
}

export const createTaskData = ({
    nid,
    title,
    created,
    field_todo_status,
    field_todo_priority,
    field_todo_short_desc,
    field_todo_value,
    changed,
    type,
    field_todo_pictures,
    field_todo_description,
    field_todo_responsible,
    data_todo_assignment_name,
    field_todo_note,
    field_todo_assignment,
}: TaskDataResponse): TaskData => {
    return {
        id: nid,
        nid,
        title,
        changed: createListDateFormat(Number(changed)),
        created: createListDateFormat(Number(created)),
        cost: field_todo_value?.und?.[0]?.value,
        entityTitle: data_todo_assignment_name,
        formattedCost: getCurrencyFormat(field_todo_value?.und?.[0]?.value),
        status: field_todo_status?.und?.[0]?.value,
        priority: field_todo_priority?.und?.[0]?.value,
        responsible: field_todo_responsible?.und?.[0]?.value,
        shortDescription: field_todo_short_desc?.und?.[0]?.value,
        type,
        images: field_todo_pictures?.und?.map((file: ImageDataResponse) =>
            createImageData({ url: file?.full_url })
        ),
        description: field_todo_description?.und?.[0]?.value,
        note: field_todo_note?.und?.[0]?.value,
        todoAssignment: field_todo_assignment?.und?.[0]?.target_id,
        dataType: MainDataTypes.Task,
    }
}

export const createTaskParams = ({
    id,
    title,
    status,
    priority,
    shortDescription,
    entityTitle,
    cost,
    images,
    description,
    changed,
    created,
    responsible,
    todoAssignment,
    note,
}: TaskData): TaskParams => {
    return {
        id,
        title,
        changed,
        created,
        status,
        entityTitle,
        cost,
        priority,
        responsible,
        shortDescription,
        todoAssignment,
        images,
        description,
        note,
    }
}
