import React, { memo, useEffect, useRef } from 'react'
import { StyledInputPage, StyledPagination } from './styled'
import {
    Box,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    SxProps,
    Typography,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'
import theme from '../../theme'
import { PAGE_SIZES } from '../../constant/const'

interface Props {
    pageIndex: number
    pageLength: number
    previousPage: () => void
    perPage: number
    setPerPage: (perPage: number) => void
    canPreviousPage: boolean
    canNextPage: boolean
    nextPage: () => void
    gotoPage: (pageIndex: number) => void
    sx?: SxProps
}

const Pagination = ({
    pageIndex,
    pageLength,
    canPreviousPage,
    canNextPage,
    sx,
    perPage,
    setPerPage,
    previousPage,
    nextPage,
    gotoPage,
}: Props) => {
    const { t } = useTranslation()
    const refInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (refInput.current) {
            refInput.current.value = pageIndex.toString()
        }
    }, [pageIndex])

    const handleChangePage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const page = e.target.value ? Number(e.target.value) - 1 : 0
        gotoPage(page)
    }

    return (
        <Box display={'flex'} gap={5} alignItems={'center'} p={2} pr={0}>
            <Typography sx={{ flexShrink: '0' }}>{t('page_size')}</Typography>
            <Select
                value={perPage.toString()}
                onChange={(e: SelectChangeEvent) => {
                    setPerPage(Number(e.target.value))
                }}
            >
                {PAGE_SIZES.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.id}
                    </MenuItem>
                ))}
            </Select>
            <StyledPagination sx={sx}>
                <IconButton disabled={!(pageIndex > 0 && canPreviousPage)} onClick={previousPage}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <StyledInputPage
                    ref={refInput}
                    type="number"
                    defaultValue={pageIndex}
                    min={1}
                    max={pageLength}
                    onChange={handleChangePage}
                />
                <Typography sx={{ margin: `0 ${theme.spacing(2)} 0 0` }}>{t('of')}</Typography>
                <Typography>{pageLength}</Typography>
                <IconButton
                    sx={{ margin: `0 0 0 ${theme.spacing(1)}` }}
                    onClick={nextPage}
                    disabled={!canNextPage}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </StyledPagination>
        </Box>
    )
}

export default memo(Pagination)
