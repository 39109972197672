import React from 'react'
import Dialog from '../../../../../../components/Dialog'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useDeleteMaintenance } from '../../../../../../hooks/maintenance/useDeleteMaintenance'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import { useGetMaintenances } from '../../../../../../hooks/maintenance/useGetMaintenances'

const DeleteMaintenance = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { maintenanceID, lastParamID } = useParamManage()
    const { mutate: deleteMaintenance, isLoading: isLoadingDelete } = useDeleteMaintenance()

    const { data: maintenances } = useGetMaintenances(lastParamID, {
        refetchOnMount: false,
    })

    const chosenMaintenanceData = maintenances?.find((m) => m?.nid === maintenanceID)

    return (
        <Dialog
            setOpen={goBack}
            onAcceptClick={() => deleteMaintenance(maintenanceID)}
            onAcceptText={t('delete')}
            title={t('delete_item.title', { name: chosenMaintenanceData?.title })}
            isLoading={isLoadingDelete}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteMaintenance
