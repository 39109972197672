import React, { FC } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { StyledForm, StyledWrapper } from '../styles'
import Input from '../components/Input'
import { Box } from '@mui/system'
import DataItem from '../../components/DataItem'
import Autocomplete from '../components/Autocomplete'
import { TASK_PRIORITY, TASK_STATUS } from '../../constant/taxonomy'
import { TaskData, TaskParams } from 'tasks'
import { useEffectOnce } from 'usehooks-ts'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './shema'
import FormData from '../components/FormData'
import { InputAdornment } from '@mui/material'
import DataGroup from '../../components/DataGroup'
import useParamManage from '../../hooks/useParamManage'
import useNavUtils from '../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import ImageUpload from '../../modules/ImageUpload'
import FormSubmitFooter from '../../modules/FormSubmitFooter'

interface Props {
    isLoading: boolean
    task?: TaskData
    onSubmit?: (values: TaskParams) => void
    onCancelClick?: () => void
}

const FormTask: FC<Props> = ({ task, onSubmit, isLoading, onCancelClick }) => {
    const { lastParamID } = useParamManage()
    const { t } = useTranslation()
    const { navigateTask } = useNavUtils()
    const isReadAction = !Boolean(onSubmit)

    const methods = useForm<TaskParams>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...(task || {
                id: '',
                shortDescription: '',
                description: '',
                note: '',
                cost: '',
                priority: '',
                responsible: '',
                images: [],
                status: TASK_STATUS[0]?.value,
                todoAssignment: lastParamID,
            }),
        },
    })

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = methods

    const images = useWatch({
        control,
        name: 'images',
    })

    const handleUploadImages = () => navigateTask(task?.id)('UPDATE')

    useEffectOnce(() => () => reset({}, { keepValues: false }))

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}>
                <StyledWrapper>
                    <Box flexBasis={'50%'}>
                        <DataGroup label={t(`${'general_information'}`)}>
                            <>
                                {task?.title && (
                                    <DataItem label={t(`${'title'}`)}>{task.title}</DataItem>
                                )}
                                {task?.entityTitle && (
                                    <DataItem label={t(`${'entity_title'}`)}>
                                        {task?.entityTitle}
                                    </DataItem>
                                )}
                                <FormData renderAsData={isReadAction}>
                                    <Input
                                        name="shortDescription"
                                        label={t('task.short_description')}
                                    />
                                    <Input
                                        name="note"
                                        label={t('task.note')}
                                        variant={'textarea'}
                                    />
                                    <Input
                                        name="description"
                                        label={t('task.description')}
                                        variant={'textarea'}
                                    />
                                    <Input name="responsible" label={t('responsible')} />
                                    <Input
                                        type="number"
                                        name="cost"
                                        label={t('task.cost')}
                                        endAdornment={
                                            <InputAdornment position="end">€</InputAdornment>
                                        }
                                    />
                                    <Autocomplete
                                        name={`priority`}
                                        label={t('task.priority')}
                                        options={TASK_PRIORITY}
                                    />
                                    <Autocomplete
                                        name={`status`}
                                        label={t('status')}
                                        options={TASK_STATUS}
                                        noBorder
                                    />
                                </FormData>

                                {isReadAction && (
                                    <DataItem label={t(`${'last_edited'}`)}>
                                        {task?.changed}
                                    </DataItem>
                                )}

                                {isReadAction && (
                                    <DataItem label={t(`${'created'}`)}>{task?.created}</DataItem>
                                )}
                            </>
                        </DataGroup>
                    </Box>
                    <Box flexBasis={'50%'} maxWidth={'50%'}>
                        <DataGroup label={t('photo_gallery')} innerPadding>
                            <ImageUpload
                                images={images}
                                name="images"
                                variant={isReadAction ? 'gallery' : 'list'}
                                onUploadEnd={isReadAction ? handleUploadImages : undefined}
                            />
                        </DataGroup>
                    </Box>
                </StyledWrapper>
                <FormSubmitFooter
                    submitDisabled={!isDirty}
                    onCancelClick={onCancelClick}
                    loading={isLoading}
                    hide={isReadAction}
                />
            </StyledForm>
        </FormProvider>
    )
}

export default FormTask
