import { css } from '@mui/system'
import styled from '../../theme/styled'
import { Typography } from '@mui/material'

export const StyledFieldGroup = styled('div')<{
    withBg: boolean
}>`
    ${({ theme, noBg }) => css`
        ${!noBg &&
        css`
            background-color: ${theme.palette.neutral.contrastText};
            border-radius: 10px;
            margin: 0 0 ${theme.spacing(2)} 0;
        `}
    `}
`

export const StyledTitle = styled(Typography)`
    ${({ theme }) => css`
        color: ${theme.palette.secondary.main};
        font-weight: 600;
        min-height: 48px;
        display: flex;
        align-items: center;
    `};
`
