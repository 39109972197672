import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiSwitch: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            width: 24,
            height: 14,
            padding: 0,
            display: 'flex',
            '&:active': {},
            '& .MuiSwitch-switchBase': {
                padding: 2,
                color: theme.palette.grey[300],
                '&.Mui-checked': {
                    transform: 'translateX(10px)',
                    color: theme.palette.neutral.contrastText,
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            },
            '& .MuiSwitch-thumb': {
                width: 10,
                height: 10,
                borderRadius: 6,
                transition: 'all .2s ease',
            },
            '& .MuiSwitch-track': {
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.grey[100],
                boxSizing: 'border-box',
            },
        }),
    },
    variants: [],
}

export default MuiSwitch
