import { ButtonBase, Typography } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledPlaceResultItem = styled(ButtonBase)`
    ${({ theme }) => css`
        padding: ${theme.spacing(1)} ${theme.spacing(6)};
        min-height: 59px;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        transition: 150ms;
        flex: 1;

        &:hover {
            background: ${theme.palette.grey[100]};

            img {
                transform: scale(1.2);
            }
        }
    `}
`
export const StyledMapMarker = styled('img')`
    ${({ theme }) => css`
        height: 30px;
        margin: 0 ${theme.spacing(2)} 0 0;
        transition: 150ms;
    `}
`
export const StyledPlaceSubtitle = styled(Typography)`
    ${({ theme }) => css`
        font-size: 0.825rem;
        max-width: 200px;
    `}
`
export const StyledPlaceName = styled(Typography)`
    ${({ theme }) => css`
        font-size: 0.825rem;
        font-weight: 900;
    `}
`
