import React, { FC } from 'react'
import theme from '../../theme/index'
import { StyledViewSelection, StyledIcons } from './styles'
import { IconButton } from '@mui/material'
import Search from '../Search'
import useSearchStore from '../../store/search'
import { t } from 'i18next'
import { useEffectOnce } from 'usehooks-ts'
import AppsIcon from '@mui/icons-material/Apps'
import MenuIcon from '@mui/icons-material/Menu'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { ViewType } from 'constant'
import useView from '../../store/view'
interface Props {
    variant?: 'view-type'
    available: ViewType[]
}

const LocalNavigation: FC<Props> = ({ variant = 'view-type', available }) => {
    const { inputValue, setInputValue, resetValue } = useSearchStore()
    const { viewType, setViewType } = useView()

    const VIEW_ELEMENTS = {
        list: {
            icon: <MenuIcon fontSize="small" />,
            onChange: setViewType,
        },
        grid: {
            icon: <AppsIcon fontSize="small" />,
            onChange: setViewType,
        },
        map: {
            icon: <LocationOnOutlinedIcon fontSize="small" />,
            onChange: setViewType,
        },
    } as const

    const getElements = () => {
        switch (variant) {
            default:
                return VIEW_ELEMENTS
        }
    }

    const elements = getElements()

    useEffectOnce(() => () => resetValue())

    return (
        <StyledViewSelection>
            <StyledIcons>
                <Search
                    placeholder={t(`search`)}
                    value={inputValue}
                    onInputChange={setInputValue}
                />
                {available.map((view) => {
                    const Icon = elements[view]?.icon
                    const onChange = elements[view]?.onChange

                    if (!elements[view]) return null
                    return (
                        <IconButton
                            key={view}
                            size="large"
                            className={`${view === viewType ? 'active' : ''}`}
                            onClick={() => onChange(view)}
                            sx={{
                                '&.active': {
                                    border: `1px solid ${theme.palette.grey['200']}`,
                                    background: theme.palette.common.white,
                                    color: theme.palette.primary.main,
                                },
                                '&:hover:not(.active)': {
                                    background: 'transparent',
                                    color: theme.palette.primary.main,
                                },
                            }}
                        >
                            {Icon}
                        </IconButton>
                    )
                })}
            </StyledIcons>
        </StyledViewSelection>
    )
}

export default LocalNavigation
