import * as yup from 'yup'

const attrSchema = {
    id: yup.string().required('form_error.required'),
    value: yup.string().required('form_error.required'),
}

const schema = yup.object({
    title: yup.string().required('form_error.required'),
    zipCode: yup.string().required('form_error.required'),
    city: yup.string().required('form_error.required'),
    street: yup.string().required('form_error.required'),
    type: yup.string().required('form_error.required'),
    attributes: yup.array().of(yup.object().shape(attrSchema)),
})

export default schema
