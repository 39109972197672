import React from 'react'
import { Box } from '@mui/system'
import { useGetObjects } from '../../../../hooks/object/useGetObjects'
import Stats from '../../../../modules/Stats'
import { useGetTasks } from '../../../../hooks/task/useGetTasks'
import DataGroup from '../../../../components/DataGroup'
import Tabs from '../../../../components/Tabs'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CONTAINER_PATHS, OBJECT_PATHS } from '../../../../routes/routes'
import LocalNavigation from '../../../../components/LocalNavigation'

const Overview = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { data: objects } = useGetObjects()
    const { data: tasks } = useGetTasks()

    const TABS = [
        {
            id: 'container.all_containers',
            path: CONTAINER_PATHS.OVERVIEW_ALL,
        },
        {
            id: 'object.all_objects',
            path: OBJECT_PATHS.OVERVIEW_ALL,
        },
    ] as const

    return (
        <>
            <DataGroup label={t('summary')} collapsable noBg>
                <Stats>
                    {({ TotalAndCityStats, TaskStatusStats, TaskUrgencyStats, TaskCostStats }) => {
                        return (
                            <>
                                <TotalAndCityStats
                                    title={t('task.total_number_of', {
                                        dataName: t('object.objects'),
                                    })}
                                    data={objects}
                                />
                                <TaskStatusStats
                                    title={t('task.todo_tasks_progress')}
                                    data={tasks}
                                />
                                <TaskUrgencyStats
                                    title={t('task.todo_tasks_urgencies')}
                                    data={tasks}
                                />
                                <TaskCostStats title={t('task.todo_tasks_costs')} data={tasks} />
                            </>
                        )
                    }}
                </Stats>
            </DataGroup>

            <Box
                display={'flex'}
                justifyContent="space-between"
                position={'relative'}
                flexDirection={'row-reverse'}
                mb={3}
            >
                <LocalNavigation available={['list', 'grid', 'map']} />
                <Box my={-3}>
                    <Tabs value={pathname}>
                        {({ StyledTab }) =>
                            TABS.map(({ id, path }) => (
                                <StyledTab
                                    key={id}
                                    value={path}
                                    label={<Typography p={3}>{t(id)}</Typography>}
                                    onClick={() => navigate(path)}
                                />
                            ))
                        }
                    </Tabs>
                </Box>
            </Box>

            <Outlet />
        </>
    )
}

export default Overview
