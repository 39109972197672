import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { SignInParams } from '../../types/auth'
import Input from '../components/Input'
import { StyledForm } from '../styles'
import schema from './shema'
import { LoadingButton } from '@mui/lab'
import { StyledButtonWrapper } from '../../theme/styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

interface Props {
    onSubmit: ({ username, password }: SignInParams) => void
    isLoading: boolean
}

const FormSignIn: FC<Props> = ({ onSubmit, isLoading }) => {
    const { t } = useTranslation()

    const methods = useForm<SignInParams>({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: '',
        },
    })

    const { handleSubmit } = methods

    return (
        <FormProvider {...methods}>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Input
                    name="username"
                    labelSmall={t('username')}
                    sx={{ mb: 4 }}
                    autoComplete={'username'}
                />
                <Input
                    labelSmall={t('password')}
                    name="password"
                    sx={{ mb: 4 }}
                    variant={'password'}
                    autoComplete={'current-password'}
                />
                <StyledButtonWrapper justifyContent="end">
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        endIcon={<ArrowRightIcon />}
                        sx={{
                            width: '50%',
                        }}
                    >
                        {t('login')}
                    </LoadingButton>
                </StyledButtonWrapper>
            </StyledForm>
        </FormProvider>
    )
}

export default FormSignIn
