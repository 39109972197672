import { MainData } from 'index'
import React, { DragEvent, FC, PropsWithChildren, useRef } from 'react'
import CustomDragPreview from './components/CustomDragPreview'
import { Box } from '@mui/material'
import Tooltip from '../../components/Tooltip'
import { useTranslation } from 'react-i18next'

interface Props {
    allowDrag?: boolean
    data: MainData
    children: any
    onDragStart?: (data: MainData) => void
    onDragEnd?: (data: MainData) => void
}

const DragItem: FC<PropsWithChildren<Props>> = ({ data, children, allowDrag, onDragStart }) => {
    const { t } = useTranslation()
    const previewRef = useRef<HTMLDivElement>(null)

    const handleDragStart = (el: DragEvent<HTMLDivElement>) => {
        if (!previewRef.current) return
        onDragStart && onDragStart(data)

        el?.dataTransfer.setDragImage(previewRef.current, 0, 0)
    }

    if (!allowDrag) return children

    return (
        <Box
            draggable="true"
            onDragStart={handleDragStart}
            sx={{ height: '100%', '& > * ': { height: '100%' } }}
        >
            <Tooltip title={t('file.can_drag')} variant="dnd" offsetY={-26} sx={{ height: '100%' }}>
                <Box sx={{ height: '100%' }}>{children}</Box>
            </Tooltip>
            {'filename' in data && <CustomDragPreview data={data} ref={previewRef} />}
        </Box>
    )
}

export default DragItem
