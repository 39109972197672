import { Box, Tooltip as MuiTooltip, TooltipProps } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'
import theme from '../../theme'

interface Props {
    title: string
    offsetY?: number
    variant: 'dnd' | 'default'
}

const Tooltip: FC<PropsWithChildren<Props & Omit<TooltipProps, 'children'>>> = ({
    children,
    variant,
    title,
    open,
    offsetY = 0,
    ...props
}) => {
    const dndTooltipStyles = {
        bgcolor: theme.palette.common.white,
        color: theme.palette.neutral.main,
        boxShadow: '0px 2px 12px 0px rgba(117, 117, 117, 0.12)',
        fontSize: '0.725rem',
        fontWeight: 300,
    }

    const render = () => {
        switch (variant) {
            case 'dnd':
                return (
                    <MuiTooltip
                        title={title}
                        open={open}
                        {...props}
                        enterDelay={1000}
                        componentsProps={{
                            popper: {
                                placement: 'top',
                                style: { pointerEvents: 'none' },
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, offsetY],
                                        },
                                    },
                                ],
                            },
                            tooltip: {
                                sx: dndTooltipStyles,
                            },
                        }}
                    >
                        <Box>{children}</Box>
                    </MuiTooltip>
                )

            default:
                return (
                    <MuiTooltip title={title}>
                        <Box>{children}</Box>
                    </MuiTooltip>
                )
        }
    }

    return <Box>{render()}</Box>
}

export default Tooltip
