import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { PATHS } from '../../../routes/routes'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StyledLink } from '../../../theme/styles'

type Props = {}

const Support = (props: Props) => {
    const { t } = useTranslation()

    return (
        <Box>
            <Box mb={6}>
                <Typography variant="h3">{t('support.title')}</Typography>
            </Box>
            <Box mb={6}>
                <Typography variant="body1" mb={4}>
                    {t('support.thanks')}
                    <StyledLink as="a" href={t('immotech_cloud_link')}>
                        {t('immotech_cloud')}
                    </StyledLink>
                </Typography>
                <Typography variant="body1">{t('support.content')}</Typography>
            </Box>
            <Box mb={6} display="flex" justifyContent="space-between">
                <Typography variant="body1">{t('support.support_landline')}</Typography>
                <Typography variant="body1">
                    <StyledLink as="a" href={t('tel: support.tel')}>
                        {t('support.tel')}
                    </StyledLink>
                </Typography>
            </Box>
            <Box mb={6} display="flex" justifyContent="space-between">
                <Typography variant="body1">{t('support.support_team_email')}</Typography>
                <Typography variant="body1">
                    <StyledLink as="a" href={t('mailto: support.email')}>
                        {t('support.email')}
                    </StyledLink>
                </Typography>
            </Box>
            <Box mb={6} display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="body1">
                        {t('support.frequently_asked_questions')}
                    </Typography>
                    <Typography variant="caption" color="grey.300">
                        {t('support.youll_be_directed_to_external_page')}
                    </Typography>
                </Box>
                <Typography variant="body1">
                    <StyledLink as="a" href={t('support.immotech_faq_email')}>
                        {' '}
                        {t('support.immotech_faq')}
                    </StyledLink>
                </Typography>
            </Box>
            <Box mb={6} width="200px">
                <Typography variant="body1">{t('support.address')}</Typography>
            </Box>
            <Divider />
            <Box mt={2}>
                <Button
                    component={Link}
                    to={PATHS.SIGNIN}
                    size="large"
                    variant="text"
                    startIcon={<ArrowLeftIcon />}
                    fullWidth
                    sx={{ paddingBottom: 0, paddingLeft: 0 }}
                >
                    {t('back_to_login')}
                </Button>
            </Box>
        </Box>
    )
}

export default Support
