import { QueryClient } from 'react-query'

export default new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            retry: false,
            refetchIntervalInBackground: false,
            keepPreviousData: false,
        },
    },
})
