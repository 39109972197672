import {
    ApartmentCreateRequest,
    ApartmentData,
    ApartmentList,
    ApartmentParams,
    ApartmentResponseData,
    ApartmentResponseList,
} from 'apartment'
import {
    createBuildAttributeApiData,
    createBuildAttributeData,
    createListDateFormat,
    createRoomsFieldApiData,
    createRoomsFieldData,
} from '.'

export const createApartmentList = (apartments: ApartmentResponseList[]): ApartmentList[] => {
    return apartments.map(({ nid, id, created, changed, parent_id, ...fields }) => ({
        ...fields,
        id: nid,
        optionalID: id,
        parentID: parent_id,
        created: createListDateFormat(Number(created)),
        changed: createListDateFormat(Number(changed)),
    }))
}

export const createApartmentData = ({
    field_build_attributes,
    field_housing_unit_floor,
    field_housing_unit_id,
    field_housing_unit_space,
    field_housing_unit_assign,
    field_housing_unit_rooms,
    title,
    nid,
    created,
    changed,
}: ApartmentResponseData): ApartmentData => {
    return {
        id: nid,
        title,
        floor: field_housing_unit_floor.und[0].value,
        optionalID: field_housing_unit_id.und[0].value,
        space: field_housing_unit_space.und[0].value,
        unitID: field_housing_unit_assign.und[0].target_id,
        attributes: createBuildAttributeData(field_build_attributes?.und),
        rooms: createRoomsFieldData(field_housing_unit_rooms?.und),
        created: createListDateFormat(Number(created)),
        changed: createListDateFormat(Number(changed)),
    }
}
export const createApartmentApiData = ({
    title,
    optionalID,
    floor,
    space,
    unitID,
    rooms,
    attributes,
    id,
}: ApartmentParams): ApartmentCreateRequest => {
    return {
        title,
        field_housing_unit_id: {
            und: [
                {
                    value: optionalID,
                },
            ],
        },
        field_housing_unit_floor: {
            und: [
                {
                    value: floor,
                },
            ],
        },
        field_housing_unit_space: {
            und: [
                {
                    value: Number(space),
                },
            ],
        },
        field_housing_unit_assign: unitID,
        field_housing_unit_rooms: {
            und: createRoomsFieldApiData(rooms),
        },
        field_build_attributes: {
            und: createBuildAttributeApiData(attributes),
        },
    }
}
