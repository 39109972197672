import { MainLanguages } from 'index'
import i18n from '../utils/i18n'
import { useUpdateLang } from './other/useUpdateLang'

export const useLanguage = () => {
    const { mutate: updateLang } = useUpdateLang()

    const changeLang = (lang?: keyof typeof MainLanguages) => {
        if (!lang) return

        i18n.changeLanguage(lang)
        updateLang(lang)
    }

    const changeLangLocally = (lang?: keyof typeof MainLanguages) => {
        if (!lang) return
        i18n.changeLanguage(lang)
    }

    return {
        changeLang,
        changeLangLocally,
        currentLang: i18n.language as keyof typeof MainLanguages,
    }
}
