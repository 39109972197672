import * as yup from 'yup'

const schema = yup.object({
    // username: yup
    //     .string()
    //     .required('form_error.required')
    //     .min(2, 'form_error.field_too_short')
    //     .max(48, 'form_error.field_too_long'),
    mail: yup
        .string()
        .email('form_error.email')
        .required('form_error.required')
        .min(2, 'form_error.field_too_short')
        .max(48, 'form_error.field_too_long'),
    // type: yup.string().required('form_error.required'),
    pass: yup
        .string()
        .required('form_error.required')
        .min(6, 'form_error.field_too_short')
        .max(48, 'form_error.field_too_long'),
    repass: yup
        .string()
        .required('form_error.required')
        .oneOf([yup.ref('pass'), null], 'Passwords are diffrents')
        .max(48, 'form_error.field_too_long'),
    // contractorSector: yup.string().when(['type'], {
    //     is: (type: string) => type === 'contractor',
    //     then: yup.string().required('form_error.required'),
    // }),
    // contractorTrade: yup.string().when(['contractorSector'], {
    //     is: (contractorSector: string) => contractorSector === 'craftsman',
    //     then: yup.string().required('form_error.required'),
    // }),
    company: yup.string().required('form_error.required'),
    companyName: yup.string().required('form_error.required'),
    street: yup.string().required('form_error.required'),
    zipCode: yup.string().required('form_error.required'),
    city: yup.string().required('form_error.required'),
})

export default schema
