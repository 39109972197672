import { UnitRequestData } from 'units'
import axiosInstance from '../axios'

const unitService = {
    getAll: (objectID: string) => {
        return axiosInstance.get(`/utilization_units?property_id=${objectID}`)
    },
    getOne: (id?: string) => {
        return axiosInstance.get(`/utilization_units/${id}`)
    },
    delete: (id?: string) => {
        return axiosInstance.delete(`/utilization_units/${id}`)
    },
    create: (params: UnitRequestData) => {
        return axiosInstance.post(`/utilization_units`, params)
    },
    update: (id?: string, params?: UnitRequestData) => {
        return axiosInstance.put(`/utilization_units/${id}`, params)
    },
}
export default unitService
