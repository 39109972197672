import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useLocationState from '../../../../../../hooks/useLocationState'
import useParamManage from '../../../../../../hooks/useParamManage'
import Dialog from '../../../../../../components/Dialog'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useDeleteApartment } from '../../../../../../hooks/apartment/useDeleteApartment'

const DeleteApartment = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { prevPath } = useLocationState()
    const { apartmentID } = useParamManage()

    const { mutate: deleteApartment, isLoading } = useDeleteApartment(prevPath)

    const handleClose = () => navigate(prevPath)

    return (
        <Dialog
            open={true}
            setOpen={handleClose}
            onAcceptClick={() => deleteApartment(apartmentID)}
            onAcceptText={t('delete')}
            title={t('delete_item.title', { name: 'chosenUnitData?.title' })}
            isLoading={isLoading}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteApartment
