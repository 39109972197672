import { styled, css } from '@mui/system'
import { IconComponentsProps, IconsOrientation } from '.'

export const StyledIcon = styled('div')<
    IconComponentsProps & { rotate?: typeof IconsOrientation[keyof typeof IconsOrientation] }
>`
    ${({ theme, height, color, width, rotate }) => css`
        display: flex;
        justify-content: center;
        align-items: center;

        ${rotate &&
        css`
            transform: rotate(${rotate});
        `}
        svg {
            ${height &&
            css`
                height: ${height}px;
            `}

            ${width &&
            css`
                width: ${width}px;
            `}
        }

        path,
        rect,
        circle {
            ${color &&
            css`
                fill: ${color};
            `}
        }
    `}
`
