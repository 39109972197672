import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import { useCreateMaintenance } from '../../../../../../hooks/maintenance/useCreateMaintenance'
import FormMaintenance from '../../../../../../forms/FormMaintenance'
import useGetMepTypes from '../../../../../../hooks/other/useGetMepTypes'

const CreateMaitenance = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { data: types = [], isLoading: isLoadingTypes } = useGetMepTypes()
    const { mutate: createMaintenance, isLoading: isLoadingCreate } = useCreateMaintenance()

    return (
        <Modal title={t('maintenance.new')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoadingTypes}>
                <FormMaintenance
                    types={types}
                    onCancelClick={goBack}
                    isLoading={isLoadingCreate}
                    onSubmit={createMaintenance}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default CreateMaitenance
