import React, { useState } from 'react'
import { StyledCookieBanner } from './styles'
import { Button, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { StyledLink } from '../../theme/styles'
import { PATHS } from '../../routes/routes'

const COOKIE_KEY = 'accept_cookie'

const CookieBanner = () => {
    const { t } = useTranslation()
    const isCookieAccept: boolean = Cookies.get(COOKIE_KEY) === 'true'
    const [isBannerVisible, setBannerVisible] = useState<boolean>(!isCookieAccept)
    const {
        palette: { grey },
    } = useTheme()

    const handleConfirm = () => {
        Cookies.set(COOKIE_KEY, 'true')
        setBannerVisible(false)
    }

    const handleCancel = () => {
        Cookies.set(COOKIE_KEY, 'false')
        setBannerVisible(false)
    }

    return (
        <StyledCookieBanner show={isBannerVisible}>
            <Typography flex={1}>
                <Trans
                    t={t}
                    i18nKey="cookie_content"
                    components={{
                        1: <StyledLink to={PATHS.PRIVACY_POLICY} />,
                    }}
                />
            </Typography>
            <Button variant="contained" onClick={handleConfirm}>
                {t('cookie_confirm')}
            </Button>
            <Button variant="contained" sx={{ background: grey[700] }} onClick={handleCancel}>
                {t('cookie_decline')}
            </Button>
        </StyledCookieBanner>
    )
}

export default CookieBanner
