import { TaskRequestData } from 'tasks'
import axiosInstance from '../axios'

const taskService = {
    getAll: (parentID?: string) => {
        return axiosInstance.get(`/todo${parentID ? `?parent_id=${parentID}` : ''}`)
    },
    getOne: (id?: string) => {
        return axiosInstance.get(`/todo/${id}`)
    },
    delete: (id?: string) => {
        return axiosInstance.delete(`/todo/${id}`)
    },
    create: (params: Partial<TaskRequestData>) => {
        return axiosInstance.post(`/todo`, params)
    },
    update: (id?: string, params?: Partial<TaskRequestData>) => {
        return axiosInstance.put(`/todo/${id}`, params)
    },
}
export default taskService
