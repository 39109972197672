import { QueryDataKey } from '../../types'
import { useMutation, useQueryClient } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { useSnackbar } from '../useSnackbar'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import apartmentService from '../../api/services/apartment'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const useDeleteApartment = (prevPath: string) => {
    const { t } = useTranslation()
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Apartment)
    const queryClient = useQueryClient()
    const { setErrorMsg, setSuccessMsg } = useSnackbar()
    const navigate = useNavigate()

    return useMutation({
        mutationFn: (id: string) => {
            return apartmentService.delete(id)
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
            setSuccessMsg(t('updated'))
            navigate(prevPath)
        },
        onError: (err: AxiosError) => {
            setErrorMsg(getTextError(err))
        },
    })
}
