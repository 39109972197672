import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { TaskParams } from 'tasks'
import taskService from '../../api/services/task'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import { createTaskApiData } from '../../api/data/task'
import useQueryKeys from '../useQueryKeys'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import useParamManage from '../useParamManage'
import useNavUtils from '../useNavUtils'
export const useCreateTask = () => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Task)
    const queryClient = useQueryClient()
    const { navigateTask, navigateMaintenance } = useNavUtils()
    const { maintenanceID } = useParamManage()

    return useMutation(
        async (data: TaskParams) => taskService.create(await createTaskApiData(data)),
        {
            mutationKey: createKey,
            onSuccess: async () => {
                setSuccessMsg(t('created'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey([maintenanceID]) })

                if (maintenanceID) return navigateMaintenance(maintenanceID)('READ')
                navigateTask()('LIST')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
