import { Marker, useGoogleMap } from '@react-google-maps/api'
import { Coordinates, MapLocation } from '../types'
import { useCallback, useMemo, useRef, useState } from 'react'
import markerImg from '../assets/images/marker3.svg'
import cluster from '../assets/images/cluster.png'
import { ContainerMapLocation } from 'map'
import useSearchStore from '../store/search'
import { Cluster, Clusterer } from '@react-google-maps/marker-clusterer'

const DEFAULT_ZOOM = 11
//Germany overview
const DEFAULT_LOCATION: Coordinates = { lat: 50.5890461, lng: 10.6161191 }

type MapLocationData<T> = T[]
type CustomMapLocation = MapLocationData<MapLocation> | MapLocationData<ContainerMapLocation>

const useGmapUtils = (places?: CustomMapLocation) => {
    const BIG_CLUSTER_ICON_SIZE = {
        height: 70,
        width: 70,
        anchorIcon: [55, 35] as [number, number],
    }
    const INITIAL_CLUSTER_ICON_SIZE = useMemo(
        () => ({
            height: 50,
            width: 50,
            anchorIcon: [44, 25] as [number, number],
        }),
        []
    )

    const BIG_MARKER_ICON_SIZE = new google.maps.Size(60, 69)
    const INITIAL_MARKER_ICON_SIZE = new google.maps.Size(40, 49)

    const [visibleTooltips, setvisibleTooltips] = useState<string[]>([])
    const map = useGoogleMap()
    const { inputValue } = useSearchStore()

    const isResultsVisible = inputValue.length > 2

    const handleShowTooltip = useCallback(
        (fieldValue: string) => {
            if (visibleTooltips.includes(fieldValue)) return
            setvisibleTooltips([fieldValue])
        },
        [visibleTooltips, setvisibleTooltips]
    )

    const handleCloseTooltip = useCallback(() => setvisibleTooltips([]), [setvisibleTooltips])

    const handleMarkerClick = useCallback(
        (fieldValue: string, location?: google.maps.LatLng | Coordinates) => {
            if (!location) return
            handleShowTooltip(fieldValue)
            map?.panTo(location)
        },
        [map, handleShowTooltip]
    )

    const handleClusterClick = useCallback((cluster: any) => {
        const markers = cluster.getMarkers().map((marker: any) => marker.getLabel().text)

        setvisibleTooltips(markers)
    }, [])

    const handleSetView = useCallback(
        (places: CustomMapLocation, zoom?: number) => {
            if (!places?.length) {
                map?.setZoom(6)
                map?.panTo(DEFAULT_LOCATION)
                return
            }

            const bounds = new google.maps.LatLngBounds()

            places.forEach((element) => {
                element.location && bounds.extend(element.location)
            })

            if (places.length === 1) {
                map?.setZoom(zoom || DEFAULT_ZOOM)
                map?.panTo(places[0].location)
                return
            }

            map?.fitBounds(bounds, 100)
            setvisibleTooltips([])
        },
        [map]
    )

    const handleZoomInTooltipClick = (places: MapLocation[]) => {
        if (!map) return
        const locations = places.filter(({ id }) => visibleTooltips.includes(id))
        const zoom = map.getZoom() || DEFAULT_ZOOM

        if (zoom >= DEFAULT_ZOOM - 1) {
            map.setZoom(zoom + 1)
            map.setCenter(locations[0].location)
            return
        } else if (locations.length === 1) {
            map.setZoom(DEFAULT_ZOOM)
            map.setCenter(locations[0].location)
            return
        }
        handleSetView(locations)
    }

    const handleSetLocation = (location: google.maps.LatLng | Coordinates) => {
        if (!map) return
        map.panTo(location)
    }

    const handleZoomOutTooltipClick = (places: MapLocation[]) => handleSetView(places)

    const markerRefs = useRef<Marker[]>([])
    const clusterRefs = useRef<Cluster[]>([])

    const handleGetVisibleMarker = () => markerRefs.current

    const handleSetVisibleMarker = (ref: Marker) => {
        markerRefs.current.push(ref)
    }

    const handleGetVisibleCluster = () => clusterRefs.current
    const handleSetVisibleCluster = (clusters: Cluster[]) => {
        clusterRefs.current = clusters
    }

    const getActiveCluster = (id: string) => {
        const clusters = handleGetVisibleCluster()
        return clusters?.filter((item) =>
            item.markers
                ?.map((marker: any) => {
                    const label = marker.getLabel()?.text
                    return label
                })
                .includes(id)
        )?.[0]
    }

    const getActiveMarker = (id: string) => {
        const markers = handleGetVisibleMarker()
        return markers?.find(
            ({ marker }) => (marker?.getLabel() as google.maps.MarkerLabel).text === id
        )
    }

    const handleClusterIconSize = (id: string, variant: 'zoom-in' | 'initial') => {
        const cluster = getActiveCluster(id)
        if (!cluster) return

        cluster.markerClusterer.styles[0] = {
            ...cluster?.markerClusterer?.styles[0],
            ...(variant === 'zoom-in' ? BIG_CLUSTER_ICON_SIZE : INITIAL_CLUSTER_ICON_SIZE),
        }
        cluster.updateIcon()
        return cluster
    }

    const handleMarkerIconSize = (id: string, variant: 'zoom-in' | 'initial') => {
        const selected = getActiveMarker(id)
        if (!selected) return

        selected?.marker?.setIcon({
            scaledSize: variant === 'zoom-in' ? BIG_MARKER_ICON_SIZE : INITIAL_MARKER_ICON_SIZE,
            url: markerImg,
        })
    }

    const handleResultMouseEnter = (id: string) => {
        handleClusterIconSize(id, 'zoom-in')
        handleMarkerIconSize(id, 'zoom-in')
    }

    const handleResultMouseLeave = (id: string) => {
        handleClusterIconSize(id, 'initial')
        handleMarkerIconSize(id, 'initial')
    }

    const markerConfig = {
        ref: (marker: Marker) => marker && handleSetVisibleMarker(marker),
        icon: {
            url: markerImg,
            scaledSize: INITIAL_MARKER_ICON_SIZE,
        },
    }

    const clusterConfig = useMemo(
        () => ({
            zoomOnClick: false,
            enableRetinaIcons: true,
            onClick: handleClusterClick,
            onClusteringEnd: (clusterer: Clusterer) => {
                handleSetVisibleCluster(clusterer.clusters)
            },
            styles: [
                {
                    ...INITIAL_CLUSTER_ICON_SIZE,
                    url: cluster,
                    textSize: 13,
                    textColor: 'white',
                    textDecoration: 'none',
                    fontStyle: 'normal',
                    fontFamily: 'Fira Sans,sans-serif',
                    backgroundPosition: '0 0',
                },
            ],
        }),
        [handleClusterClick, INITIAL_CLUSTER_ICON_SIZE]
    )

    const infoWindowConfig = useMemo(
        () => ({
            options: {
                maxWidth: 220,
                pixelOffset: {
                    height: -20,
                    width: 0,
                    equals: () => false,
                },
            },
        }),
        []
    )

    return {
        markerRefs,
        markerConfig,
        clusterConfig,
        isResultsVisible,
        infoWindowConfig,
        visibleTooltips,
        handleResultMouseEnter,
        handleResultMouseLeave,
        handleShowTooltip,
        handleCloseTooltip,
        handleZoomInTooltipClick,
        handleZoomOutTooltipClick,
        handleMarkerClick,
        handleSetLocation,
        handleSetView,
    }
}

export default useGmapUtils
