import React from 'react'
import Dialog from '../../../../../../components/Dialog'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useGetObjects } from '../../../../../../hooks/object/useGetObjects'
import { useDeleteObject } from '../../../../../../hooks/object/useDeleteObject'

const DeleteObject = () => {
    const { t } = useTranslation()
    const { objectID } = useParamManage()
    const { goBack } = useNavUtils()
    const { mutate: deleteObject, isLoading: isLoadingDelete } = useDeleteObject(objectID)

    const { data: objects } = useGetObjects(objectID, {
        queryKey: [objectID],
    })
    const object = objects?.find((c) => c?.nid === objectID)

    return (
        <Dialog
            onAcceptClick={deleteObject}
            onAcceptText={t('delete')}
            onCancelClick={goBack}
            title={t('delete_item.title', { name: object?.title })}
            isLoading={isLoadingDelete}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteObject
