import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { ContainerData } from 'containers'
import containerService from '../../api/services/container'
import { createContainerData } from '../../api/data/container'

export const useGetContainer = (
    containerID: string,
    options: UseQueryOptions
): UseQueryResult<ContainerData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Container)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await containerService.getOne(containerID)
            return createContainerData(data)
        },
    }) as UseQueryResult<ContainerData>
}
