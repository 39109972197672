import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Modal from "../../../../components/Modal"
import FormCompany from "../../../../forms/FormCompany"
import { useCreateCompanySettings } from "../../../../hooks/settings/useCreateCompanySettings"
import { useGetCompanySettings } from "../../../../hooks/settings/useGetCompanySettings"
import { CONTAINER_PATHS } from "../../../../routes/routes"
import { QueryDataKey } from "../../../../types"

const CompanySettings = () => {
      const { t } = useTranslation()
      const navigate = useNavigate()


      const { data: companySettings, isLoading } = useGetCompanySettings({
            queryKey: [QueryDataKey.CompanySettings],
      })


      const { mutate: updateCompanySettings, isLoading: isLoadingUpdate } = useCreateCompanySettings()


      const handleClose = () => navigate(CONTAINER_PATHS.OVERVIEW_ALL)

      return (<Modal
            title={t('navigation.company_settings')}
            open={true}
            onClose={handleClose}
      >

            <FormCompany isLoading={isLoadingUpdate} onSubmit={updateCompanySettings} companySettings={companySettings} onCancelClick={handleClose} />
      </Modal>)
}

export default CompanySettings