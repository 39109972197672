import { Box, Button } from '@mui/material'
import React, { ChangeEvent, DragEvent, FC, PropsWithChildren, RefObject, useReducer } from 'react'
import { StyledDnD, StyledHiddenInput } from './styles'
import useDialog from '../../hooks/useDialog'
import { t } from 'i18next'
import Dialog from '../../components/Dialog'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { getUrlExt } from '../../utils/helpers'
import { Trans } from 'react-i18next'
import Tooltip from '../../components/Tooltip'
import Icon, { FileExtList } from '../../components/Icon'
import ReplyIcon from '@mui/icons-material/Reply'

interface FileUploadState {
    isDragOver: boolean
    isTypeError: boolean
    isMultipleError: boolean
    dropData: File[]
}

enum ACTIONS {
    SET_DROP_DATA = 'SET_DROP_DATA',
    SET_MULTIPLE_ERROR = 'SET_MULTIPLE_ERROR',
    SET_IMAGE_ERROR = 'SET_IMAGE_ERROR',
    SET_IS_DRAG_OVER = 'SET_IS_DRAG_OVER',
    SET_STATE = 'SET_STATE',
}

type Action =
    | {
        type: ACTIONS.SET_IS_DRAG_OVER
        payload: Pick<FileUploadState, 'isDragOver'>
    }
    | {
        type: ACTIONS.SET_DROP_DATA
        payload: Pick<FileUploadState, 'dropData'>
    }
    | {
        type: ACTIONS.SET_IMAGE_ERROR
        payload: Pick<FileUploadState, 'isTypeError'>
    }
    | {
        type: ACTIONS.SET_MULTIPLE_ERROR
        payload: Pick<FileUploadState, 'isMultipleError'>
    }
    | {
        type: ACTIONS.SET_STATE
        payload: Partial<FileUploadState>
    }

const reducer = (state: FileUploadState, { type, payload }: Action) => {
    switch (type) {
        case ACTIONS.SET_IS_DRAG_OVER:
            return {
                ...state,
                isDragOver: payload.isDragOver,
            }
        case ACTIONS.SET_DROP_DATA:
            return {
                ...state,
                dropData: payload.dropData,
            }
        case ACTIONS.SET_MULTIPLE_ERROR:
            return {
                ...state,
                isMultipleError: payload.isMultipleError,
            }
        case ACTIONS.SET_IMAGE_ERROR:
            return {
                ...state,
                isTypeError: payload.isTypeError,
            }
        case ACTIONS.SET_STATE:
            return {
                ...state,
                ...payload,
            }

        default:
            console.error('No action found...')
            return state
    }
}

interface Props {
    variant: 'upload' | 'drop'
    isLoading?: boolean
    inputRef?: RefObject<HTMLInputElement>
    uploadPath?: string
    overContainer?: boolean
    isDropAllow?: boolean
    allowMultiple?: boolean
    accepted?: string[]
    onDragEnter?: () => void
    onUpload?: (files: File[], onSettled: () => void) => void
    onDrop?: () => void
}

const FileUpload: FC<PropsWithChildren<Props>> = ({
    variant,
    children,
    inputRef,
    allowMultiple = true,
    isLoading,
    accepted,
    overContainer,
    uploadPath,
    isDropAllow,
    onDragEnter,
    onDrop,
    onUpload,
}) => {
    const [{ isDragOver, dropData, isTypeError, isMultipleError }, dispatch] = useReducer(reducer, {
        isDragOver: false,
        dropData: [],
        isMultipleError: false,
        isTypeError: false,
    })

    const [isOpen, acceptCallback, handleSetDialog, handleToggleDialog, handleCloseDialog] =
        useDialog()

    const handleResetInput = () => {
        inputRef?.current && (inputRef.current.value = '')
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { files },
        } = e
        e.preventDefault()
        if (!files) return
        const isValid = isValidUploadBtnFile(files)
        if (!isValid) return handleSetTypeError(true)
        const filesdata = Array.from(files) as File[]
        onUpload &&
            onUpload(filesdata, () => {
                handleResetInput()
                handleCloseDialog()
            })
    }

    const handleSetTypeError = (isErr?: boolean) => {
        dispatch({
            type: ACTIONS.SET_IMAGE_ERROR,
            payload: { isTypeError: isErr || false },
        })
    }

    const handleResetTypeError = () => {
        dispatch({
            type: ACTIONS.SET_IMAGE_ERROR,
            payload: { isTypeError: false },
        })
    }

    const handleSetMultipleErr = (isErr: boolean) => {
        dispatch({
            type: ACTIONS.SET_MULTIPLE_ERROR,
            payload: { isMultipleError: isErr },
        })
    }

    const handleResetMultipleError = () => {
        dispatch({
            type: ACTIONS.SET_MULTIPLE_ERROR,
            payload: { isMultipleError: false },
        })
    }

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        onDragEnter && onDragEnter()
        if (!isDropAllow) return
        dispatch({
            type: ACTIONS.SET_IS_DRAG_OVER,
            payload: { isDragOver: true },
        })
    }

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) return
        dispatch({
            type: ACTIONS.SET_IS_DRAG_OVER,
            payload: { isDragOver: false },
        })
    }

    const handleDrop = (e: DragEvent<HTMLInputElement>) => {
        e.preventDefault()
        dispatch({
            type: ACTIONS.SET_STATE,
            payload: { isDragOver: false },
        })

        if (variant === 'drop' && onDrop) return onDrop()
        if (!allowMultiple && e.dataTransfer.files.length > 1) return handleSetMultipleErr(true)
        if (accepted && !isValidDragFile(e)) return handleSetTypeError(true)

        return handleUpload(e)
    }

    const isValidDragFile = ({ dataTransfer: { items } }: DragEvent<HTMLInputElement>): boolean => {
        let result: boolean[] = []
        for (let i = 0; i < items.length; i++) {
            const file = items[i].getAsFile()
            if (!file) continue
            const ext: FileExtList = getUrlExt(file.name)
            const isValid = !!accepted?.includes(ext)
            result.push(isValid)
        }

        return !!result.filter((v) => !!v)?.length
    }

    const isValidUploadBtnFile = (files: FileList): boolean => {
        let result: boolean[] = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (!file) continue
            const ext: FileExtList = getUrlExt(file.name)
            const isValid = !!accepted?.includes(ext)
            result.push(isValid)
        }

        return !!!result.filter((v) => v === false)?.length
    }

    const handleUpload = (e: DragEvent<HTMLInputElement>) => {
        const {
            dataTransfer: { items: files },
        } = e

        const filesData = createFilesData(files)
        dispatch({
            type: ACTIONS.SET_STATE,
            payload: { isDragOver: false, dropData: filesData },
        })

        const onAccept = () => {
            onUpload && onUpload(filesData, handleCloseDialog)
        }
        handleSetDialog(true, onAccept)
    }

    const createFilesData = (data: DataTransferItemList): File[] => {
        let files: File[] = []
        for (let i = 0; i < data.length; i++) {
            const file = data[i].getAsFile()
            if (!file) continue
            files.push(file)
        }
        return files
    }

    const title = (() => {
        if (!uploadPath) return t('file.upload_dialog')

        return (
            <Trans i18nKey={'file.upload_dialog_path'} path={uploadPath}>
                {/* @ts-ignore */}
                <strong>{{ path: uploadPath }}</strong>
            </Trans>
        )
    })()

    const filesData = dropData.map(({ name }) => ({
        name: name,
        icon: getUrlExt(name),
    }))

    const handleDragOver = (e: DragEvent) => e.preventDefault()
    const handleNotAllowedDrop = (e: DragEvent) => e.preventDefault()

    return (
        <Box>
            <StyledDnD
                overContainer={overContainer}
                isDragOver={Boolean(isDragOver && isDropAllow)}
                onDragLeave={handleDragLeave}
                onDragEnter={handleDragEnter}
                onDrop={isDropAllow ? handleDrop : handleNotAllowedDrop}
                onDragOver={handleDragOver}
            >
                <Tooltip
                    title={t('file.can_drop')}
                    variant="dnd"
                    open={isDragOver && isDropAllow}
                    offsetY={overContainer ? -26 : -19}
                >
                    {children}
                </Tooltip>
            </StyledDnD>

            <Box>
                <Dialog
                    open={isOpen}
                    setOpen={handleToggleDialog}
                    onAcceptClick={acceptCallback}
                    title={title}
                    isLoading={isLoading}
                    icon={<CloudUploadIcon />}
                >
                    <Box maxWidth="300px">
                        {filesData.map(({ name, icon }, i) => (
                            <Box key={i} display="flex" alignItems={'center'} gap={2}>
                                <Box display={'flex'}>
                                    <Icon variant="file" icon={icon} height={20} />
                                </Box>
                                {name}
                            </Box>
                        ))}
                    </Box>
                </Dialog>

                <Dialog
                    open={isTypeError}
                    setOpen={handleSetTypeError}
                    title={t('format_error')}
                    renderButtons={
                        <Button
                            startIcon={<ReplyIcon />}
                            onClick={handleResetTypeError}
                            size="large"
                            variant="contained"
                        >
                            {t('go_back')}
                        </Button>
                    }
                >
                    {accepted?.join(', ')}
                </Dialog>

                <Dialog
                    open={isMultipleError}
                    setOpen={handleSetMultipleErr}
                    title={t('multiple_error')}
                    renderButtons={
                        <Button
                            startIcon={<ReplyIcon />}
                            onClick={handleResetMultipleError}
                            size="large"
                            variant="contained"
                        >
                            {t('go_back')}
                        </Button>
                    }
                />
            </Box>

            <StyledHiddenInput type="file" onChange={handleInputChange} multiple ref={inputRef} />
        </Box>
    )
}

export default FileUpload
