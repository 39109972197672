import React from 'react'
import Dialog from '../../../../../../components/Dialog'
import { useDeleteContainer } from '../../../../../../hooks/container/useDeleteContainer'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useGetContainers } from '../../../../../../hooks/container/useGetContainers'
import useNavUtils from '../../../../../../hooks/useNavUtils'

const DeleteContainer = () => {
    const { t } = useTranslation()
    const { containerID } = useParamManage()
    const { goBack } = useNavUtils()
    const { data: containers } = useGetContainers()
    const { mutate: deleteContainer, isLoading: isLoadingDelete } = useDeleteContainer(containerID)
    const container = containers?.find((c) => c?.nid === containerID)

    return (
        <Dialog
            onAcceptClick={deleteContainer}
            onAcceptText={t('delete')}
            onCancelClick={goBack}
            title={t('delete_item.title', { name: container?.title })}
            isLoading={isLoadingDelete}
            icon={<DeleteForeverIcon />}
            btnColor={'error'}
        >
            {t('delete_item.content')}
        </Dialog>
    )
}

export default DeleteContainer
