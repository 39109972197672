import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import fileService from '../../api/services/file'
import { createGroupList } from '../../api/data/files'
import { FileGroupData } from 'files'

export const useGetFileGroup = (
    entityID: string,
    options: UseQueryOptions
): UseQueryResult<FileGroupData[]> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.FileGroup)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await fileService.getGroups(entityID)
            return createGroupList(data)
        },
    }) as UseQueryResult<FileGroupData[]>
}
