import {
    CompanySettingsData,
    CompanySettingsParams,
    CompanySettingsRequestData,
} from '../../types/company'
import { getBase64 } from '../../utils/helpers'

export const createCompanySettingsApiData = async (
    data: CompanySettingsParams
): Promise<CompanySettingsRequestData> => {
    const transformedData = {
        primary_bg: data.primary_bg,
        secondary_bg: data.secondary_bg,
        btn_bg: data.btn_bg,
        btn_color: data.btn_color,
        primary_font_color: data.primary_font_color,
        secondary_font_color: data.secondary_font_color,
        ...(data?.logo?.src.includes('blob:') && {
            logo: (await getBase64(data.logo.src)) as string,
        }),
        ...(data?.letterhead_primary?.src.includes('blob:') && {
            letterhead_primary: (await getBase64(data.letterhead_primary.src)) as string,
        }),
        letterhead_primary_offset_x: data.letterhead_primary_offset_x,
        letterhead_primary_offset_y: data.letterhead_primary_offset_y,
        letterhead_primary_box_width: data.letterhead_primary_box_width,
        letterhead_primary_box_height: data.letterhead_primary_box_height,
        ...(data?.letterhead_secondary?.src.includes('blob:') && {
            letterhead_secondary: (await getBase64(data.letterhead_secondary.src)) as string,
        }),
        letterhead_secondary_offset_x: data.letterhead_secondary_offset_x,
        letterhead_secondary_offset_y: data.letterhead_secondary_offset_y,
        letterhead_secondary_box_width: data.letterhead_secondary_box_width,
        letterhead_secondary_box_height: data.letterhead_secondary_box_height,
    }

    return transformedData
}

export const createCompanySettingsData = (data: CompanySettingsData): CompanySettingsParams => {
    return {
        primary_bg: data.primary_bg || '',
        secondary_bg: data.secondary_bg || '',
        btn_bg: data.btn_bg || '',
        btn_color: data.btn_color || '',
        primary_font_color: data.primary_font_color || '',
        secondary_font_color: data.secondary_font_color || '',
        logo: data.logo
            ? {
                  src: data.logo.full_url,
                  filename: data.logo.filename,
              }
            : undefined,
        letterhead_primary: data.letterhead_primary
            ? {
                  src: data.letterhead_primary.full_url,
                  filename: data.letterhead_primary.filename,
              }
            : undefined,
        letterhead_primary_offset_x: parseInt(data.letterhead_primary_offset_x || '0'),
        letterhead_primary_offset_y: parseInt(data.letterhead_primary_offset_y || '0'),
        letterhead_primary_box_width: parseInt(data.letterhead_primary_box_width || '0'),
        letterhead_primary_box_height: parseInt(data.letterhead_primary_box_height || '0'),
        letterhead_secondary: data.letterhead_secondary
            ? {
                  src: data.letterhead_secondary.full_url,
                  filename: data.letterhead_secondary.filename,
              }
            : undefined,
        letterhead_secondary_offset_x: parseInt(data.letterhead_secondary_offset_x || '0'),
        letterhead_secondary_offset_y: parseInt(data.letterhead_secondary_offset_y || '0'),
        letterhead_secondary_box_width: parseInt(data.letterhead_secondary_box_width || '0'),
        letterhead_secondary_box_height: parseInt(data.letterhead_secondary_box_height || '0'),
    }
}
