import * as yup from 'yup'

const attrSchema = {
    id: yup.string().required('form_error.required'),
    value: yup.string().required('form_error.required'),
}

const schema = yup.object({
    title: yup.string().required('form_error.required'),
    floor: yup.string().required('form_error.required'),
    space: yup.string().required('form_error.required'),
    optionalID: yup.string(),
    attributes: yup.array().of(yup.object().shape(attrSchema)),
    rooms: yup.array(),
})

export default schema
