import TaskListData from '../../../../modules/TaskListData'
import theme from '../../../../theme'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { t } from 'i18next'
import useSearchStore from '../../../../store/search'
import Search from '../../../../components/Search'
import { useGetTasks } from '../../../../hooks/task/useGetTasks'
import useParamManage from '../../../../hooks/useParamManage'
import useNavUtils from '../../../../hooks/useNavUtils'

const Task = () => {
    const { inputValue, setInputValue } = useSearchStore()
    const { navigateTask } = useNavUtils()
    const { lastParamID } = useParamManage()
    const {
        palette: {
            secondary: { main },
        },
    } = useTheme()

    const { data: tasks, isLoading } = useGetTasks(lastParamID, {
        queryKey: [lastParamID],
        refetchOnMount: false,
    })

    const renderOnBottom = (renderPagination: () => JSX.Element | null) => (
        <Box mt={4}>{renderPagination()}</Box>
    )

    const handleCreateTask = () => navigateTask()('CREATE')

    return (
        <>
            <Box display={'flex'} justifyContent="space-between" alignItems={'center'} mb={3}>
                <Typography color={main} fontWeight={600}>
                    {t('task.tasks')}
                </Typography>
                <Box display="flex" gap={theme.spacing(3)}>
                    <Search
                        placeholder={t(`search`)}
                        value={inputValue}
                        onInputChange={setInputValue}
                    />
                    {lastParamID && (
                        <Button
                            startIcon={<DriveFileRenameOutlineIcon />}
                            size="large"
                            variant="contained"
                            onClick={handleCreateTask}
                        >
                            {t('task.new_task')}
                        </Button>
                    )}
                </Box>
            </Box>

            <TaskListData
                tasks={tasks || []}
                renderOnBottom={renderOnBottom}
                isLoading={isLoading}
            />
        </>
    )
}

export default Task
