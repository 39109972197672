import { MainDataTypes } from '../../types'
import {
    UnitRequestData,
    UnitResponseList,
    UnitResponseData,
    UnitData,
    UnitParams,
    UnitList,
} from 'units'
import {
    createBuildAttributeApiData,
    createBuildAttributeData,
    createListDateFormat,
    createModelData,
} from '.'

export const createUnitApiData = ({
    title,
    idOptional,
    objectID,
    street,
    city,
    zipCode,
    type,
    attributes,
}: UnitParams): UnitRequestData => {
    return {
        title: title,
        field_utilization_unit_id: {
            und: [
                {
                    value: idOptional,
                },
            ],
        },
        field_utilization_unit_assign: objectID,
        field_utilization_unit_address: {
            und: [
                {
                    thoroughfare: street,
                    postal_code: zipCode,
                    locality: city,
                },
            ],
        },
        field_utilization_unit_typ: {
            und: [type] as string[],
        },
        field_build_attributes: {
            und: createBuildAttributeApiData(attributes ?? []),
        },
    }
}

export const createUnitsList = (data?: UnitResponseList[]): UnitList[] => {
    if (!data) return []

    return data.map(
        ({
            changed,
            city,
            created,
            id,
            lat,
            lon,
            nid,
            parent_id,
            street,
            type,
            title,
            zip_code,
        }) => {
            return {
                changed: createListDateFormat(Number(changed)),
                created: createListDateFormat(Number(created)),
                city,
                id: nid,
                idOptional: id,
                lat: Number(lat),
                lng: Number(lon),
                nid,
                type,
                parentID: parent_id,
                street,
                title,
                zipCode: zip_code,
                dataType: MainDataTypes.Unit,
            }
        }
    )
}

export const createUnitData = ({
    changed,
    field_utilization_unit_address,
    field_utilization_unit_id,
    created,
    nid,
    title,
    field_utilization_unit_geofield,
    field_build_attributes,
    field_utilization_unit_typ,
    field_utilization_unit_assign,
    field_3d_models,
}: Partial<UnitResponseData>): UnitData => {
    return {
        changed: createListDateFormat(Number(changed)),
        created: createListDateFormat(Number(created)),
        city: field_utilization_unit_address?.und?.[0]?.locality || '',
        idOptional: field_utilization_unit_id?.und?.[0]?.value || '',
        lat: Number(field_utilization_unit_geofield?.und?.[0]?.lat || 0),
        lng: Number(field_utilization_unit_geofield?.und?.[0]?.lon || 0),
        nid: nid || '',
        objectID: field_utilization_unit_assign?.und?.[0]?.target_id || '',
        id: nid || '',
        street: field_utilization_unit_address?.und?.[0]?.thoroughfare || '',
        title: title || '',
        zipCode: field_utilization_unit_address?.und?.[0]?.postal_code || '',
        models: field_3d_models?.und?.map((item) => createModelData(item)) || [],
        attributes: createBuildAttributeData(field_build_attributes?.und),
        type: field_utilization_unit_typ?.und?.[0]?.tid || '',
        dataType: MainDataTypes.Unit,
    }
}

export const createUnitParams = ({
    title,
    city,
    idOptional,
    street,
    zipCode,
    objectID,
    type,
    attributes,
}: Partial<UnitData>): UnitParams => {
    return {
        title: title || '',
        idOptional: idOptional || '',
        objectID: objectID || '',
        street: street || '',
        city: city || '',
        zipCode: zipCode || '',
        type: type || '',
        attributes: attributes || [],
        location: undefined,
    }
}
