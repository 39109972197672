import { Box } from '@mui/system'
import DataItem from '../../../../../../components/DataItem'
import Loader from '../../../../../../components/Loader'
import Map from '../../../../../../modules/Map'
import { MapTooltipItem } from '../../../../../../modules/Map/components/MapTooltip'
import { useGetContainer } from '../../../../../../hooks/container/useGetContainer'
import { StyledGeneralInfo } from '../../../../../../theme/styles'
import MapBoundaries from '../../../../../../modules/Map/components/MapBoundaries'
import DataGroup from '../../../../../../components/DataGroup'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'

const GeneralInfoContainer = () => {
    const { t } = useTranslation()
    const { containerID } = useParamManage()

    const { data: container, isLoading } = useGetContainer(containerID, {
        queryKey: [containerID],
        enabled: Boolean(containerID),
    })

    const handleCreateTooltip = () => <MapTooltipItem data={container} />

    if (!containerID || isLoading) return <Loader absoluteCenter />

    return (
        <StyledGeneralInfo>
            <Box flexBasis={'50%'}>
                <DataGroup label={t('general_information')}>
                    <DataItem variant={'data'} label={t('container.container_name')}>
                        {container?.title}
                    </DataItem>
                    <DataItem variant={'data'} label={t('zip_code')}>
                        {container?.zipCode}
                    </DataItem>
                    <DataItem variant={'data'} label={t('city')}>
                        {container?.city}
                    </DataItem>
                    <DataItem variant={'data'} label={'ID'}>
                        {container?.id}
                    </DataItem>
                </DataGroup>
            </Box>
            <Box flexBasis={'50%'}>
                {container && (
                    <DataGroup label={t('map')} collapsable innerPadding>
                        <Map
                            height={600}
                            renderMap={({ createBoundarieLocation }) => (
                                <MapBoundaries
                                    data={createBoundarieLocation(container)}
                                    renderTooltip={handleCreateTooltip}
                                />
                            )}
                        />
                    </DataGroup>
                )}
            </Box>
        </StyledGeneralInfo>
    )
}

export default GeneralInfoContainer
