import { Modal } from '@mui/material'
import { styled, css } from '@mui/system'
import { Swiper } from 'swiper/react'

export const StyledLightboxPagination = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.spacing(2)};
    `}
`
export const StyledModal = styled(Modal)`
    ${({ theme }) => css`
        margin: ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        border-radius: ${theme.spacing(2)};
        overflow: hidden;
        justify-content: center;
    `}
`
export const StyledMainSwiper = styled(Swiper)`
    ${({ theme }) => css`
        height: 80%;
        width: 100%;
        margin-bottom: ${theme.spacing(2)};
        padding: 85px ${theme.spacing(2)} 0 ${theme.spacing(2)};

        .swiper-slide {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    `}
`

export const StyledThumbSwiper = styled(Swiper)`
    ${({ theme }) => css`
        position: relative;
        width: calc(100% - 20px);
        margin: 0 auto;
        height: 17%;
        padding-bottom: ${theme.spacing(2)};

        .swiper-slide {
            cursor: pointer;
            border: 4px solid transparent;
            border-radius: ${theme.spacing(2)};

            &.swiper-slide-thumb-active {
                border: 4px solid ${theme.palette.secondary.main};
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    `}
`
