import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { MaintenanceData } from 'maintenance'
import { createMaintenanceData, createMaintenanceParams } from '../../api/data/maintenance'
import maintenanceService from '../../api/services/maintenance'

export const useGetMaintenance = (
    maintenanceID: string,
    options: UseQueryOptions
): UseQueryResult<MaintenanceData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Maintenance)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await maintenanceService.getOne(maintenanceID)
            return createMaintenanceParams(createMaintenanceData(data))
        },
    }) as UseQueryResult<MaintenanceData>
}
