import { IconButton } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledRow = styled('div')<{ pointer?: boolean }>`
    ${({ theme, pointer }) => css`
        position: relative;
        background: ${theme.palette.common.white};
        ${pointer &&
        css`
            cursor: pointer;
        `}

        .td:nth-of-type(1) {
            background: ${theme.palette.common.white};
        }
        .td {
            transition: 0.15s;
            border-top: 1px solid ${theme.palette.grey[200]};
            background: ${theme.palette.common.white};
        }
        &:nth-of-type(2n) {
            background: ${theme.palette.grey[50]};
            .td {
                background: ${theme.palette.grey[50]};
            }
            .td:nth-of-type(1) {
                background: ${theme.palette.grey[50]};
            }
            .td:nth-last-of-type(1) {
                background: ${theme.palette.grey[50]};
            }
        }
        &:hover {
            .icon-wrapper {
                opacity: 1;
                visibility: visible;
            }
            && .td {
                background: ${theme.palette.info.light};
            }
        }
    `}
`
export const StyledHeadRow = styled('div')`
    ${({ theme }) => css`
        position: relative;
        max-height: 65px;

        .td:nth-of-type(1) {
            border-right: 1px solid ${theme.palette.grey[200]};
            border-radius: 10px 0 0 0;
        }
        .td:nth-of-type(2) {
            border-left: 0;
        }
        .td {
            border-left: 1px solid ${theme.palette.grey[200]};
            background: ${theme.palette.grey[100]};
            user-select: none;
        }
        .td:nth-last-of-type(1) {
            background: ${theme.palette.grey[100]};
            border-right: 1px solid ${theme.palette.grey[200]};
            border-radius: 0 10px 0 0;
        }
    `}
`

export const StyledWrapper = styled('div')`
    ${({ theme }) => css`
        margin-top: auto;
        border-top: 1px solid ${theme.palette.grey[200]};
        border-radius: 10px 10px 0 0;
        width: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    `}
`

export const StyledRowWrapper = styled('div')`
    ${() => css``}
`
export const StyledCol = styled('div')<{ pointer?: boolean }>`
    ${({ theme, pointer }) => css`
        ${pointer &&
        css`
            cursor: pointer;
        `}
        padding: ${theme.spacing(4)} ${theme.spacing(2)};
        display: flex;
        align-items: center;
        //first col
        &:nth-of-type(1) {
            padding: 0 ${theme.spacing(2)};
            position: sticky !important;
            left: 0;
            top: 0;
            flex: 100%;
            z-index: 2;
            text-align: center;
            color: ${theme.palette.primary.main};
            max-width: 70px;
            display: flex;
            justify-content: center;
        }
        //last-col
        &:nth-last-of-type(1) {
            position: sticky !important;
            background: ${theme.palette.common.white};
            right: 0;
            top: 0;
            padding: 0;
            max-width: 115px;
        }
    `}
`

export const StyledRowIcon = styled(IconButton)`
    ${() => css``}
`

export const StyledTableBottom = styled('div')<{
    isFixed?: boolean
    fullWidth: boolean
}>`
    ${({ theme, isFixed, fullWidth }) =>
        isFixed
            ? css`
                  position: fixed;
                  width: 100%;
                  bottom: 5px;
                  right: 30px;
                  background: ${theme.palette.common.white};
                  display: flex;
                  justify-content: center;
                  max-width: unset;
                  z-index: 9;
                  border-radius: ${theme.spacing(2)};
                  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
                  overflow: hidden;
                  width: ${fullWidth ? '100%' : 'auto'};
              `
            : css`
                  display: flex;
                  justify-content: flex-end;
              `}
`
export const StyledRowIconWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        column-gap: ${theme.spacing(2)};
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;

        position: absolute;
        right: ${theme.spacing(2)};
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            opacity: 1;
            visibility: visible;
        }
    `}
`
