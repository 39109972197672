import Box from '@mui/material/Box'
import { StyledModalWrapper, StyledWrapper } from './styles'
import theme from '../../theme/index'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import { default as MaterialModal } from '@mui/material/Modal'
import useDialog from '../../hooks/useDialog'
import Dialog from '../Dialog'
import { t } from 'i18next'
import { StyledCloseModalBar } from '../../theme/styles'
import { FC, PropsWithChildren } from 'react'

interface Props {
    open?: boolean
    title: string
    loading?: boolean
    headerElement?: JSX.Element | boolean
    onClose: () => void
    createFooterElement?: (onCancelClick: () => void) => JSX.Element
}

const Modal: FC<PropsWithChildren<Props>> = ({
    children,
    open,
    onClose,
    title,
    loading,
    createFooterElement,
    headerElement,
}) => {
    const [isOpen, , , handleToggleDialog, handleCloseDialog] = useDialog()
    const handleCloseModal = () => onClose()

    return (
        <>
            <MaterialModal
                open={open === undefined ? true : open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <StyledModalWrapper>
                    <StyledCloseModalBar>
                        <Box>
                            <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
                        </Box>
                        <Box display="flex" gap={theme.spacing(2)}>
                            {headerElement && headerElement}
                            <IconButton onClick={() => handleCloseModal()} size="large">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </StyledCloseModalBar>
                    <StyledWrapper>
                        {children}
                        {createFooterElement && createFooterElement(handleCloseModal)}
                    </StyledWrapper>
                </StyledModalWrapper>
            </MaterialModal>

            <Dialog
                open={!!isOpen}
                setOpen={handleToggleDialog}
                onAcceptClick={() => {
                    onClose()
                    handleCloseDialog()
                }}
                onAcceptText={t('yes')}
                onCancelClick={handleCloseDialog}
                onCancelText={t('no')}
                btnColor={'error'}
                title={t('cancel_agreement.title')}
                isLoading={loading}
            >
                {t('cancel_agreement.content')}
            </Dialog>
        </>
    )
}

export default Modal
