import { QueryDataKey } from '../../types'
import { useMutation, useQueryClient } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import fileService from '../../api/services/file'
import { useSnackbar } from '../useSnackbar'
import { createFormData, getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'

export const usePostFiles = (entityID: string) => {
    const { transferKey, getQueryAllKey } = useQueryKeys(QueryDataKey.File)
    const queryClient = useQueryClient()
    const { setErrorMsg, setSuccessMsg } = useSnackbar()

    return useMutation({
        mutationFn: ({ groupID, files }: { groupID: string; files: File[] }) => {
            return fileService.uploadFile(entityID, groupID, createFormData(files))
        },
        mutationKey: transferKey,
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
            setSuccessMsg(res?.data?.[0])
        },
        onError: (err: AxiosError) => {
            setErrorMsg(getTextError(err))
        },
    })
}
