import * as yup from 'yup'

const schema = yup.object({
    title: yup.string(),
    description: yup.string(),
    shortDescription: yup.string(),
    note: yup.string(),
    cost: yup.string().required('form_error.required'),
    priority: yup.string().required('form_error.required'),
    todoAssignment: yup.string().required('form_error.required'),
    status: yup.string().required('form_error.required'),
    images: yup.array(),
})

export default schema
