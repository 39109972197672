import { styled, css } from '@mui/system'

export const StyledHighlighted = styled('span')`
    ${({ theme }) => css`
        color: inherit!important;

        span {
                background: ${theme.palette.primary.main};
                padding: ${theme.spacing(1)} 0;
                color: ${theme.palette.common.white}!important;
                border-radius: 5px;
            }
        }
    `}
`
