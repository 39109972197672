import { createTheme } from '@mui/material/styles'

//components
import MuiAlert from './MuiAlert'
import MuiButton from './MuiButton'
import MuiCssBaseline from './MuiCssBaseline'
import MuiFormControl from './MuiFormControl'
import MuiTypography from './MuiTypography'
import MuiIconButton from './MuiIconButton'
import MuiMenu from './MuiMenu'
import MuiPopover from './MuiPopover'
import MuiSwitch from './MuiSwitch'
import MuiMenuItem from './MuiMenuItem'

import { Theme } from '@mui/material'

const customTheme: Theme = createTheme({
    spacing: 5,
    typography: {
        fontFamily: 'Fira Sans, sans-serif',
        fontSize: 12.25,
        h1: {
            fontSize: '2rem',
            color: '#232F5C',
        },
        h2: {
            fontSize: '1.6rem',
            color: '#232F5C',
        },
        h3: {
            fontSize: '1.2rem',
            color: '#232F5C',
        },
        h4: {
            fontSize: '1rem',
            color: '#232F5C',
        },
        h5: {
            fontSize: '0.9rem',
            color: '#232F5C',
        },
        h6: {
            fontSize: '0.8rem',
            color: '#232F5C',
        },
    },
    palette: {
        primary: {
            light: 'rgba(22, 157, 227, 0.08)',
            main: '#6D4EE7',
        },
        grey: {
            '50': '#fbfbfb',
            '100': '#F8F8FA',
            '200': '#E8E8E8',
            '300': '#D0D0D0',
            '700': '#707070',
            '800': '#808080',
        },
        neutral: {
            main: '#2D2F31',
            contrastText: '#fff',
        },
        secondary: {
            main: '#3931FF',
            light: '#4F9CFF',
        },
        darker: {
            main: '#141515',
            light: '#2C3140',
        },
        info: {
            main: '#0288d1',
            light: '#f5fafe',
        },
    },
    shape: {
        borderRadius: 5,
        boxBorderRadius: '10px',
    },
    //components overrides
    components: {
        MuiAlert,
        MuiButton,
        MuiCssBaseline,
        MuiFormControl,
        MuiTypography,
        MuiPopover,
        MuiIconButton,
        MuiMenu,
        MuiMenuItem,
        MuiSwitch,
    },
})

customTheme.shadows[1] = '0px 0px 5px rgba(0, 0, 0, 0.1)'

export default customTheme
