import { ContainerRequestData } from 'containers'
import axiosInstance from '../axios'

const containerService = {
    getAll: () => {
        return axiosInstance.get(`/accounting_entity`)
    },
    getOne: (nid?: string) => {
        return axiosInstance.get(`/accounting_entity/${nid}`)
    },
    getOneExtended: (nid?: string) => {
        return axiosInstance.get(`/property?accounting_entity_id=${nid}`)
    },
    create: (params: ContainerRequestData) => {
        return axiosInstance.post(`/accounting_entity`, params)
    },
    update: (nid?: string, params?: ContainerRequestData) => {
        return axiosInstance.put(`/accounting_entity/${nid}`, params)
    },
    delete: (nid?: string) => {
        return axiosInstance.delete(`/accounting_entity/${nid}`)
    },
}
export default containerService
