import React from 'react'
import FormSignUp from '../../../forms/FormSignUp'
import { useSignUp } from '../../../hooks/auth/useSignUp'
import { useGetContractorTrades } from '../../../hooks/other/useGetContractorTrades'

const SignUp = () => {
    const { data: trades } = useGetContractorTrades({})
    const { mutate: signUp, isLoading } = useSignUp()
    return <FormSignUp onSubmit={signUp} isLoading={isLoading} trades={trades} />
}

export default SignUp
