import { useLocation } from 'react-router-dom'
import { RouterState } from 'router'

const useLocationState = () => {
    const defaultState = {
        prevPath: '',
    }

    return (useLocation()?.state || defaultState) as RouterState
}

export default useLocationState
