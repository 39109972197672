import { ViewType } from 'constant'
import create from 'zustand'

interface ViewStore {
    viewType: 'grid' | 'list' | 'map'
    setViewType: (type: ViewType) => void
}

export const useView = create<ViewStore>((set) => ({
    viewType: 'list',
    setViewType: (type: ViewType) => set(() => ({ viewType: type })),
}))

export default useView
