import { styled, css } from '@mui/system'

export const StyledBottomBar = styled('div')`
    height: 70px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        pointer-events: none;
        bottom: 0;
        width: 100vw;
        height: 100%;
        box-shadow: 0px 2px 12px 0px rgba(117, 117, 117, 0.12);
    }
`

export const StyledContainerName = styled('div')`
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
`

export const StyledNumberOfItems = styled('div')``

export const StyledBadge = styled('div')`
    ${({ theme }) => css`
        margin: 0 0 0 ${theme.spacing(1)};
    `}
`

export const StyledNav = styled('nav')`
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 30px;
`
