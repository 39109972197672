import { Box, debounce, Grid, TextField, Typography } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import parse from 'autosuggest-highlight/parse'
import { useEffectOnce } from 'usehooks-ts'
import { SxProps } from '@mui/system'
import { StyledFormItem } from '../../../theme/styles'
import { LocationField } from 'index'
import DataItem from '../../../components/DataItem'
import { useGmap } from '../../../context/gmap'

interface Props {
    locationOptions: {
        input: string
    }
    children?: (noBorder?: boolean) => JSX.Element | JSX.Element[]
    inputStyles?: SxProps
    label?: string
    noBorder?: boolean
    noBorderAutocomplete?: boolean
    noMargin?: boolean
    placeholder?: string
    onLocationChange?: () => void
    types?: string[]
}

export enum LocationInputs {
    Street = 'street',
    City = 'city',
    ZipCode = 'zipCode',
}

interface AutocompleteOptions extends google.maps.places.AutocompleteOptions {}
interface GeocoderAddressComponent extends google.maps.GeocoderAddressComponent {}
interface AutocompletePrediction extends google.maps.places.AutocompletePrediction {}
interface PlaceResult extends google.maps.places.PlaceResult {}

const LocationFields = ({
    locationOptions,
    onLocationChange,
    children,
    inputStyles,
    label,
    noBorder,
    noBorderAutocomplete,
    placeholder,
    types = [],
}: Props) => {
    const [locations, setLocations] = useState<AutocompleteOptions[] | []>([])
    const { control, setValue, getValues } = useFormContext()
    const { isGmapLoaded } = useGmap()

    const autocompleteService = useMemo(() => {
        if (!isGmapLoaded) return
        return new google.maps.places.AutocompleteService()
    }, [isGmapLoaded])

    const placeDetails = useMemo(() => {
        if (!isGmapLoaded) return
        return new google.maps.places.PlacesService(document.createElement('div'))
    }, [isGmapLoaded])

    // eslint-disable-next-line
    const handleInputChange = useCallback(
        debounce((query: string) => {
            const location = getValues('location')

            const options = {
                input: query,
                componentRestrictions: {
                    country: 'de',
                },
                fields: ['geometry'],
                types: [...types],
            }

            autocompleteService?.getPlacePredictions(
                options,
                (data: AutocompletePrediction[] | null) => {
                    let newOptions = [] as AutocompletePrediction[]

                    if (location) {
                        newOptions = [location]
                    }

                    if (data) {
                        newOptions = [...newOptions, ...data]
                    }

                    setLocations(newOptions)
                }
            )
        }, 500),
        [autocompleteService, setLocations, getValues]
    )

    const handleAutocompleteChange = (item: AutocompletePrediction) => {
        if (!item) setLocations([])
        onLocationChange && onLocationChange()
        setFields([item])
    }

    const setFields = useCallback(
        (data: AutocompletePrediction[] | null) => {
            const currentPlace = data?.[0]
            if (!currentPlace) return

            placeDetails?.getDetails(
                {
                    placeId: currentPlace.place_id,
                    fields: ['address_component', 'geometry', 'place_id'],
                },
                (place: PlaceResult | null) => {
                    const location = getValues('location')

                    const fieldOptions = {
                        shouldDirty: Boolean(location),
                    }

                    const address = place?.address_components as GeocoderAddressComponent[]
                    const { street, streetNumber, city, zipCode } = {
                        street:
                            address.find(({ types }: { types: string[] }) =>
                                types.includes('route')
                            )?.long_name || '',
                        streetNumber:
                            address.find(({ types }: { types: string[] }) =>
                                types.includes('street_number')
                            )?.long_name || '',
                        city:
                            address.find(({ types }: { types: string[] }) =>
                                types.includes('locality')
                            )?.long_name || '',
                        zipCode:
                            address.find(({ types }: { types: string[] }) =>
                                types.includes('postal_code')
                            )?.long_name || '',
                    }

                    const locationField: LocationField = {
                        description: currentPlace.description,
                        zipCode,
                        id: place?.place_id || '',
                        lat: place?.geometry?.location?.lat() || 0,
                        lng: place?.geometry?.location?.lng() || 0,
                    }

                    setValue(
                        LocationInputs.Street,
                        street ? `${street} ${streetNumber}` : '',
                        fieldOptions
                    )
                    setValue(LocationInputs.City, city, fieldOptions)
                    setValue(LocationInputs.ZipCode, zipCode, fieldOptions)
                    setValue('location', locationField, {
                        shouldDirty: Boolean(location),
                        shouldValidate: false,
                        shouldTouch: false,
                    })
                }
            )
        },
        [setValue, getValues, placeDetails]
    )

    useEffectOnce(() => {
        autocompleteService?.getPlacePredictions(locationOptions, setFields)

        return () => {
            setLocations([])
        }
    })

    if (!isGmapLoaded) return null

    return (
        <>
            <StyledFormItem noBorder={noBorderAutocomplete}>
                <DataItem variant="input" label={label}>
                    <Controller
                        name={`location`}
                        control={control}
                        render={({ field: { value } }) => {
                            return (
                                <MuiAutocomplete
                                    sx={{ ...inputStyles, width: '100%' }}
                                    getOptionLabel={(option) => option?.description || ''}
                                    filterOptions={(x) => x.filter((o) => Boolean(o?.place_id))}
                                    options={[{ value: '' }, ...locations]}
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    disableClearable={true}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.place_id === value.place_id
                                    }}
                                    value={value || ''}
                                    onChange={(event, newValue) => {
                                        handleAutocompleteChange(newValue)
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        handleInputChange(newInputValue)
                                    }}
                                    renderInput={(params) => {
                                        return <TextField {...params} placeholder={placeholder} />
                                    }}
                                    renderOption={(props, option) => {
                                        const matches: google.maps.places.PredictionSubstring[] =
                                            option.structured_formatting
                                                .main_text_matched_substrings
                                        const parts = parse(
                                            option.structured_formatting.main_text,
                                            matches?.map(
                                                (match: google.maps.places.PredictionSubstring) => [
                                                    match.offset,
                                                    match.offset + match.length,
                                                ]
                                            )
                                        )

                                        return (
                                            <li {...props}>
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <Box
                                                            component={LocationOnIcon}
                                                            sx={{
                                                                color: 'text.secondary',
                                                                mr: 2,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        {parts.map((part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight
                                                                        ? 700
                                                                        : 400,
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {
                                                                option.structured_formatting
                                                                    .secondary_text
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                </DataItem>
            </StyledFormItem>
            {children && children(noBorder)}
        </>
    )
}

export default LocationFields
