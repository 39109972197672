import { Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    StyledPageItem,
    StyledPageList,
    StyledPageListItem,
    StyledPageSubtitle,
    StyledPageTitle,
    StyledPageWrapper,
    StyledPublicPage,
} from '../styles'
import { StyledLink } from '../../../theme/styles'

const PrivacyPolicy = () => {
    const { t } = useTranslation(['privacy-policy'])
    const { t: t2 } = useTranslation(['common'])

    return (
        <StyledPublicPage>
            <StyledPageTitle>{t('title')}</StyledPageTitle>

            <StyledPageWrapper>
                <StyledPageItem>
                    <StyledPageSubtitle>{t('general.title')}</StyledPageSubtitle>
                    <Typography>{t('general.p1')}</Typography>
                    <Typography>{t('general.p2')}</Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('controller.title')}</StyledPageSubtitle>
                    <Typography maxWidth={'200px'}>{t2('support.address')}</Typography>
                    <Typography>
                        {t2('phone')}:{' '}
                        <StyledLink as="a" href={`tel:${t2('support.tel')}`}>
                            {t2('support.tel')}
                        </StyledLink>
                        <br />
                        {t2('email')}:{' '}
                        <StyledLink as="a" href={`mailto:${t2('support.email2')}`}>
                            {t2('support.email2')}
                        </StyledLink>
                    </Typography>
                    <Typography>
                        {t2('web')}:{' '}
                        <StyledLink as="a" href={t2('omnystate_link')} target="_blank">
                            {t2('omnystate_link')}
                        </StyledLink>
                        <br />
                        {t('controller.content')}{' '}
                        <StyledLink as="a" href={t2('omnystate_details_link')} target="_blank">
                            {t('company_details')}
                        </StyledLink>
                    </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('privacy_issue.title')}</StyledPageSubtitle>
                    <Typography>
                        {t('privacy_issue.p1')}{' '}
                        <StyledLink as="a" href={`mailto:${t2('support.email2')}`}>
                            {t2('support.email2')}
                        </StyledLink>
                    </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('transmission.title')}</StyledPageSubtitle>
                    <Typography>{t('transmission.p1')}</Typography>

                    <StyledPageList>
                        <StyledPageListItem>{t('transmission.points.1')}</StyledPageListItem>
                        <StyledPageListItem>{t('transmission.points.2')}</StyledPageListItem>
                        <StyledPageListItem>{t('transmission.points.3')}</StyledPageListItem>
                        <StyledPageListItem>{t('transmission.points.4')}</StyledPageListItem>
                    </StyledPageList>

                    <Typography>{t('transmission.p2')}</Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('technique.title')}</StyledPageSubtitle>
                    <Typography>
                        <strong>{t('technique.subtitle')}</strong>
                        <br />
                        {t('technique.p1')}
                    </Typography>
                    <Typography>
                        <strong>{t('technique.subtitle2')}</strong>
                        <br />
                        {t('technique.p2')}
                    </Typography>

                    <StyledPageList>
                        <StyledPageListItem>{t('technique.points.1')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.2')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.3')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.4')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.5')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.6')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points.7')}</StyledPageListItem>
                    </StyledPageList>
                    <Typography>{t('technique.p3')}</Typography>

                    <StyledPageList>
                        <StyledPageListItem>{t('technique.points2.1')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points2.2')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points2.3')}</StyledPageListItem>
                        <StyledPageListItem>{t('technique.points2.4')}</StyledPageListItem>
                    </StyledPageList>

                    <Typography>
                        {t('technique.p4')}
                        <br />
                        {t('technique.p5')}
                    </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('cookies.title')}</StyledPageSubtitle>
                    <Typography>{t('cookies.p1')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('contact.title')}</StyledPageSubtitle>

                    <Typography>
                        <strong>{t('contact.subtitle')}</strong>
                        <br />
                        {t('contact.p1')}{' '}
                        <StyledLink as="a" href={t2('omnystate_link')} target="_blank">
                            {t('contact.link')}
                        </StyledLink>
                    </Typography>

                    <StyledPageList listStyleType={'abc'}>
                        <StyledPageListItem fontWeight={600}>
                            <strong>{t('contact.points.a.title')}</strong>
                            <br />
                            <Typography>{t('contact.points.a.p1')}</Typography>
                            <Typography>{t('contact.points.a.p2')}</Typography>
                            <Typography>{t('contact.points.a.p3')}</Typography>
                        </StyledPageListItem>

                        <StyledPageListItem fontWeight={600}>
                            <strong>{t('contact.points.b.title')}</strong>
                            <br />
                            <Typography>{t('contact.points.b.p1')}</Typography>
                        </StyledPageListItem>
                    </StyledPageList>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('services.title')}</StyledPageSubtitle>
                    <Typography>{t('services.p1')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('cooperation.title')}</StyledPageSubtitle>
                    <Typography>{t('cooperation.p1')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('use.title')}</StyledPageSubtitle>
                    <Typography>{t('use.p1')}</Typography>
                    <Typography>
                        <strong>{t('use.subtitle')}</strong> <br />
                        {t('use.p2')}
                    </Typography>
                    <Typography>
                        <strong>{t('use.subtitle2')}</strong> <br />
                        {t('use.p3')}
                    </Typography>
                    <Trans
                        t={t}
                        i18nKey="use.p4"
                        components={{
                            1: (
                                <StyledLink as="a" href={t('use.link')} target="_blank">
                                    {t('use.link')}
                                </StyledLink>
                            ),
                            3: (
                                <StyledLink as="a" href={t('use.link2')} target="_blank">
                                    {t('use.link2')}
                                </StyledLink>
                            ),
                        }}
                        values={{
                            link: t('use.link'),
                            link2: t('use.link2'),
                        }}
                    />
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('paid_orders.title')}</StyledPageSubtitle>
                    <Typography>
                        {t('paid_orders.p1')} <br /> {t('paid_orders.p2')}{' '}
                    </Typography>
                    <Typography>{t('paid_orders.p3')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('rights.title')}</StyledPageSubtitle>
                    <Typography>{t('rights.p1')} </Typography>
                    <Typography>{t('rights.p2')} </Typography>
                    <Typography>{t('rights.p3')} </Typography>
                    <Typography>{t('rights.p4')} </Typography>
                    <Typography>{t('rights.p5')} </Typography>
                    <Typography>{t('rights.p6')} </Typography>
                    <Typography>{t('rights.p7')} </Typography>
                    <Typography>{t('rights.p8')} </Typography>
                    <Typography>{t('rights.p9')} </Typography>
                    <Typography>{t('rights.p10')} </Typography>
                    <Typography>{t('rights.p11')} </Typography>
                    <Typography>{t('rights.p12')} </Typography>
                    <Typography>
                        {t('rights.p13')}
                        <StyledLink as="a" href={t('rights.link.url')} target="_blank">
                            {t('rights.link.title')}
                        </StyledLink>{' '}
                    </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('automated_decision.title')}</StyledPageSubtitle>
                    <Typography>{t('automated_decision.p1')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('routine.title')}</StyledPageSubtitle>
                    <Typography>{t('routine.p1')} </Typography>
                    <Typography>{t('routine.p2')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('duration.title')}</StyledPageSubtitle>
                    <Typography>{t('duration.p1')} </Typography>
                </StyledPageItem>

                <StyledPageItem>
                    <StyledPageSubtitle>{t('other.title')}</StyledPageSubtitle>
                    <Typography>{t('other.p1')} </Typography>
                </StyledPageItem>
            </StyledPageWrapper>
        </StyledPublicPage>
    )
}

export default PrivacyPolicy
