import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import unitService from '../../api/services/unit'
import { createUnitApiData } from '../../api/data/unit'
import { UnitParams } from 'units'
import useQueryKeys from '../useQueryKeys'
import { getTextError } from '../../utils/helpers'
import { AxiosError } from 'axios'
import { createResponseData } from '../../api/data'
import useParamManage from '../useParamManage'
import useNavUtils from '../useNavUtils'

export const useCreateUnit = () => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { createKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Unit)
    const { objectID } = useParamManage()
    const { navigateUnit } = useNavUtils()

    return useMutation(
        (params: UnitParams) => {
            return unitService.create(createUnitApiData(params))
        },
        {
            mutationKey: createKey,
            onSuccess: ({ data }) => {
                const { id } = createResponseData(data)

                setSuccessMsg(t('created'))
                navigateUnit(id)('GENERAL_INFORMATION')
                queryClient.invalidateQueries({ queryKey: getQueryAllKey([objectID]) })
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
