import React, { forwardRef } from 'react'
import {
    IconButton,
    InputAdornment,
    FormControl,
    FormHelperText,
    TextFieldProps,
    FormControlProps,
    OutlinedInputProps,
    TextField,
} from '@mui/material'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

interface Props {}

const InputPassword = forwardRef(
    (
        {
            name,
            label,
            fullWidth,
            error,
            value,
            onChange,
            onBlur,
            autoComplete,
            InputLabelProps,
            onAnimationStart,
            helperText,
        }: FormControlProps & OutlinedInputProps & TextFieldProps & Props,
        ref
    ) => {
        const [showPassword, setShowPassword] = React.useState<boolean>(false)

        const handleClickShowPassword = () => {
            setShowPassword(!showPassword)
        }

        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
        }

        return (
            <FormControl variant="outlined" fullWidth={fullWidth} error={error}>
                <TextField
                    id={`outlined-password-${label || name}}`}
                    label={label}
                    type={showPassword ? 'text' : 'password'}
                    value={value || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth={fullWidth}
                    error={error}
                    ref={ref as null}
                    autoComplete={autoComplete}
                    InputLabelProps={InputLabelProps}
                    onAnimationStart={(e) => onAnimationStart && onAnimationStart(e)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <VisibilityOffOutlinedIcon fontSize="small" />
                                    ) : (
                                        <VisibilityOutlinedIcon color="primary" fontSize="small" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    }
)

export default InputPassword
