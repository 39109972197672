import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import { styled, css } from '@mui/system'

export const StyledDnDIcon = styled(AddPhotoAlternateOutlinedIcon)`
    ${({ theme }) => css`
        font-size: 50px;
        color: ${theme.palette.neutral.contrastText};
        margin: 0 auto;
        margin-bottom: ${theme.spacing(5)};
        display: flex;
        justify-content: center;
    `}
`

export const StyledImageContainer = styled('div')`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        object-fit: cover;
        border-radius: ${theme.spacing(1)};
        position: relative;
        height: 400px;
        background-color: ${theme.palette.neutral.main};
    `}
`

export const StyledImageWrapper = styled('div')`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.contrastText};
        border-top-left-radius: ${theme.spacing(2)};
        border-top-right-radius: ${theme.spacing(2)};
        border-bottom: 1px solid ${theme.palette.grey['200']};
    `}
`
