import { useReducer } from 'react'

interface DialogState {
    isOpen: boolean
    dialogContent: JSX.Element | null | boolean
    acceptCallback?: null | Function
}
enum ACTIONS {
    SET_DIALOG = 'SET_DIALOG',
    SET_DIALOG_VISIBLE = 'SET_DIALOG_VISIBLE',
}

type Action =
    | {
          type: ACTIONS.SET_DIALOG
          payload: DialogState
      }
    | {
          type: ACTIONS.SET_DIALOG_VISIBLE
          payload: Pick<DialogState, 'isOpen'>
      }

const reducer = (state: DialogState, { type, payload }: Action) => {
    switch (type) {
        case ACTIONS.SET_DIALOG:
            return {
                ...state,
                isOpen: payload.isOpen,
                dialogContent: payload.dialogContent,
                acceptCallback: payload.acceptCallback,
            }
        case ACTIONS.SET_DIALOG_VISIBLE:
            return {
                ...state,
                isOpen: payload.isOpen,
            }

        default:
            console.error('No action found...')
            return state
    }
}

const useDialog = () => {
    const [{ isOpen, dialogContent, acceptCallback }, dispatch] = useReducer(reducer, {
        isOpen: false,
        dialogContent: null,
        acceptCallback: null,
    })

    const handleSetDialog = (el?: JSX.Element | null | boolean, callbackParam?: Function) =>
        dispatch({
            type: ACTIONS.SET_DIALOG,
            payload: {
                acceptCallback: callbackParam || null,
                dialogContent: el || null,
                isOpen: !!el,
            },
        })

    const handleToggleDialog = () =>
        dispatch({
            type: ACTIONS.SET_DIALOG_VISIBLE,
            payload: {
                isOpen: !isOpen,
            },
        })

    const handleCloseDialog = () =>
        dispatch({
            type: ACTIONS.SET_DIALOG,
            payload: {
                acceptCallback: null,
                dialogContent: null,
                isOpen: false,
            },
        })

    return [
        isOpen,
        acceptCallback,
        handleSetDialog,
        handleToggleDialog,
        handleCloseDialog,
        dialogContent,
    ] as const
}

export default useDialog
