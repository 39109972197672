import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import otherService from '../../api/services/other'
import { createOptionsFromResponse } from '../../api/data'
import { Option } from 'forms'

export const useGetContractorTrades = (options: UseQueryOptions): UseQueryResult<Option[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.ContractorTrade)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        refetchOnMount: false,
        queryFn: async () => {
            const { data } = await otherService.contractorTrades()
            return createOptionsFromResponse(data)
        },
    }) as UseQueryResult<Option[]>
}
