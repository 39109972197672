import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { ApartmentData } from '../../types/apartment'
import { createApartmentData } from '../../api/data/apartment'
import apartmentService from '../../api/services/apartment'

export const useGetApartment = (
    apartmentID: string,
    options: UseQueryOptions
): UseQueryResult<ApartmentData> => {
    const { getQueryOneKey } = useQueryKeys(QueryDataKey.Apartment)
    const { queryKey, ...restOptions } = options

    return useQuery({
        queryKey: getQueryOneKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await apartmentService.getOne(apartmentID)
            return createApartmentData(data)
        },
    }) as UseQueryResult<ApartmentData>
}
