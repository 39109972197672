import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiIconButton: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: {},
    },
    variants: [
        {
            props: { color: 'primary_outlined', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.grey['200']}`,
                    '&:hover': {
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.grey['200']}`,
                        background: theme.palette.common.white,
                    },
                },
            }),
        },
        {
            props: { color: 'primary_filled', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.common.white,
                    background: theme.palette.primary.main,
                    border: `1px solid transparent`,
                    '&:hover': {
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.grey['200']}`,
                    },
                },
            }),
        },
        {
            props: { color: 'secondary_light', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        background: theme.palette.common.white,
                    },
                },
            }),
        },
        {
            props: { color: 'primary', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.common.white,
                    background: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.primary.main,
                    },
                },
            }),
        },
        {
            props: { color: 'secondary', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.common.white,
                    '&:hover': {
                        color: theme.palette.secondary.main,
                    },
                    '&[aria-expanded="true"]': {
                        color: theme.palette.secondary.main,
                    },
                },
            }),
        },
        {
            props: { color: 'default', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.grey['500'],
                    background: 'transparent',
                    '&:hover': {
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.grey['200']}`,
                    },
                },
            }),
        },
        {
            props: { color: 'secondary_filled', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&': {
                    color: theme.palette.common.white,
                    background: theme.palette.secondary.main,
                    '&:hover': {
                        color: theme.palette.secondary.main,
                    },
                    '&.Mui-disabled': {
                        opacity: 0.5,
                    },
                },
            }),
        },
        {
            props: { color: 'neutral', size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                '&&': {
                    color: theme.palette.grey['500'],
                    background: 'transparent',
                    '&:hover': {
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.grey['200']}`,
                    },
                    '&:disabled': {
                        border: 'none',
                        background: theme.palette.neutral.contrastText,
                        color: theme.palette.grey['200'],
                        cursor: 'initial',
                    },
                },
            }),
        },
        {
            props: { size: 'large' },
            style: ({ theme }: { theme: Theme }) => ({
                borderRadius: theme.shape.borderRadius,
                padding: `${theme.spacing(3)}`,

                boxSizing: 'border-box',
                width: 48,
                height: 48,
                '&:hover': {
                    background: theme.palette.neutral.contrastText,
                    color: theme.palette.primary.main,
                },
                '&[aria-expanded="true"]': {
                    background: theme.palette.neutral.contrastText,
                    color: theme.palette.primary.main,
                },
                '&:disabled': {
                    background: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                },
            }),
        },
    ],
}

export default MuiIconButton
