import { Box } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'
import { StyledInputWrapper, StyledItem, StyledLabel, StyledValueWrapper } from './styles'

interface Props {
    variant?: 'input' | 'data'
    label?: string
    noBorder?: boolean
    hide?: boolean
}

const DataItem: FC<PropsWithChildren<Props>> = ({
    label,
    children,
    variant = 'data',
    noBorder,
}) => {
    const renderChild = () => {
        switch (variant) {
            case 'input':
                return (
                    <StyledInputWrapper noMargin={!Boolean(label)} className="input-wrapper">
                        {children}
                    </StyledInputWrapper>
                )

            case 'data':
                return <StyledValueWrapper>{children}</StyledValueWrapper>

            default:
                console.error('No data to render')
                break
        }
    }

    return (
        <StyledItem noBorder={noBorder}>
            {label && (
                <StyledLabel>
                    <Box mx={4}>{label}</Box>
                </StyledLabel>
            )}

            {renderChild()}
        </StyledItem>
    )
}
export default DataItem
