import { format, fromUnixTime } from 'date-fns'
import { Option } from 'forms'
import {
    Coordinates,
    MapLocation,
    ModelData,
    ModelResponseData,
    RoomsFieldResponseData,
} from 'index'

export interface CreateLocationParams extends Coordinates {
    id: string
}

export interface BuildAttributeData {
    name: string
    value: string
}
export interface NoteData {
    name: string
    value: string
}
export interface BuildAttributeRequestData {
    field_build_attributes_value: {
        und: [
            {
                value: string
            }
        ]
    }
    field_build_attributes_attribute: {
        und: string
    }
}
export interface RoomsFieldRequestData {
    field_hu_rooms_space: {
        und: [
            {
                value: string
            }
        ]
    }
    field_hu_rooms_room: {
        und: string
    }
}

export interface NoteRequestData {
    field_fbn_title: {
        und: {
            value: string
        }[]
    }
    field_fbn_text: {
        und: {
            value: string
        }[]
    }
}

export interface BuildAttributeResponseData {
    id: string
    attribute: string
    value: string
}
export interface NoteResponseData {
    id: string
    text: string
    title: string
}

export interface FileUploadedData {
    size: number
    name: string
}

export const createFileUploadedData = (data: File) => {
    return data
}
export const createNoteApiData = (notes: NoteData[]): NoteRequestData[] => {
    return notes.map((item) => {
        return {
            field_fbn_title: {
                und: [
                    {
                        value: item?.name,
                    },
                ],
            },
            field_fbn_text: {
                und: [
                    {
                        value: item?.value,
                    },
                ],
            },
        }
    })
}

export const createNoteData = (notes: NoteResponseData[]): NoteData[] => {
    if (!notes) return []
    return notes?.map((item) => ({
        name: item?.title,
        value: item?.text,
    }))
}

export const createBuildAttributeApiData = (attributes: Option[]): BuildAttributeRequestData[] => {
    return attributes.map((item) => {
        return {
            field_build_attributes_value: {
                und: [
                    {
                        value: item.value,
                    },
                ],
            },
            field_build_attributes_attribute: {
                und: item.id,
            },
        }
    })
}

export const createRoomsFieldData = (roomsData?: RoomsFieldResponseData[]): Option[] => {
    if (!roomsData) return []

    return roomsData.map(({ room, value }) => ({
        id: room || '',
        value: value || '',
    }))
}
export const createRoomsFieldApiData = (roomsData?: Option[]): RoomsFieldRequestData[] => {
    if (!roomsData) return []

    return roomsData?.map(({ id, value }) => ({
        field_hu_rooms_space: {
            und: [
                {
                    value,
                },
            ],
        },
        field_hu_rooms_room: {
            und: id,
        },
    }))
}
export const createBuildAttributeData = (attrs?: BuildAttributeResponseData[]): Option[] => {
    if (!attrs) return []

    return attrs?.map(({ attribute, value }) => ({
        id: attribute,
        value: value,
    }))
}

export const createImageData = ({
    url,
    filename,
    fullUrl,
}: Partial<{ url: string; filename: string; fullUrl: string }>) => ({
    src: url || fullUrl || '',
    filename: filename || '',
})

export const createLocationData = ({ id, lat, lng }: CreateLocationParams): MapLocation => ({
    id,
    location: {
        lat,
        lng,
    },
})

export const createModelData = ({ url, type, timestamp }: ModelResponseData): ModelData => ({
    url,
    type,
    timestamp: Number(timestamp),
})
export const createOptionsFromResponse = (data: { [key: string]: string }): Option[] =>
    Object.entries(data).map((item) => ({
        id: item[1] || '',
        value: item[0] || '',
    }))

export const createListDateFormat = (timestamp: number): string => {
    if (isNaN(timestamp)) return `${timestamp}`

    const result = fromUnixTime(timestamp)

    return `${format(result, 'dd/MM/yyyy')} at ${format(result, 'k:mm')}`
}

interface CreateResponse {
    nid: string
}
export const createResponseData = ({ nid }: Partial<CreateResponse>): { id: string } => ({
    id: nid || '',
})
