import BrokenImageIcon from "@mui/icons-material/BrokenImage"
import { Box, SxProps, Typography } from "@mui/material"
import { Viewer, Worker } from "@react-pdf-viewer/core"
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import { t } from "i18next"
import theme from "../../theme"


interface Props {
      src: string
      sxWrapper?: SxProps
}

const PdfViewer = ({ src, sxWrapper }: Props): JSX.Element => {

      return (
            <Box
                  sx={sxWrapper || { width: '100%', height: '100%', display: 'flex' }}
                  className="swiper-zoom-container"
            >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" >

                        <Viewer
                              withCredentials={true}
                              fileUrl={src}
                              renderLoader={() => (
                                    <Box
                                          display="flex"
                                          alignItems={'center'}
                                          justifyContent="center"
                                          flexDirection="column"
                                    >
                                          <BrokenImageIcon
                                                htmlColor={theme.palette.common.black}
                                                fontSize={'large'}
                                                sx={{ mb: 3 }}
                                          />
                                          <Typography color={theme.palette.common.black}>{t('image_loading')}</Typography>
                                    </Box>
                              )}
                              renderError={(error) => (
                                    <Box
                                          display="flex"
                                          alignItems={'center'}
                                          justifyContent="center"
                                          flexDirection="column"
                                    >
                                          <BrokenImageIcon
                                                htmlColor={theme.palette.common.black}
                                                fontSize={'large'}
                                                sx={{ mb: 3 }}
                                          />
                                          <Typography color={theme.palette.common.black}>{error.message}</Typography>
                                    </Box>
                              )}
                        />
                  </Worker>
            </Box>
      )
}

export default PdfViewer