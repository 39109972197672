import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FC } from 'react';
import PdfPageWithSelection from './components/PdfPageWithSelection';
import { PdfContainer } from './styles';

interface PdfViewerWithSelectionProps {
    pdfUrl: string;
    onSave: (selectionData: { x: number; y: number; width: number; height: number }) => void;
    onExit: () => void;
    initialSelection?: { x: number; y: number; width: number; height: number };
}

const PdfViewerWithSelection: FC<PdfViewerWithSelectionProps> = ({ pdfUrl, onSave, onExit, initialSelection }) => {
    return (
        <PdfContainer>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                    withCredentials={true}
                    theme="dark"
                    fileUrl={pdfUrl}
                    defaultScale={1.0}
                    renderPage={(props) => {
                        if (props.pageIndex === 0) {
                            return (
                                <PdfPageWithSelection
                                    pageIndex={props.pageIndex}
                                    canvasLayer={props.canvasLayer}
                                    textLayer={props.textLayer}
                                    annotationLayer={props.annotationLayer}
                                    svgLayer={props.svgLayer}
                                    textLayerRendered={props.textLayerRendered}
                                    onSave={onSave}
                                    onExit={onExit}
                                    doc={props.doc}
                                    height={props.height}
                                    width={props.width}
                                    rotation={props.rotation}
                                    scale={props.scale}
                                    markRendered={props.markRendered}
                                    initialSelection={initialSelection}
                                    canvasLayerRendered={props.canvasLayerRendered}
                                    onRotatePage={props.onRotatePage}
                                />
                            );
                        }
                        return <></>;
                    }}
                />
            </Worker>
        </PdfContainer>
    );
};

export default PdfViewerWithSelection;
