import * as yup from 'yup'

const schema = yup
    .object({
        email: yup
            .string()
            .email('form_error.email')
            .required('form_error.required')
            .min(2, 'form_error.field_too_short')
            .max(48, 'form_error.field_too_long'),
    })
    .required()

export default schema
