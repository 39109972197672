import React from 'react'
import {
    StyledCircleNumber,
    StyledStatBody,
    StyledStatCard,
    StyledStatHeading,
    StyledStatItem,
    StyledStatListHeading,
} from '../../styles'
import { TotalAndCityStatsProps } from '../..'
import { Box } from '@mui/material'
import { t } from 'i18next'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import NoData from '../../../../components/NoData'

const TotalAndCityStats = ({ title, data }: TotalAndCityStatsProps) => {
    const cities: string[] | undefined = data?.map(({ city }) => city)

    const stats = cities?.reduce<{ [key: string]: number }>((acc, curr) => {
        if (!acc[curr]) {
            acc[curr] = 1
        } else {
            acc[curr] += 1
        }

        return acc
    }, {})

    return (
        <StyledStatCard>
            <StyledStatHeading>{title}</StyledStatHeading>
            <StyledStatBody>
                <LoaderWrapper isLoading={data === undefined}>
                    {data?.length !== 0 ? (
                        <>
                            <StyledCircleNumber>{data?.length}</StyledCircleNumber>
                            <Box width={'100%'}>
                                <>
                                    <StyledStatListHeading>{t('top_values')}</StyledStatListHeading>
                                    {stats &&
                                        Object.entries(stats)
                                            .sort((a, b) => a[0].localeCompare(b[0]))
                                            .sort((a, b) => b[1] - a[1])
                                            .filter((_, i) => i < 3)
                                            .map(([key, value]) => (
                                                <StyledStatItem key={key}>
                                                    <Box>{key}</Box>
                                                    <Box>{value}</Box>
                                                </StyledStatItem>
                                            ))}
                                </>
                            </Box>
                        </>
                    ) : (
                        <NoData />
                    )}
                </LoaderWrapper>
            </StyledStatBody>
        </StyledStatCard>
    )
}

export default TotalAndCityStats
