import * as yup from 'yup'

const schema = yup.object({
    username: yup
        .string()
        .min(2, 'form_error.field_too_short')
        .max(48, 'form_error.field_too_long'),
    email: yup
        .string()
        .email('form_error.email')
        .required('form_error.required')
        .min(2, 'form_error.field_too_short')
        .max(48, 'form_error.field_too_long'),
    type: yup.string(),
    company: yup.string().required('form_error.required'),
    companyName: yup.string().required('form_error.required'),
    street: yup.string().required('form_error.required'),
    zipCode: yup.string().required('form_error.required'),
    city: yup.string().required('form_error.required'),
    contractorSector: yup.string().when(['type'], {
        is: (type: string) => type === 'contractor',
        then: yup.string().required('form_error.required'),
    }),
    contractorTrade: yup.string().when(['contractorSector'], {
        is: (contractorSector: string) => contractorSector === 'craftsman',
        then: yup.string().required('form_error.required'),
    }),
})

export default schema
