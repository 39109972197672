import { Box } from '@mui/system'
import { t } from 'i18next'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Input from '../Input'
import { StyledNestedItems } from '../../styles'
import { Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../../theme'
import { StyledLabel } from '../../../components/DataItem/styles'

interface Props {
    renderAsData?: boolean
}

const defaultAttr = {
    name: '',
    value: '',
}

const NoteFields = ({ renderAsData }: Props) => {
    const { control } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'notes',
    })

    if (renderAsData && !fields?.length) return null

    if (renderAsData)
        return (
            <>
                {fields.map((field: any, i: number) => (
                    <Box key={field.id} p={2}>
                        <Typography mb={2} fontWeight={600}>
                            {field.name}
                        </Typography>
                        <Typography mb={fields?.length - 1 === i ? 0 : 5}>{field.value}</Typography>
                    </Box>
                ))}
            </>
        )

    return (
        <>
            {fields.map((field, i: number) => (
                <StyledNestedItems key={field?.id}>
                    <Box display="flex" flexBasis={'100%'}>
                        <StyledLabel padding={`0 ${theme.spacing(2)}`} width="50%">
                            <Input
                                name={`notes.${i}.name`}
                                variant={'textarea'}
                                sx={{ p: '8px' }}
                                noBorder
                            />
                        </StyledLabel>
                        <Input
                            name={`notes.${i}.value`}
                            variant={'textarea'}
                            sx={{ p: '8px' }}
                            noBorder={i === fields.length - 1}
                        />
                    </Box>
                    <IconButton onClick={() => remove(i)} sx={{ mx: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </StyledNestedItems>
            ))}
            <Button
                variant="contained"
                size="large"
                sx={{ m: 2 }}
                onClick={() => append(defaultAttr)}
            >
                {t('add_note')}
            </Button>
        </>
    )
}

export default NoteFields
