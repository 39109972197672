import { MainDataTypes } from '../../types'
import {
    ObjectRequestData,
    ObjectResponseList,
    ObjectList,
    ObjectData,
    ObjectParams,
    ObjectDataResponse,
} from 'objects'
import {
    createBuildAttributeApiData,
    createBuildAttributeData,
    createImageData,
    createListDateFormat,
    createModelData,
} from '.'
import { getBase64 } from '../../utils/helpers'

export const createObjectApiData = async ({
    title,
    zipCode,
    city,
    entityID,
    street,
    type,
    image,
    attributes,
    idOptional,
}: ObjectParams): Promise<ObjectRequestData> => {
    return {
        title: title,
        field_property_id: {
            und: [
                {
                    value: idOptional,
                },
            ],
        },
        field_property_accounting_entity: entityID,
        field_property_address: {
            und: [
                {
                    thoroughfare: street,
                    postal_code: zipCode,
                    locality: city,
                },
            ],
        },
        field_property_type: {
            und: [type],
        },
        ...(image?.src.includes('blob:') && {
            field_property_overview_picture: image?.src
                ? ((await getBase64(image?.src)) as string)
                : null,
        }),
        field_build_attributes: {
            und: createBuildAttributeApiData(attributes ?? []),
        },
    }
}
export const createObjectData = ({
    title,
    changed,
    created,
    field_property_address,
    field_property_accounting_entity,
    nid,
    field_property_type,
    field_property_overview_picture,
    field_property_geofield,
    field_build_attributes,
    field_3d_models,
    field_property_id,
}: Partial<ObjectDataResponse>): ObjectData => {
    const imageUrl = field_property_overview_picture?.und?.[0]?.full_url

    return {
        id: nid || '',
        idOptional: field_property_id?.und?.[0]?.value || '',
        entityID: field_property_accounting_entity?.und?.[0]?.target_id || '',
        type: field_property_type?.und?.[0]?.tid || '',
        attributes: createBuildAttributeData(field_build_attributes?.und),
        nid: nid || '',
        lat: Number(field_property_geofield?.und?.[0]?.lat || 0),
        lng: Number(field_property_geofield?.und?.[0]?.lon || 0),
        street: field_property_address?.und?.[0]?.thoroughfare || '',
        city: field_property_address?.und?.[0]?.locality || '',
        title: title || '',
        zipCode: field_property_address?.und?.[0]?.postal_code || '',
        image: imageUrl ? createImageData({ url: imageUrl }) : undefined,
        changed: Number(changed),
        created: Number(created),
        models: field_3d_models?.und.map((item) => createModelData(item)) || [],
        dataType: MainDataTypes.Object,
    }
}
export const createObjectParams = ({
    city,
    idOptional,
    nid,
    street,
    title,
    zipCode,
    image,
    entityID,
    type,
    attributes,
}: ObjectData): ObjectParams => {
    return {
        idOptional: idOptional,
        entityID,
        type,
        location: undefined,
        attributes,
        street,
        city,
        title,
        zipCode,
        image,
    }
}

export const createObjectList = (data: ObjectResponseList[]) => {
    if (!data) return []

    return data?.map(
        ({
            city,
            id,
            nid,
            street,
            title,
            zip_code,
            image,
            lat,
            count_use_units,
            lon,
            changed,
            created,
            parent_id,
            type,
        }: ObjectResponseList): ObjectList => {
            return {
                id: nid,
                idOptional: id,
                nid,
                street,
                city,
                childrenCount: Number(count_use_units),
                title,
                zipCode: zip_code,
                image,
                lat: Number(lat),
                lng: Number(lon),
                changed: createListDateFormat(Number(changed)),
                created: createListDateFormat(Number(created)),
                parentID: parent_id,
                type,
                dataType: MainDataTypes.Object,
            }
        }
    )
}
