import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import {
    StyledBreadcrumbs,
    StyledFilesFooter,
    StyledFilesGrid,
    StyledFileTableWrapper,
    StyledTreeWrapper,
} from './styles'
import Table from '../../../../components/Table'
import { Column, TableInstance, TableToggleRowsSelectedProps } from 'react-table'
import { Box, Button, ButtonBase, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/Download'
import { useGetFileGroup } from '../../../../hooks/file/useGetFileGroups'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import { useGetFiles } from '../../../../hooks/file/useGetFiles'
import DataTree, { OnDropFunction, TreeItem } from '../../../../components/DataTree'
import useDataTree from '../../../../hooks/useDataTree'
import { FileData } from 'files'
import { MainData } from '../../../../types'
import Image from '../../../../components/Image'
import GridItem, { FileGridItem } from '../../../../modules/GridItem'
import Icon from '../../../../components/Icon'
import useFileUtils from '../../../../hooks/useFileUtils'
import { useBatchDownload } from '../../../../hooks/file/useBatchDownload'
import { LoadingButton } from '@mui/lab'
import { useTransferFiles } from '../../../../hooks/file/useTransferFiles'
import useDialog from '../../../../hooks/useDialog'
import Dialog from '../../../../components/Dialog'
import { usePostFiles } from '../../../../hooks/file/usePostFiles'
import { useEffectOnce } from 'usehooks-ts'
import { Trans, useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { useDeleteFiles } from '../../../../hooks/file/useDeleteFiles'
import useFileUpload from '../../../../hooks/useFileUpload'
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'
import { Row } from 'react-table'
import FileUpload from '../../../../modules/FileIUpload'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import { PATHS } from '../../../../routes/routes'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import LocalNavigation from '../../../../components/LocalNavigation'
import useParamManage from '../../../../hooks/useParamManage'

interface FilesState {
    dragItem: null | FileData
    isUploadFile: boolean
    groupID: null | string
    chosenFiles: FileData[]
    deleteItem: null | FileData
}
enum ACTIONS {
    SET_DRAG_ITEM = 'SET_DRAG_ITEM',
    SET_UPLOAD_FILE = 'SET_UPLOAD_FILE',
    SET_GROUP_ID = 'SET_GROUP_ID',
    SET_CHOSEN_FILES = 'SET_CHOSEN_FILES',
    SET_DELETE_ITEM = 'SET_DELETE_ITEM',
    SET_STATE = 'SET_STATE',
}

type Action =
    | {
          type: ACTIONS.SET_DRAG_ITEM
          payload: Pick<FilesState, 'dragItem'>
      }
    | {
          type: ACTIONS.SET_UPLOAD_FILE
          payload: Pick<FilesState, 'isUploadFile'>
      }
    | {
          type: ACTIONS.SET_GROUP_ID
          payload: Pick<FilesState, 'groupID'>
      }
    | {
          type: ACTIONS.SET_CHOSEN_FILES
          payload: Pick<FilesState, 'chosenFiles'>
      }
    | {
          type: ACTIONS.SET_DELETE_ITEM
          payload: Pick<FilesState, 'deleteItem'>
      }
    | {
          type: ACTIONS.SET_STATE
          payload: Partial<FilesState>
      }

const reducer = (state: FilesState, { type, payload }: Action) => {
    switch (type) {
        case ACTIONS.SET_DRAG_ITEM:
            return {
                ...state,
                dragItem: payload.dragItem,
            }
        case ACTIONS.SET_UPLOAD_FILE:
            return {
                ...state,
                isUploadFile: payload.isUploadFile,
            }
        case ACTIONS.SET_GROUP_ID:
            return {
                ...state,
                groupID: payload.groupID,
            }
        case ACTIONS.SET_CHOSEN_FILES:
            return {
                ...state,
                chosenFiles: payload.chosenFiles,
            }
        case ACTIONS.SET_DELETE_ITEM:
            return {
                ...state,
                deleteItem: payload.deleteItem,
            }
        case ACTIONS.SET_STATE:
            return {
                ...state,
                ...payload,
            }

        default:
            console.error('No action found...')
            return state
    }
}

const LOCKED_COLUMN = 'v0'

const Files = () => {
    const [{ dragItem, groupID, isUploadFile, chosenFiles, deleteItem }, dispatch] = useReducer(
        reducer,
        {
            deleteItem: null,
            dragItem: null,
            groupID: null,
            chosenFiles: [],
            isUploadFile: false,
        }
    )

    const {
        palette: {
            secondary: { main: secondaryMain },
            grey,
        },
    } = useTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParamManage()
    const { pathname } = useLocation()
    const title = 'Item'

    const {
        currentGroup,
        activeGroups,
        expanded,
        breadcrumbs,
        handleChangeFromTable,
        handleChange,
        handleChangeBreadcrumbs,
        handleCloseGroup,
        handleBreadcrumbClick,
    } = useDataTree()

    const [isOpen, acceptCallback, handleSetDialog, handleToggleDialog, handleCloseDialog] =
        useDialog()

    const [
        isOpenDelete,
        deleteCallback,
        handleSetDeleteDialog,
        handleToggleDeleteDialog,
        handleCloseDeleteDialog,
    ] = useDialog()

    const { isFile, allowedFileTypes } = useFileUtils()
    const { uploadInputRef, handleUploadBtnClick } = useFileUpload()
    const { lastParamID } = useParamManage()

    const { mutate: batchDownlodFiles, isLoading } = useBatchDownload(lastParamID)
    const {
        data: groups,
        isLoading: isLoadingGroups,
        remove: removeGetGroup,
    } = useGetFileGroup(lastParamID, {
        keepPreviousData: true,
    })

    const { mutate: deleteFiles, isLoading: isLoadingDelete } = useDeleteFiles(lastParamID)
    const { mutate: transferFiles, isLoading: isLoadingTransfer } = useTransferFiles(lastParamID)
    const { mutate: uploadFiles, isLoading: isLoadingUpload } = usePostFiles(lastParamID)

    const {
        data: files,
        isFetching: isFetchingFiles,
        remove: removeGetFiles,
    } = useGetFiles(lastParamID, currentGroup?.id || '', {
        enabled: Boolean(currentGroup?.id) && !Boolean(currentGroup?.children),
        keepPreviousData: false,
        queryKey: [currentGroup?.id],
    })

    const filesData = useMemo(() => files || [], [files])
    const isLockedColumnActive = useMemo(() => activeGroups.includes(LOCKED_COLUMN), [activeGroups])
    const treeItemData = (groups || []).map(({ id, title, children, parents }) => ({
        id: id || '',
        title,
        children,
        parents,
    })) as TreeItem[]

    const currentPath = breadcrumbs.map(({ title }) => title).join(' / ')
    const isClickable = useCallback(() => true, [])
    const handleDownloadFiles = () => batchDownlodFiles(chosenFiles.map(({ id }) => id))

    const handleClickFolderSettings = () =>
        navigate(generatePath(PATHS.FILE_SETTINGS, params), {
            state: { prevPath: pathname },
        })

    const handleUploadFiles = (files: File[], onSettled?: () => void) => {
        uploadFiles(
            { files, groupID: currentGroup?.id || '' },
            {
                onSettled,
            }
        )
    }

    const handleSetChosenFiles = useCallback(
        (files: string[]) => {
            dispatch({
                type: ACTIONS.SET_CHOSEN_FILES,
                payload: { chosenFiles: filesData.filter(({ id }) => files.includes(id)) },
            })
        },
        [filesData]
    )

    const handleWindowDragStart = (isDragExternal: boolean) => {
        dispatch({
            type: ACTIONS.SET_UPLOAD_FILE,
            payload: {
                isUploadFile: isDragExternal,
            },
        })
    }

    const handleRowDragStart = useCallback(
        (dragItem: MainData) => {
            if (!('filename' in dragItem)) return
            handleCloseGroup(LOCKED_COLUMN)
            dispatch({
                type: ACTIONS.SET_STATE,
                payload: { dragItem, isUploadFile: false, groupID: currentGroup?.id },
            })
        },
        [currentGroup, handleCloseGroup]
    )

    const handleTreeDrop: OnDropFunction = () => {
        const { id } = dragItem || {}
        const onDrop = () => {
            transferFiles(
                {
                    fileID: id || '',
                    newGroupID: currentGroup?.id || '',
                },
                {
                    onSuccess: handleCloseDialog,
                }
            )
        }

        handleSetDialog(true, onDrop)
    }

    const handleDropAllow = (id: string, hasChildren: boolean) => {
        const res = groupID !== id && !hasChildren && !isLockedColumnActive && id !== 'v0'
        return res
    }

    const handleDeleteFileSuccess = () => {
        handleCloseDeleteDialog()
        dispatch({
            type: ACTIONS.SET_DELETE_ITEM,
            payload: { deleteItem: null },
        })
    }

    const handleDeleteChosenFiles = useCallback(() => {
        const handleDeleteChosen = () =>
            deleteFiles(
                {
                    files: chosenFiles.map(({ id }) => id),
                    groupID: currentGroup?.id || '',
                },
                {
                    onSuccess: handleCloseDeleteDialog,
                }
            )
        handleSetDeleteDialog(true, handleDeleteChosen)
    }, [chosenFiles, currentGroup, handleSetDeleteDialog, deleteFiles, handleCloseDeleteDialog])

    const handleDeleteFile = useCallback(
        (id: string) => {
            dispatch({
                type: ACTIONS.SET_DELETE_ITEM,
                payload: { deleteItem: filesData.find(({ id: fileID }) => fileID === id) ?? null },
            })

            const onDelete = () =>
                deleteFiles(
                    {
                        files: [id],
                        groupID: currentGroup?.id || '',
                    },
                    {
                        onSuccess: handleDeleteFileSuccess,
                    }
                )

            handleSetDeleteDialog(true, onDelete)
        },
        // eslint-disable-next-line
        [currentGroup, filesData]
    )

    const columnsGroup: Column<TreeItem>[] = useMemo(() => {
        return [
            {
                Header: t('name'),
                accessor: 'title',
                size: 1,
                Cell: ({ row: { original: file } }: TableInstance<TreeItem>) => {
                    return (
                        <Box display="flex" alignItems={'flex-start'}>
                            <Box mr={2}>
                                <FolderCopyOutlinedIcon width={20} sx={{ fill: grey[500] }} />
                            </Box>
                            {file?.title}
                        </Box>
                    )
                },
            },
        ]
    }, [t, grey])

    const columns: Column<FileData>[] = useMemo(() => {
        return [
            {
                id: 'selection',
                accessor: 'id',
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    return (
                        <Checkbox
                            {...getToggleAllRowsSelectedProps()}
                            onClick={getToggleAllRowsSelectedProps().onChange}
                        />
                    )
                },
                Cell: ({ row: { getToggleRowSelectedProps } }: TableInstance<FileData>) => {
                    return (
                        <Checkbox
                            {...getToggleRowSelectedProps()}
                            onClick={getToggleRowSelectedProps().onChange}
                        />
                    )
                },
                width: 60,
                maxWidth: 60,
                size: 1,
            },
            {
                Header: '',
                accessor: 'thumbnail',
                size: 1,
                width: 50,
                maxWidth: 50,
                Cell: ({
                    row: {
                        original: { thumbnail },
                    },
                }: TableInstance<FileData>) =>
                    thumbnail && (
                        <Image
                            objectFit="cover"
                            src={thumbnail}
                            sxWrapper={{ width: '50px', height: '50px', display: 'flex' }}
                        />
                    ),
            },
            {
                Header: t('name'),
                accessor: 'name',
                size: 1,
                Cell: ({ row: { original: file } }: { row: Row<FileData> }) => {
                    return (
                        <Box display="flex" gap={2}>
                            <Icon width={20} icon={file.ext} variant="file" />
                            <Typography>{file?.name}</Typography>
                        </Box>
                    )
                },
            },

            {
                Header: t('file.last_modified'),
                accessor: 'date',
                size: 1,
            },
            {
                Header: t('file.file_size'),
                accessor: 'filesize',
                size: 1,
            },
        ]
    }, [t])

    const renderGridItem = useCallback(
        (item: MainData, props?: Partial<FileGridItem> & TableToggleRowsSelectedProps) => {
            if (!isFile(item)) return

            return (
                <GridItem
                    key={item.id}
                    variant={'file'}
                    image={item.thumbnail}
                    data={item}
                    allowDrag={!isLockedColumnActive}
                    {...props}
                />
            )
        },
        [isFile, isLockedColumnActive]
    )
    const renderGroupGridItem = (item: MainData) => {
        if (!('children' in item)) return

        return (
            <GridItem
                key={item.id}
                data={item}
                variant={'group'}
                onClick={(data) => handleChangeFromTable(data)}
            />
        )
    }

    const renderOnBottom = useCallback(
        (renderPagination?: () => JSX.Element | null, itemsCount?: number) => {
            return (
                <StyledFilesFooter>
                    {Boolean(itemsCount) && Boolean(filesData?.length) && (
                        <Box display="flex" gap={2} mr={'auto'}>
                            <LoadingButton
                                loading={isLoading}
                                variant="outlined"
                                size="large"
                                onClick={handleDownloadFiles}
                            >
                                <DownloadIcon sx={{ mr: 1 }} /> {t('file.download_selected')}
                            </LoadingButton>

                            {!isLockedColumnActive && (
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={handleDeleteChosenFiles}
                                >
                                    <DeleteOutlineIcon sx={{ mr: 1 }} /> {t('file.delete_selected')}
                                </Button>
                            )}

                            {itemsCount && (
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography color={'primary'}>
                                        ({itemsCount} {t('file.files')})
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    {!isLockedColumnActive && Boolean(activeGroups?.length) && !itemsCount && (
                        <Box display="flex" gap={2} mr="auto" position={'relative'}>
                            <LoadingButton
                                loading={isLoadingUpload}
                                variant="contained"
                                size="large"
                                onClick={handleUploadBtnClick}
                                startIcon={<AddIcon />}
                            >
                                {t('file.add_files')}
                            </LoadingButton>
                        </Box>
                    )}

                    {renderPagination && renderPagination()}
                </StyledFilesFooter>
            )
        },
        // eslint-disable-next-line
        [
            isLockedColumnActive,
            activeGroups,
            filesData,
            currentGroup,
            chosenFiles,
            isLoadingUpload,
            t,
        ]
    )

    useEffect(() => {
        isUploadFile &&
            dispatch({
                type: ACTIONS.SET_STATE,
                payload: { dragItem: null, groupID: null },
            })
    }, [isUploadFile])

    useEffectOnce(() => {
        const DRAG_ENTER_NAME = 'dragenter'
        const dragEnterEvent: any = window.addEventListener(DRAG_ENTER_NAME, (e) => {
            handleWindowDragStart(!!e.dataTransfer?.items.length)
        })

        return () => {
            window.removeEventListener(DRAG_ENTER_NAME, dragEnterEvent)
            removeGetFiles()
            removeGetGroup()
        }
    })

    return (
        <Box display="flex" gap={7}>
            <Box>
                <Box
                    height={'48px'}
                    display={'flex'}
                    alignItems={'center'}
                    mb={3}
                    justifyContent={'space-between'}
                >
                    <Typography color={secondaryMain} fontWeight={600}>
                        {title} {t('file.title')}
                    </Typography>
                    <ButtonBase onClick={handleClickFolderSettings}>
                        <SettingsIcon sx={{ color: secondaryMain, height: 20 }} />
                    </ButtonBase>
                </Box>
                <StyledTreeWrapper>
                    <LoaderWrapper isLoading={isLoadingGroups} sx={{ my: 3 }}>
                        <DataTree
                            data={treeItemData}
                            breadcrumbs={breadcrumbs}
                            expanded={expanded}
                            active={activeGroups}
                            onChangeBreadcrumbs={handleChangeBreadcrumbs}
                            onChange={handleChange}
                            onUpload={handleUploadFiles}
                            uploadPath={currentPath}
                            onDrop={handleTreeDrop}
                            checkDropAllow={handleDropAllow}
                            isUploadFile={isUploadFile}
                        />
                    </LoaderWrapper>
                </StyledTreeWrapper>
            </Box>
            <StyledFileTableWrapper>
                <Box display="flex" justifyContent={'space-between'} mb={3}>
                    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                        {breadcrumbs?.length ? (
                            breadcrumbs.map((value: TreeItem, i: number) => {
                                const isNextExists = !!breadcrumbs?.[i + 1]

                                return (
                                    <StyledBreadcrumbs
                                        key={value.id}
                                        onClick={
                                            isNextExists
                                                ? () => handleBreadcrumbClick(value)
                                                : undefined
                                        }
                                    >
                                        <Box display={'flex'}>
                                            <Box mr={2}>{value.title}</Box>
                                            {isNextExists && (
                                                <Icon
                                                    width={7}
                                                    icon={'ChevronIcon'}
                                                    color={secondaryMain}
                                                    orientation={'right'}
                                                />
                                            )}
                                        </Box>
                                    </StyledBreadcrumbs>
                                )
                            })
                        ) : (
                            <Typography color={secondaryMain} fontWeight={600}>
                                {title} {t('file.files')}
                            </Typography>
                        )}
                    </Box>
                    <LocalNavigation available={['list', 'grid']} />
                </Box>
                <FileUpload
                    variant={isUploadFile ? 'upload' : 'drop'}
                    isDropAllow={handleDropAllow(currentGroup?.id || '', !!currentGroup?.children)}
                    onDrop={handleTreeDrop}
                    isLoading={isLoadingUpload}
                    onUpload={handleUploadFiles}
                    inputRef={uploadInputRef}
                    accepted={allowedFileTypes}
                    uploadPath={currentPath}
                >
                    <LoaderWrapper
                        isLoading={isFetchingFiles || isLoadingGroups}
                        center
                        sx={{ minHeight: '500px' }}
                    >
                        {files === undefined || !activeGroups?.length ? (
                            <Table
                                columns={columnsGroup}
                                onRowClick={handleChangeFromTable}
                                data={currentGroup?.children || treeItemData}
                                GridWrapperElement={StyledFilesGrid}
                                renderGridItem={renderGroupGridItem}
                                renderOnBottom={null}
                            />
                        ) : (
                            <Table
                                columns={columns}
                                isRowClickable={isClickable}
                                getChosenRows={handleSetChosenFiles}
                                data={filesData}
                                isColClickable={isClickable}
                                isLoading={isFetchingFiles}
                                GridWrapperElement={StyledFilesGrid}
                                renderGridItem={renderGridItem}
                                isFullWidthPaginationWrapper={!isLockedColumnActive}
                                renderOnBottom={renderOnBottom}
                                onDragStart={handleRowDragStart}
                                onDelete={isLockedColumnActive ? undefined : handleDeleteFile}
                                allowDrag={!isLockedColumnActive}
                            />
                        )}
                    </LoaderWrapper>
                </FileUpload>
            </StyledFileTableWrapper>

            <Dialog
                open={isOpen}
                setOpen={handleToggleDialog}
                onAcceptClick={acceptCallback}
                title={
                    <Trans i18nKey={'file.transfer_dialog'} path={currentPath}>
                        {/* @ts-ignore */}
                        <strong>{{ path: currentPath }}</strong>
                    </Trans>
                }
                isLoading={isLoadingTransfer}
            >
                <Box maxWidth="300px" mt={2}>
                    <Box display="flex" alignItems={'center'} gap={2}>
                        <Box display={'flex'}>
                            {dragItem?.ext && (
                                <Icon variant="file" icon={dragItem.ext} height={20} />
                            )}
                        </Box>
                        {dragItem?.filename}
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={isOpenDelete}
                setOpen={handleToggleDeleteDialog}
                onCancelClick={handleCloseDeleteDialog}
                onAcceptClick={deleteCallback}
                title={
                    deleteItem ? (
                        t('file.single_delete_confirm')
                    ) : (
                        <Trans i18nKey={'file.delete_confirm'} count={chosenFiles?.length}>
                            {/* @ts-ignore */}
                            {{ count: chosenFiles?.length }}
                        </Trans>
                    )
                }
                isLoading={isLoadingDelete}
                btnColor="error"
            >
                <Box maxWidth="300px" mt={2}>
                    <Box display="flex" alignItems={'center'} gap={2}>
                        <Box display={'flex'}>
                            {deleteItem?.ext && (
                                <Icon variant="file" icon={deleteItem.ext} height={20} />
                            )}
                        </Box>
                        {deleteItem?.filename}
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )
}

export default Files
