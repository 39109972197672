import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../../../hooks/useParamManage'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import FormUnit from '../../../../../../forms/FormUnit'
import { useUpdateUnit } from '../../../../../../hooks/unit/useUpdateUnit'
import { useGetUnit } from '../../../../../../hooks/unit/useGetUnit'

const UpdateUnit = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { unitID } = useParamManage()
    const { data: unit, isLoading } = useGetUnit(unitID, {
        queryKey: [unitID],
    })
    const { mutate: updateUnit, isLoading: isLoadingUpdate } = useUpdateUnit(unitID)
    const { data: types = [], isLoading: isLoadingTypes } = useGetTypes()
    const { data: attributes = [], isLoading: isLoadingAttr } = useGetAttributes()

    return (
        <Modal title={t('unit.edit_unit')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoading || isLoadingTypes || isLoadingAttr}>
                <FormUnit
                    attributes={attributes}
                    types={types}
                    unit={unit}
                    isLoading={isLoadingUpdate}
                    onCancelClick={goBack}
                    onSubmit={updateUnit}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default UpdateUnit
