import axios from 'axios'
import authService from './services/auth'

axios.defaults.withCredentials = true
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

export const getAccessToken = async () => {
    try {
        const { data } = await authService.getToken()
        return data?.token
    } catch (error) {}
}

export default axiosInstance
