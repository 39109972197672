import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiMenu: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            '.MuiMenu-paper': {
                border: `1px solid ${theme.palette.grey[200]}`,
                boxShadow: 'none',
            },
            '.MuiList-root': {
                padding: 0,
            },
            '.Mui-selected': {
                background: `${theme.palette.primary.light}`,
                color: `${theme.palette.primary.main}`,
            },
            '.MuiMenuItem-root': {
                padding: `${theme.spacing(0)} ${theme.spacing(4)}`,
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                minHeight: '52px',

                '&:nth-last-of-type(1)': {
                    border: 0,
                },

                '&:hover': {
                    backgroundColor: `${theme.palette.grey[100]}`,
                    color: `${theme.palette.primary.main}`,
                    svg: {
                        path: {
                            fill: `${theme.palette.primary.main}`,
                        },
                    },
                },
            },
        }),
    },
    variants: [],
}

export default MuiMenu
