import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import unitService from '../../api/services/unit'
import useQueryKeys from '../useQueryKeys'
import useNavUtils from '../useNavUtils'

export const useDeleteUnit = () => {
    const { setSuccessMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { deleteKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Unit)
    const { navigateObject } = useNavUtils()

    return useMutation(
        (unitID: string) => {
            return unitService.delete(unitID)
        },
        {
            mutationKey: deleteKey,
            onSuccess: () => {
                setSuccessMsg(t('deleted'))
                queryClient.invalidateQueries({ queryKey: getQueryAllKey() })
                navigateObject()('OVERVIEW')
            },
        }
    )
}
