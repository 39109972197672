import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../i18n/en.json'
import de from '../i18n/de.json'
import ppEN from '../pages/Public/PrivacyPolicy/en.json'
import ppDE from '../pages/Public/PrivacyPolicy/de.json'
import termsEN from '../pages/Public/Terms/en.json'
import termsDE from '../pages/Public/Terms/de.json'
import companyDetailsEN from '../pages/Public/CompanyDetails/en.json'
import companyDetailsDE from '../pages/Public/CompanyDetails/de.json'

i18n.use(initReactI18next).init({
    fallbackLng: ['en', 'de'],
    lng: 'en',
    debug: false,
    resources: {
        en: {
            common: en,
            'privacy-policy': ppEN,
            terms: termsEN,
            'company-details': companyDetailsEN,
        },
        de: {
            common: de,
            'privacy-policy': ppDE,
            terms: termsDE,
            'company-details': companyDetailsDE,
        },
    },
    defaultNS: 'common',
    interpolation: {
        escapeValue: false,
    },
    returnNull: false,
    returnEmptyString: true,
})

export default i18n
