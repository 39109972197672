export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.59 56">
        <defs>
            <clipPath id="a">
                <path
                    d="M179 139.21h46.59v56H179Z"
                    transform="translate(-179 -139.21)"
                    fill="#ea3323"
                />
            </clipPath>
        </defs>
        <g>
            <g clipPath="url(#a)">
                <path
                    d="M6.564 56h33.462a6.457 6.457 0 0 0 6.457-6.457V6.457A6.457 6.457 0 0 0 40.026 0H6.564A6.457 6.457 0 0 0 .107 6.457v43.087a6.457 6.457 0 0 0 6.457 6.457ZM3.607 6.456a2.96 2.96 0 0 1 2.957-2.957h33.462a2.96 2.96 0 0 1 2.957 2.957v43.088a2.96 2.96 0 0 1-2.957 2.957H6.564a2.96 2.96 0 0 1-2.957-2.956Z"
                    fill="#ea3323"
                />
            </g>
            <text
                transform="translate(23.295 24.424)"
                fill="#ea3323"
                fontSize="16"
                fontFamily="FiraSans-Bold, Fira Sans"
                fontWeight="700"
            >
                <tspan x="-13.4" y="0">
                    LAS
                </tspan>
            </text>
            <g transform="translate(-54.705 -6)" fill="#ea3323">
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(66 37)" />
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(76 37)" />
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(86 37)" />
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(66 46)" />
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(76 46)" />
                <circle cx="2.5" cy="2.5" r="2.5" transform="translate(86 46)" />
            </g>
        </g>
    </svg>
)
