import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'

const MuiFormControl: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            label: {
                color: theme.palette.grey[500],
            },
            '&&& .MuiInputBase-input': {
                height: '100%',
            },
            '&&&& .MuiSelect-select': {
                display: 'inline-flex',
            },
            '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey[200],
                '&:hover': {
                    borderColor: theme.palette.grey[200],
                },
            },
            '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main,
            },
            '.MuiInputAdornment-root': {
                '.MuiSvgIcon-root': {
                    color: theme.palette.secondary.main,
                },
            },
            '.MuiSvgIcon-root': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                '.MuiOutlinedInput-root:not(.Mui-disabled)': {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.grey[500],
                    },
                },
            },
            '.MuiFormHelperText-root': {
                marginLeft: '5px',
                marginRight: '5px',
                // color: theme.palette.error.main,
            },
        }),
    },
    variants: [
        {
            props: { className: 'header-select' },
            style: ({ theme }: { theme: Theme }) => ({
                label: {
                    color: 'white',
                    '&.Mui-focused': {
                        color: 'white',
                    },
                },
                '&&& .MuiInputBase-input': {
                    height: 'auto',
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '.MuiOutlinedInput-input': {},
                '.MuiSelect-select': {
                    color: 'white',
                    paddingRight: '40px !important',
                    paddingLeft: 0,
                    marginLeft: '20px',
                },
            }),
        },
    ],
}

export default MuiFormControl
