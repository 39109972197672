import { Chart, TooltipModel } from 'chart.js'
import { createContext, useContext } from 'react'

interface Props {
    children: JSX.Element
    value: StatsTools
}

export interface StatsTools {
    drawInsideDoughnut: ({ ctx }: Chart, count?: string, text?: string) => void
    createTooltip: (
        data: { chart: Chart; tooltip: TooltipModel<'bar' | 'doughnut'> },
        valueCallback?: (value: string) => string
    ) => void
    colors: string[]
}

const StatsContext = createContext<StatsTools | {}>({})
export const useChartData = () => useContext<StatsTools | {}>(StatsContext) as StatsTools

export const StatsProvider = ({ children, value }: Props) => {
    return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>
}
