export enum MaintenanceBase {
    Mep = 'mep',
    Maintenance = 'maintenance',
}

export enum AccountTypes {
    Contractor = 'contractor',
    Company = 'company',
}

export enum RoomsTaxonomy {
    Livingroom = 'livingroom',
    Bedroom = 'bedroom',
    Kidsroom = 'kidsroom',
    Office = 'office',
    Bathroom = 'bathroom',

    Corridor = 'corridor',
}

export type ViewType = 'grid' | 'list' | 'map'
