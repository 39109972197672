import React from 'react'
import { Box, Typography } from '@mui/material'
import { SignInParams } from '../../../types/auth'
import { PATHS } from '../../../routes/routes'
import { useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'
import { StyledLinks, StyledLink } from '../../../theme/styles'
import { useSignIn } from '../../../hooks/auth/useSignIn'
import FormSignIn from '../../../forms/FormSignIn'

const SignIn = () => {
    const { t } = useTranslation()
    const { mutate: signIn, isLoading } = useSignIn()

    const onSubmit = ({ username, password }: SignInParams) => signIn({ username, password })

    return (
        <>
            <Box marginBottom={6}>
                <Typography variant="h3">{t('login_to_immotech')}</Typography>
            </Box>

            <FormSignIn onSubmit={onSubmit} isLoading={isLoading} />

            <Divider />
            <StyledLinks>
                <StyledLink to={PATHS.SIGNUP}>{t('create_account')}</StyledLink>
                <StyledLink to={PATHS.RESET_PASSWORD}>{t('reset_password')}</StyledLink>
            </StyledLinks>
            <Divider />
            <StyledLinks>
                <StyledLink to={PATHS.SUPPORT}>{t('contact_support')}</StyledLink>
            </StyledLinks>
        </>
    )
}

export default SignIn
