export default (
    <svg data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
        <path
            data-name="Polygon 3"
            d="M4.211 3.578a2 2 0 0 1 3.578 0l2.764 5.528A2 2 0 0 1 8.764 12H3.236a2 2 0 0 1-1.789-2.894Z"
            fill="#911212"
        />
        <g data-name="Group 1">
            <path
                data-name="Path 393"
                d="m6.381 8.43.09-4.13h-.918l.1 4.131Zm-.36.927a.617.617 0 0 0-.621.621.619.619 0 0 0 .621.63.621.621 0 0 0 .63-.63.619.619 0 0 0-.63-.621Z"
                fill="#f0f0f0"
            />
        </g>
    </svg>
)
