import { Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledBottomBar, StyledContainerName, StyledNav, StyledNumberOfItems } from './styles'
import {
    APARTMENT_PATHS,
    CONTAINER_PATHS,
    MAINTENANCE_PATHS,
    OBJECT_PATHS,
    TASK_PATHS,
    UNIT_PATHS,
} from '../../routes/routes'
import { useGetUnits } from '../../hooks/unit/useGetUnits'
import theme from '../../theme/index'
import { Box } from '@mui/system'
import { useGetContainers } from '../../hooks/container/useGetContainers'
import { useGetObjects } from '../../hooks/object/useGetObjects'
import { useGetMaintenances } from '../../hooks/maintenance/useGetMaintenances'
import { useGetTasks } from '../../hooks/task/useGetTasks'
import { generatePath, matchPath, useLocation } from 'react-router-dom'
import Tabs from '../../components/Tabs'
import { useLanguage } from '../../hooks/useLanguage'
import { useGetApartments } from '../../hooks/apartment/useGetApartments'
import useParamManage from '../../hooks/useParamManage'

const MainNavigation = () => {
    const { t } = useTranslation()
    const { currentLang } = useLanguage()
    const { pathname } = useLocation()
    const { containerID, objectID, unitID, apartmentID, lastParamID, maintenanceID } =
        useParamManage()

    const { data: objects } = useGetObjects(containerID, {
        queryKey: [containerID],
        refetchOnMount: false,
        enabled: !maintenanceID,
    })
    const { data: containers } = useGetContainers({})
    const { data: units } = useGetUnits(objectID, {
        queryKey: [objectID],
        enabled: !!objectID,
    })

    const { data: apartments } = useGetApartments(unitID, {
        queryKey: [unitID],
        enabled: Boolean(unitID),
        refetchOnMount: false,
    })

    const unit = useMemo(() => units?.find((c) => c.nid === unitID), [unitID, units])

    const container = useMemo(
        () => containers?.find((c) => c.nid === containerID),
        [containerID, containers]
    )

    const object = useMemo(() => objects?.find((o) => o.nid === objectID), [objectID, objects])

    const { data: maintenances } = useGetMaintenances(lastParamID, {
        queryKey: [lastParamID],
        enabled: !maintenanceID,
    })

    const { data: tasks } = useGetTasks(lastParamID, {
        queryKey: [lastParamID],
    })

    const isSingular = (length?: number) => Number(length) < 2

    const MAIN_NAVIGATION = {
        MAIN: [
            { name: 'overview', route: CONTAINER_PATHS.OVERVIEW_ALL },

            {
                name: 'maintenance',
                route: MAINTENANCE_PATHS.LIST,
            },
            {
                name: 'task',
                route: TASK_PATHS.LIST,
            },
        ],
        CONTAINER: [
            {
                name: 'general_information',
                route: CONTAINER_PATHS.GENERAL_INFORMATION,
            },
            {
                name: 'overview',
                route: CONTAINER_PATHS.OVERVIEW,
            },
        ],
        OBJECT: [
            {
                name: 'general_information',
                route: OBJECT_PATHS.GENERAL_INFORMATION,
            },
            {
                name: 'overview',
                route: OBJECT_PATHS.OVERVIEW,
            },
            {
                name: 'files',
                route: OBJECT_PATHS.FILE,
            },
            {
                name: 'maintenance',
                route: MAINTENANCE_PATHS.LIST_OBJECT,
            },
            {
                name: 'task',
                route: TASK_PATHS.LIST_OBJECT,
            },
        ],
        UNIT: [
            {
                name: 'general_information',
                route: UNIT_PATHS.GENERAL_INFORMATION,
            },
            {
                name: 'overview',
                route: UNIT_PATHS.OVERVIEW,
            },
            {
                name: 'files',
                route: UNIT_PATHS.FILE,
            },
            {
                name: 'maintenance',
                route: MAINTENANCE_PATHS.LIST_UNIT,
            },
            {
                name: 'task',
                route: TASK_PATHS.LIST_UNIT,
            },
        ],
        APARTMENT: [
            {
                name: 'general_information',
                route: APARTMENT_PATHS.GENERAL_INFORMATION,
            },
            {
                name: 'maintenance',
                route: MAINTENANCE_PATHS.LIST_APARTMENT,
            },
            {
                name: 'task',
                route: TASK_PATHS.LIST_APARTMENT,
            },
        ],
    }

    const headingData = [
        {
            title: unit?.title,
            uriChunk: unitID,
            subtitle: isSingular(apartments?.length) ? t('unit.unit') : t('unit.units'),
            childLength: undefined,
        },
        {
            title: object?.title,
            subtitle: isSingular(units?.length) ? t('unit.unit') : t('unit.units'),
            uriChunk: objectID,
            childLength: units?.length,
        },
        {
            title: container?.title,
            subtitle: isSingular(objects?.length) ? t('object.object') : t('object.objects'),
            uriChunk: containerID,
            childLength: objects?.length,
        },
        {
            title: t('maintenance.available_maintenances'),
            subtitle: undefined,
            childLength: undefined,
            uriChunk: MAINTENANCE_PATHS.LIST,
        },
        {
            title: t('task.available_tasks'),
            subtitle: undefined,
            childLength: undefined,
            uriChunk: TASK_PATHS.LIST,
        },
        {
            title: t('container.available_containers'),
            subtitle: undefined,
            childLength: undefined,
            uriChunk: '',
        },
    ] as const

    const heading = headingData.find(
        (heading) => heading.title && pathname.includes(heading.uriChunk)
    )

    const getElements = () => {
        if (apartmentID) return MAIN_NAVIGATION.APARTMENT
        if (unitID) return MAIN_NAVIGATION.UNIT
        if (objectID) return MAIN_NAVIGATION.OBJECT
        if (containerID) return MAIN_NAVIGATION.CONTAINER
        return MAIN_NAVIGATION.MAIN
    }

    const getCurrentRoute = (): string => {
        //Conditionaly return if overview route has nested pages too keep active el
        if (pathname === OBJECT_PATHS.OVERVIEW_ALL) return CONTAINER_PATHS.OVERVIEW_ALL
        return elements.find(({ route }) => !!matchPath(route, pathname))?.route || ''
    }

    const elements = getElements()
    const currentRoute = getCurrentRoute()

    if (!currentRoute) return null
    return (
        <StyledBottomBar>
            <StyledContainerName>
                <Box>
                    <Typography sx={{ fontWeight: 600 }}>{heading?.title}</Typography>
                    {containerID && (
                        <StyledNumberOfItems>
                            <Typography
                                sx={{ color: theme.palette.grey['800'] }}
                                textTransform={currentLang === 'en' ? 'lowercase' : 'initial'}
                            >
                                {Boolean(heading?.childLength) &&
                                    `${heading?.childLength} ${heading?.subtitle}`}
                            </Typography>
                        </StyledNumberOfItems>
                    )}
                </Box>
            </StyledContainerName>
            <StyledNav>
                <Tabs
                    sx={{ p: 0 }}
                    value={currentRoute || ''}
                    TabIndicatorProps={{
                        sx: { height: 3, background: theme.palette.secondary.main },
                    }}
                >
                    {({ StyledTab, StyledNavLink }) =>
                        elements?.map(({ name, route }) => (
                            <StyledTab
                                key={name}
                                value={route}
                                TouchRippleProps={{ style: { color: theme.palette.grey[500] } }}
                                label={
                                    <StyledNavLink
                                        to={generatePath(route, {
                                            containerID,
                                            objectID,
                                            unitID,
                                            apartmentID,
                                        })}
                                    >
                                        <Typography>
                                            {t(`navigation.${name}`)}

                                            {name === 'maintenance' &&
                                                ` (${maintenances?.length || 0})`}
                                            {name === 'task' && ` (${tasks?.length || 0})`}
                                        </Typography>
                                    </StyledNavLink>
                                }
                            />
                        ))
                    }
                </Tabs>
            </StyledNav>
        </StyledBottomBar>
    )
}

export default MainNavigation
