import { styled, css } from '@mui/system'

export const StyledCalendarIconWrapper = styled('div')`
    ${({ theme }) => css`
        padding: 0 ${theme.spacing(6)} 0 ${theme.spacing(3)};
        height: ${theme.spacing(4)};
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiInputAdornment-root {
            margin: 0;
            .MuiSvgIcon-root {
                font-size: 18px;
            }
        }
    `}
`
