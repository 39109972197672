import React from 'react'
import useUserStore from '../../../../../../store/user'
import { useGetUser } from '../../../../../../hooks/auth/useGetUser'
import FormPassword from '../../../../../../forms/FormPassword'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'

const UserSettingsPassword = () => {
    const { userID } = useUserStore()
    const { isLoading } = useGetUser(userID, {})

    return (
        <LoaderWrapper isLoading={isLoading}>
            <FormPassword />
        </LoaderWrapper>
    )
}

export default UserSettingsPassword
