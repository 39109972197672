import React from 'react'
import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import { useGetUnits } from '../../../../../../hooks/unit/useGetUnits'
import theme from '../../../../../../theme'
import OverviewUnitList from '../../../../../../modules/OverviewUnitList'
import LocalNavigation from '../../../../../../components/LocalNavigation'
import useParamManage from '../../../../../../hooks/useParamManage'

const OverviewObject = () => {
    const { objectID } = useParamManage()
    const { data: units, isLoading } = useGetUnits(objectID, {
        queryKey: [objectID],
        enabled: !!objectID,
        refetchOnMount: false,
    })

    return (
        <Box>
            <Box
                display={'flex'}
                justifyContent="space-between"
                flexDirection={'row'}
                alignItems="center"
                mb={3}
            >
                <Typography color={theme.palette.secondary.main} fontWeight={600}>
                    {t('unit.all_units')}
                </Typography>
                <LocalNavigation available={['list', 'grid']} />
            </Box>

            <OverviewUnitList data={units || []} isLoading={isLoading} />
        </Box>
    )
}

export default OverviewObject
