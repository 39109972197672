import { Column } from 'react-table'
import React, { FC, useMemo } from 'react'
import { ContainerMapLocation } from 'map'
import { useTranslation } from 'react-i18next'
import { ContainerList } from 'containers'
import { useView } from '../../../../../../store/view'
import { MainData } from '../../../../../../types'
import { MapTooltipItem } from '../../../../../../modules/Map/components/MapTooltip'
import GridItem from '../../../../../../modules/GridItem'
import MapResultItem from '../../../../../../modules/Map/components/MapResultItem'
import Table from '../../../../../../components/Table'
import Map from '../../../../../../modules/Map'
import MapBoundaries from '../../../../../../modules/Map/components/MapBoundaries'
import { StyledGridWrapper } from '../../../../../../theme/styles'
import { useGetContainers } from '../../../../../../hooks/container/useGetContainers'
import useNavUtils from '../../../../../../hooks/useNavUtils'

type Action = 'update' | 'delete' | 'grid-click' | 'cell-click' | 'tooltip-click'

const OverviewContainers: FC = () => {
    const { t } = useTranslation()
    const { data: containers = [], isLoading } = useGetContainers()
    const { viewType } = useView()
    const { navigateContainer } = useNavUtils()

    const columns: Column<ContainerList>[] = useMemo(() => {
        return [
            {
                Header: 'ID',
                id: 'ID',
                accessor: ({ idOptional, id }) => idOptional || id,
                size: 1,
                width: 70,
                maxWidth: 70,
            },
            {
                Header: t('title'),
                accessor: ({ childrenCount, title }) => {
                    if (!childrenCount) return title
                    return `${title} (${childrenCount})`
                },
                size: 1,
            },
            {
                Header: t('zip_code'),
                accessor: 'zipCode',
                size: 1,
            },
            {
                Header: t('city'),
                accessor: 'city',
                size: 1,
            },
            {
                Header: t('last_edited'),
                accessor: 'changed',
                size: 1,
            },
        ]
    }, [t])

    const handleNavigate = (action: Action) => (id: string) => {
        const navigate = navigateContainer(id)

        switch (action) {
            case 'update':
                return navigate('UPDATE')

            case 'delete':
                return navigate('DELETE')

            case 'grid-click':
            case 'cell-click':
            case 'tooltip-click':
                return navigate('GENERAL_INFORMATION')

            default:
                navigate('OVERVIEW')
        }
    }

    const handleCreateTooltip = (zipCodes: string[]): JSX.Element[] => {
        const currentContainers = containers?.filter(({ zipCode }) => zipCodes.includes(zipCode))

        return currentContainers?.map((c, i) => (
            <MapTooltipItem
                key={i}
                data={c}
                index={i}
                length={currentContainers?.length}
                onClick={handleNavigate('tooltip-click')}
            />
        ))
    }

    const renderGridItem = ({ id, title }: MainData) => {
        return (
            <GridItem
                id={id}
                title={title}
                image={''}
                variant={'basic'}
                onEdit={handleNavigate('update')}
                onDelete={handleNavigate('delete')}
                onClick={handleNavigate('grid-click')}
            />
        )
    }

    const handleCreateResults = (containerItems: ContainerMapLocation[]) => {
        const data = containers.filter(({ id: currentID }) =>
            containerItems.find(({ id }) => id === currentID)
        )

        return data.map((c) => (
            <MapResultItem key={c.id} id={c.zipCode} subtitle={`${c.zipCode}, ${c.city}`}>
                {c.title}
            </MapResultItem>
        ))
    }

    return (
        <>
            {viewType !== 'map' && (
                <Table
                    onEdit={handleNavigate('update')}
                    onDelete={handleNavigate('delete')}
                    onCellClick={handleNavigate('cell-click')}
                    columns={columns}
                    data={containers}
                    isLoading={isLoading}
                    isGridView={viewType === 'grid'}
                    renderGridItem={renderGridItem}
                    GridWrapperElement={StyledGridWrapper}
                />
            )}

            {viewType === 'map' && (
                <Map
                    renderMap={({ createAndFilterBoundarieLocations }) => (
                        <MapBoundaries
                            data={createAndFilterBoundarieLocations(containers)}
                            renderTooltip={handleCreateTooltip}
                            renderResults={handleCreateResults}
                        />
                    )}
                    large
                />
            )}
        </>
    )
}

export default OverviewContainers
