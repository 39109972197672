import React from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { QueryClientProvider } from 'react-query'
import Router from './routes'
import theme from './theme'
import queryClient from './utils/queryClient'
import i18n from './utils/i18n'
import { SnackbarProvider } from 'notistack'
import { GmapProvider } from './context/gmap'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ResponseInterceptor } from './api/components/ResponseInterceptor'
import RequestInterceptor from './api/components/RequestInterceptor'
import CookieBanner from './modules/CookieBanner'

const App = () => {
    return (
        <>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <GmapProvider>
                        <SnackbarProvider
                            maxSnack={4}
                            hideIconVariant
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            style={{
                                fontSize: '0.9rem',
                                maxWidth: '320px',
                                minWidth: 'auto',
                                overflow: 'hidden',
                            }}
                        >
                            <CssBaseline />
                            <QueryClientProvider client={queryClient}>
                                <Router />
                                <ResponseInterceptor />
                                <RequestInterceptor />
                            </QueryClientProvider>
                        </SnackbarProvider>
                    </GmapProvider>
                    <CookieBanner />
                </ThemeProvider>
            </I18nextProvider>
        </>
    )
}

const container = document.getElementById('root')
const root = container && createRoot(container)
root?.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
