import { ObjectRequestData } from 'objects'
import axiosInstance from '../axios'

const objectService = {
    getAll: (nid?: string) => {
        return axiosInstance.get(`/property${nid ? `?accounting_entity_id=${nid}` : ''}`)
    },
    getOne: (id?: string) => {
        return axiosInstance.get(`/property/${id}`)
    },
    delete: (id?: string) => {
        return axiosInstance.delete(`/property/${id}`)
    },
    create: (params: ObjectRequestData) => {
        return axiosInstance.post(`/property`, params)
    },
    update: (id?: string, params?: ObjectRequestData) => {
        return axiosInstance.put(`/property/${id}`, params)
    },
}
export default objectService
