import { useQuery, UseQueryOptions, UseQueryResult } from "react-query"
import { createCompanySettingsData } from "../../api/data/company"
import companySettingsService from "../../api/services/company-settings"
import { QueryDataKey } from "../../types"
import { CompanySettingsParams } from "../../types/company"
import useQueryKeys from "../useQueryKeys"

export const useGetCompanySettings = (
      options: UseQueryOptions
): UseQueryResult<CompanySettingsParams> => {
      const { getQueryOneKey } = useQueryKeys(QueryDataKey.CompanySettings)
      const { queryKey, ...restOptions } = options

      return useQuery({
            queryKey: getQueryOneKey(queryKey),
            ...restOptions,
            queryFn: async () => {
                  const { data } = await companySettingsService.get()
                  return createCompanySettingsData(data)
            },
      }) as UseQueryResult<CompanySettingsParams>
}