import React, { FC, useState } from 'react'
import {
    StyledBackgroundImageWrapper,
    StyledBackgroundImage,
    StyledTextIconWrapper,
    StyledFirstImage,
} from './styles'
import { Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Frankfurt from '../../assets/images/bg/frankfurt.png'
import Hafencity from '../../assets/images/bg/hafencity.png'
import Nuremberg from '../../assets/images/bg/nuremberg.png'
import { useEffectOnce } from 'usehooks-ts'

const IMAGES = [
    {
        text: 'Speicherstadt, Hamburg',
        src: Hafencity,
    },
    {
        text: 'Commerzbank Tower, Frankfurt',
        src: Frankfurt,
    },
    {
        text: 'Henkersteg, Nuremberg',
        src: Nuremberg,
    },
] as const

const DynamicBackground: FC = () => {
    const [showInitialLoadImage, setShowInitialLoadImage] = useState<boolean>(true)

    useEffectOnce(() => {
        const timer = setTimeout(() => {
            setShowInitialLoadImage(false)
        }, 6000)
        return () => clearTimeout(timer)
    })

    return (
        <>
            {showInitialLoadImage && (
                <StyledBackgroundImageWrapper>
                    <StyledFirstImage style={{ backgroundImage: `url(${IMAGES[0].src})` }}>
                        <StyledTextIconWrapper>
                            <LocationOnIcon />
                            <Typography>{IMAGES[0].text}</Typography>
                        </StyledTextIconWrapper>
                    </StyledFirstImage>
                </StyledBackgroundImageWrapper>
            )}
            <StyledBackgroundImageWrapper>
                {IMAGES.map(({ text, src }) => (
                    <StyledBackgroundImage
                        key={text}
                        style={{
                            backgroundImage: `url(${src})`,
                        }}
                    >
                        <StyledTextIconWrapper>
                            <LocationOnIcon />
                            <Typography>{text}</Typography>
                        </StyledTextIconWrapper>
                    </StyledBackgroundImage>
                ))}
            </StyledBackgroundImageWrapper>
        </>
    )
}

export default DynamicBackground
