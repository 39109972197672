import { styled, css } from '@mui/system'

export const StyledFormWrapper = styled('div')`
    ${({ theme }) => css`
        max-width: 100vw;
        height: 100%;
    `}
`

export const StyledInputsWrapper = styled('div')`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.contrastText};
        border-radius: 10px;
        height: fit-content;
    `}
`
