import { css } from '@mui/system'
import styled from '../../theme/styled'

export const StyledFieldGroup = styled('div')`
    ${({ theme }) => css`
        background-color: ${theme.palette.neutral.contrastText};
        border-radius: 10px;
        margin: 0 0 ${theme.spacing(2)} 0;
    `}
`
export const StyledItem = styled('div')<{
    noBorder: boolean
}>`
    width: 100%;
    ${({ theme, noBorder }) => css`
        display: flex;
        align-items: stretch;
        &:not(:last-of-type) {
            ${!noBorder &&
            css`
                border-bottom: 1px solid ${theme.palette.grey[200]};
            `}
        }
        &:last-of-type {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &:first-of-type {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    `}
`

export const StyledValueWrapper = styled('div')`
    ${({ theme }) => css`
        padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)};
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 350px;
    `}
`

export const StyledInputWrapper = styled('div')<{
    noMargin?: boolean
}>`
    ${({ noMargin }) => css`
        width: 100%;
        display: flex;
        align-items: center;

        ${!noMargin &&
        css`
            margin: 8px;
        `}
    `}
`

export const StyledLabel = styled('div')<{
    width?: string
}>`
    ${({ theme, width }) => css`
        display: flex;
        color: ${theme.palette.grey[600]};
        align-items: center;
        flex-basis: ${width || '160px'};
        min-height: 65px;
        flex-shrink: 0;
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
        clip-path: inset(0px -15px 0px 0px);
    `}
`
