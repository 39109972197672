export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14.424">
        <defs>
            <clipPath id="a">
                <path d="M0 0h12v14.424H0Z" fill="#213f6e" />
            </clipPath>
        </defs>
        <g>
            <g>
                <g clipPath="url(#a)">
                    <path
                        d="M1.691 14.424h8.619a1.663 1.663 0 0 0 1.663-1.663v-11.1A1.663 1.663 0 0 0 10.31-.002H1.691A1.663 1.663 0 0 0 .028 1.661v11.1a1.663 1.663 0 0 0 1.663 1.663ZM.929 1.663a.763.763 0 0 1 .762-.762h8.619a.762.762 0 0 1 .762.762v11.1a.762.762 0 0 1-.762.762H1.691a.762.762 0 0 1-.762-.761Z"
                        fill="#213f6e"
                    />
                </g>
                <text
                    transform="translate(6 6.424)"
                    fill="#213f6e"
                    fontSize="4"
                    fontFamily="FiraSans-Bold, Fira Sans"
                    fontWeight="700"
                >
                    <tspan x="-4.286" y="0">
                        DWG
                    </tspan>
                </text>
            </g>
            <path fill="none" stroke="#154072" d="M.5 9.924h3" />
            <g fill="#fff" stroke="#154072">
                <path stroke="none" d="M4 8.424h4v4H4z" />
                <path fill="none" d="M4.5 8.924h3v3h-3z" />
            </g>
            <path fill="none" stroke="#154072" d="M8.5 9.924h3" />
        </g>
    </svg>
)
