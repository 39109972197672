import { Theme } from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

const MuiAlert: OverridesStyleRules = {
    defaultProp: {},
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            borderRadius: '10px',
            boxShadow: theme.shadows[1],
        }),
    },
    variants: [
        {
            props: { variant: 'filled', severity: 'error' },
            style: ({ theme }: { theme: Theme }) => ({
                backgroundColor: theme.palette.error.dark,
            }),
        },
    ],
}

export default MuiAlert
