import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { Theme } from '@mui/system'

const MuiMenuItem: OverridesStyleRules = {
    defaultProp: {
        color: '',
    },
    styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
            '&&&&:hover': {
                color: theme.palette.secondary.main,
            },
            '&&.Mui-selected': {
                color: theme.palette.secondary.main,
                background: theme.palette.grey[100],
            },
        }),
    },
    variants: [],
}

export default MuiMenuItem
