import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import useQueryKeys from '../useQueryKeys'
import { createContainerApiData } from '../../api/data/container'
import { ContainerParams } from 'containers'
import containerService from '../../api/services/container'
import { AxiosError } from 'axios'
import { getTextError } from '../../utils/helpers'
import useNavUtils from '../useNavUtils'
export const useUpdateContainer = (containerID: string) => {
    const { setSuccessMsg, setErrorMsg } = useSnackbar()
    const queryClient = useQueryClient()
    const { updateKey, getQueryAllKey, getQueryOneKey } = useQueryKeys(QueryDataKey.Container)
    const { navigateContainer } = useNavUtils()

    return useMutation(
        (params: ContainerParams) => {
            return containerService.update(containerID, createContainerApiData(params))
        },
        {
            mutationKey: updateKey,
            onSuccess: async () => {
                await queryClient.invalidateQueries({ queryKey: getQueryAllKey() })

                await queryClient.invalidateQueries({
                    queryKey: getQueryOneKey([containerID]),
                })

                await queryClient.invalidateQueries({
                    queryKey: getQueryOneKey([containerID]),
                })

                setSuccessMsg(t('updated'))
                navigateContainer()('OVERVIEW_ALL')
            },
            onError: (err: AxiosError) => {
                setErrorMsg(getTextError(err))
            },
        }
    )
}
