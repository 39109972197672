import React, { useMemo } from 'react'
import TotalAndCityStats from './components/TotalAndCityStats'
import TaskStatusStats from './components/TaskStatusStats'
import TaskUrgencyStats from './components/TaskUrgencyStats'
import TaskCostStats from './components/TaskCostStats'
import { ObjectList } from 'objects'
import { ContainerList } from 'containers'
import { StyledStatWrapper } from './styles'
import { TaskList } from 'tasks'
import { StatsTools, StatsProvider } from '../../context/stats'
import theme from '../../theme'
import { useTranslation } from 'react-i18next'

interface Props {
    children: (components: typeof STATS_COMPONENTS) => JSX.Element | JSX.Element[]
}
export interface TotalAndCityStatsProps {
    title: string
    data: ContainerList[] | ObjectList[] | undefined
}
export interface TaskStatusStatsProps {
    title: string
    data: TaskList[] | undefined
}
export interface TaskUrgencyStatsProps {
    title: string
    data: TaskList[] | undefined
}
export interface TaskCostStatsProps {
    title: string
    data: TaskList[] | undefined
}

const STATS_COMPONENTS = {
    TotalAndCityStats,
    TaskStatusStats,
    TaskUrgencyStats,
    TaskCostStats,
} as const

const Stats = ({ children }: Props) => {
    const {
        i18n: { language },
    } = useTranslation()

    const chartTools: StatsTools = useMemo(
        () => ({
            drawInsideDoughnut: ({ ctx }, count, text) => {
                if (!count || !text) return
                ctx.restore()
                let fontSize = 2
                ctx.font = 'normal 900 ' + fontSize + 'rem Fira Sans,sans-serif'
                ctx.textBaseline = 'top'
                let textX = language === 'de' ? 23 : 28
                let textY = 55
                let countX = 45
                let countY = 25
                count.split('').forEach((v, i) => (countX -= 8))
                ctx.fillText(count, countX, countY)
                fontSize = 0.6
                ctx.font = fontSize + 'rem Fira Sans,sans-serif'
                ctx.fillText(text, textX, textY)
                ctx.save()
            },
            createTooltip: ({ chart, tooltip: tooltipModel }, valueCallback) => {
                let tooltipEl = document.getElementById('chartjs-tooltip')
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div')
                    tooltipEl.id = 'chartjs-tooltip'
                    tooltipEl.innerHTML = '<table></table>'
                    document.body.appendChild(tooltipEl)
                }

                if (!tooltipModel) return
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0'
                    return
                }

                if (tooltipModel.body) {
                    const bodyLines = tooltipModel.body
                    let innerHtml = '<thead>'
                    innerHtml += '</thead><tbody>'

                    bodyLines.forEach(function (body, i) {
                        let value = body.lines.join('').replace(',', '')
                        if (valueCallback) value = valueCallback(value)

                        let style = `background: ${theme.palette.common.white}`
                        style += `; border: 1px solid ${theme.palette.grey[300]}`
                        style += '; border-radius: 5px'
                        style += `; padding: ${theme.spacing(2)}`
                        const span = `<span style="${style}">${value} ${tooltipModel.title}</span>`
                        innerHtml += `<tr><td>${span}</td></tr>`
                    })
                    innerHtml += '</tbody>'

                    let tableRoot = tooltipEl.querySelector('table')
                    if (!tableRoot) return
                    tableRoot.innerHTML = innerHtml
                }

                const position = chart.canvas.getBoundingClientRect()

                tooltipEl.style.opacity = '1'
                tooltipEl.style.position = 'absolute'
                tooltipEl.style.left =
                    position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
                tooltipEl.style.fontFamily = 'Fira Sans'
                tooltipEl.style.pointerEvents = 'none'
            },
            colors: [
                theme.palette.primary.main,
                theme.palette.secondary.main,
                theme.palette.secondary.light,
                theme.palette.grey[900],
            ],
        }),
        [language]
    )

    return (
        <StatsProvider value={chartTools}>
            <StyledStatWrapper>{children(STATS_COMPONENTS)}</StyledStatWrapper>
        </StatsProvider>
    )
}

export default Stats
