import { FileData, FileDataResponse, FileGroupData, FileGroupResponseItem } from 'files'
import { MainDataTypes } from '../../types'
import { createListDateFormat } from '.'
import { getUrlExt } from '../../utils/helpers'

export const createGroupList = (data: Partial<FileGroupResponseItem>[]) => {
    const parseChild = (folders: FileGroupResponseItem[]): Partial<FileGroupData>[] => {
        return folders.map((folder: FileGroupResponseItem) => {
            if (folder?.children)
                return {
                    ...createGroupData(folder),
                    children: parseChild(Object.values(folder?.children)),
                }

            return createGroupData(folder)
        })
    }

    const newData = data.map((d, i) => {
        return {
            ...createGroupData(d),
            ...(d?.children && { children: parseChild(Object.values(d.children)) }),
        }
    })

    return newData
}

export const createGroupData = ({
    tid,
    vid,
    name,
    weight,
    children,
    hierarchy,
}: Partial<FileGroupResponseItem>): Partial<FileGroupData> => {
    //FIX: DRUPAL DATABASE NEEDS ALIAS :) - FIRST NEST LEVEL HAS VID INSTEAD TID PROPERTY AND COUSING DUPLICATES IDS
    return {
        id: (tid || `v${vid}`)?.toString(),
        title: name,
        weight,
        parents: (() => {
            const parentsWithPrefix = Object.values(hierarchy || { id: tid || `${vid}` }).map(
                (p, i) => (i === 0 ? `v${p}` : p)
            )
            return parentsWithPrefix
        })(),
        children: children as unknown as Partial<FileGroupData>[],
    }
}

export const createFileList = (data: FileDataResponse[]): FileData[] => {
    return data.map(
        ({
            alt,
            delete_allowed,
            description,
            display,
            fcid,
            fid,
            filemime,
            filename,
            filesize,
            full_url,
            status,
            timestamp,
            title,
            type,
            uid,
            thumbnail,
            uri,
        }: FileDataResponse) => ({
            id: fid,
            alt,
            deleteAllowed: delete_allowed,
            description,
            display,
            fcid,
            thumbnail,
            fid,
            name: filename,
            filemime,
            filename,
            filesize: `${Math.round((Number(filesize) / 1000000) * 10) / 10} mb`,
            fullUrl: full_url,
            ext: getUrlExt(full_url) || '',
            status,
            date: createListDateFormat(Number(timestamp)),
            timestamp,
            title,
            type,
            uid,
            uri,
            dataType: MainDataTypes.File,
        })
    )
}
