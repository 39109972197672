import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { useCreateApartment } from '../../../../../../hooks/apartment/useCreateApartment'
import FormApartment from '../../../../../../forms/FormApartment'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import useNavUtils from '../../../../../../hooks/useNavUtils'

const CreateApartment = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()

    const { mutate: create, isLoading } = useCreateApartment()
    const { data: attributes } = useGetAttributes()

    return (
        <Modal title={t('apartment.new_apartment')} onClose={goBack}>
            <FormApartment
                onCancelClick={goBack}
                isLoading={isLoading}
                onSubmit={create}
                attributes={attributes}
            />
        </Modal>
    )
}

export default CreateApartment
