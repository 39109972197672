import { Typography } from '@mui/material'
import { styled, css } from '@mui/system'

export const StyledDialogHeader = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing(2)} ${theme.spacing(5)};
        margin: 0 0 ${theme.spacing(3)};
        gap: ${theme.spacing(5)};
        width: 100%;
        box-shadow: 0px 2px 12px 0px rgba(117, 117, 117, 0.12);

        p {
            font-weight: 600;
            font-size: 0.825rem;
        }
    `}
`
export const StyledDialogContentWrapper = styled('div')`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
    `}
`
export const StyledDialogBody = styled('div')`
    ${({ theme }) => css`
        padding: ${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)};
    `}
`
export const StyledDialogContent = styled(Typography)`
    ${({ theme }) => css`
        text-align: center;
        max-width: 300px;
    `}
`
export const StyledDialogFooter = styled('div')`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${theme.spacing(3)};
        padding: ${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(8)};
    `}
`
