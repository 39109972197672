import React, { ForwardedRef, PropsWithChildren, forwardRef } from 'react'
import { StyledCollapse, StyledTreeItem, StyledTreeItemLabel } from './styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { OnDropFunction } from '../..'
import FileUpload from '../../../../modules/FileIUpload'

interface Props {
    label: string
    isActive: boolean
    isExpanded: boolean
    isDropAllow?: boolean
    uploadPath?: string
    isUploadFile?: boolean
    onDrop?: OnDropFunction
    onUpload?: (files: File[]) => void
    onClick: () => void
    onDragEnter: () => void
}

const DataTreeItem = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    (
        {
            children,
            label,
            isActive,
            isDropAllow,
            isExpanded,
            isUploadFile,
            uploadPath,
            onUpload,
            onDrop,
            onDragEnter,
            onClick,
        },
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <StyledTreeItem>
                <FileUpload
                    isDropAllow={!!isDropAllow}
                    variant={isUploadFile ? 'upload' : 'drop'}
                    uploadPath={uploadPath}
                    overContainer
                    onDrop={onDrop}
                    onUpload={onUpload}
                    onDragEnter={onDragEnter}
                >
                    <StyledTreeItemLabel isActive={isActive} onClick={onClick} ref={ref}>
                        {children && (
                            <ArrowRightIcon
                                fontSize="small"
                                sx={{
                                    transition: '300ms',
                                    transform: `rotate(${isExpanded ? '90deg' : 0})`,
                                }}
                            />
                        )}

                        {label}
                    </StyledTreeItemLabel>
                </FileUpload>
                <StyledCollapse in={isExpanded}>{children}</StyledCollapse>
            </StyledTreeItem>
        )
    }
)

export default DataTreeItem
