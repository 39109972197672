import { Option } from 'forms'
import { MainLanguages } from '../types'

export const TOKEN_KEY = 'auth_token'
export const STATUS_SUCCESS = 200
export const TOAST_TRANSITION_DURATION = 300
export const SEARCH_INPUT_MIN = 2

export const AUTH_ERRORS = {
    INCORRECT_CREDENTIALS: 'Falscher Benutzername oder Passwort.',
    INVALID_TOKEN: 'CSRF validation failed',
}

export const PAGE_SIZES: Option<number>[] = [
    {
        id: '10',
        value: 10,
    },
    {
        id: '30',
        value: 30,
    },
    {
        id: '50',
        value: 50,
    },
    {
        id: '100',
        value: 100,
    },
]
export const MONTH_DATE_FORMAT = 'MM/yyyy'
export const MONTH_API_DATE_FORMAT = 'MM.yyyy'
export const LANGUAGES = Object.entries(MainLanguages).map(([key, value]) => ({
    code: key as keyof typeof MainLanguages,
    lang: value,
}))
