import { Box, SxProps, TextField } from '@mui/material'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import theme from '../../../theme/index'
import { StyledFormItem } from '../../../theme/styles'
import { StyledCalendarIconWrapper } from './styles'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import DataItem from '../../../components/DataItem'

interface Props {
    label: string
    name: string
    noBorder?: boolean
    format: string
}

const DateInput: FC<Props> = ({ label, name, noBorder, format }) => {
    const { control } = useFormContext()

    const popperSx: SxProps = {
        width: '290px',
        height: '300px',
        '& .MuiPaper-root': {
            marginTop: 1,
            borderRadius: 2,
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.grey['50'],
            width: '290px',
        },
        '& .MuiCalendarOrClockPicker-root': {
            '& > div': {
                width: '290px',
                height: '300px',
            },
        },
        '& .MuiPickersCalendarHeader-root': {
            paddingBottom: 4,
            borderBottom: `1px solid ${theme.palette.grey['200']}`,
            maxHeight: '70px',
        },
        '& .PrivatePickersSlideTransition-root': {
            minHeight: '200px',
        },
        '& .MuiCalendarPicker-root': {
            width: '290px',
            height: '300px',
        },
        '& .MuiPickersDay-root': {
            borderRadius: '2px',
            width: '26px',
            height: '26px',
            border: `1px solid ${theme.palette.grey['200']}`,
        },
        '& .MuiDayPicker-weekContainer': {
            gap: 2,
            marginBottom: 1,
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiYearPicker-root ': {
            maxHeight: '282px',
        },
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledFormItem noBorder={noBorder}>
                <DataItem variant="input" label={label}>
                    <Box width={'100%'} sx={{ display: 'flex' }}>
                        <Controller
                            name={name}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <DesktopDatePicker
                                        views={['month', 'year']}
                                        inputFormat={format}
                                        PopperProps={{
                                            sx: popperSx,
                                        }}
                                        components={{
                                            OpenPickerIcon: CalendarMonthOutlinedIcon,
                                            LeftArrowIcon: ArrowLeftIcon,
                                            RightArrowIcon: ArrowRightIcon,
                                        }}
                                        defaultCalendarMonth={true}
                                        value={value || undefined}
                                        onChange={onChange}
                                        renderInput={({
                                            inputRef,
                                            inputProps,
                                            InputProps,
                                            ...rest
                                        }) => (
                                            <Box display="flex" alignItems="center" width="100%">
                                                <TextField
                                                    {...rest}
                                                    ref={inputRef}
                                                    inputProps={inputProps}
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                                <StyledCalendarIconWrapper>
                                                    {InputProps?.endAdornment}
                                                </StyledCalendarIconWrapper>
                                            </Box>
                                        )}
                                    />
                                )
                            }}
                        />
                    </Box>
                </DataItem>
            </StyledFormItem>
        </LocalizationProvider>
    )
}

export default DateInput
