import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
    PATHS,
    PRIVATE_ROUTES,
    AUTH_ROUTES,
    RouteProps,
    PUBLIC_PAGE_ROUTES,
    CONTAINER_PATHS,
} from './routes'
import RequireAuth from './RequireAuth'

import Public from '../pages/Public'
import SignOut from '../pages/SignOut'
import NotFound from '../pages/NotFound'

const renderRoutes = (routes: Array<RouteProps>): any => {
    return routes.map((route, index) => (
        <Route
            key={index}
            path={route.path}
            element={
                <RequireAuth>
                    <route.element />
                </RequireAuth>
            }
            index={route.index}
        >
            {Boolean(route.children) && renderRoutes(route.children as Array<RouteProps>)}
        </Route>
    ))
}

const Router = () => {
    return (
        <Routes>
            <Route
                path={PATHS.DASHBOARD}
                element={<Navigate to={CONTAINER_PATHS.OVERVIEW_ALL} />}
            />
            <Route element={<Public />}>
                {AUTH_ROUTES.map((route, index) => (
                    <Route key={index} path={route.path} element={<route.element />} />
                ))}
                {PUBLIC_PAGE_ROUTES.map((route, index) => (
                    <Route key={index} path={route.path} element={<route.element />} />
                ))}
            </Route>
            {renderRoutes(PRIVATE_ROUTES)}
            <Route path={PATHS.SIGNOUT} element={<SignOut />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Router
