import { QueryDataKey } from '../../types'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useQueryKeys from '../useQueryKeys'
import { createObjectList } from '../../api/data/object'
import objectService from '../../api/services/object'
import { ObjectList } from 'objects'

export const useGetObjects = (
    containerID?: string,
    options?: UseQueryOptions
): UseQueryResult<ObjectList[]> => {
    const { getQueryAllKey } = useQueryKeys(QueryDataKey.Object)
    const { queryKey, ...restOptions } = options || {}

    return useQuery({
        queryKey: getQueryAllKey(queryKey),
        ...restOptions,
        queryFn: async () => {
            const { data } = await objectService.getAll(containerID)
            return createObjectList(data)
        },
        initialData: [],
        placeholderData: [],
    }) as UseQueryResult<ObjectList[]>
}
