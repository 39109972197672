import create from 'zustand'

interface SearchState {
    inputValue: string
    setInputValue: (e: React.ChangeEvent<HTMLInputElement>) => void
    resetValue: () => void
}

const useSearchStore = create<SearchState>((set) => ({
    inputValue: '',
    setInputValue: (e: React.ChangeEvent<HTMLInputElement>) =>
        set((state) => ({
            ...state,
            inputValue: e.target.value,
        })),
    resetValue: () =>
        set((state) => ({
            ...state,
            inputValue: '',
        })),
}))

export default useSearchStore
