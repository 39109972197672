import React from 'react'
import Modal from '../../../../../../components/Modal'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { useTranslation } from 'react-i18next'
import useGetTypes from '../../../../../../hooks/other/useGetTypes'
import useGetAttributes from '../../../../../../hooks/other/useGetAttributes'
import FormObject from '../../../../../../forms/FormObject'
import useParamManage from '../../../../../../hooks/useParamManage'
import { useGetContainer } from '../../../../../../hooks/container/useGetContainer'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import { useCreateObject } from '../../../../../../hooks/object/useCreateObject'

const CreateObject = () => {
    const { t } = useTranslation()
    const { goBack } = useNavUtils()
    const { containerID } = useParamManage()
    const { data: types = [], isLoading: isLoadingTypes } = useGetTypes()
    const { data: attributes = [], isLoading: isLoadingAttr } = useGetAttributes()
    const { mutate: createObject, isLoading: isLoadingCreate } = useCreateObject()

    const { data: container } = useGetContainer(containerID, {
        queryKey: [containerID],
    })

    return (
        <Modal title={t('object.new_object')} onClose={goBack}>
            <LoaderWrapper isLoading={isLoadingTypes || isLoadingAttr}>
                <FormObject
                    types={types}
                    container={container}
                    onCancelClick={goBack}
                    attributes={attributes}
                    isLoading={isLoadingCreate}
                    onSubmit={createObject}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default CreateObject
