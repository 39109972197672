import React from 'react'
import Modal from '../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import useNavUtils from '../../../../../../hooks/useNavUtils'
import { IconButton } from '@mui/material'
import LoaderWrapper from '../../../../../../components/LoaderWrapper'
import FormMaintenance from '../../../../../../forms/FormMaintenance'
import { useGetMaintenance } from '../../../../../../hooks/maintenance/useGetMaintenance'
import useParamManage from '../../../../../../hooks/useParamManage'
import { useGetTasks } from '../../../../../../hooks/task/useGetTasks'
import useGetMepTypes from '../../../../../../hooks/other/useGetMepTypes'
import EditIcon from '@mui/icons-material/Edit'
import { useUpdateMaintenance } from '../../../../../../hooks/maintenance/useUpdateMaintenance'

const ReadMaintenance = () => {
    const { t } = useTranslation()
    const { data: types } = useGetMepTypes()
    const { navigateMaintenance, navigateTask } = useNavUtils()
    const { maintenanceID } = useParamManage()

    const { data: tasks, isLoading: isLoadingReadTasks } = useGetTasks(maintenanceID, {
        queryKey: [maintenanceID],
    })

    const { mutate: updateMaintenance, isLoading: isLoadingUpdate } =
        useUpdateMaintenance(maintenanceID)

    const { data: maintenance, isLoading: isLoadingGet } = useGetMaintenance(maintenanceID, {
        queryKey: [maintenanceID],
    })

    const handleEditMaintenance = () => navigateMaintenance(maintenanceID)('UPDATE')

    const handleCreateTask = () => navigateTask()('CREATE')

    const handleGoBack = () => navigateMaintenance('')('LIST')

    return (
        <Modal
            title={t('maintenance.title')}
            onClose={handleGoBack}
            headerElement={
                <IconButton color="primary_filled" size="large" onClick={handleEditMaintenance}>
                    <EditIcon fontSize="small" />
                </IconButton>
            }
        >
            <LoaderWrapper isLoading={Boolean(isLoadingReadTasks || isLoadingGet)} center>
                <FormMaintenance
                    types={types}
                    onSubmit={updateMaintenance}
                    isLoading={isLoadingUpdate}
                    maintenance={maintenance}
                    onCreateTask={handleCreateTask}
                    onCancelClick={handleGoBack}
                    tasks={tasks}
                    isLoadingTask={isLoadingReadTasks}
                />
            </LoaderWrapper>
        </Modal>
    )
}

export default ReadMaintenance
