import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useParams } from 'react-router'
import { useEffectOnce } from 'usehooks-ts'
import useUserStore from '../../../store/user'
import { useSignInResetPassword } from '../../../hooks/auth/useSignInResetPassword'

const ResetPasswordValidation = () => {
    const params = useParams()
    const { setUser } = useUserStore()
    const { mutate: resetPassSignIn } = useSignInResetPassword()

    useEffectOnce(() => {
        resetPassSignIn({
            uid: params.uid,
            timestamp: params.timestamp,
            hashed_pass: params.token,
        })
        params?.uid && setUser(params?.uid)
    })

    return (
        <Box display={'flex'} justifyContent="center">
            <CircularProgress />
        </Box>
    )
}

export default ResetPasswordValidation
