import theme from '../../../../theme'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Box, Button, Typography } from '@mui/material'
import useSearchStore from '../../../../store/search'
import Search from '../../../../components/Search'
import { useMemo } from 'react'
import { Column } from 'react-table'
import { useGetMaintenances } from '../../../../hooks/maintenance/useGetMaintenances'
import Table from '../../../../components/Table'
import LoaderWrapper from '../../../../components/LoaderWrapper'
import { MaintenanceList } from 'maintenance'
import { useTranslation } from 'react-i18next'
import useParamManage from '../../../../hooks/useParamManage'
import useNavUtils from '../../../../hooks/useNavUtils'
import { MainData } from '../../../../types'
import GridItem from '../../../../modules/GridItem'
import { StyledGridWrapper } from '../../../../theme/styles'
type Action = 'read' | 'create' | 'update' | 'delete' | 'cell-click'

const Maintenance = () => {
    const { t } = useTranslation()
    const { lastParamID } = useParamManage()
    const { navigateMaintenance } = useNavUtils()
    const { inputValue: searchValue, setInputValue } = useSearchStore()

    const { data: maintenances, isLoading: isLoadingGetList } = useGetMaintenances(lastParamID, {
        queryKey: [lastParamID],
        refetchOnMount: false,
    })

    const handleNavigate = (action: Action) => (id: string) => {
        const navigate = navigateMaintenance(id || lastParamID)

        switch (action) {
            case 'create':
                return navigate('CREATE')
            case 'update':
                return navigate('UPDATE')
            case 'delete':
                return navigate('DELETE')
            case 'read':
            case 'cell-click':
            default:
                return navigate('READ')
        }
    }

    const columns: Column<MaintenanceList>[] = useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: 'nid',
                size: 1,
                width: 70,
                maxWidth: 70,
            },
            {
                Header: t('title'),
                accessor: 'title',
                size: 1,
            },
            {
                Header: t('name'),
                accessor: 'name',
                size: 1,
            },
            {
                Header: t('location'),
                accessor: 'location',
                size: 1,
            },
            {
                Header: t('entity_type'),
                accessor: (maintenance) => {
                    return `${t(maintenance.entityType?.id || '')}`
                },
                size: 1,
            },
            {
                Header: t('entity_title'),
                accessor: 'entityTitle',
                size: 1,
            },
            {
                Header: t('type'),
                accessor: 'type',
                size: 1,
            },
            {
                Header: t('responsible'),
                accessor: 'responsible',
                size: 1,
            },
        ]
    }, [t])

    const renderGridItem = (gridItem: MainData) => {
        if (!('parentID' && 'images' in gridItem)) return

        const { id, title, images } = gridItem

        return (
            <GridItem
                id={id}
                title={title}
                variant="basic"
                image={images?.[0]}
                onEdit={handleNavigate('update')}
                onDelete={handleNavigate('delete')}
                onClick={handleNavigate('cell-click')}
            />
        )
    }

    return (
        <>
            <Box display={'flex'} justifyContent="space-between" alignItems={'center'} mb={3}>
                <Typography color={theme.palette.secondary.main} fontWeight={600}>
                    {t('maintenance.maintenances')}
                </Typography>

                <Box display="flex" gap={theme.spacing(3)}>
                    <Search
                        placeholder={t(`search`)}
                        value={searchValue}
                        onInputChange={setInputValue}
                    />

                    {lastParamID && (
                        <Button
                            startIcon={<DriveFileRenameOutlineIcon />}
                            size="large"
                            variant="contained"
                            onClick={() => handleNavigate('create')('')}
                        >
                            {t('maintenance.new')}
                        </Button>
                    )}
                </Box>
            </Box>

            <LoaderWrapper isLoading={isLoadingGetList}>
                <Table
                    onEdit={handleNavigate('update')}
                    onDelete={handleNavigate('delete')}
                    columns={columns}
                    GridWrapperElement={StyledGridWrapper}
                    renderGridItem={renderGridItem}
                    data={maintenances || []}
                    onCellClick={handleNavigate('cell-click')}
                />
            </LoaderWrapper>
        </>
    )
}

export default Maintenance
