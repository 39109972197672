import { QueryDataKey } from '../../types/index'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackbar } from '../useSnackbar'
import { t } from 'i18next'
import taskService from '../../api/services/task'
import useQueryKeys from '../useQueryKeys'
import useNavUtils from '../useNavUtils'
import useParamManage from '../useParamManage'
export const useDeleteTask = () => {
    const { setSuccessMsg } = useSnackbar()
    const { deleteKey, getQueryAllKey } = useQueryKeys(QueryDataKey.Task)
    const queryClient = useQueryClient()
    const { navigateTask, navigateMaintenance } = useNavUtils()
    const { maintenanceID } = useParamManage()

    return useMutation(
        async (taskID: string) => {
            return taskService.delete(taskID)
        },
        {
            mutationKey: deleteKey,
            onSuccess: async () => {
                setSuccessMsg(t('deleted'))
                await queryClient.invalidateQueries({ queryKey: getQueryAllKey([maintenanceID]) })

                if (maintenanceID) return navigateMaintenance(maintenanceID)('READ')
                navigateTask()('LIST')
            },
        }
    )
}
