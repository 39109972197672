import { Box, capitalize } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, TableInstance } from 'react-table'
import HighlightMatch from '../../components/HighlightMatch'
import Table from '../../components/Table'
import { TASK_PRIORITY, TASK_STATUS } from '../../constant/taxonomy'
import FormStatus from '../../forms/FormStatus'
import { useUpdateTask } from '../../hooks/task/useUpdateTask'
import useNavUtils from '../../hooks/useNavUtils'
import useParamManage from '../../hooks/useParamManage'
import { StyledGridWrapper } from '../../theme/styles'
import { MainData } from '../../types'
import { TaskList, TaskParams } from '../../types/tasks'
import GridItem from '../GridItem'
interface Props {
    tasks: TaskList[]
    renderOnBottom?: (renderPagination: () => JSX.Element | null) => JSX.Element
    isLoading?: boolean
}
type Action = 'read' | 'create' | 'update' | 'delete' | 'cell-click'

const TaskListData: FC<Props> = ({ tasks, renderOnBottom, isLoading }) => {
    const { t } = useTranslation()
    const { lastParamID } = useParamManage()
    const [task, setTask] = useState<string>('')
    const { mutate: updateTask, isLoading: isLoadingUpdate } = useUpdateTask()
    const { navigateTask } = useNavUtils()

    const isLoadingStatus = isLoading || isLoadingUpdate

    const columns: Column<TaskList>[] = useMemo(() => {
        return [
            {
                accessor: 'nid',
                size: 1,
            },
            {
                Header: t('title'),
                accessor: 'title',
                size: 1,
            },
            {
                Header: t('task.priority'),
                accessor: 'priority',
                size: 1,
                Cell: ({
                    cell: {
                        row: { original: task },
                    },
                }: TableInstance<TaskList>) => {
                    const { id = '', Icon } =
                        TASK_PRIORITY?.find(({ value }) => value === task.priority) || {}

                    return (
                        <Box display="flex" gap={1} alignItems={'center'}>
                            {Icon} <HighlightMatch>{capitalize(t(id))}</HighlightMatch>
                        </Box>
                    )
                },
            },
            {
                Header: t('task.cost'),
                accessor: 'cost',
                Cell: ({
                    cell: {
                        row: { original: task },
                    },
                }: TableInstance<TaskList>) => task.formattedCost,
                size: 1,
            },
            {
                Header: t('task.short_description'),
                accessor: 'shortDescription',
                size: 1,
            },
            {
                Header: t('status'),
                accessor: (task) => {
                    const { id = '' } =
                        TASK_STATUS?.find(({ value }) => value === task.status) || {}
                    return t(id)
                },
                size: 1,
                Cell: ({
                    cell: {
                        row: {
                            values: { nid: currentTaskID },
                            original: { status },
                        },
                    },
                }: TableInstance<TaskList>) => {
                    const handleStatusChange = ({ id, status }: Partial<TaskParams>) => {
                        id && setTask(id)
                        updateTask({ id, status })
                    }
                    return (
                        <Box
                            minHeight={'31px'}
                            display="flex"
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <FormStatus
                                id={currentTaskID}
                                isLoading={task === currentTaskID && isLoadingStatus}
                                onChange={handleStatusChange}
                                defaultValue={status}
                            />
                        </Box>
                    )
                },
            },
            {
                Header: t('entity_title'),
                accessor: 'entityTitle',
                size: 1,
                Cell: ({
                    cell: {
                        row: {
                            original: { entityTitle },
                        },
                    },
                }) => {
                    return <HighlightMatch>{entityTitle}</HighlightMatch>
                },
            },
            {
                Header: t('entity_type'),
                accessor: (task) => `${t(task.entityType?.id || '')}`,
                size: 1,
            },
            {
                Header: t('responsible'),
                accessor: 'responsible',
                size: 1,
            },
            {
                Header: t('last_edited'),
                accessor: 'changed',
                size: 1,
            },
            {
                Header: t('created'),
                accessor: 'created',
                size: 1,
            },
        ]
    }, [isLoadingStatus, updateTask, t, task])

    const handleNavigate = (action: Action) => (id: string) => {
        const navigate = navigateTask(id || lastParamID)

        switch (action) {
            case 'create':
                return navigate('CREATE')
            case 'update':
                return navigate('UPDATE')
            case 'delete':
                return navigate('DELETE')
            case 'read':
            case 'cell-click':
            default:
                return navigate('READ')
        }
    }

    const renderGridItem = (gridItem: MainData) => {
        if (!('entityID' in gridItem)) return

        const { id, title, entityID } = gridItem

        return (
            <GridItem
                id={id}
                parentID={entityID}
                title={title}
                variant="basic"
                onEdit={handleNavigate('update')}
                onDelete={handleNavigate('delete')}
                onClick={handleNavigate('cell-click')}
            />
        )
    }

    const isColClickable = (item: MainData, columnID: string | undefined): boolean =>
        Boolean(columnID !== 'Status')

    return (
        <Table
            onEdit={handleNavigate('update')}
            onDelete={handleNavigate('delete')}
            columns={columns}
            data={tasks}
            GridWrapperElement={StyledGridWrapper}
            renderGridItem={renderGridItem}
            isColClickable={isColClickable}
            renderOnBottom={renderOnBottom}
            onCellClick={handleNavigate('cell-click')}
            isLoading={isLoading}
        />
    )
}

export default TaskListData
