import React from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import { ResetPasswordParams } from '../../../types/auth'
import { PATHS } from '../../../routes/routes'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import Divider from '@mui/material/Divider'
import { StyledButtonWrapper, StyledLink, StyledLinks } from '../../../theme/styles'
import { LoadingButton } from '@mui/lab'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Link } from 'react-router-dom'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import shema from './shema'
import { useResetPassword } from '../../../hooks/auth/useResetPassword'

type Props = {}

const ResetPassword = (props: Props) => {
    const { t } = useTranslation()
    const { control, handleSubmit } = useForm<ResetPasswordParams>({
        resolver: yupResolver(shema),
    })

    const onSubmit = ({ email }: ResetPasswordParams) => {
        resetPassMutate({ name: email })
    }
    const email = useWatch({
        control,
        name: 'email',
    })

    const { mutate: resetPassMutate, isLoading } = useResetPassword(email)

    return (
        <Box>
            <Box mb={6}>
                <Typography variant="h3">{t('reset_password')}</Typography>
            </Box>
            <Box mb={7}>
                <Typography variant="body1">{t('reset_password_page.content')}</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={4}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                error={!!error}
                                helperText={error ? t(`${error.message}`) : null}
                                fullWidth
                                value={value}
                                onChange={onChange}
                                name="email"
                                label={t('email_address')}
                            />
                        )}
                    />
                </Box>
                <StyledButtonWrapper>
                    <Button
                        component={Link}
                        to={PATHS.SIGNIN}
                        size="large"
                        variant="text"
                        startIcon={<ArrowLeftIcon />}
                        sx={{
                            width: '60%',
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        loading={isLoading}
                        endIcon={<ArrowRightIcon />}
                        fullWidth
                    >
                        {t('reset_password')}
                    </LoadingButton>
                </StyledButtonWrapper>
                <Divider />
                <StyledLinks>
                    <StyledLink to={PATHS.SUPPORT}>{t('contact_support')}</StyledLink>
                </StyledLinks>
            </form>
        </Box>
    )
}

export default ResetPassword
