import { ElementType } from 'react'

//PUBLIC pages
import ActivationLink from '../pages/Public/ActivationLink'
import EmailSent from '../pages/Public/EmailSent'
import ResetPassword from '../pages/Public/ResetPassword'
import SignIn from '../pages/Public/SignIn'
import SignUp from '../pages/Public/SignUp'
import Support from '../pages/Public/Support'

//PRIVATE pages
import Dashboard from '../pages/Dashboard'
import CreateApartment from '../pages/Dashboard/pages/Apartment/pages/CreateApartment'
import DeleteApartment from '../pages/Dashboard/pages/Apartment/pages/DeleteApartment'
import GeneralInfoApartment from '../pages/Dashboard/pages/Apartment/pages/GeneralInfoApartment'
import UpdateApartment from '../pages/Dashboard/pages/Apartment/pages/UpdateApartment'
import CompanySettings from '../pages/Dashboard/pages/CompanySettings'
import CreateContainer from '../pages/Dashboard/pages/Container/pages/CreateContainer'
import DeleteContainer from '../pages/Dashboard/pages/Container/pages/DeleteContainer'
import GeneralInfoContainer from '../pages/Dashboard/pages/Container/pages/GeneralInfoContainer'
import OverviewContainer from '../pages/Dashboard/pages/Container/pages/OverviewContainer'
import UpdateContainer from '../pages/Dashboard/pages/Container/pages/UpdateContainer'
import Files from '../pages/Dashboard/pages/Files'
import FileSettings from '../pages/Dashboard/pages/FileSettings'
import Maintenance from '../pages/Dashboard/pages/Maintenance'
import CreateMaitenance from '../pages/Dashboard/pages/Maintenance/pages/CreateMaintenance'
import DeleteMaintenance from '../pages/Dashboard/pages/Maintenance/pages/DeleteMaintenance'
import ReadMaintenance from '../pages/Dashboard/pages/Maintenance/pages/ReadMaintenance'
import UpdateMaintenance from '../pages/Dashboard/pages/Maintenance/pages/UpdateMaintenance'
import CreateObject from '../pages/Dashboard/pages/Object/pages/CreateObject'
import DeleteObject from '../pages/Dashboard/pages/Object/pages/DeleteObject'
import GeneralInfoObject from '../pages/Dashboard/pages/Object/pages/GeneralInfoObject'
import OverviewObject from '../pages/Dashboard/pages/Object/pages/OverviewObject'
import UpdateObject from '../pages/Dashboard/pages/Object/pages/UpdateObject'
import Overview from '../pages/Dashboard/pages/Overview'
import OverviewAllContainers from '../pages/Dashboard/pages/Overview/pages/OverviewContainers'
import OverviewAllObjects from '../pages/Dashboard/pages/Overview/pages/OverviewObjects'
import Task from '../pages/Dashboard/pages/Task'
import CreateTask from '../pages/Dashboard/pages/Task/pages/CreateTask'
import DeleteTask from '../pages/Dashboard/pages/Task/pages/DeleteTask'
import ReadTask from '../pages/Dashboard/pages/Task/pages/ReadTask'
import UpdateTask from '../pages/Dashboard/pages/Task/pages/UpdateTask'
import CreateUnit from '../pages/Dashboard/pages/Unit/pages/CreateUnit'
import DeleteUnit from '../pages/Dashboard/pages/Unit/pages/DeleteUnit'
import GeneralInfoUnit from '../pages/Dashboard/pages/Unit/pages/GeneralInfoUnit'
import OverviewUnit from '../pages/Dashboard/pages/Unit/pages/OverviewUnit'
import UpdateUnit from '../pages/Dashboard/pages/Unit/pages/UpdateUnit'
import UserSettings from '../pages/Dashboard/pages/UserSettings'
import UserSettingsPassword from '../pages/Dashboard/pages/UserSettings/pages/UserSettingsPassword'
import UserSettingsPersonal from '../pages/Dashboard/pages/UserSettings/pages/UserSettingsPersonal'
import CompanyDetails from '../pages/Public/CompanyDetails'
import NewPassword from '../pages/Public/NewPassowrd'
import PrivacyPolicy from '../pages/Public/PrivacyPolicy'
import ResetPasswordEmailSent from '../pages/Public/ResetPasswordEmailSent'
import ResetPasswordValidation from '../pages/Public/ResetPasswordValidation'
import Terms from '../pages/Public/Terms'

export const APARTMENT_PATHS = {
    GENERAL_INFORMATION:
        '/general-information/apartment/:containerID/:objectID/:unitID/:apartmentID',
    OVERVIEW: '/overview/apartment/:containerID/:objectID/:unitID/:apartmentID',
    MAINTENANCE: '/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID',
    CREATE: '/create/apartment/:containerID/:objectID/:unitID',
    UPDATE: '/edit/apartment/:containerID/:objectID/:unitID/:apartmentID',
    DELETE: '/delete/apartment/:containerID/:objectID/:unitID/:apartmentID',
    TASK: '/task/apartment/:containerID/:objectID/:unitID/:apartmentID',
}
export const UNIT_PATHS = {
    GENERAL_INFORMATION: '/general-information/unit/:containerID/:objectID/:unitID',
    OVERVIEW: '/overview/unit/:containerID/:objectID/:unitID',
    MAINTENANCE: '/maintenance/unit/:containerID/:objectID/:unitID',
    TASK: '/task/unit/:containerID/:objectID/:unitID',
    CREATE: '/create/unit/:containerID/:objectID',
    UPDATE: '/edit/unit/:containerID/:objectID/:unitID',
    DELETE: '/delete/unit/:containerID/:objectID/:unitID',
    FILE: '/files/unit/:containerID/:objectID/:unitID',
}
export const CONTAINER_PATHS = {
    GENERAL_INFORMATION: '/general-information/container/:containerID',
    OVERVIEW: '/overview/container/:containerID',
    MAINTENANCE: '/maintenance/container/:containerID',
    CREATE: '/create/container',
    UPDATE: '/edit/container/:containerID',
    DELETE: '/delete/container/:containerID',
    OVERVIEW_ALL: '/overview/containers',
} as const

export const OBJECT_PATHS = {
    GENERAL_INFORMATION: '/general-information/object/:containerID/:objectID',
    OVERVIEW: '/overview/object/:containerID/:objectID',
    MAINTENANCE: '/maintenance/object/:containerID/:objectID',
    TASK: '/task/object/:containerID/:objectID',
    CREATE: '/create/object/:containerID',
    UPDATE: '/update/object/:containerID/:objectID',
    DELETE: '/delete/object/:containerID/:objectID',
    FILE: '/files/object/:containerID/:objectID',
    OVERVIEW_ALL: '/overview/objects',
} as const

export const MAINTENANCE_PATHS = {
    LIST: '/maintenance',
    LIST_OBJECT: '/maintenance/object/:containerID/:objectID',
    LIST_UNIT: '/maintenance/unit/:containerID/:objectID/:unitID',
    LIST_APARTMENT: '/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID',
    CREATE_OBJECT: '/create/maintenance/object/:containerID/:objectID',
    CREATE_UNIT: '/create/maintenance/unit/:containerID/:objectID/:unitID',
    CREATE_APARTMENT: '/create/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID',
    READ: '/maintenance/:maintenanceID',
    READ_OBJECT: '/maintenance/object/:containerID/:objectID/:maintenanceID',
    READ_UNIT: '/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID',
    READ_APARTMENT:
        '/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID',
    UPDATE: '/edit/maintenance/:maintenanceID',
    UPDATE_OBJECT: '/edit/maintenance/object/:containerID/:objectID/:maintenanceID',
    UPDATE_UNIT: '/edit/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID',
    UPDATE_APARTMENT:
        '/edit/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID',
    DELETE: '/delete/maintenance/:maintenanceID',
    DELETE_UNIT: '/delete/maintenance/apartment/:containerID/:objectID/:unitID/:maintenanceID',
    DELETE_OBJECT: '/delete/maintenance/apartment/:containerID/:objectID/:maintenanceID',
    DELETE_APARTMENT:
        '/delete/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID',
} as const

export const TASK_IN_MAINTENANCES_PATHS = {
    CREATE: '/create/task/maintenance/:maintenanceID',
    CREATE_OBJECT: '/create/task/maintenance/object/:containerID/:objectID/:maintenanceID',
    CREATE_UNIT: '/create/task/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID',
    CREATE_APARTMENT:
        '/create/task/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID',
    READ: '/task/maintenance/:maintenanceID/:taskID',
    READ_APARTMENT:
        '/task/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID/:taskID',
    READ_UNIT: '/task/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID/:taskID',
    READ_OBJECT: '/task/maintenance/object/:containerID/:objectID/:maintenanceID/:taskID',
    UPDATE: '/edit/maintenance/task/:maintenanceID/:taskID',
    UPDATE_OBJECT: '/edit/task/maintenance/object/:containerID/:objectID/:maintenanceID/:taskID',
    UPDATE_UNIT:
        '/edit/task/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID/:taskID',
    UPDATE_APARTMENT:
        '/edit/task/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID/:taskID',
    DELETE: '/delete/task/maintenance/:maintenanceID/:taskID',
    DELETE_OBJECT: '/delete/task/maintenance/object/:containerID/:objectID/:maintenanceID/:taskID',
    DELETE_UNIT:
        '/delete/task/maintenance/unit/:containerID/:objectID/:unitID/:maintenanceID/:taskID',
    DELETE_APARTMENT:
        '/delete/task/maintenance/apartment/:containerID/:objectID/:unitID/:apartmentID/:maintenanceID/:taskID',
}

export const TASK_PATHS = {
    LIST: '/task',
    LIST_OBJECT: '/task/object/:containerID/:objectID',
    LIST_UNIT: '/task/unit/:containerID/:objectID/:unitID',
    LIST_APARTMENT: '/task/apartment/:containerID/:objectID/:unitID/:apartmentID',
    CREATE_OBJECT: '/create/task/object/:containerID/:objectID',
    CREATE_UNIT: '/create/task/unit/:containerID/:objectID/:unitID',
    CREATE_APARTMENT: '/create/task/apartment/:containerID/:objectID/:unitID/:apartmentID',
    READ_OBJECT: '/task/object/:containerID/:objectID/:taskID',
    READ: '/task/:taskID',
    READ_UNIT: '/task/unit/:containerID/:objectID/:unitID/:taskID',
    READ_APARTMENT: '/task/apartment/:containerID/:objectID/:unitID/:apartmentID/:taskID',
    UPDATE: '/edit/task/:taskID',
    UPDATE_OBJECT: '/edit/task/object/:containerID/:objectID/:taskID',
    UPDATE_UNIT: '/edit/task/unit/:containerID/:objectID/:unitID/:taskID',
    UPDATE_APARTMENT: '/edit/task/apartment/:containerID/:objectID/:unitID/:apartmentID/:taskID',
    DELETE: '/delete/task/:taskID',
    DELETE_OBJECT: '/delete/task/object/:containerID/:objectID/:taskID',
    DELETE_UNIT: '/delete/task/unit/:containerID/:objectID/:unitID/:taskID',
    DELETE_APARTMENT: '/delete/task/apartment/:containerID/:objectID/:unitID/:apartmentID/:taskID',
} as const

export const PATHS = {
    DASHBOARD: '/',
    OVERVIEW: '/overview',
    FILE_SETTINGS: '/file-settings/:containerID/:objectID',
    SIGNIN: '/signin',
    SIGNOUT: '/signout',
    SIGNUP: '/signup',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS: '/terms-of-use',
    COMPANY_DETAILS: '/company-details',
    EMAIL_SENT: '/email-sent',
    COMPANY_SETTINGS: '/company-settings',
    USER_SETTINGS: '/user-settings',
    USER_SETTINGS_PERSONAL_INFORMATIONS: '/user-settings/personal-informations',
    USER_SETTINGS_CHANGE_PW: '/user-settings/change-password',
    UPDATE_PASSWORD: '/update-password',
    NEW_PASSWORD: '/new-password',
    RESET_PASSWORD: '/reset-password',
    SUPPORT: '/support',
    RESET_PASSWORD_USER: '/user/reset/:uid/:timestamp/:token',
    RESET_PASSWORD_APPROVAL: '/reset-password-approval',
    RESET_PASSWORD_EMAIL_SENT: '/reset-password-email-sent',
    ACTIVATION_LINK: '/activation-link',
} as const
export interface RouteProps {
    path?: string
    element: ElementType
    children?: Array<RouteProps>
    index?: boolean
    caseSensitive?: boolean
}

export const PUBLIC_PAGE_ROUTES: RouteProps[] = [
    {
        path: PATHS.PRIVACY_POLICY,
        element: PrivacyPolicy,
    },
    {
        path: PATHS.TERMS,
        element: Terms,
    },
    {
        path: PATHS.COMPANY_DETAILS,
        element: CompanyDetails,
    },
]

export const AUTH_ROUTES: Array<RouteProps> = [
    {
        path: PATHS.SIGNIN,
        element: SignIn,
    },
    {
        path: PATHS.SIGNUP,
        element: SignUp,
    },
    {
        path: PATHS.SUPPORT,
        element: Support,
    },
    {
        path: PATHS.EMAIL_SENT,
        element: EmailSent,
    },
    {
        path: PATHS.RESET_PASSWORD,
        element: ResetPassword,
    },
    {
        path: PATHS.RESET_PASSWORD_USER,
        element: ResetPasswordValidation,
    },
    {
        path: PATHS.RESET_PASSWORD_EMAIL_SENT,
        element: ResetPasswordEmailSent,
    },
    {
        path: PATHS.ACTIVATION_LINK,
        element: ActivationLink,
    },
    {
        path: PATHS.NEW_PASSWORD,
        element: NewPassword,
    },
]

export const PRIVATE_ROUTES: Array<RouteProps> = [
    {
        path: PATHS.DASHBOARD,
        element: Dashboard,
        children: [
            {
                path: CONTAINER_PATHS.GENERAL_INFORMATION,
                element: GeneralInfoContainer,
                index: true,
            },
            {
                path: CONTAINER_PATHS.OVERVIEW,
                element: OverviewContainer,
                index: true,
            },
            {
                path: CONTAINER_PATHS.CREATE,
                element: CreateContainer,
                index: true,
            },
            {
                path: CONTAINER_PATHS.UPDATE,
                element: UpdateContainer,
                index: true,
            },
            {
                path: CONTAINER_PATHS.DELETE,
                element: DeleteContainer,
                index: true,
            },
            {
                path: CONTAINER_PATHS.MAINTENANCE,
                element: Maintenance,
                index: true,
            },
            {
                path: OBJECT_PATHS.GENERAL_INFORMATION,
                element: GeneralInfoObject,
                index: true,
            },
            {
                path: OBJECT_PATHS.CREATE,
                element: CreateObject,
                index: true,
            },
            {
                path: OBJECT_PATHS.UPDATE,
                element: UpdateObject,
                index: true,
            },
            {
                path: OBJECT_PATHS.DELETE,
                element: DeleteObject,
                index: true,
            },
            {
                path: OBJECT_PATHS.OVERVIEW,
                element: OverviewObject,
                index: true,
            },
            {
                path: OBJECT_PATHS.FILE,
                element: Files,
                index: true,
            },
            {
                path: OBJECT_PATHS.TASK,
                element: Task,
                index: true,
            },
            {
                path: OBJECT_PATHS.MAINTENANCE,
                element: Maintenance,
                index: true,
            },
            {
                path: UNIT_PATHS.GENERAL_INFORMATION,
                element: GeneralInfoUnit,
                index: true,
            },
            {
                path: UNIT_PATHS.OVERVIEW,
                element: OverviewUnit,
                index: true,
            },
            {
                path: UNIT_PATHS.CREATE,
                element: CreateUnit,
                index: true,
            },
            {
                path: UNIT_PATHS.UPDATE,
                element: UpdateUnit,
                index: true,
            },
            {
                path: UNIT_PATHS.DELETE,
                element: DeleteUnit,
                index: true,
            },
            {
                path: UNIT_PATHS.FILE,
                element: Files,
                index: true,
            },
            {
                path: UNIT_PATHS.TASK,
                element: Task,
                index: true,
            },
            {
                path: UNIT_PATHS.MAINTENANCE,
                element: Maintenance,
                index: true,
            },
            {
                path: APARTMENT_PATHS.GENERAL_INFORMATION,
                element: GeneralInfoApartment,
                index: true,
            },
            {
                path: APARTMENT_PATHS.CREATE,
                element: CreateApartment,
                index: true,
            },
            {
                path: APARTMENT_PATHS.MAINTENANCE,
                element: Maintenance,
                index: true,
            },
            {
                path: APARTMENT_PATHS.TASK,
                element: Task,
                index: true,
            },
            {
                path: APARTMENT_PATHS.UPDATE,
                element: UpdateApartment,
                index: true,
            },
            {
                path: APARTMENT_PATHS.DELETE,
                element: DeleteApartment,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.LIST,
                element: Maintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.LIST_OBJECT,
                element: Maintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.READ_OBJECT,
                element: ReadMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.UPDATE_OBJECT,
                element: UpdateMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.CREATE_OBJECT,
                element: CreateMaitenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.DELETE_OBJECT,
                element: DeleteMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.LIST_UNIT,
                element: Maintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.READ_UNIT,
                element: ReadMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.UPDATE_UNIT,
                element: UpdateMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.UPDATE,
                element: UpdateMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.CREATE_UNIT,
                element: CreateMaitenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.CREATE_UNIT,
                element: CreateMaitenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.DELETE_UNIT,
                element: DeleteMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.LIST_APARTMENT,
                element: Maintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.CREATE_APARTMENT,
                element: CreateMaitenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.UPDATE_APARTMENT,
                element: UpdateMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.READ_APARTMENT,
                element: ReadMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.DELETE_APARTMENT,
                element: DeleteMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.DELETE,
                element: DeleteMaintenance,
                index: true,
            },
            {
                path: MAINTENANCE_PATHS.READ,
                element: ReadMaintenance,
                index: true,
            },
            {
                path: TASK_PATHS.LIST,
                element: Task,
                index: true,
            },
            {
                path: TASK_PATHS.UPDATE,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_PATHS.DELETE,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_PATHS.READ,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_PATHS.LIST_OBJECT,
                element: Task,
                index: true,
            },
            {
                path: TASK_PATHS.READ_OBJECT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_PATHS.CREATE_OBJECT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_PATHS.UPDATE_OBJECT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_PATHS.DELETE_OBJECT,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_PATHS.LIST_UNIT,
                element: Task,
                index: true,
            },
            {
                path: TASK_PATHS.READ_UNIT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_PATHS.CREATE_UNIT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_PATHS.UPDATE_UNIT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_PATHS.DELETE_UNIT,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_PATHS.LIST_APARTMENT,
                element: Task,
                index: true,
            },
            {
                path: TASK_PATHS.READ_APARTMENT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_PATHS.CREATE_APARTMENT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_PATHS.UPDATE_APARTMENT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_PATHS.DELETE_APARTMENT,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.READ,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.UPDATE,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.DELETE,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.CREATE_OBJECT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.CREATE_UNIT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.CREATE_APARTMENT,
                element: CreateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.READ_OBJECT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.READ_UNIT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.READ_APARTMENT,
                element: ReadTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.UPDATE_OBJECT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.UPDATE_UNIT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.UPDATE_APARTMENT,
                element: UpdateTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.DELETE_OBJECT,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.DELETE_UNIT,
                element: DeleteTask,
                index: true,
            },
            {
                path: TASK_IN_MAINTENANCES_PATHS.DELETE_APARTMENT,
                element: DeleteTask,
                index: true,
            },
            {
                path: PATHS.FILE_SETTINGS,
                element: FileSettings,
                index: true,
            },
            {
                path: PATHS.USER_SETTINGS,
                element: UserSettings,
                children: [
                    {
                        path: PATHS.USER_SETTINGS_PERSONAL_INFORMATIONS,
                        element: UserSettingsPersonal,
                        index: true,
                    },
                    {
                        path: PATHS.USER_SETTINGS_CHANGE_PW,
                        element: UserSettingsPassword,
                        index: true,
                    },
                ],
            },
            {
                path: PATHS.COMPANY_SETTINGS,
                element: CompanySettings,
            },
            {
                path: PATHS.OVERVIEW,
                element: Overview,
                children: [
                    {
                        path: CONTAINER_PATHS.OVERVIEW_ALL,
                        element: OverviewAllContainers,
                        index: true,
                    },
                    {
                        path: OBJECT_PATHS.OVERVIEW_ALL,
                        element: OverviewAllObjects,
                        index: true,
                    },
                ],
            },
        ],
    },
]
